import React, { useEffect, useState } from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";

import MagalUserHome from "./MagalUser/MagalUserHome";
import MagalUserTicketsView from "./MagalUser/MagalUserTicketsView";
// import ClientSurveysPage from "./ClientSurveysPage";
// import ClientProfilePage from "./ClientProfilePage";

const MagalHome = () => <MagalUserHome />
const MagalUserTickets = () => <MagalUserTicketsView />
// const ClientSurveys = () => <ClientSurveysPage />
// const ClientProfile = () => <ClientProfilePage />

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const MagalUserHomePage = () => {
    const accordionClasses = useStyles();
    const classes = useStylesPages();
    const location = useLocation()
    const history = useHistory();
    
    const [expanded, setExpanded] = React.useState(false);

    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/usuariomagal/':
                return 0

            case '/usuariomagal/reportes':
                return 0

            // case '/cliente/encuestas':
            //     return 1

            // case '/cliente/perfil':
            //     return 3

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Usuario Empresa"

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="client sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Inicio"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Mis Requerimientos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/tickets`)}
                />
                {/* <Tab
                    label="Mis Encuestas"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/encuestas`)}
                />
                <Tab
                    label="Mi Perfil"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/perfil`)}
                /> */}
            </Tabs>

            <Switch>
                <Route exact path={path} component={MagalHome} />
                <Route path={`${path}/tickets`} component={MagalUserTickets} />
                {/* <Route path={`${path}/encuestas`} component={ClientSurveys} />
                <Route path={`${path}/perfil`} component={ClientProfile} /> */}
            </Switch>
        </div>
    );
};

export default MagalUserHomePage;