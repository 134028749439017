import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStylesButtons from "./styles";

const SecondaryButtons = (props) => {
    const {
        text,
        icon,
        onClickHandler,
        disabled,
        size,
    } = props

    const classes = useStylesButtons()
    const getClassDict = () => {
        if (window.innerWidth < 769) {
            return {
                fontSize: '26px',
                minWidth: '180px',
                minHeight: '76px',
                maxWidth: '180px',
                maxHeight: '76px'
            }
        }
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return {
                fontSize: '26px',
                minWidth: '120px',
                minHeight: '80px',
                maxWidth: '400px',
                maxHeight: '80px'
            }
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
            return {
                fontSize: '18px',
                minWidth: '160px',
                minHeight: '54px',
                maxWidth: '160px',
                maxHeight: '54px'
            }
        }
        return {}
    }

    return (
        <Button
            className={clsx(classes.general, classes.secondary)}
            variant="outlined"
            startIcon={icon}
            onClick={onClickHandler}
            disabled={disabled}
            size={size || 'small'}
            style={getClassDict()}
        >
            { text }
        </Button>
    );
};

SecondaryButtons.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    size: PropTypes.string
};

SecondaryButtons.defaultProps = {
    disabled: false,
    size: 'small'
}

export default SecondaryButtons;