import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
// import snakecaseKeys from "snakecase-keys";
import camelcaseKeys from 'camelcase-keys';
import moment from "moment";

import BaseDialog from "../../../components/Dialogs/BaseDialog";
import BaseTable from "../../../components/Tables/BaseTable";
import MagalUserTicketDetail from "./MagalUserTicketDetail";
import {
  getClientTickets,
} from "../../../api/ticketApi";

const stateLabels = {
  P: 'Pendiente',
  A: 'Activo',
  C: 'Cerrado',
  PA: 'Por aprobar',
  NA: 'Rechazado',
  EI: 'Rechazado',
  S: 'Solucionado.',
  CD: 'Cerrado'
}

const MagalUserTicketsView = () => {
    
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refetchSwitch, setRefetchSwitch] = useState(false)
    const [loading, setLoading] = useState(true)

    const queryParams = {page: page + 1, maxResults}

    // const { tickets, loading, removeTicket } = useTickets(queryParams)
    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const [ticket, setTicket] = useState(null)
    // const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()

    const fetchTicketFromClient = () => {
      getClientTickets(maxResults, page).then((response) => {
          // setRows(response.data.rows)
          setRows(camelcaseKeys(response.data.rows, {deep: true}))
          setTotal(response.data.total)
          setLoading(false)
      })
    }

    useEffect(() => {
      fetchTicketFromClient();
    }, [page, maxResults, refetchSwitch])

    const refetchPreviews = () => {
      setRefetchSwitch(!refetchSwitch)
    }
    
    const refreshTickets = () => {
      getClientTickets(maxResults, page).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
      })
    }

    const setTicketForDialog = (row) => {
      setTicket(row);
      setSelectedTicketId(row.id);
    } 

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'propertyName', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'reportDate', numeric: false, disablePadding: false, label: 'Fecha reporte' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problema' },
        { id: 'stateName', numeric: false, disablePadding: false, label: 'Estado' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              {/* <IconButton color="primary" onClick={e => setSelectedTicketId(row.id)}> */}
              <IconButton color="primary" onClick={e => setTicketForDialog(row)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.propertyName || <i>Sin propiedad</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {moment(row.reportDate).format('DD/MM/YY HH:mm') || <i>Sin fecha</i>}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        const fault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null

        if (fault) {
          content = <>
            <div>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div>
            {leftCount > 0 && <Typography color="secondary" variant="inherit">+{leftCount} PROBLEMA{leftCount !== 1 ? 'S': ''}</Typography>}
          </>

        } else {
          content = <i>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell>
                {row.stateName}
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setTicket(null);
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setTicket(null);
                setSelectedTicketId(null)
            }}
            title="Detalles del requerimiento seleccionado"
            successButtonIcon={<ExitToAppIcon />}
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información reportada."
          >
              {ticket && <MagalUserTicketDetail
                  ticket={ticket}
                />
              }
          </BaseDialog>
        <BaseTable
            title='Mis Requerimientos'
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
        />
        </>
    );
};

MagalUserTicketsView.propTypes = {

};

export default MagalUserTicketsView;
