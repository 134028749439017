import snakecaseKeys from "snakecase-keys";
import axiosAPI from "./axiosApi";
import useData from "./useData";
import useDataList from "./useDataList";

export async function createTicketfromHomePage(data) {
    const response = await axiosAPI.post("tickets/create_by_client", data)

    return response
}

export async function createTicketManagement(data) {
    const response = await axiosAPI.post("tickets/create_management", data)

    return response
}


export async function getTicketPreviews(maxResults, page) {
    const response = await axiosAPI.get(
        `tickets/get_previews?max_results=${maxResults}&page=${page}`)

    return response
}

export async function getTicketsPending(maxResults, page) {
    const response = await axiosAPI.get(
        `tickets/get_pending?max_results=${maxResults}&page=${page}`)

    return response
}

export async function getTicketPreview({id}) {
    const response = await axiosAPI.get(`tickets/preview/${id}/`)
    return response
}

export async function destroyTicketPreviews(data) {
    const response = await axiosAPI.post('tickets/preview/bulk_destroy/', {tickets: data})
    return response
}

export async function updateTicketParams(paramName, paramVal, ticketId) {
    const response = await axiosAPI.post(
        'tickets/update_params/',
        {
            param: paramName,
            value: paramVal,
            ticket: ticketId
        }
    )

    return response
}

export function useTicketPreview({id}){
    const {item , loading } = useData({id, url: 'tickets/preview/'})
    return { ticketPreview: item, loading }
}

export function useTickets(queryParams) {
    const { items: tickets, loading, removeItem } = useDataList({url: "tickets/", queryParams, paginated: true})
    return { tickets, loading, removeTicket: (ticketId) => {
        removeItem(ticketId);
        } }
}

export function useTicket({id}){
    const {item , loading, updateItem } = useData({id, url: 'tickets/'})
    return {
        ticket: item,
        loading,
        addVisit: (data) => {
            const visits = [...item.visits, data];
            visits.sort(function(a,b){
              return new Date(b.suggestedDate) - new Date(a.suggestedDate);
            });
            updateItem({...item, visits});
        }, removeVisit: (visitId) => {
            const visits = item.visits.filter(v => v.id !== visitId);
            updateItem({...item, visits});
        }, updateVisit: (visit) => {
            const visits = [...item.visits];
            const index = visits.findIndex(v => v.id === visit.id);
            visits.splice(index, 1, {...visits[index], ...visit});
            updateItem({...item, visits});
        }
    };
}

export async function ticketCreate({client, property, reportDate, faults, ...data}){
    const jsonData = snakecaseKeys({
        ...data,
        client: client?.id,
        property: property?.property.id,
        faults: faults?.map(f => f.faultType.id),
        reportDate: reportDate?.format()
    }, {deep: true})
    const response = await axiosAPI.post('tickets/', jsonData)
    return response
}

export function useLastTicket({id, propertyId, faultTypeIds}){
    const { item: ticket, loading} = useData({noSpecific: true, url: 'tickets/last_ticket/', queryParams: snakecaseKeys({propertyId, faultTypeIds})} )
    return {ticket, loading}
}

export async function getTicketsPreApproved(maxResults, page) {
    const response = await axiosAPI.get(
        `tickets/get_preapproved?max_results=${maxResults}&page=${page}`)

    return response
}

export async function getClientTickets(maxResults, page) {
    const response = await axiosAPI.get(
        `clients/get_client_tickets?max_results=${maxResults}&page=${page}`)

    return response
}

export async function getTicketsHistory(projectId, propertyId, maxResults, page) {
    const response = await axiosAPI.get(
        `tickets/get_tickets_history?project_id=${projectId}&property_id=${propertyId}&max_results=${maxResults}&page=${page}`)

    return response
}

export function useTicketsPreApproved(queryParams) {
    const { items: tickets, loading, removeItem } = useDataList({url: "tickets/", queryParams, paginated: true})
    return { tickets, loading, removeTicket: (ticketId) => {
        removeItem(ticketId);
        } }
}

export async function approveTicket(data) {

    const response = await axiosAPI.post('tickets/approve/', snakecaseKeys(data, {deep: true}));

    return response
}

export async function rejectTicketLacksInfo(ticketId) {

    const response = await axiosAPI.post(
        'tickets/reject_lacks_information/',
        {
            ticket: ticketId
        }
    )

    return response
}

export async function rejectTicketNotAppliesPV(data) {

    // const response = await axiosAPI.post(
    //     'tickets/not_applies_pv/',
    //     {
    //         ticket: ticketId
    //     }
    // )
    const response = await axiosAPI.post('tickets/not_applies_pv/', snakecaseKeys(data, {deep: true}));

    return response
}

export async function closeTicket(ticketId) {

    const response = await axiosAPI.post(
        'tickets/close/',
        {
            ticket: ticketId
        }
    )

    return response
}

export async function closeTicketNC(ticketId, reason) {

    const response = await axiosAPI.post(
        'tickets/close_non_conformity/',
        {
            ticket: ticketId,
            reason,
        }
    )

    return response
}

export async function reactivateTicket(ticketId, ticketFaultsUpdated, reason) {

    const response = await axiosAPI.post(
        'tickets/reactivate/',
        {
            ticket: ticketId,
            faults: ticketFaultsUpdated,
            reason,
        }
    )

    return response
}

export async function getTicketsClosed(maxResults, page, userType) {
    if (userType === null){
        const response = await axiosAPI.get(
            `tickets/get_closed?max_results=${maxResults}&page=${page}`)
        return response
    }   
    const response = await axiosAPI.get(
        `tickets/get_closed?max_results=${maxResults}&page=${page}&userType=${userType}`)
    return response
}

export async function getTicketsRejected(maxResults, page, userType) {
    if (userType === null){
        const response = await axiosAPI.get(
            `tickets/get_rejected?max_results=${maxResults}&page=${page}`)
        return response
    }   
    const response = await axiosAPI.get(
        `tickets/get_rejected?max_results=${maxResults}&page=${page}&userType=${userType}`)
    return response
}



export async function getTicketForClient(rut, ticketId) {
    const response = await axiosAPI.get(
        `tickets/get_ticket_for_client?rut=${rut}&ticket_id=${ticketId}`)

    return response
}

export async function cancelTicketNextVisit(data) {

    const response = await axiosAPI.post(
        'tickets/cancel_visit/',
        {
            ticket: data.id,
            value: data.actionValue
        }
    )

    return response

}

export async function updateTicketMagalText(data) {

    const response = await axiosAPI.post(
        'tickets/update_magal_text/',
        {
            ticket: data.id,
            magalText: data.magalText
        }
    )

    return response

}


export async function forceTicketState(data) {

    const response = await axiosAPI.post('tickets/force_state/', snakecaseKeys(data, {deep: true}));

    return response
}