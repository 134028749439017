import snakecaseKeys from "snakecase-keys";
import axiosAPI from "./axiosApi";
import useDataList from "./useDataList";

export async function getUserData() {
    const response = await axiosAPI.get("users/get_info")

    return response.data
}

export async function useSpace() {
    return true
}

export function useUsers(queryParams){
    const { items: users, loading, removeItem} = useDataList({url: 'users/', queryParams, paginated: true})
    return {users, loading, removeUser: (userId) => {
        removeItem(userId);
        } }
}

export async function getUser({userId}) {
    const response = await axiosAPI.get(`users/info?userId=${userId}`)

    return response.data
}

export async function createUser(data) {
    const response = await axiosAPI.post(`users/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function updateUserAdmin(data) {
    const response = await axiosAPI.put(`users/${data.id}/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function updateUserClient(data) {
    const response = await axiosAPI.post(`users/update_client/`, snakecaseKeys(data, {deep: true}))
    return response
}


export async function updateUser(data) {
    const response = await axiosAPI.put(`usersupdate/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function deleteUser(id) {
    const response = await axiosAPI.post(`users/${id}/set_not_active/`);
    return response;
}

export async function getUserInformation(data) {
    const response = await axiosAPI.get("users/get_client_info")

    return response.data
}

export async function getUserInfo(data) {
    const response = await axiosAPI.get("users/get_info_by_username")

    return response.data
}


export async function updateUserPassword(data) {
    const response = await axiosAPI.put(`usersupdatepw/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function getEngineers() {
    const response = await axiosAPI.get("users/get_engineers")

    return response.data
}

export async function createTeam(data) {
    const response = await axiosAPI.post(`team/`, snakecaseKeys(data, {deep: true}))
    return response
}

// export async function deleteTeam(id) {
//     const response = await axiosAPI.delete(`team/${id}/`);
//     return response;
// }

export async function deleteTeam(data) {
    const response = await axiosAPI.post(`team/change_status/`, snakecaseKeys(data, {deep: true}))

    return response.data
}

export async function updateTeam(data) {
    const response = await axiosAPI.put(`team/${data.id}/`, snakecaseKeys(data, {deep: true}))
    return response;
}

export async function getAvailableMembers(data) {
    const response = await axiosAPI.post(`team/get_available_members/`, snakecaseKeys(data, {deep: true}))

    return response.data
}

export async function addNewMemberToTeam(data) {
    const response = await axiosAPI.post(`team/add_new_member/`, snakecaseKeys(data, {deep: true}))

    return response
}

export async function deleteTeamMember(data) {
    const response = await axiosAPI.post(`team/delete_member/`, snakecaseKeys(data, {deep: true}))

    return response
}

export async function filterUsers(data) {
    const response = await axiosAPI.post(`users/filter_users/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function registerClient(data) {
    const response = await axiosAPI.post(`clients/create_client`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function registerNewClient(data) {
    const response = await axiosAPI.post(`clients/create_new_client`, snakecaseKeys(data, {deep: true}))
    return response
}


export async function updateUserClientData(data) {
    const response = await axiosAPI.put(`usersupdateclient/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function getClients(maxResults, page) {
    const response = await axiosAPI.get(
        `users/get_clients?max_results=${maxResults}&page=${page}`)

    return response
}

export async function validateClientUser(data) {
    const response = await axiosAPI.post(`users/validate_client/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function rejectClientUser(data) {
    const response = await axiosAPI.post(`users/reject_client/`, snakecaseKeys(data, {deep: true}))
    return response
}


export async function getUsers(page, maxResults, userQuery) { 
    const response = await axiosAPI.get(
        `users/get_list?max_results=${maxResults}&page=${page}&userQuery=${userQuery}`)
    return response
}

export async function getClientUsers(page, maxResults, userQuery) { 
    const response = await axiosAPI.get(
        `users/get_client_list?max_results=${maxResults}&page=${page}&userQuery=${userQuery}`)
    return response
}

export async function getClientSurveys(page, maxResults, userQuery) { 
    const response = await axiosAPI.get(
        `clients/get_surveys?max_results=${maxResults}&page=${page}&userQuery=${userQuery}`)
    return response
}

