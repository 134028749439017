import React, {Component} from 'react';
import Grid from "@material-ui/core/Grid";
import UsernameInput from "../Inputs/UsernameInput";
import {
    isValidPassword,
    isValidUsername,
    passwordMinimalConditions,
    usernameMinimalConditions
} from "../../utils/loginValidations";
import PasswordInput from "../Inputs/PasswordInput";

class ClientLoginForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            usernameError: false,
            usernameHelperText: '',
            password: '',
            passwordError: false,
            passwordHelperText: ''
        }
    }

    render() {
        const {
            username,
            usernameError,
            usernameHelperText,
            password,
            passwordError,
            passwordHelperText
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    container
                    xs={12}
                >
                    <UsernameInput
                        value={username}
                        onChange={(event) => {
                            const { value } = event.target
                            if(usernameMinimalConditions(value)) {
                                if(isValidUsername(value)) {
                                    const re = /^[K0-9\b]+$/;
                                    if (value === '' || re.test(value)) {
                                        this.setState({
                                            username: value,
                                            usernameError: false,
                                            usernameHelperText: ''
                                        })
                                     }
                                } else {
                                    this.setState({
                                        username: value,
                                        usernameError: true,
                                        usernameHelperText: 'Rut debe contener al menos 8 caracteres'
                                    })
                                }
                            } else {
                                const re = /^[K0-9\b]+$/;
                                if (value === '' || re.test(value)) {
                                    this.setState({
                                        username: value
                                    })
                                 }
                            }
                        }}
                        id='username-input'
                        label="Rut"
                        placeholder="Ingresa tu rut sin puntos ni guión"
                        error={usernameError}
                        helperText={usernameHelperText}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <PasswordInput
                        value={password}
                        onChange={(event) => {
                            const { value } = event.target
                            if(passwordMinimalConditions(value)) {
                                if(isValidPassword(value)) {
                                    this.setState({
                                        password: value,
                                        passwordError: false,
                                        passwordHelperText: ''
                                    })
                                } else {
                                    this.setState({
                                        password: value,
                                        passwordError: true,
                                        passwordHelperText: 'Contraseña invalida'
                                    })
                                }
                            } else {
                                this.setState({
                                    password: value
                                })
                            }
                        }}
                        id='password-input'
                        label="Contraseña"
                        placeholder="Ingresa tu contraseña"
                        error={passwordError}
                        helperText={passwordHelperText}
                    />
                </Grid>
                
            </Grid>
        );
    }
};

export default ClientLoginForm;