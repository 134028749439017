import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import {
    useHistory,
    useLocation
} from "react-router-dom"
import Icon from '@material-ui/core/Icon'
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Drawer from "@material-ui/core/Drawer";
import AssignmentTwoToneIcon from '@material-ui/icons/AssignmentTwoTone';
import InsertInvitationTwoToneIcon from '@material-ui/icons/InsertInvitationTwoTone';
import TimelineTwoToneIcon from '@material-ui/icons/TimelineTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import BuildTwoToneIcon from '@material-ui/icons/BuildTwoTone';
import PostAddIcon from '@material-ui/icons/PostAdd';
import FaceTwoToneIcon from '@material-ui/icons/FaceTwoTone';
import { BarChart, Equalizer, ListAlt } from '@material-ui/icons';
// import DataUsageIcon from '@material-ui/icons/DataUsage';
import ApartmentIcon from '@material-ui/icons/Apartment';
import {useTheme} from "@material-ui/core/styles";
import useStylesDrawer from "./styles";

const MainDrawer = (props) => {
    const {
        open,
        handleClose,
        showNav
    } = props

    const classes = useStylesDrawer()
    const theme = useTheme();
    const history = useHistory()
    const location = useLocation()

    const getIconSize = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return 'large'
        }
        return 'default'
    }

    const options = [
        {
            icon: <AssignmentTwoToneIcon fontSize={getIconSize()} />,
            label: 'Requerimientos',
            url: '/tickets',
            permissions: ['A', 'ING', 'EPV', 'SUP'] // Admin, Engineer and EPV
        },
        {
            icon: <InsertInvitationTwoToneIcon fontSize={getIconSize()} />,
            label: 'Visitas',
            url: '/visitas',
            permissions: ['A', 'ING', 'EPV', 'SUP'] // Admin, Engineer and EPV
        },
        // {
        //     icon: <BuildTwoToneIcon fontSize={getIconSize()} />,
        //     label: 'Equipo Técnico',
        //     url: '/equipo_tecnico',
        //     permissions: ['A','ET','ING','SUP'] // Technician
        // },
        {
            icon: <Icon style={{textAlign: 'center'}}> 
                    <img style={{height: '100%'}} alt='eticon' src='/src/images/iconET2.svg' /> 
                </Icon>,
            label: 'Equipo Técnico',
            url: '/equipo_tecnico',
            permissions: ['A','ET','ING','SUP'] // Technician
        },
        {
            icon: <PostAddIcon fontSize={getIconSize()} />,
            label: 'Enviar Requerimiento',
            url: '/enviar_requerimiento',
            permissions: ['A','EPV','ING'] // TBD
        },
        {
            icon: <ListAlt />,
            label: 'Mis Requerimientos',
            url: '/usuariomagal',
            permissions: ['A','ING','SUP'] //
        },
        {
            icon: <Equalizer fontSize={getIconSize()} />,
            label: 'Estadísticas',
            url: '/estadisticas',
            permissions: ['A', 'ING', 'EPV', 'SUP'] // Admin, Engineer and EPV
        },
        {
            icon: <FaceTwoToneIcon fontSize={getIconSize()} />,
            label: 'Usuarios',
            url: '/administracion',
            permissions: ['A'] // Admin, Engineer
        },
        {
            icon: <ApartmentIcon />,
            label: 'Administración',
            url: '/datos',
            permissions: ['A'] //
        },
        {
            icon: <FaceTwoToneIcon />,
            label: 'Cliente',
            url: '/cliente',
            permissions: ['C'] // Client
        },
        
    ]

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open,
                [classes.hide]: !showNav,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                    [classes.hide]: !showNav,
                }),
            }}
        >
            <div className={classes.toolbar}>
                <Typography
                    variant="h5"
                >
                    Magal postventa
                </Typography>
                <IconButton onClick={handleClose}>
                    {
                        theme.direction === 'rtl' ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                                )
                    }
                </IconButton>
            </div>
            <Divider />
            <List>
                {
                    options.map((option) => {
                        const userType = localStorage.getItem('userType')

                        if(option.permissions.includes(userType)) {
                            return(
                                <ListItem
                                    key={option.label}
                                    button
                                    onClick={() => {
                                        history.push(option.url)
                                    }}
                                    style={location.pathname.split('/')[1] === option.url.split('/')[1] ? {
                                        borderRight: '#ba000d 3px solid'
                                    } : {}}
                                >
                                    <ListItemIcon>
                                        { option.icon }
                                    </ListItemIcon>
                                    <ListItemText primary={ option.label } />
                                </ListItem>
                            )
                        }

                        return null
                    })
                }
            </List>
        </Drawer>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    showNav: PropTypes.bool.isRequired
};

export default MainDrawer;