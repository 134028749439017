
export const usernameMinimalConditions = (value) => {
    return value.length > 7
}

export const isValidUsername = (value) => {
    return value.length > 7
}

export const passwordMinimalConditions = (value) => {
    return value.length > 7
}

export const isValidPassword = (value) => {
    return value.length > 7
}