import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import {Checkbox, FormControlLabel} from "@material-ui/core";
// import SelectAutocompleteInput from "../../Inputs/SelectAutocompleteInput";
// import {
//     getClientPropertyRelations,
//     getClientsByRelationForProperty
// } from "../../../api/clientApi";
import FullNameInput from "../../Inputs/FullNameInput";
import RunInput from "../../Inputs/RUNInput";
import PhoneInput from "../../Inputs/PhoneInput";
import EmailInput from "../../Inputs/EmailInput";
import {
    // setReportClient,
    // setReportClients,
    // setReportRelation,
    // setReportRelations,
    setReportName,
    setReportRun,
    setReportEmail,
    setReportPhone,
    setIsOwner,
    setOwnerName,
    setOwnerRun,
    setOwnerEmail,
    setOwnerPhone,
} from "../../../actions/reportActions";


class ReportStepTwo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // relations: props.relations,
            // relation: props.relation,
            // clients: props.clients,
            // client: props.client,
            
            reporterName: props.reporterName,
            reporterRun: props.reporterRun,
            reporterEmail: props.reporterEmail,
            reporterPhone: props.reporterPhone,
            isOwner: props.isOwner,
            ownerName: props.ownerName,
            ownerRun: props.ownerRun,
            ownerEmail: props.ownerEmail,
            ownerPhone: props.ownerPhone,
            emailError: props.emailError,
            emailErrorMessage: props.emailErrorMessage,
            rutErr: props.rutErr,
            rutErrMsg: props.rutErrMsg,
            emailErrProp: props.emailErrorProp,
            emailErrMsgProp: props.emailErrMsgProp,
            rutErrProp: props.rutErrProp,
            rutErrMsgProp: props.rutErrMsgProp
        }
    }

    componentDidMount() {
        const {
            successDisabledControl,
            // relationCode,
            propertyId,
            propertyType,
            // setReportRelations, // eslint-disable-line no-shadow
            // setReportRelation, // eslint-disable-line no-shadow
            setReportName, // eslint-disable-line no-shadow
            setReportRun, // eslint-disable-line no-shadow
            setReportPhone, // eslint-disable-line no-shadow
            setReportEmail, // eslint-disable-line no-shadow
            setIsOwner, // eslint-disable-line no-shadow
            setOwnerName, // eslint-disable-line no-shadow
            setOwnerRun, // eslint-disable-line no-shadow
            setOwnerPhone, // eslint-disable-line no-shadow
            setOwnerEmail, // eslint-disable-line no-shadow
            setEmailError, // eslint-disable-line no-shadow
        } = this.props
        const { 
            // relations,
            // relation, 
            // client, 
            reporterName,
            reporterRun,
            reporterEmail,
            reporterPhone,
            isOwner,
            ownerName,
            ownerRun,
            ownerEmail,
            ownerPhone,
            emailError,
            emailErrorMessage,
            rutErr,
            rutErrMsg,
            emailErrProp,
            emailErrMsgProp,
            rutErrProp,
            rutErrMsgProp
        } = this.state
    }

    updateStatus() {
        const { successDisabledControl } = this.props
        const { 
            reporterName,
            reporterRun,
            reporterEmail,
            reporterPhone,
            isOwner,
            ownerName,
            ownerRun,
            ownerEmail,
            ownerPhone,
            emailError,
            emailErrorMessage,
            rutErr,
            rutErrMsg,
            emailErrProp,
            emailErrMsgProp,
            rutErrProp,
            rutErrMsgProp
        } = this.state

        // Validate Inputs
        if (reporterName !== undefined && reporterName !== ''
            && reporterRun !== undefined && reporterRun !== '' 
            && reporterEmail !== undefined && reporterEmail !== '' 
            && reporterPhone !== undefined && reporterPhone !== '') {

            // eslint-disable-next-line
            const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
            // eslint-disable-next-line
            if (!/^([1-9](\d{6,8})-([\dkK]))$/.test(reporterRun.toString())){
                this.setState({rutErr: true})
                this.setState({rutErrMsg: 'Ingrese un rut válido'})
                successDisabledControl(true)
            } else if (!mailRegex.test(reporterEmail)) {
                this.setState({rutErrMsg: ''})
                this.setState({rutErr: false})
                this.setState({emailError: true})
                this.setState({emailErrorMessage: 'Ingrese un email válido'})
            } else{
                this.setState({emailErrorMessage: ''})
                this.setState({emailError: false})
                this.setState({rutErrMsg: ''})
                this.setState({rutErr: false})
                if (isOwner === true){
                    if (ownerName !== undefined && ownerName !== '' 
                        && ownerRun !== undefined && ownerRun !== '' 
                        && ownerEmail !== undefined && ownerEmail !== '' 
                        && ownerPhone !== undefined && ownerPhone !== '') {
                            // eslint-disable-next-line
                            if (!/^([1-9](\d{6,8})-([\dkK]))$/.test(ownerRun)){
                                this.setState({rutErrProp: true})
                                this.setState({rutErrMsgProp: 'Ingrese un rut válido'})
                            } else if (!mailRegex.test(ownerEmail)) {
                                this.setState({rutErrMsgProp: ''})
                                this.setState({rutErrProp: false})
                                this.setState({emailErrProp: true})
                                this.setState({emailErrMsgProp: 'Ingrese un email válido'})
                            } else {
                                this.setState({emailErrMsgProp: ''})
                                this.setState({emailErrProp: false})
                                this.setState({rutErrMsgProp: ''})
                                this.setState({rutErrProp: false})
                                successDisabledControl(false)
                            }
                    } else {
                        successDisabledControl(true)
                    }
                } else if (isOwner === undefined || isOwner === false){
                    successDisabledControl(false)
                } else {
                    successDisabledControl(false)
                }
            }
            
        } else {
            successDisabledControl(true)
        }
    }

    // getClientForProperty(propertyId, relationCode) {
    //     const { clients } = this.state
    //     const { setReportClients } = this.props // eslint-disable-line no-shadow

    //     getClientsByRelationForProperty(relationCode, propertyId).then((response) => {
    //         if (clients !== response.data) {
    //             this.setState({clients: response.data})
    //             setReportClients(response.data)
    //         }
    //     })
    // }

    render() {
        const {
            successDisabledControl,
            propertyId,
            controlStep,
            // setReportRelation, // eslint-disable-line no-shadow
            // setReportClient, // eslint-disable-line no-shadow
            setReportName, // eslint-disable-line no-shadow
            setReportRun, // eslint-disable-line no-shadow
            setReportEmail, // eslint-disable-line no-shadow
            setReportPhone, // eslint-disable-line no-shadow
            setIsOwner, // eslint-disable-line no-shadow
            setOwnerName, // eslint-disable-line no-shadow
            setOwnerRun, // eslint-disable-line no-shadow
            setOwnerEmail, // eslint-disable-line no-shadow
            setOwnerPhone, // eslint-disable-line no-shadow
            setEmailError, // eslint-disable-line no-shadow
        } = this.props

        const {
            // relations,
            // relation,
            // clients,
            // client,
            reporterName,
            reporterRun,
            reporterEmail,
            reporterPhone,
            isOwner,
            ownerName,
            ownerRun,
            ownerEmail,
            ownerPhone,
            emailError,
            emailErrorMessage,
            rutErr,
            rutErrMsg,
            emailErrProp,
            emailErrMsgProp,
            rutErrProp,
            rutErrMsgProp,
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black',
                        textAlign: 'center'
                    }}
                >
                    Ingresa tus datos de contacto y<br />
                    los del propietario si corresponde.
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <FullNameInput
                        value={reporterName}
                        onChange={(event) => {
                            setReportName(event.target.value)
                            this.setState({
                                reporterName: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="reporter-name"
                        error={false}
                        placeholder="Nombre y apellidos"
                        label="Ingrese su nombre"
                        helperText=''
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <RunInput
                        value={reporterRun}
                        onChange={(event) => {
                            setReportRun(event.target.value)
                            this.setState({
                                reporterRun: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="reporter-run"
                        placeholder="12345678-9"
                        label="Ingrese su RUT"
                        error={rutErr}
                        helperText={rutErrMsg}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <PhoneInput
                        value={reporterPhone}
                        onChange={(event) => {
                            setReportPhone(event.target.value)
                            this.setState({
                                reporterPhone: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="reporter-phone"
                        placeholder="+56 9 1234 5678"
                        label="Ingrese su teléfono"
                        error={false}
                        helperText=''
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <EmailInput
                        value={reporterEmail}
                        onChange={(event) => {
                            setReportEmail(event.target.value)
                            this.setState({
                                reporterEmail: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="reporter-email"
                        placeholder="ejemplo@ejemplo.cl"
                        label="Ingrese su correo"
                        error={emailError}
                        helperText={emailErrorMessage}
                    />
                </Grid>
                <FormControlLabel
                    style={{
                        marginLeft: '2%'
                    }}
                    control={<Checkbox 
                        checked={isOwner} 
                        onChange={(event) => {
                            setIsOwner(event.target.checked)
                            this.setState({
                                isOwner: event.target.checked
                            }, () => this.updateStatus())
                        }}
                        name='is-owner' />}
                    label='Marca esta casilla si no eres propietario'
                />
                { isOwner ? <><Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black',
                        textAlign: 'center'
                    }}
                >
                    Ingresa los datos de contacto<br/>
                    del propietario.
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <FullNameInput
                        value={ownerName}
                        onChange={(event) => {
                            setOwnerName(event.target.value)
                            this.setState({
                                ownerName: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="owner-name"
                        error={false}
                        placeholder="Nombre y apellidos del dueño"
                        label="Ingrese nombre del dueño"
                        helperText=''
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <RunInput
                        value={ownerRun}
                        onChange={(event) => {
                            setOwnerRun(event.target.value)
                            this.setState({
                                ownerRun: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="owner-run"
                        placeholder="12345678-9"
                        label="Ingrese RUT del dueño"
                        error={rutErrProp}
                        helperText={rutErrMsgProp}
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <PhoneInput
                        value={ownerPhone}
                        onChange={(event) => {
                            setOwnerPhone(event.target.value)
                            this.setState({
                                ownerPhone: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="owner-phone"
                        placeholder="+56 9 1234 5678"
                        label="Ingrese teléfono del dueño"
                        error={false}
                        helperText=''
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <EmailInput
                        value={ownerEmail}
                        onChange={(event) => {
                            setOwnerEmail(event.target.value)
                            this.setState({
                                ownerEmail: event.target.value
                            }, () => this.updateStatus())
                        }}
                        id="owner-email"
                        placeholder="ejemplo@ejemplo.cl"
                        label="Ingrese correo del dueño"
                        error={emailErrProp}
                        helperText={emailErrMsgProp}
                    />
                </Grid></> : null }
                
            </Grid>
        );
    }
}

ReportStepTwo.propTypes = {
    successDisabledControl: PropTypes.func.isRequired,
    propertyId: PropTypes.number.isRequired,
    controlStep: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    // relations: state.report.partTwo.relations,
    // relation: state.report.partTwo.relation,
    // clients: state.report.partTwo.clients,
    // client: state.report.partTwo.client,
    reporterName: state.report.partTwo.reporterName,
    reporterRun: state.report.partTwo.reporterRun,
    reporterPhone: state.report.partTwo.reporterPhone,
    reporterEmail: state.report.partTwo.reporterEmail,
    isOwner: state.report.partTwo.isOwner,
    ownerName: state.report.partTwo.ownerName,
    ownerRun: state.report.partTwo.ownerRun,
    ownerPhone: state.report.partTwo.ownerPhone,
    ownerEmail: state.report.partTwo.ownerEmail,
})

const mapDispatchToProps = {
    // setReportRelations,
    // setReportRelation,
    // setReportClients,
    // setReportClient,
    setReportName,
    setReportRun,
    setReportPhone,
    setReportEmail,
    setIsOwner,
    setOwnerName,
    setOwnerRun,
    setOwnerPhone,
    setOwnerEmail,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportStepTwo);