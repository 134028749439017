import React from 'react';
import { useHistory,Route,Switch,useRouteMatch,useLocation } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";
import StatisticsTable from "./StatisticsTable";

const StatisticsBaseTable = () => <StatisticsTable />

const StatisticsHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const location = useLocation();
    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/estadisticas/':
                return 0

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Estadisticas"

    const userType = localStorage.getItem('userType')

    if (!isAuth || userType !== 'A') {
        history.push('/')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="statistics sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Estadísticas"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(url)}
                />
            </Tabs>

            <Switch>
                <Route exact path={path} component={StatisticsBaseTable} />
                {/* <Route path={`${path}/agregar`} component={UserCreate} /> */}
            </Switch>
        </div>
    );
};

export default StatisticsHomePage;