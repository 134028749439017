import React, {useCallback, useState} from "react";
import {useSnackbar} from "notistack";
import PropTypes from 'prop-types';
import {useCalendars, useSchedules} from "../../../api/visitApi";
import CalendarNav from "../../../components/Calendar/CalendarNav";
import CustomCalendar from "../../../components/Calendar/CustomCalendar";



export default function VisitCalendar({visit, defaultViews, onSchedule }){

    const { calendars, loading: loadingCalendars } = useCalendars();
    const { schedules } = useSchedules({});
    const [ selectedView, setSelectedView] = useState('month');
    const [ calendar, setCalendar] = useState(null);

    const calendarRef = useCallback(node => {
        if (node !== null) {
            setCalendar(node.getInstance());
        }
    }, []);

    return <>
        <CalendarNav
            calendar={calendar}
            creationDefaultTitle={visit?.propertyName || ''}
            creationDefaultLocation={visit?.propertyAddress || ''}
            view={selectedView}
            views={defaultViews}
        />
        <CustomCalendar
            ref={calendarRef}
            height="700px"
            calendars={calendars}
            isReadOnly={false}
            schedules={schedules}
            useDetailPopup={false}
            useCreationPopup
            view={selectedView}
            onBeforeCreateSchedule={onSchedule}
        />
    </>
}

VisitCalendar.propTypes = {
    defaultViews: PropTypes.array,
}

VisitCalendar.defaultProps = {
    defaultViews: ['day', 'week', 'month'],
}
