import {
    REPORT_CLIENT,
    REPORT_CLIENTS, REPORT_COMMENT, REPORT_FAULTNUMBER, REPORT_FAULTS, REPORT_IMAGES,
    REPORT_NAME,
    REPORT_RUN,
    REPORT_EMAIL,
    REPORT_PHONE,
    REPORT_ISOWNER,
    REPORT_OWNERNAME,
    REPORT_OWNERRUN,
    REPORT_OWNEREMAIL,
    REPORT_OWNERPHONE,
    REPORT_CLIENTINFO,
    REPORT_PROJECT,
    REPORT_PROJECTS,
    REPORT_PROPERTIES,
    REPORT_PROPERTY,
    REPORT_PROPERTY_TYPE,
    REPORT_PROPERTY_TYPES,
    REPORT_RELATION,
    REPORT_RELATIONS,
    REPORT_STAGE,
    REPORT_STAGES
} from "./constants";


export function setReportProjects(projects) {
    return {
        type: REPORT_PROJECTS,
        projects
    }
}

export function setReportProject(project) {
    return {
        type: REPORT_PROJECT,
        project
    }
}

export function setReportStages(stages) {
    return {
        type: REPORT_STAGES,
        stages
    }
}

export function setReportStage(stage) {
    return {
        type: REPORT_STAGE,
        stage
    }
}

export function setReportPropertyTypes(propertyTypes) {
    return {
        type: REPORT_PROPERTY_TYPES,
        propertyTypes
    }
}

export function setReportPropertyType(propertyType) {
    return {
        type: REPORT_PROPERTY_TYPE,
        propertyType
    }
}

export function setReportProperties(properties) {
    return {
        type: REPORT_PROPERTIES,
        properties
    }
}

export function setReportProperty(property) {
    return {
        type: REPORT_PROPERTY,
        property
    }
}

export function setReportRelations(relations) {
    return {
        type: REPORT_RELATIONS,
        relations
    }
}

export function setReportRelation(relation) {
    return {
        type: REPORT_RELATION,
        relation
    }
}

export function setReportClients(clients) {
    return {
        type: REPORT_CLIENTS,
        clients
    }
}

export function setReportClient(client) {
    return {
        type: REPORT_CLIENT,
        client
    }
}

export function setReportFaults(faults) {
    return {
        type: REPORT_FAULTS,
        faults
    }
}

export function setResportFaultNumber(faultNumber) {
    return {
        type: REPORT_FAULTNUMBER,
        faultNumber
    }
}

export function setReportImages(images) {
    return {
        type: REPORT_IMAGES,
        images
    }
}

export function setReportComment(comment) {
    return {
        type: REPORT_COMMENT,
        comment
    }
}

export function setReportName(name) {
    return {
        type: REPORT_NAME,
        name
    }
}

export function setReportRun(run) {
    return {
        type: REPORT_RUN,
        run
    }
}

export function setReportEmail(email) {
    return {
        type: REPORT_EMAIL,
        email
    }
}

export function setReportPhone(phone) {
    return {
        type: REPORT_PHONE,
        phone
    }
}

export function setIsOwner(isowner) {
    return {
        type: REPORT_ISOWNER,
        isowner
    }
}

export function setOwnerName(ownername) {
    return {
        type: REPORT_OWNERNAME,
        ownername
    }
}

export function setOwnerRun(ownerrun) {
    return {
        type: REPORT_OWNERRUN,
        ownerrun
    }
}

export function setOwnerEmail(owneremail) {
    return {
        type: REPORT_OWNEREMAIL,
        owneremail
    }
}

export function setOwnerPhone(ownerphone) {
    return {
        type: REPORT_OWNERPHONE,
        ownerphone
    }
}

export function setClientInfo(clientInfo) {
    return {
        type: REPORT_CLIENTINFO,
        clientInfo
    }
}
