import axiosAPI from "./axiosApi";
import useDataList from "./useDataList";
import useData from "./useData";

export async function getFaultZones(propetyType) {
    const response = await axiosAPI.get(`faults/get_fault_zones/${propetyType}`)

    return response
}

export async function getFaultTargets(faultZoneId) {
    const response = await axiosAPI.get(`faults/get_fault_targets/${faultZoneId}`)

    return response
}

export async function getFaultTypes(faultSurfaceId) {
    const response = await axiosAPI.get(`faults/get_fault_types/${faultSurfaceId}`)

    return response
}

export async function checkAndAddFault(zoneId, surfaceId, typeId) {
    const response = await axiosAPI.get(`faults/get_fault/${zoneId}/${surfaceId}/${typeId}`)

    return response
}

export function useMacrozones() {
    const { items: macrozones, loading, ...rest } = useDataList({url: 'fault/macrozone/'})
    return { macrozones, loading }
}

export function useMacrozone({id}) {
    const { item: macrozone, loading, ...rest } = useData({url: 'fault/macrozone/', id})
    return { macrozone, loading }
}