import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {
    sendMailToResetPassword,
} from "../../api/authenticationApi";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import EmailInput from "../../components/Inputs/EmailInput"

const PasswordReset = () => {
    const [mail, setMail] = useState('')
    const [confirmed, setConfirmed] = useState(false)

    const sendMailToReset = () => {
        sendMailToResetPassword(mail).then((response) => {
            setConfirmed(true)
        })
    }

    document.title = "Magal PSEE | Resetear Contraseña "


    return (
        <Box 
            textAlign="center" 
            direction="column"
            alignItems="center"
            mt={3}
            xs={6}
            style={{ paddingTop: '10%'}}
        >  
            <Paper
                style={{
                    padding: 15,
                    justifyContent: 'center'
                }}
                elevation={3}
                xs={12}
                
            >
                <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">Perdiste tu contraseña?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography 
                        variant="h6" 
                        color="textPrimary"
                        style={{fontSize: '15px'}}
                    >
                        Ingresa tu mail para solicitar un reseteo de contraseña.
                    </Typography>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        item
                        container
                        xs={6}                        
                    >
                        <EmailInput
                            value={mail}
                            onChange={(event) => {
                                setMail(event.target.value)
                            }}
                            id='email-input'
                            label="Email"
                            placeholder="Ingresa tu email"
                        />
                    </Grid>
                </Grid>
            
                <Grid 
                    item
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center',
                        paddingTop: '1%',
                    }} md="auto"
                >
                    <SuccessButtons
                    icon={null}
                    onClickHandler={() => {
                        sendMailToReset()
                    }}
                    text="Resetear Contraseña"
                    disabled={mail === ''}
                    />
                </Grid>
                {(confirmed) ? 
                    <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">
                        Si existe un usuario registrado con el correo, se le enviarán
                    </Typography>
                    <Typography variant="h6" color="textPrimary">
                        las instrucciones para resetear su contraseña.
                    </Typography>
                    </Grid> : <></>
                }
            </Paper>
        </Box> 
    );
};

export default PasswordReset;