import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import DangerButtons from "../../components/Buttons/DangerButtons";
import FullNameInput from "../../components/Inputs/FullNameInput";
import EmailInput from "../../components/Inputs/EmailInput";
import 'react-lightbox-component/build/css/index.css';
import {
    sendMailToResetPassword,
} from "../../api/authenticationApi";

const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
    CA: 'Consulta'
  }

export default function UserDetail({user, handleDeleteUser, handleUpdateUser, onClose}){

  const [nUserFirstName, setNUserFirstName] = useState(user.first_name)
  const [nUserLastName, setNUserLastName] = useState(user.last_name)
  const [nUserUsername, setNUserUsername] = useState(user.username)
  const [nUserEmail, setNUserEmail] = useState(user.email)
  const [nUserTypeCode, setNUserTypeCode] = useState(user.type_code)

  const { enqueueSnackbar } = useSnackbar()

  const deleteUser = () => {
    handleDeleteUser({
        id: user.id
    });
    onClose();
  };

  const updateUser = () => {
    handleUpdateUser({
        id: user.id,
        firstName: nUserFirstName,
        lastName: nUserLastName,
        username: nUserUsername,
        email: nUserEmail,
        typeCode: nUserTypeCode,
    });
    onClose();
  };

  const forceResetPassword = () => {
    sendMailToResetPassword(user.email).then((response) => {
        if (response.data.error){
            enqueueSnackbar(
                'Ha ocurrido un error al resetear la contraseña.',
                {
                    variant: 'success'
                }
            );
        } else {
            enqueueSnackbar(
                'Se ha enviado un correo al usuario para resetear la contraseña.',
                {
                    variant: 'success'
                }
            );
        }
    })
  }

  return <>
      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información de Usuario</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre</Typography>
                        <Grid
                            item
                            xs={6}
                            md={5}
                            lg={4}
                        >
                            <FullNameInput
                                value={nUserFirstName}
                                onChange={(event) => {
                                    setNUserFirstName(event.target.value);
                                }}
                                id="user-firstname"
                                error={false}
                                placeholder="Nombre"
                                label=""
                                helperText=''
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Apellido</Typography>
                        <Grid
                            item
                            xs={6}
                            md={5}
                            lg={4}
                        >
                            <FullNameInput
                                value={nUserLastName}
                                onChange={(event) => {
                                    setNUserLastName(event.target.value);
                                }}
                                id="user-lastname"
                                error={false}
                                placeholder="Apellido"
                                label=""
                                helperText=''
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre de usuario</Typography>
                        <Grid
                            item
                            xs={6}
                            md={5}
                            lg={4}
                        >
                            <Typography 
                                variant="body1" 
                                color="textPrimary"
                                style={{fontSize: '16px'}}
                            >
                                {nUserUsername}
                            </Typography>                            
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Email</Typography>
                        <Grid
                            item
                            xs={6}
                            md={5}
                            lg={4}
                        >
                            <EmailInput
                                value={nUserEmail}
                                onChange={(event) => {
                                    setNUserEmail(event.target.value);
                                }}
                                id="user-email"
                                error={false}
                                placeholder="Email"
                                label=""
                                helperText=''
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Tipo de Usuario</Typography>
                        <Typography variant="body1" color="textPrimary">
                            <FormControl variant="outlined" >
                                {/* <InputLabel htmlFor="outlined-age-native-simple">Tipo de Usuario</InputLabel> */}
                                <Select
                                    native
                                    value={nUserTypeCode}
                                    onChange={(event) => {
                                        setNUserTypeCode(event.target.value);
                                    }}
                                    label=""
                                    inputProps={{
                                        name: 'userCode',
                                        id: 'user-typecode',
                                    }}
                                >
                                <option aria-label="None" value="" selected={nUserTypeCode}/>
                                {
                                    ['A', 'EPV' , 'SUP', 'ET', 'ING', 'C', 'CA'].map(userType => (
                                        <option value={userType} label={userTypes[userType]}  />
                                    ))
                                }
                                </Select>
                            </FormControl>
                        
                        </Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Grid 
            container 
            xs={12}
            alignItems="right" 
            justifyContent="flex-end"
            style={{
                paddingTop: '1%',
                paddingBottom: '1%',
            }}
        >
            <Grid item md="auto">
                <DangerButtons
                icon={null}
                onClickHandler={() => {
                    forceResetPassword();
                }}
                text="Resetear Contraseña"
                />
            </Grid>
        </Grid>
    
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
                <DangerButtons
                icon={<CancelTwoToneIcon />}
                onClickHandler={() => {
                    deleteUser();
                }}
                text="Eliminar Usuario"
                />
            </Grid>
            <Grid item xs={12} md="auto">
                <SuccessButtons
                icon={<CheckCircleTwoToneIcon />}
                onClickHandler={() => {
                    updateUser();
                }}
                text="Actualizar Usuario"
                />
            </Grid>
        </Grid>
    
      </Grid>
      </>
}

UserDetail.propTypes = {
    user: PropTypes.object,
    handleDeleteUser: PropTypes.func,
    handleUpdateUser: PropTypes.func,
    onClose: PropTypes.func,
}

UserDetail.defaultProps = {
    user: {},
    handleDeleteUser: null,
    handleUpdateUser: null,
    onClose: null,
}