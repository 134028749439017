import React,  { useState } from 'react';
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import useStylesBanners from "./styles";
import BaseDialog from "../Dialogs/BaseDialog";
import imagenAnuncio from "../../images/imagenNueva.png";


const AnnounceBanner = () => {
    const classes = useStylesBanners()
    const [openDialog, setOpenDialog] = useState(true)

    // const getClassName = () => {
    //     if (window.innerWidth === 980 && window.innerHeight === 1294){
    //         return classes.baseBlueFrameTablet
    //     }
    //     if (window.innerWidth === 980 && window.innerHeight === 441){
    //         return classes.baseBlueFrameTabletLong
    //     }
    //     return classes.baseBlueFrame
    // } 

    return (
        <BaseDialog
            hideCloseButton
            open={Boolean(openDialog)}
            handleClose={() => {
                setOpenDialog(false)
            }}
            handleSuccess={() => {
                setOpenDialog(false)
            }}
            title="Anuncio"
            successButtonIcon={<ExitToAppIcon />}
            successButtonText="Cerrar"
            size="md"
            tooltipText=""
          >
            <div className={classes.alignCenter}>
                <img
                    src={imagenAnuncio}
                    className={classes.image}
                    alt="Anuncio"
                />    
            </div>
        </BaseDialog>
        // <div className={getClassName()}>
        //     <div className={classes.alignCenter}>
        //         <img
        //             src={logo}
        //             className={classes.image}
        //             alt="Magal Logo"
        //         />
        //         <Typography
        //             variant="h2"
        //             component="h1"
        //             className={classes.magalText}
        //         >
        //             Magal postventa
        //         </Typography>
        //     </div>
        // </div> 
    );
};

export default AnnounceBanner;