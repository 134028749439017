import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from "notistack";
import BaseDialog from "./BaseDialog";
import LoginForm from "../Forms/LoginForm";
import { obtainToken } from "../../api/authenticationApi"
import { getUserData } from "../../api/usersApi";
import { setUserName } from "../../actions/userActions";

const LoginDialog = (props) => {
    const {
        open,
        handleClose,
        setUserName // eslint-disable-line no-shadow
    } = props

    const { enqueueSnackbar } = useSnackbar()

    const loginFormRef = useRef(null)
    const history = useHistory()

    const isDesktop = window.innerWidth > 980;

    const redirectOnUserPermissions = (typeCode) => {
        switch (typeCode) {
            case 'A':
            case 'EPV':
                history.push('/tickets')
                break
            case 'ING':
                history.push('/tickets/pendientes')
                break
            case 'ET':
                history.push('/equipo_tecnico')
                break
            case 'C':
                history.push('/cliente')
                break
            default:
                history.push('/')
        }
    }

    return (
        <BaseDialog
            open={open}
            handleClose={handleClose}
            handleSuccess={() => {
                const {
                    username,
                    password
                } = loginFormRef.current.state

                obtainToken(username, password).then(() => {
                    handleClose()
                    getUserData().then((response) => {
                        setUserName(`${response.first_name} ${response.last_name}`)
                        localStorage.setItem('userName', `${response.first_name} ${response.last_name}`)
                        localStorage.setItem('userType', `${response.type_code}`)
                        localStorage.setItem('username', username)
                        localStorage.setItem('userId', `${response.id}`)
                        enqueueSnackbar(
                            `${response.first_name} has iniciado sesión, bienvenido!`,
                            {
                                variant: 'success'
                            }
                        )
                        redirectOnUserPermissions(response.type_code)
                    }).catch((error) => {
                        console.error(error)
                    })
                }).catch(() => {
                    enqueueSnackbar(
                        'No se ha podido iniciar sesión. Revisa tus credenciales',
                        {
                            variant: 'error'
                        }
                    )
                })

            }}
            title="Iniciar Sesión"
            successButtonText="Iniciar Sesión"
            successButtonIcon={<ExitToAppIcon />}
            size={isDesktop ? 'xs' : 'lg'}
            tooltipText={
                `En este cuadro se debe ingresar su usuario y 
                 contraseña para poder acceder al contenido 
                 interno del sistema.`
            }
        >
            <LoginForm ref={loginFormRef} />
        </BaseDialog>
    );
};

LoginDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setUserName: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setUserName
}

export default connect(null, mapDispatchToProps)(LoginDialog);
