import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import 'react-lightbox-component/build/css/index.css';
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";

export default function ProblemsStep({visit, formik, allFaultsSolved}){
  const { values, setFieldValue, handleChange } = formik;
  const pendingFaults = values.faults.some(p => !p.solved );

  const getClassDict = () => {
    if (window.innerWidth < 769) {
        return {
            paddingLeft: '1%',
            fontSize: '21px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {
            paddingLeft: '1%',
            fontSize: '25px',
            width: '100%'
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return {
            paddingLeft: '1%',
            fontSize: '20px',
        }
    }
    return {}
}

const getCheckBoxDict = () => {
  if (window.innerWidth < 769) {
      return {
        transform: "scale(1.5)",
        paddingLeft: '2%',
        paddingRight: '3%',
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {
          transform: "scale(1.8)",
          paddingLeft: '3%',
          paddingRight: '3%',
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
      return {
        transform: "scale(1.5)",
        paddingLeft: '2%',
        paddingRight: '3%',
      }
  }
  return {}
}

const getTextStyle = () => {
  if (window.innerWidth < 769) {
      return {
        fontSize: '22px'
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {
        fontSize: '24px'
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
      return {
        fontSize: '22px'
      }
  }
  return {}
}

const getFormStyle = () => {
  if (window.innerWidth < 769) {
      return {
        width: '75%', 
        paddingTop: '3%',
        paddingLeft: '1%',
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {
        width: '75%', 
        paddingTop: '2%',
        paddingLeft: '1%',
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
      return {
        width: '75%', 
        paddingTop: '1%',
        paddingLeft: '1%',
      }
  }
  return {}
}

  return <>
  <Grid container >
    <Grid item xs={12}>
      <Typography style={getClassDict()}>Marca todos los problemas que han sido resueltos durante esta visita. Si no se han resuelto todos los problemas, puedes aprovechar de agendar una nueva cita.</Typography>

    </Grid>
    <Grid item xs={12}>
      {!values.faults?.length && <Typography variant="body2" color="textSecondary"><i>No hay problemas asociados a esta visita.</i></Typography>}
      <FormGroup fullWidth column>
        {values.faults?.map((fault, i) => {
          if (!fault.isApproved) {
            return <></>
          }
          return <FormControlLabel
              key={fault.id}
              style={getFormStyle()}               
              control={
                <Checkbox 
                  checked={fault.solved} 
                  onChange={handleChange} 
                  name={`faults.${i}.solved`} 
                  style={getCheckBoxDict()}
                  />}
              // label={`${fault.faultZoneName} - ${fault.faultTargetName} - ${fault.faultTypeName}`}
                label={
                  <span style={getTextStyle()}>
                    {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}
                  </span>
                }
            />
        })}
      </FormGroup>
    </Grid>
  </Grid>
  </>
}
