import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useSnackbar} from "notistack";
import {useQueryParam, StringParam} from "use-query-params";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisitCalendar from "../../Tech/VisitCalendar";
import {useSchedules, useVisit, visitClientCancel, visitMagalCancel} from "../../../api/visitApi";
import PendingVisitDetail from "./PendingVisitDetail";
import VisitList from "../../Tech/VisitList";
import BaseDialog from "../../Tickets/Utils/BaseDialog";
import SecondaryButtons from "../../Tickets/Utils/Buttons/SecondaryButtons";

const PendingVisits = () => {
    const queryParams = {visit__state__state_category: 'P'};

    // const { schedules, loading: loadingSchedules, addSchedule, removeSchedule, updateSchedule: handleUpdateSchedule } = useSchedules(queryParams);
    const { schedules, loading: loadingTodaySchedules } = useSchedules(queryParams);
    const [visitId, setVisitId] = useQueryParam('visitId', StringParam);
    const { visit, loading: loadingVisit } = useVisit({id: visitId });
    const { enqueueSnackbar } = useSnackbar()

    // const [ reloadWindow, setReloadWindow] = useState(false); 

    const cancelVisitClient = async (Id) => {
      const response = await visitClientCancel({id: Id});
      enqueueSnackbar(
        'La visita ha sido cancelada por parte del Cliente.',
        {
          variant: 'success'
        }
      );
      setTimeout(() => {
        setVisitId(null);
        window.location.reload();
      }, 2000);
    }

    const cancelVisitMagal = async (Id) => {
      const response = await visitMagalCancel({id: Id});
      enqueueSnackbar(
        'La visita ha sido cancelada por parte de Magal.',
        {
          variant: 'success'
        }
      );
      setTimeout(() => {
        setVisitId(null);
        window.location.reload();
      }, 2000);
    }

    const getClassNum = () => {
      // 0 = desktop | 1 tablet | 2 tabletLong
      if (window.innerWidth === 980 && window.innerHeight === 1294){
          return 1
      }
      if (window.innerWidth === 980 && window.innerHeight === 441){
          return 2
      }
      return 0
  }

    return (
        <>
          <BaseDialog
            open={Boolean(visitId)}
            handleClose={() => {
              setVisitId(null)
              window.location.reload();
            }}
            handleSuccess={null}
            hideDefaultActions
            actions={true && (() => <>
              <Box flexGrow={1} />
              <SecondaryButtons
                onClickHandler={() => {
                  setVisitId(null)
                  window.location.reload();
                }}
                text="Cerrar"
                size="medium"
              /></>)
            }
            title="Visita"
            successButtonText="Continuar"
            successButtonIcon={<ArrowForwardIcon />}
            size="lg"
            tooltipText="Permite ver una visita pendiente y su información"
          >
            {loadingVisit && <LinearProgress />}
            {visit && <PendingVisitDetail visit={visit} cancelVisitClient={cancelVisitClient} cancelVisitMagal={cancelVisitMagal} />}
          </BaseDialog>
          <Grid 
            container 
            spacing={3}
            style={{
              paddingLeft: '2%',
              paddingTop: '2%'
            }}
          >
            <Grid item xs={12} md={3} >
              <Typography style={{fontSize: (getClassNum() === 1 ) ? '27px' : 'default' }} variant="h5">
                Visitas Pendientes
              </Typography>
              <VisitList
                loading={loadingTodaySchedules}
                setVisitId={setVisitId}
                schedules={schedules} />
            </Grid>
            <Grid item xs={12} md={9} >
              <VisitCalendar schedules={schedules} defaultView="day" defaultViews={[]} isReadOnly/>
            </Grid>
          </Grid>
        </>
    );
};

export default PendingVisits;
