import * as types from "../actions/constants";

export default function authReducer(state = '', action) {
    switch (action.type) {
        case types.LOGIN_USER_SUCCESS:
            return action.token;
        case types.LOGOUT_USER:
            return "";
        default:
            return state;
    }
}