import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { useSnackbar } from "notistack";
import Box from "@material-ui/core/Box";
import useStylesMainAppBar from "./styles";
import NamedLogo from "../Logos/NamedLogo";
import Logo from "../Logos/Logo";

import MainDrawer from "../Drawers/MainDrawer";
import { isAuthenticated, verifyToken } from "../../api/authenticationApi";
import SuccessButtons from "./Buttons/SuccessButtons";
import LoginDialog from "../Dialogs/LoginDialog";
import SecondaryButtons from "./Buttons/SecondaryButtons";
import { logoutUser } from "../../actions/auth";

const MainAppBar = (props) => {
    const {
        children,
        logoutUser, // eslint-disable-line no-shadow
        userName
    } = props
    const classes = useStylesMainAppBar();
    const [open, setOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false)
    const isAuth = isAuthenticated()
    const tokenVerify = verifyToken()
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()
    // console.log(tokenVerify);

    const [dimensions, setDimensions] = React.useState({ 
        height: window.innerHeight,
        width: window.innerWidth
      })

    const handleResize = () => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth
            }) 
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const buttonPermissions = ['A', 'ING', 'EPV', 'SUP', 'ET']

    const redirectOnUserPermissions = (typeCode) => {
        switch (typeCode) {
            case 'A':
            case 'EPV':
            case 'CA':
            case 'ING':
                history.push('/tickets')
                break
            case 'ET':
                history.push('/equipo_tecnico')
                break
            case 'C':
                history.push('/cliente')
                break
            default:
                history.push('/')
        }
    }

    const userType = localStorage.getItem('userType')

    const profileButton = () => {
        const currentUserType = localStorage.getItem('userType')
        if (buttonPermissions.includes(currentUserType)){
            history.push('/perfil')
        } else{
            history.push('/cliente/perfil')
        }
    }

    window.addEventListener('resize', handleResize)

    return (
        <div   className={classes.root}>
            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
                xs={12}
                md={6}
                lg={4}
            >
                <Toolbar className={classes.toolbar} >
                    <div className={classes.root} >
                        {
                            isAuth ? (
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={handleDrawerOpen}
                                    edge="start"
                                    className={clsx(classes.menuButton, {
                                        [classes.hide]: open,
                                    })}
                                >
                                    <MenuIcon />
                                </IconButton>
                            ) : null
                        }
                        <Typography variant="h6" noWrap>
                            {
                                open ? (
                                    <Logo
                                        clickHandler={
                                            () => {
                                                if(isAuth){
                                                    redirectOnUserPermissions(userType)
                                                } else {
                                                    history.push("/")
                                                }
                                            }
                                        }
                                    />
                                ) : (
                                    <NamedLogo
                                        clickHandler={
                                            () => {
                                                if(isAuth){
                                                    redirectOnUserPermissions(userType)
                                                } else {
                                                    history.push("/")
                                                }
                                            }
                                        }
                                    />
                                )
                            }
                        </Typography>
                    </div>                  
                    {
                        isAuth ? (
                            <div className={classes.root}>
                                <SuccessButtons
                                    icon={null}
                                    onClickHandler={profileButton}
                                    text="Editar perfil"
                                />                               
                                <Typography
                                    variant="h5"
                                    style={{
                                        marginLeft: 25,
                                        marginRight: 25,
                                        verticalAlign: "center",
                                        marginTop: "auto",
                                        marginBottom: "auto"
                                    }}
                                >
                                    { userName }
                                </Typography>
                                <SecondaryButtons
                                    icon={<MeetingRoomIcon />}
                                    onClickHandler={() => {
                                        logoutUser()
                                        history.push('/')
                                        // window.location.reload()
                                        setTimeout( () => window.location.reload(), 1000)
                                        enqueueSnackbar(
                                            'Has cerrado sesión',
                                            {
                                                variant: 'success'
                                            }
                                        ) 
                                        
                                    }}
                                    text={<b>Cerrar Sesión</b>}
                                    size="medium"
                                />
                            </div>
                        ) : (
                            <>
                            <Box 
                                textAlign="right"
                                // style={{
                                //     flexDirection: "row",
                                //     justifyContent: 'right',
                                //     paddingRight: '1%'
                                // }}
                                style={{
                                    flexDirection: "row" ,
                                    marginLeft: 20, 
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <SuccessButtons
                                    text={<b>Iniciar Sesión</b>}
                                    icon={<PersonIcon />}
                                    onClickHandler={() => {
                                        setDialogOpen(true)
                                    }}
                                    size="medium"
                                />
                                <Typography 
                                    variant="body2" 
                                    color="white"
                                    style={{ 
                                        cursor: "pointer",
                                        fontSize: '12px',
                                        fontColor: 'white',
                                        marginRight: '12%',
                                    }}
                                    onClick={() => {
                                        history.push('/resetpassword')
                                    }}
                                >
                                    Perdí mi contraseña                                    
                                </Typography>
                            </Box>
                            </>
                        )
                    }
                </Toolbar>
            </AppBar>
            <MainDrawer
                open={open}
                handleClose={handleDrawerClose}
                showNav={isAuth}
            />
            <div style={{width: '100%'}}>
                { children }
            </div>
            <LoginDialog
                open={dialogOpen}
                handleClose={() => {
                    setDialogOpen(false)
                }}
            />         

        </div>
    );
};

MainAppBar.propTypes = {
    children: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    logoutUser: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    userName: state.user.name
})

const mapDispatchToProps = {
    logoutUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainAppBar);