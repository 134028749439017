import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@material-ui/core";
import CameraIcon from "@material-ui/icons/Camera";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Card from "@material-ui/core/Card/Card";
import SuccessButtons from "../Utils/SuccessButtons";
import CustomWebcam from "../../../components/Webcam/CustomWebcam";

export default function VisitStepForm1({formik}){
  const [ open, setOpen ] = useState(false);
  const { values, setFieldValue, handleChange } = formik;

  const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;

  return <Grid container spacing={3}>
    <Grid item xs={12} >
      <Box textAlign="center">
        <Box mb={3}>
          <Typography style={{ fontSize: isTablet ? '26px' : 'default'}} variant="body1" color="textSecondary">Marca la siguiente casilla para poder continuar:</Typography>
          <FormControlLabel
            control={<Checkbox size='medium' checked={values.arrived} onChange={handleChange} name="arrived" />}
            label={<Typography style={{ fontSize: isTablet ? '29px' : 'default'}} variant="body1" color="textPrimary">Hemos llegado al lugar a visitar</Typography>}
            // label="Hemos llegado al lugar a visitar"
          />
        </Box>
        <Box>
          <Typography style={{ fontSize: isTablet ? '26px' : 'default'}} variant="body1" color="textSecondary">Se sugiere tomar una fotografía para registrar el evento.</Typography>
          <SuccessButtons
            icon={<CameraIcon />}
            onClickHandler={() => {
              setOpen(true)
            }}
            text="Tomar una fotografía"
            disabled={!values.arrived}
            size="large"
          />
        </Box>
        <Box mb={3}>
          <Typography style={{ fontSize: isTablet ? '29px' : 'default'}} variant="body1" color="textSecondary">Marca esta casilla si no hay moradores presentes en la vivienda.</Typography>
          <FormControlLabel
            control={<Checkbox checked={values.noPeople} onChange={handleChange} name="noPeople" />}
            label={<Typography style={{ fontSize: isTablet ? '29px' : 'default'}} variant="body1" color="textPrimary">No hay residentes presentes.</Typography>}
            // label="No hay residentes presentes."
          />
        </Box>
      </Box>
    </Grid>
      {open && <CustomWebcam  onCapture={(imageSrc) => {
        setFieldValue('images', [...values.images, {image: imageSrc, message: ''}]);
        setOpen(false);
      }} onClose={() => setOpen(false)} style={{display: 'fixed', top: 0, left: 0 }}/>}
      <Grid item xs={12}>
        <Grid container spacing={3}>
          { values.images.map((image, index) => (
            <Grid item xs={12} md={4} key={image.image}>
              <Card variant="outlined">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    alt={`Fotografía ${index}`}
                    height="140"
                    // image={image.image}
                    src={image.image}
                  />
                  <CardContent>
                    <Grid item xs>
                      <TextField
                        multiline
                        rows={4}
                        fullWidth
                        onChange={handleChange}
                        label="Descripción de imagen"
                        variant="outlined"
                        value={image.message}
                        name={`images.${index}.message`} />
                    </Grid>
                    <Grid item xs="auto">
                    <IconButton
                      title="Eliminar imagen"
                      aria-label="delete"
                      onClick={e => {
                        setFieldValue('images', values.images.filter((v, _i) => _i !== index))
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          )) }
        </Grid>
      </Grid>

  </Grid> ;
}
