import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import EventIcon from "@material-ui/icons/Event";
import Lightbox from 'react-lightbox-component';
import Paper from '@material-ui/core/Paper';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
// import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import Radio from '@material-ui/core/Radio';
import {FormControlLabel, RadioGroup} from "@material-ui/core";
import SuccessButtons from "../../Tech/Utils/SuccessButtons"
import DangerButtons from "../../Tech/Utils/DangerButtons"

import VisitForm from "./VisitForm";
import 'react-lightbox-component/build/css/index.css';
import VisitList from "./VisitList";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import {
  forceTicketState,
  cancelTicketNextVisit,
  updateTicketMagalText
} from "../../../api/ticketApi"
import baja from "../../../images/baja.png";
import moderada from "../../../images/moderada.png";
import grave from "../../../images/grave.png";
import urgente from "../../../images/urgente.png";

const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

const stateLabels = {
  EI: 'Error de Información',
  NA: 'No aplica postventa',
}


export default function TicketDetail({ticket, handleVisitAdded, handleVisitRemoved, handleVisitUpdated}){
  const [ visitFormOpen, setVisitFormOpen ] = useState(false)
  const [ expanded, setExpanded ] = useState('')
  const [ forceState, setForceState] = useState(false)
  const [ forceValue, setForceValue] = useState('')
  const [ forceReason, setForceReason] = useState('')
  const [ magalText, setMagalText] = useState(ticket.magalComment)
  const { enqueueSnackbar } = useSnackbar()

  const [ showVisitCancel, setShowVisitCancel] = useState(false)
  const [ actionValue, setActionValue ] = useState(null)

  const visitAdded = (data) => {
    handleVisitAdded(data);
    setExpanded(data.id);
  };

  const cancelNextVisit = async () => {
    const response = await cancelTicketNextVisit({id : ticket.id, actionValue});
    enqueueSnackbar(
      'Se ha cancelado la visita pendiente más cercana.',
      {
        variant: 'success'
      }
    );
    setTimeout(() => {
      window.location.reload();
    }, 1500);

  }

  const setMagalTextUpdate = async () => {
    const response = await updateTicketMagalText({id : ticket.id, magalText});
    enqueueSnackbar(
      'Se ha actualizado el comentario.',
      {
        variant: 'success'
      }
    );
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  const setForceTicketState = async () => {
    if (forceValue !== ''){
      const response = await forceTicketState({
        id: ticket.id,
        forceValue,
        forceReason
      });
      enqueueSnackbar(
        'Se ha cambiado el estado del requerimiento.',
        {
          variant: 'success'
        }
      );
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } 
    return true;
  }

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

  const getBody1Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '25px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '18px'}
    }
    return {}
  }
  

  const getBody2Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '23px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '16px'}
    }
    return {}
  }


  const getDetailsClass = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {color: "#666666", fontSize: "21px"}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {color: "#666666", fontSize: "16px"}
    }
    return {color: "#666666", fontSize: "14px"}
  }


  const getClientClass = (col) => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {color: col, fontSize: "21px"}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {color: col, fontSize: "16px"}
    }
    return {color: col, fontSize: "14px"}
  }


  const getInfoSClass = (col) => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        if (col) return {color: col, fontSize: "21px" , marginRight: 20}
        return {fontSize: "21px" , marginRight: 20}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      if (col) return {color: col, fontSize: "16px" , marginRight: 20}
        return {fontSize: "16px" , marginRight: 20}
    }
    if (col) return {color: col, fontSize: "13px" , marginRight: 20}
    return {fontSize: "13px" , marginRight: 20}
  }

  const getGravityImg = (label) => {
    if (label === 'low') {
      return <img
                src={baja}
                // className={classes.image}
                alt="Gravedad Baja"
            />
    } 
    if (label === 'moderate') {
      return <img
                src={moderada}
                // className={classes.image}
                alt="Gravedad Moderada"
            />
    } 
    if (label === 'high') {
      return <img
                src={grave}
                // className={classes.image}
                alt="Gravedad Alta"
            />
    } 
    
    return <img
              src={urgente}
              // className={classes.image}
              alt="Gravedad Urgente"
          />
    
  }


  const userType = localStorage.getItem('userType')
  return <>
    <VisitForm
        open={visitFormOpen}
        handleClose={() => setVisitFormOpen(false)}
        ticket={ticket}
        handleVisitAdded={visitAdded}
        // handleVisitRemoved={handleVisitRemoved}
        // handleVisitUpdated={handleVisitUpdated}
    />
  <Grid container>
    <Grid item xs={12}>
          <Typography style={{fontSize: (getClassNum() === 1) ? '30px' : 'default'}} variant="h6" color="textPrimary">
            Información de Requerimiento
          </Typography>
    </Grid>
    <Grid item xs={12}>
      {/* If the resolution is Tablet, the distribution of Table Cells is different */}
      { (getClassNum() === 1) ?
      <Table>
        <TableBody>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()}  variant="body2" color="textSecondary">Datos Cliente</Typography>
                <span style={getDetailsClass()}> 
                  Nombre:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientName}
                </span>
              <Typography variant="body1">
                <span style={getDetailsClass()}> 
                  Rut:&nbsp; 
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientRut}
                </span>
              </Typography>
              <Typography variant="body1">
                <span style={getDetailsClass()}> 
                  Email:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientEmail}
                </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
                <span style={getDetailsClass()}> 
                Teléfono:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientPhone}
                </span>
              </Typography>
              {ticket.clientIsValidated ? 
                <span style={getClientClass("#3fb102")}> 
                  El Cliente está validado.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Cliente no está validado.
                </span>
              }
              {ticket.sentByOwner ? 
                <span style={getClientClass("#3fb102")}> 
                  El Requerimiento fue enviado por el propietario.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Requerimiento fue enviado por el usuario {ticket.senderUser}
                </span>
              }
            </TableCell>
            <TableCell/>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.propertyCode}
                </span>
                </Typography>
                <Typography variant="body1" color="textPrimary">
                <span style={getDetailsClass()}> 
                Dirección:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.propertyAddress}
                </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>  

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
             <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
              {/* <span style={{color: "#666666", fontSize: "13px" , marginRight: 20}}></span> */}
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                {ticket.faults.map(fault => (
                    <div key={fault.id} style={{ display: "flex"}}>
                      <div>
                        <span style={getInfoSClass("#666666")}> 
                          Iteración:&nbsp;{fault.iteration}&nbsp;  
                        </span> 
                      </div>
                      {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          {
                          fault.solved ? (
                              <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                          ) : (
                              <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                          )
                          }
                        </div> 
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic', paddingRight: '1%'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red', paddingLeft: '1%'}}>
                            &nbsp;   (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
            </TableCell>
            <TableCell/>
          </TableRow>


          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
            </TableCell>
            {ticket.uniqueId ? 
              <TableCell>
                <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
                <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
              </TableCell> :
              <TableCell/>
            }            
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
            </TableCell>
          </TableRow>

          {ticket.wasReactivated ? 
          <TableRow >     
            <TableCell>
              <Typography style={getBody1Class()} variant="body2" color="textPrimary">Requerimiento fue Reactivado</Typography>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado : {ticket.timesReactivated}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
            </TableCell>
          </TableRow> : <></>}

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario Empresa</Typography>
              <TextAreaInput
                  onChange={(event) => {
                    setMagalText(event.target.value)
                  }}
                  id="magal-comment"
                  value={magalText}
                  placeholder=""
                  label="Comentario"
                  name="MagalComment"
                  helperText=""
              />
              <SuccessButtons
                icon={null}
                onClickHandler={() => {
                  setMagalTextUpdate()
                }}
                text="Actualizar comentario"
              />
            </TableCell>
          </TableRow>

        </TableBody>
      </Table> : 

      <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Datos Cliente</Typography>
            <span style={getDetailsClass()}> 
              Nombre:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientName}
            </span>
            
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Rut:&nbsp; 
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientRut}
            </span>
            </Typography>
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Email:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientEmail}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={getDetailsClass()}> 
            Teléfono:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientPhone}
            </span>
            </Typography>
            {ticket.clientIsValidated ? 
              <span style={getClientClass("#3fb102")}> 
                El Cliente está validado.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Cliente no está validado.
              </span>
            }
            {ticket.sentByOwner ? 
              <span style={getClientClass("#3fb102")}> 
                El Requerimiento fue enviado por el propietario.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Requerimiento fue enviado por el usuario {ticket.senderUser}
              </span>
            }
          </TableCell>
          <TableCell/>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              <span className="textPrimary">
                {ticket.propertyCode}
              </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
              <span style={getDetailsClass()}> 
              Dirección:&nbsp;  
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.propertyAddress}
              </span>
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>
           <TableCell colSpan='100%'>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              {ticket.faults.map(fault => (
                  <div style={{ display: "flex"}}>
                    <div>
                      <span style={getInfoSClass("#666666")}> 
                        Iteración:&nbsp;{fault.iteration}&nbsp;  
                      </span> 
                    </div>
                    {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName}
                        </div> 
                        {
                        fault.solved ? (
                            <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                        ) : (
                            <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                        )
                        }
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red'}}>
                            (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                    {/* <div style={getInfoSClass(null)}>
                      • {fault.faultZoneName} 
                      - {fault.faultTargetName} 
                      - {fault.faultTypeName}
                    </div> 
                    {
                    fault.solved ? (
                        <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                    ) : (
                        <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                    )
                  } */}
                  </div>
              ))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
          </TableCell>
          <TableCell/>
        </TableRow>


        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
          </TableCell>
          {ticket.uniqueId ? 
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
            </TableCell> :
            <></>
          }            
          <TableCell colSpan={5}>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
          </TableCell>
        </TableRow>
        
        <TableRow>     
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
          </TableCell> : <></>}    
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.timesReactivated}</Typography>
          </TableCell> : <></>}
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
          </TableCell> : <></>}
        </TableRow>

        <TableRow>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario Empresa</Typography>
              <TextAreaInput
                  onChange={(event) => {
                    setMagalText(event.target.value)
                  }}
                  id="magal-comment"
                  value={magalText}
                  placeholder=""
                  label=""
                  name="MagalComment"
                  helperText=""
              />
              <SuccessButtons
                icon={null}
                onClickHandler={() => {
                  setMagalTextUpdate()
                }}
                text="Actualizar comentario"
              />
            </TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        
      </TableBody>
    </Table>
      }
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Imágenes
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {(ticket.images.length > 0) ? 
            <TableCell colSpan={5}>
              <div>
                <Lightbox showImageModifiers={false}
                          images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                          }/></div>
            </TableCell> : 
            <>
              {'\n'}
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                No hay imágenes adjuntas
              </Typography>
            </>
            }
        </Grid>
      </Grid>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Visitas
              </Typography>
            </Grid>
            {ticket.stateLabel !== 'Cerrado' && <Grid item xs={12} md="auto">
              <SuccessButtons
                icon={<EventIcon fontSize="large" />}
                onClickHandler={() => {
                  setVisitFormOpen(true)
                }}
                text="Agendar visita"
              />
            </Grid>}
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <List
              dense
              style={{
                  width: "100%"
              }}
            >
          <VisitList visits={ticket.visits} expanded={expanded} setExpanded={setExpanded} />
          </List>
        </Grid>

        {ticket.stateName === 'Activo' ? 
        <Grid 
          item 
          xs={12}
          style={{
            paddingLeft: '1%',
            paddingTop: '1%'
          }}
        >
          <Typography variant="body1" color="textPrimary" style={{ paddingBottom: '1%' }}>
            Cancelar visita pendiente más próxima a ocurrir : 
          </Typography>
          <DangerButtons
            icon={null}
            onClickHandler={() => {
              setShowVisitCancel(!showVisitCancel)
            }}
            text="Cancelar visita"
          />
          {(showVisitCancel && (userType==='EPV'||userType==='A'||userType==='ING')) ? <Paper>
            <Grid 
              item 
              xs={12}
              spacing={5}
              mb={3}
              style={{
                paddingTop: '1%',
                paddingLeft: '3%',
                paddingRight: '1%',
              }}
            >
              <Box mt={3}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={12} md="auto">
                      <Typography variant="h6" color="textPrimary">Seleccionar motivo de la cancelación</Typography>
                    </Grid>
                  </Grid>
                </Grid>

                <RadioGroup 
                  column 
                  name="actionRadio" 
                  value={actionValue} 
                  onChange={(event) => {
                    setActionValue(event.target.value)
                }}>
                        <FormControlLabel 
                          value="cancelClient" 
                          control={<Radio />} 
                          label={<Typography >El cliente solicita cancelar la visita.</Typography>}
                          // disabled={pendingFaults} 
                        />
                        <FormControlLabel 
                          value="cancelMagal" 
                          control={<Radio />} 
                          label={<Typography >La visita debe ser cancelada por parte de Magal.</Typography>}
                        />
                </RadioGroup>
              </Grid>
              </Box>
            </Grid>
        
            <Grid 
              item 
              xs={12}
              spacing={5}
              mb={3}
              style={{
                paddingTop: '1%',
                paddingLeft: '2%',
                paddingBottom: '2%',
                paddingRight: '1%',
              }}
            >
              <Box mt={3}>
                <Grid container>
                  <Grid item xs={12}>
                    <Grid container alignItems="right" justifyContent="space-between">

                      <Grid item xs={12} md="auto">
                        <DangerButtons
                          icon={<CancelTwoToneIcon size='inherit' />}
                          onClickHandler={() => {
                            cancelNextVisit()
                          }}
                          text="Confirmar Cancelación"
                          disabled={actionValue === null}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Paper> : <></>}
        </Grid> : <></>}

      </Grid>
      </Box>
    </Grid>

    {(userType === 'A') ? 
    <>
    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>

        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography variant="h6" color="textPrimary">Forzar cambio de estado</Typography>
            </Grid>            
          </Grid>
        </Grid>

        <Grid item xs={12} style={{paddingTop: '1%', paddingBottom: '1%'}}>
          <Typography variant="body2" color="textSecondary">
            Si hubo un error al aprobar el requerimiento, se puede cambiar su estado a rechazado haciendo click en el botón a continuación.
          </Typography>
        </Grid>

        <Grid item xs={12} md="auto">
          <SuccessButtons
            icon={null}
            onClickHandler={() => {
              setForceState(!forceState)
            }}
            text="Forzar cambio"
          />
        </Grid>

      </Grid>
      </Box>
    </Grid>
    {forceState ? 
    <Grid item xs={12} md="auto" style={{paddingTop: '2%'}}>
      <Paper 
        spacing={5}
        mb={3}
        style={{
            paddingTop: '2%',
            paddingBottom: '2%',
            paddingLeft: '1%',
            paddingRight: '1%',
        }}>
      <Grid container spacing={3}>
        <Grid
              item
              xs={12}
              style={{paddingLeft: '3%'}}
          >
            <Typography variant="body1" color="textPrimary">Seleccionar Estado</Typography>
        </Grid>
        <Grid
              container
              item
              xs={12}
          > 
            <Typography variant="body1" color="textPrimary">
                <FormControl variant="outlined" >
                    <Select
                        native
                        value={forceValue}
                        onChange={(event) => {
                            setForceValue(event.target.value);
                        }}
                        label=""
                        error={null}
                        helperText=''
                        inputProps={{
                            name: 'stateValue',
                            id: 'state-value',
                        }}
                    >
                    {
                        ['', 'NA'].map(state => (
                            <option value={state} label={stateLabels[state]}  />
                        ))
                    }
                    </Select>
                </FormControl>
            
            </Typography>
        </Grid>
        <Grid
            container
            item
            xs={12}
        >
            <TextAreaInput
                onChange={(event) => {
                    setForceReason(event.target.value)
                }}
                id="comment-closed"
                value={forceReason}
                placeholder="Escribe la razón por la que se debe hacer este cambio."
                label="Razón"
                name="Comment"
                helperText=""
            />
        </Grid>
      
      </Grid>
      <Grid item xs={12} md="auto" style={{paddingTop: '2%'}}>
        <SuccessButtons
          icon={null}
          disabled={forceValue===''||forceReason===''}
          onClickHandler={() => {
            setForceTicketState()
          }}
          text="Realizar cambio"
        />
      </Grid>
      </Paper>
    </Grid> : <></>}
    </> : <></>}

  </Grid>
  </>
}
