import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import SelectAutocompleteInput from "../../Inputs/SelectAutocompleteInput";
import {
    getFaultZones,
    getFaultTargets,
    getFaultTypes,
    checkAndAddFault
} from "../../../api/faultsApi";
import {
    setReportFaults,
    setResportFaultNumber
} from "../../../actions/reportActions";

class ReportStepThree extends Component {
    constructor(props){
        super(props)

        this.state = {
            faultZones: [],
            faultZone: '',
            faultSurfaces: [],
            faultSurface: '',
            faultTypes: [],
            faultType: '',
            faults: props.faults,
            faultNumber: props.faultNumber
        }
    }

    componentDidMount() {
        const {
            successDisabledControl,
            propertyType,
            faultNumber
        } = this.props

        if (faultNumber > 0) {
            successDisabledControl(false)
        }

        getFaultZones(propertyType).then((response) => {
            this.setState({faultZones: response.data})
        })
    }

    getFaultSurfaces(zoneId) {
        getFaultTargets(zoneId).then((response) => {
            this.setState({faultSurfaces: response.data})
        })
    }

    getFaultTypesForSurface(surfaceId) {
        getFaultTypes(surfaceId).then((response) => {
            this.setState({faultTypes: response.data})
        })
    }

    getFault(zoneId, surfaceId, typeId) {
        const {
            successDisabledControl,
            setReportFaults, // eslint-disable-line no-shadow
            setResportFaultNumber // eslint-disable-line no-shadow
        } = this.props

        const {
            faults,
            faultNumber
        } = this.state

        checkAndAddFault(zoneId, surfaceId, typeId).then((response) => {
            faults.push({
                number: faultNumber,
                id: response.data.id,
                name: `${response.data.department_zone.name} - 
                ${response.data.fault_target.name} - 
                ${response.data.fault_type.name}`
            })

            setReportFaults(faults)
            setResportFaultNumber(faultNumber + 1)

            this.setState({
                faults,
                faultZone: '',
                faultSurfaces: [],
                faultSurface: '',
                faultTypes: [],
                faultType: '',
                faultNumber: faultNumber + 1
            }, () => successDisabledControl(false))
        })
    }

    render() {
        const {
            successDisabledControl
        } =this.props

        const {
            faultZones,
            faultZone,
            faultSurfaces,
            faultSurface,
            faultTypes,
            faultType,
            faults,
            faultNumber
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black',
                        textAlign: 'center'
                    }}
                >
                    Selecciona los problemas que quieras reportar.
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={faultZone}
                        options={faultZones}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            successDisabledControl(true)
                            this.setState({
                                faultType: '',
                                faultTypes: [],
                                faultSurface: '',
                                faultSurfaces: [],
                                faultZone: newValue
                            })

                            this.getFaultSurfaces(newValue.id)
                        }}
                        renderOptionFunction={(option) => {
                            return option.name
                        }}
                        optionLabelFunction={(option) => {
                            return option.name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Zona afectada"
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={faultSurface}
                        options={faultSurfaces}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            successDisabledControl(true)
                            this.setState({
                                faultType: '',
                                faultTypes: [],
                                faultSurface: newValue
                            })

                            this.getFaultTypesForSurface(newValue.id)
                        }}
                        renderOptionFunction={(option) => {
                            return option.name
                        }}
                        optionLabelFunction={(option) => {
                            return option.name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Superficie afectada"
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={faultType}
                        options={faultTypes}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            this.setState({
                                faultType: newValue
                            })

                            this.getFault(faultZone.id, faultSurface.id, newValue.id)
                        }}
                        renderOptionFunction={(option) => {
                            return option.name
                        }}
                        optionLabelFunction={(option) => {
                            return option.name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Tipo de problema"
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <List
                        dense
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            faults.map((fault) => {
                                return (
                                    <ListItem key={fault.number}>
                                        <ListItemAvatar
                                            style={{
                                                marginTop: 5
                                            }}
                                        >
                                            <ArrowForwardIosIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={fault.name || ''}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => {
                                                    const newFaults = faults.filter((option) => {
                                                        return option.number !== fault.number
                                                    })

                                                    const newFaultNumber = faultNumber - 1

                                                    this.setState({
                                                        faults: newFaults,
                                                        faultNumber: newFaultNumber
                                                    }, () => {successDisabledControl(!newFaultNumber > 0)})
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Grid>
            </Grid>
        );
    }
}

ReportStepThree.propTypes = {
    successDisabledControl: PropTypes.func.isRequired,
    propertyType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
    faults: state.report.partThree.faults,
    faultNumber: state.report.partThree.faultNumber
})

const mapDispatchToProps = {
    setReportFaults,
    setResportFaultNumber
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportStepThree);