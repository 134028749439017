import React, { useEffect, useState } from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";

import ClientHomeView from "./ClientHomeView";
import ClientTicketsView from "./ClientTicketsView";
import ClientSurveysPage from "./ClientSurveysPage";
import ClientProfilePage from "./ClientProfilePage";

const ClientHome = () => <ClientHomeView />
const ClientTickets = () => <ClientTicketsView />
const ClientSurveys = () => <ClientSurveysPage />
const ClientProfile = () => <ClientProfilePage />

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
}));

const ClientsHomePage = () => {
    const accordionClasses = useStyles();
    const classes = useStylesPages();
    const location = useLocation()
    const history = useHistory();
    
    const [expanded, setExpanded] = React.useState(false);

    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/cliente/':
                return 0

            case '/cliente/tickets':
                return 1

            case '/cliente/encuestas':
                return 2

            case '/cliente/perfil':
                return 3

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Clientes"

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="client sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Inicio"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Mis requerimientos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/tickets`)}
                />
                <Tab
                    label="Mis Encuestas"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/encuestas`)}
                />
                <Tab
                    label="Mi Perfil"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/perfil`)}
                />
            </Tabs>

            <Switch>
                <Route exact path={path} component={ClientHome} />
                <Route path={`${path}/tickets`} component={ClientTickets} />
                <Route path={`${path}/encuestas`} component={ClientSurveys} />
                <Route path={`${path}/perfil`} component={ClientProfile} />
            </Switch>
        </div>
    );
};

export default ClientsHomePage;