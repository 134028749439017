import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState, useEffect} from "react";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import FullNameInput from "../../../components/Inputs/FullNameInput";
import EmailInput from "../../../components/Inputs/EmailInput";
import UsernameInput from "../../../components/Inputs/UsernameInput";
import 'react-lightbox-component/build/css/index.css';
import {
    createTeam,
    getEngineers,
  } from "../../../api/usersApi";

export default function TeamCreate(){

  const [teamEngineer, setTeamEngineer] = useState('')
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()
  const [engineers, setEngineers] = useState(null)

  const [teamName, setTeamName] = useState('')
  const [color, setColor] = useState(null)
  const [received, setReceived] = useState(false)

  const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        container: {
            maxHeight: '100vh'
        },
  }));
  const classes = useStyles();

  const fetchEngineers = () => {
        getEngineers().then((response) => {
            setEngineers(response)
            if (response.length > 0){
                setTeamEngineer(response[0].username)
            }
        })
  }

  useEffect(() => {
        if (!received){
            fetchEngineers();
            setReceived(true)
        }
  },[received])
  

  const createNewTeam = async () => {
    // Validar inputs

    const response = await createTeam({
        teamName,
        teamEngineer,
        color
    });

    if (response.data.error){
        enqueueSnackbar(
            'Hubo un error al crear el equipo. Revisa la información ingresada.',
            {
                variant: 'success'
            }
        );
    } else{
        enqueueSnackbar(
            'El equipo ha sido creado.',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            history.push(`/administracion/equipos/`);
            window.location.reload();
        }, 1500)
    }
    
    return true;
  };

  if (engineers === null){
    return <></>
  }

  return <>
    <Grid
        container
        spacing={12}
        alignItems="center"
        justifyContent="center"
        mb={3}
        style={{
            paddingTop: '1%',
            paddingLeft: '1%',
            paddingRight: '1%',
        }}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 5
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid item xs={12} container mb={3} alignItems="center" justifyContent="center">
                    <Typography variant="h6"  color="textPrimary">
                        Crear nuevo Equipo
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Ingresar nombre del equipo</Typography>
                                    <Grid
                                        container
                                        item
                                        xs={12}
                                        style={{
                                            justifyContent: 'flex-start'
                                        }}
                                    >
                                        <UsernameInput
                                            value={teamName}
                                            onChange={(event) => {
                                                setTeamName(event.target.value);
                                            }}
                                            id="user-username"
                                            error={false}
                                            placeholder="Nombre del Equipo"
                                            label=""
                                            helperText=''
                                        />
                                    </Grid>                               
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Seleccionar ingeniero a cargo</Typography>
                                    <Typography variant="body1" color="textPrimary">
                                        <FormControl variant="outlined" >
                                            <Select
                                                native
                                                value={teamEngineer}
                                                onChange={(event) => {
                                                    setTeamEngineer(event.target.value);
                                                }}
                                                label=""
                                                inputProps={{
                                                    name: 'engineer',
                                                    id: 'project-engineer',
                                                }}
                                            >
                                            {
                                                engineers.map(engineer => 
                                                    (<option value={engineer.username} label={engineer.username}  />)
                                                )
                                            }
                                            </Select>
                                        </FormControl>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                <Typography variant="body2" color="textSecondary">Seleccionar color del equipo</Typography>
                                <input type="color" value={color} onChange={(event) => {
                                    setColor(event.target.value);
                                }}/>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            
                <Grid 
                    container 
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    style={{
                        paddingTop: '2%',
                        paddingBottom: '2%',
                        paddingLeft: '1%',
                        paddingRight: '1%',
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<CheckCircleTwoToneIcon />}
                        disabled={teamName===''}
                        onClickHandler={() => {
                            createNewTeam();
                        }}
                        text="Agregar Equipo"
                        />
                    </Grid>
                </Grid>
            
            </Grid>
            </TableContainer>
            </Paper>
        </Grid>

    </Grid>
    </>
}

TeamCreate.propTypes = {

}

TeamCreate.defaultProps = {
}