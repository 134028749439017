import React from 'react';
import PropTypes from 'prop-types';
import TicketTable from "./Pending/TicketTable";

const NotClosedTickets = props => {
    const { active } = props

    return (
        <div>
            {
                active ? <TicketTable 
                            statusCategory="A" 
                            title="Requerimientos Activos" /> : 
                        <TicketTable statusCategory="P" 
                            title="Requerimientos Pendientes" 
                            removeOnSchedule
                        />
            }
        </div>
    );
};

NotClosedTickets.propTypes = {
    active: PropTypes.bool
};

NotClosedTickets.defaultProps = {
    active: false
}

export default NotClosedTickets;
