import React from 'react';
import PropTypes from 'prop-types';
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Logo from "./Logo";
import useStylesLogos from "./styles";


const NamedLogo = (props) => {
    const { clickHandler } = props
    const classes = useStylesLogos()
    const mobile = window.innerWidth < 536

    return (
        <div
            className={clsx(classes.root, classes.itemsAlignedCenter)}
            onClick={clickHandler}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
        >
            <Logo
                clickHandler={clickHandler}
            />
            <Typography
                variant="h4"
            >
                {
                    mobile ? "Postventa" : "Magal postventa"
                }
            </Typography>
        </div>
    );
};

NamedLogo.propTypes = {
    clickHandler: PropTypes.func.isRequired
}

export default NamedLogo;