import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import BaseDialog from "./BaseDialog";
import ReportForm from "../Forms/ReportForm";

const ReportDialog = (props) => {
    const {
        open,
        handleClose,
    } = props

    const [step, setStep] = useState(1)
    const [disabledSuccess, setDisabledSuccess] = useState(true)
    const [sendData, setSendData] = useState(false)

    const setHandleClose = () => {
        setStep(1)
        setSendData(false)
        handleClose()
    }

    return (
        <BaseDialog
            open={open}
            handleClose={setHandleClose}
            // handleClose={() => {
            //     setStep(1)
            //     setSendData(false)
            //     handleClose()
            // }}
            handleSuccess={() => {
                if (step === 3) {
                    setSendData(true)
                    setDisabledSuccess(false)
                } else {
                    setStep(step + 1)
                    setDisabledSuccess(true)
                }

            }}
            handleFailed={() => {
                setStep(1)
                handleClose()
            }}
            title={`Reportar postventa | Etapa ${step}`}
            successButtonText={step === 3 ? "Enviar reporte" : "Continuar"}
            successButtonIcon={step === 3 ? <SendIcon /> : <ArrowForwardIcon />}
            successButtonDisabled={disabledSuccess}
            failedButtonText="Cerrar"
            failedButtonIcon={<CloseIcon />}
            size="sm"
            tooltipText={
                `Completar las 3 etapas del formulario 
                para reportar problemas en una propiedad.`
            }
            hideCloseButton={step === 1}
            cancelButtonText="Ir atrás"
            givenAction={() => {
                setStep(step - 1)
            }}
        >
            <ReportForm
                successDisabledControl={setDisabledSuccess}
                step={step}
                controlStep={setStep}
                handleClose={setHandleClose}
                sendData={sendData}
            />
        </BaseDialog>
    );
};

ReportDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default ReportDialog;