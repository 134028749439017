import axios from "axios";

const baseURL = process.env.API_URL;
const accessToken = localStorage.getItem("access_token");

const axiosAPI = axios.create({
    baseURL,
    timeout: 20000,
    headers: {
        Authorization: accessToken ? `JWT ${accessToken}` : null,
        "Content-Type": "application/json",
        accept: "application/json",
    },
});

export function setNewHeaders(response) {
    axiosAPI.defaults.headers.Authorization = `JWT ${response.data.access}`;
    localStorage.setItem("access_token", response.data.access);
    localStorage.setItem("refresh_token", response.data.refresh);
}

axiosAPI.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        // Prevent infinite loops
        if (
            error.response.status === 401 &&
            originalRequest.url === `${baseURL}auth/token/refresh/`
        ) {
            window.location.href = "/";
            return Promise.reject(error);
        }

        if (
            error.response.status === 401 &&
            error.response.statusText === "Unauthorized"
        ) {
            const refresh = localStorage.getItem("refresh_token");

            if (refresh) {
                const tokenParts = JSON.parse(atob(refresh.split(".")[1]));

                // exp date in token is expressed in seconds, while now() returns milliseconds:
                const now = Math.ceil(Date.now() / 1000);

                if (tokenParts.exp > now) {
                    try {
                        const response = await axiosAPI.post("/auth/token/refresh/", {
                            refresh,
                        });
                        setNewHeaders(response);
                        originalRequest.headers.Authorization = `JWT ${response.data.access}`;
                        return axiosAPI(originalRequest);
                    } catch (err) {
                        console.error(err);
                        localStorage.removeItem("access_token");
                        localStorage.removeItem("refresh_token");
                        localStorage.removeItem("userName");
                        localStorage.removeItem("userType");
                        localStorage.removeItem("username");
                        window.location.href = "/";
                    }
                } else {
                    console.log("Refresh token is expired", tokenParts.exp, now);
                    localStorage.removeItem("access_token");
                    localStorage.removeItem("refresh_token");
                    localStorage.removeItem("userName");
                    localStorage.removeItem("userType");
                    localStorage.removeItem("username");
                    window.location.href = "/";
                }
            } else {
                console.log("Refresh token not available.");
                localStorage.removeItem("access_token");
                localStorage.removeItem("refresh_token");
                localStorage.removeItem("userName");
                localStorage.removeItem("userType");
                localStorage.removeItem("username");
                window.location.href = "/";
            }
        }

        // specific error handling done elsewhere
        return Promise.reject(error);
    }
);

export default axiosAPI;