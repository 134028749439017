import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import SelectAutocompleteInput from "../../Inputs/SelectAutocompleteInput";
import {
    getProjects,
    getStagesFromProject,
    getPropertyTypes,
    getPropertiesByStageAndType,
    getPropertyClient
} from "../../../api/projectsApi";
import {
    setReportProject,
    setReportProjects,
    setReportProperties,
    setReportProperty,
    setReportPropertyType,
    setReportPropertyTypes,
    setReportStage,
    setReportStages,
    setClientInfo
} from "../../../actions/reportActions";

class ReportStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            projects: props.projects,
            project: props.project,
            stages: props.stages,
            stage: props.stage,
            propertyTypes: props.propertyTypes,
            propertyType: props.propertyType,
            properties: props.properties,
            property: props.property,
            clientInfo: props.clientInfo,
        }
    }

    componentDidMount() {
        const {
            projects,
            project,
            stage,
            propertyType,
            property,
        } = this.state
        const { setReportProjects, successDisabledControl } = this.props // eslint-disable-line no-shadow

        getProjects().then((response) => {
            if (response.data !== projects) {
                this.setState({projects: response.data})
                setReportProjects(response.data)
            }
        })

        if (project !== "" && stage !== "" && propertyType !== ""){
            if (propertyType.code === 'ac'){
                successDisabledControl(false)
            } else if (propertyType.code !== "ac" && property !== "") {
                successDisabledControl(false)
            } else {
                successDisabledControl(true)
            }
        }
    }

    componentDidUpdate(prevProps, prevState){
        const {
            projects,
            project,
            stage,
            propertyType,
            property,
        } = this.state
        if (propertyType !== prevState.propertyType){
        // if (prevState.count !== this.state.count){
            const { setReportPropertyType, successDisabledControl } = this.props // eslint-disable-line no-shadow
            if (project !== "" && stage !== "" && propertyType !== ""){
                if (propertyType.code === 'ac'){
                    successDisabledControl(false)
                } else if (propertyType.code !== "ac" && property !== "") {
                    successDisabledControl(false)
                } else {
                    successDisabledControl(true)
                }
            }
        }
    }

    getPropertiesTypes = () => {
        const { propertyTypes } = this.state
        const { setReportPropertyTypes } = this.props // eslint-disable-line no-shadow

        getPropertyTypes().then((response) => {
            if (response.data !== propertyTypes) {
                this.setState({propertyTypes: response.data})
                setReportPropertyTypes(response.data)
            }
        })
    }

    getStages = (projectId) => {
        const { stages } = this.state
        const { setReportStages, setReportStage } = this.props // eslint-disable-line no-shadow

        getStagesFromProject(projectId).then((response) => {
            if (response.data !== stages) {
                this.setState({stages: response.data})
                setReportStages(response.data)

                if (response.data.length === 1) {
                    this.setState({stage: response.data[0]})
                    setReportStage(response.data[0])
                    this.getPropertiesTypes()
                }
            }
        })
    }

    getProperties = (stageId, propertyTypeCode) => {
        const { properties } = this.state
        const { setReportProperties } = this.props // eslint-disable-line no-shadow

        getPropertiesByStageAndType(stageId, propertyTypeCode).then((response) => {
            if (response.data !== properties) {
                this.setState({properties: response.data})
                setReportProperties(response.data)
            }
        })
    }

    getClientInfo = (value) => {
        const { clientInfo } = this.state
        const { setClientInfo } = this.props // eslint-disable-line no-shadow
        
        getPropertyClient(value).then((response) => {
            if (response.data.length > 0){
                this.setState({clientInfo: response.data[0]})
                setClientInfo(response.data[0])
            } else {
                this.setState({clientInfo: response.data})
                setClientInfo(response.data)
            }
        })
    }

    render() {
        const {
            successDisabledControl,
            setReportProject, // eslint-disable-line no-shadow
            setReportStage, // eslint-disable-line no-shadow
            setReportPropertyType, // eslint-disable-line no-shadow
            setReportProperty, // eslint-disable-line no-shadow
            setClientInfo // eslint-disable-line no-shadow
        } = this.props

        const {
            projects,
            project,
            stages,
            stage,
            propertyTypes,
            propertyType,
            properties,
            property,
            clientInfo
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black'
                    }}
                >
                    Selecciona la propiedad donde se debe reportar problema
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={project}
                        options={projects}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            successDisabledControl(true)
                            this.setState({
                                property: '',
                                propertyType: '',
                                stage: '',
                                project: newValue,
                                clientInfo: null
                            })
                            setReportProject(newValue)
                            
                            this.getStages(newValue.id)
                        }}
                        renderOptionFunction={(option) => {
                            return option.Name
                        }}
                        optionLabelFunction={(option) => {
                            return option.Name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Proyecto"
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={stage}
                        options={stages}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            successDisabledControl(true)
                            this.setState({
                                properties: [],
                                property: '',
                                propertyType: '',
                                stage: newValue,
                                clientInfo: null
                            })
                            setReportStage(newValue)

                            this.getPropertiesTypes()
                        }}
                        renderOptionFunction={(option) => {
                            return option.name
                        }}
                        optionLabelFunction={(option) => {
                            return option.name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Etapa"
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={propertyType}
                        options={propertyTypes}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            successDisabledControl(true);
                            this.setState({
                                properties: [],
                                property: '',
                                propertyType: newValue,
                                clientInfo: null
                            })
                            setReportPropertyType(newValue);

                            this.getProperties(stage.id, newValue.code);
                        }}
                        renderOptionFunction={(option) => {
                            return option.label
                        }}
                        optionLabelFunction={(option) => {
                            return option.label
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Tipo de Propiedad"
                    />
                </Grid>

                {propertyType && propertyType.code !== 'ac' && <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={property}
                        options={properties}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            this.setState({
                                property: newValue
                            }, () => successDisabledControl(false))
                            setReportProperty(newValue)
                            this.getClientInfo(newValue.id)
                        }}
                        renderOptionFunction={(option) => {
                            return option.code
                        }}
                        optionLabelFunction={(option) => {
                            return option.code
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Número"
                    />
                </Grid>}

                {clientInfo && <Grid
                    container
                    item
                    xs={12}
                >
                    {clientInfo.length === 0 ? 
                        <Typography
                            style={{
                                marginBottom: '2%',
                                marginRight: 'auto',
                                marginLeft: 'auto',
                                borderBottom: '1px solid black'
                            }}
                        >
                            No hay Cliente asociado a esta Propiedad.
                        </Typography> :
                        <Grid 
                            container
                        >
                            <Grid 
                                container
                                item
                                xs={12}
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                <Typography
                                    style={{
                                        marginBottom: '2%',
                                        borderBottom: '1px solid black'
                                    }}
                                >
                                    Datos del Cliente asociado a esta Propiedad.
                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="body2" 
                                    color="textPrimary"
                                    style={{
                                        marginBottom: '2%',
                                    }}
                                >
                                    Nombre: {clientInfo.name}
                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="body2" 
                                    color="textPrimary"
                                    style={{
                                        marginBottom: '2%',
                                    }}
                                >
                                    Rut: {clientInfo.rut}
                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="body2" 
                                    color="textPrimary"
                                    style={{
                                        marginBottom: '2%',
                                    }}
                                >
                                    Email: {clientInfo.email}
                                </Typography>
                            </Grid>
                            <Grid 
                                container
                                item
                                xs={12}
                            >
                                <Typography
                                    variant="body2" 
                                    color="textPrimary"
                                    style={{
                                        marginBottom: '2%',
                                    }}
                                >
                                    Teléfono: {clientInfo.phone}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                </Grid>}
            </Grid>
        );
    }
}

ReportStepOne.propTypes = {
    successDisabledControl: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    projects: state.report.partOne.projects,
    project: state.report.partOne.project,
    stages: state.report.partOne.stages,
    stage: state.report.partOne.stage,
    propertyTypes: state.report.partOne.propertyTypes,
    propertyType: state.report.partOne.propertyType,
    properties: state.report.partOne.properties,
    property: state.report.partOne.property
})

const mapDispatchToProps = {
    setReportProjects,
    setReportProject,
    setReportStages,
    setReportStage,
    setReportPropertyTypes,
    setReportPropertyType,
    setReportProperties,
    setReportProperty,
    setClientInfo
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportStepOne);