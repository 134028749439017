import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import Lightbox from 'react-lightbox-component';
import {green, red} from "@material-ui/core/colors";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import VisitList from "../Tickets/Pending/VisitList";
import 'react-lightbox-component/build/css/index.css';
import baja from "../../images/baja.png";
import moderada from "../../images/moderada.png";
import grave from "../../images/grave.png";
import urgente from "../../images/urgente.png";

const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

const useStyles = makeStyles(theme => ({
  body1: {
    fontSize: '25px'
  },
  body2: {
    fontSize: '23px'
  },
  details: {
    color: "#666666", 
    fontSize: "21px"
  },
  clientOwner: {
    color: "#3fb102", 
    fontSize: "21px"
  },
  clientNotOwner: {
    color: "#fc0303", 
    fontSize: "21px"
  },
  infoSColor: {
    color: "#666666", 
    fontSize: "21px" , 
    marginRight: 20
  },
  infoS:{
    fontSize: "21px" , 
    marginRight: 20
  },
  textTablet: {
      fontSize: '22px',
  }
}))

const getGravityImg = (label) => {
  if (label === 'low') {
    return <img
              src={baja}
              // className={classes.image}
              alt="Gravedad Baja"
          />
  } 
  if (label === 'moderate') {
    return <img
              src={moderada}
              // className={classes.image}
              alt="Gravedad Moderada"
          />
  } 
  if (label === 'high') {
    return <img
              src={grave}
              // className={classes.image}
              alt="Gravedad Alta"
          />
  } 
  
  return <img
            src={urgente}
            // className={classes.image}
            alt="Gravedad Urgente"
        />
  
}


export default function TicketDetail({ticket}){
  const [ expanded, setExpanded ] = useState('')

  const classes = useStyles();

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

  return <>
  <Grid container>
    <Grid item xs={12}>
        <Typography style={{fontSize: (getClassNum() === 1) ? '34px' : 'default'}} variant="h6" color="textPrimary">
            Información de requerimiento
        </Typography>
    </Grid>
    <Grid item xs={12}>

      {(getClassNum() === 1) ? <Table>
        <TableBody>
          <TableRow colSpan='100%'>
          <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Datos Cliente</Typography>
              <span className={classes.details}> 
                Nombre:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.senderName}
              </span>
              
              <Typography variant="body1">
              <span className={classes.details}> 
                Rut:&nbsp; 
              </span> 
              <span className={classes.body1}>
                {ticket.senderRut}
              </span>
              </Typography>
              <Typography variant="body1">
              <span className={classes.details}> 
                Email:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.senderEmail}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span className={classes.details}> 
              Teléfono:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.senderPhone}
              </span>
              </Typography>
              {ticket.isOwner ? 
                <span className={classes.clientOwner}> 
                  El cliente es propietario 
                </span> : 
                <span className={classes.clientNotOwner}> 
                  El cliente no es propietario 
                </span>
              }
            </TableCell>
            {!ticket.isOwner ? 
              <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Datos Propietario</Typography>
              <span className={classes.details}> 
                Nombre:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.ownerName}
              </span>
              
              <Typography variant="body1">
              <span className={classes.details}> 
                Rut:&nbsp; 
              </span> 
              <span className={classes.body1}>
                {ticket.ownerRut}
              </span>
              </Typography>
              <Typography variant="body1">
              <span className={classes.details}> 
                Email:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.ownerEmail}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span className={classes.details}> 
                Teléfono:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.ownerPhone}
              </span>
              </Typography>
            </TableCell> : <></>}
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">
              <span className={classes.body1}>
                {ticket.propertyCode}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span className={classes.details}> 
              Dirección:&nbsp;  
              </span> 
              <span className={classes.body1}>
                {ticket.propertyAddress}
              </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Fecha de recepción</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Estado</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Gravedad</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
            </TableCell>
          </TableRow>
          
          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Problemas</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">
                {ticket.faults.map(fault => (
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <div>
                        <span className={classes.infoSColor}> 
                          Iteración:&nbsp;{fault.iteration}&nbsp;  
                        </span> 
                      </div>
                      <div className={classes.infoS}>
                        • {fault.faultZoneName} 
                        - {fault.faultTargetName} 
                        - {fault.faultTypeName}
                      </div> 
                      {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                      )
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Responsable</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
            </TableCell>
          </TableRow>


          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">N° Días abierto</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
            </TableCell>
            {ticket.uniqueId ? 
              <TableCell>
                <Typography className={classes.body2} variant="body2" color="textSecondary">ID</Typography>
                <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
              </TableCell> :
              <TableCell/>
            }            
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Explicación del cliente</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
            </TableCell>
          </TableRow>

          {ticket.wasReactivated ? 
          <TableRow colSpan='100%'>     
            <TableCell>
              <Typography className={classes.body1} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Veces reactivado : {ticket.timesReactivated}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
            </TableCell>
          </TableRow> : <></>}

        </TableBody>
      </Table> :

      <Table>
      <TableBody>
        <TableRow>
        <TableCell>
            <Typography variant="body2" color="textSecondary">Datos Cliente</Typography>
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Nombre:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.senderName}
            </span>
            
            <Typography variant="body1">
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Rut:&nbsp; 
            </span> 
            <span className="textPrimary">
              {ticket.senderRut}
            </span>
            </Typography>
            <Typography variant="body1">
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Email:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.senderEmail}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={{color: "#666666", fontSize: "14px"}}> 
            Teléfono:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.senderPhone}
            </span>
            </Typography>
            {ticket.isOwner ? 
              <span style={{color: "#3fb102", fontSize: "14px"}}> 
                El cliente es propietario 
              </span> : 
              <span style={{color: "#fc0303", fontSize: "14px"}}> 
                El cliente no es propietario 
              </span>
            }
          </TableCell>
          {!ticket.isOwner ? 
            <TableCell>
            <Typography variant="body2" color="textSecondary">Datos Propietario</Typography>
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Nombre:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.ownerName}
            </span>
            
            <Typography variant="body1">
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Rut:&nbsp; 
            </span> 
            <span className="textPrimary">
              {ticket.ownerRut}
            </span>
            </Typography>
            <Typography variant="body1">
            <span style={{color: "#666666", fontSize: "14px"}}> 
              Email:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.ownerEmail}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={{color: "#666666", fontSize: "14px"}}> 
            Teléfono:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.ownerPhone}
            </span>
            </Typography>
          </TableCell> : <></>}
          <TableCell>
            <Typography variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
            <Typography variant="body1" color="textPrimary">
            <span className="textPrimary">
              {ticket.propertyCode}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={{color: "#666666", fontSize: "14px"}}> 
            Dirección:&nbsp;  
            </span> 
            <span className="textPrimary">
              {ticket.propertyAddress}
            </span>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell>
            <Typography variant="body2" color="textSecondary">Fecha de recepción</Typography>
            <Typography variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Estado</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Gravedad</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
        <TableCell>
            <Typography variant="body2" color="textSecondary">Problemas</Typography>
            <Typography variant="body1" color="textPrimary">
              {ticket.faults.map(fault => (
                  <div style={{ display: "flex", alignItems: 'center' }}>
                    <div>
                      <span style={{color: "#666666", fontSize: "13px" , marginRight: 20}}> 
                        Iteración:&nbsp;{fault.iteration}&nbsp;  
                      </span> 
                    </div>
                    <div style={{ fontSize: "13px", marginRight: 20 }}>
                      • {fault.faultZoneName} 
                      - {fault.faultTargetName} 
                      - {fault.faultTypeName}
                    </div> 
                    {
                    fault.solved ? (
                        <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                    ) : (
                        <CancelTwoToneIcon style={{ color: red[500] }} />
                    )
                  }
                  </div>
              ))}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Responsable</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Número de Requerimiento</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.iteration}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2" color="textSecondary">N° Días abierto</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
          </TableCell>
          {ticket.uniqueId ? 
            <TableCell>
              <Typography variant="body2" color="textSecondary">ID</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
            </TableCell> :
            <></>
          }            
          <TableCell colSpan={5}>
            <Typography variant="body2" color="textSecondary">Explicación del cliente</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>     
          {ticket.wasReactivated ? <TableCell>
            <Typography variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
          </TableCell> : <></>}    
          {ticket.wasReactivated ? <TableCell>
            <Typography variant="body2" color="textSecondary">Veces reactivado</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.timesReactivated}</Typography>
          </TableCell> : <></>}
          {ticket.wasReactivated ? <TableCell>
            <Typography variant="body2" color="textSecondary">Razón de Reactivación</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
          </TableCell> : <></>}
        </TableRow>
      </TableBody>
      </Table>}

    </Grid>
    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Imágenes
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {(ticket.images.length > 0) ? 
            <TableCell colSpan={5}>
              <div>
                <Lightbox showImageModifiers={false}
                          images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                          }/></div>
            </TableCell> : 
            <>
              {'\n'}
              <Typography className={ (getClassNum() === 1) ? classes.body1 : null} variant="body1" color="textPrimary">
                No hay imágenes adjuntas
              </Typography>
            </>
            }
        </Grid>
      </Grid>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Visitas
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List
              dense
              style={{
                  width: "100%"
              }}
            >
          <VisitList visits={ticket.visits} expanded={expanded} setExpanded={setExpanded} />
          </List>
        </Grid>
      </Grid>
      </Box>
    </Grid>
  </Grid>
  </>
}
