import { combineReducers } from "redux";
import {
    SNACKBAR_OPEN,
    SNACKBAR_TEXT,
    SNACKBAR_VARIANT
} from "../actions/constants";

function open(state = false, action) {
    if (action.type === SNACKBAR_OPEN) {
        return action.open
    }

    return state
}

function text(state = '', action) {
    if (action.type === SNACKBAR_TEXT) {
        return action.text
    }

    return state
}

function variant(state = '', action) {
    if (action.type === SNACKBAR_VARIANT) {
        return action.variant
    }

    return state
}

const snackbar = combineReducers({
    open,
    text,
    variant
})

export default snackbar