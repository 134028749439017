import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
// import { KeyboardDateTimePicker } from '@material-ui/pickers';
// import IconButton from "@material-ui/core/IconButton";
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import Box from "@material-ui/core/Box";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Grid from "@material-ui/core/Grid";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";
import BaseInput from "../../../components/Inputs/BaseInput";

import {
    getPropertiesByProject
} from "../../../api/projectsApi";
import 'react-lightbox-component/build/css/index.css';

const propertyTypes = {
    depa: 'Departamento',
    park: 'Estacionamiento',
    cell: 'Bodega',
    local: 'Local',
    ac: 'Areas Comunes',
    zc: 'Zonas Comunes'
  }

  const relationTypes = {
    O: 'Dueño(a)',
    A: 'Administrador',
    L: 'Arrendatario',
    OT: 'Otro'
  }

export default function PropertyDetail({property, handleDeleteProperty, handleUpdateProperty, handleRemoveRelation, onClose}){

    // const [projectName, setProjectName] = useState(property.name)

    const [propertyCode, setPropertyCode] = useState(property.code)
    const [propertyType, setPropertyType] = useState(property.property_type)
    const [deedDate, setDeedDate] = useState(property.deed_date)
    const [propertyActive, setPropertyActive] = useState(property.is_complete) 

    const isTablet = (window.innerWidth === 980 && window.innerHeight === 1294)

    const updatePropertyActive = () => {
        setPropertyActive(!propertyActive);
    }

    const deleteProperty = () => {
        handleDeleteProperty({
            id: property.id
        });
        onClose();
    };

    const updateProperty = () => {
        handleUpdateProperty({
            id: property.id,
            propertyCode,
            propertyType,
            deedDate,
            propertyActive
            // projectName
        });
        onClose();
    };

    const deleteClientRelation = () => {
        handleRemoveRelation({
            propertyId: property.id,
            clientRut: property.client_user_relation.client_rut
        })
        onClose();
    }

  
    const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            marginBottom: '5%',
            marginTop: '1%'
            },
            container: {
                maxHeight: '100vh'
            },
    }));
    
    const classes = useStyles();

  return <>

      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información de la Propiedad</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Código</Typography>
                        <Grid
                            item
                            xs={4}
                            md={4}
                            lg={4}
                        >
                            <BaseInput
                                value={propertyCode}
                                onChange={(event) => {
                                    setPropertyCode(event.target.value);
                                }}
                                id="property-code"
                                error={false}
                                placeholder="Código"
                                label=""
                                helperText=''
                                startAdornment={null}
                                labelFontSize={isTablet ? '23px' : 'default'}
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Tipo</Typography>
                        <Typography variant="body1" color="textPrimary">
                            <FormControl variant="outlined" >
                                <Select
                                    native
                                    value={propertyType}
                                    onChange={(event) => {
                                        setPropertyType(event.target.value);
                                    }}
                                    label=""
                                    inputProps={{
                                        name: 'propertyType',
                                        id: 'property-type',
                                    }}
                                >
                                <option aria-label="None" value={propertyType} label={propertyTypes[propertyType]} selected={propertyType}/>
                                {
                                    ['depa', 'park' , 'cell', 'local', 'zc'].map(propType => (
                                        propType !== propertyType ? <option value={propType} label={propertyTypes[propType]}  /> : <></>
                                    ))
                                }
                                </Select>
                            </FormControl>
                        
                        </Typography>
                        {/* <Typography variant="body1" color="textPrimary">{propertyTypes[property.type]}</Typography> */}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Etapa</Typography>
                        <Typography variant="body1" color="textPrimary">{property.stage_address}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Fecha de Entrega al Cliente</Typography>
                        <Grid
                            item
                            xs={11}
                            md={11}
                            lg={11}
                        >
                            <KeyboardDatePicker
                                // fullWidth
                                // label="Fecha de reporte"
                                // format="DD/MM/YY"
                                format="YYYY/MM/DD"
                                name="deedDate"
                                inputVariant="outlined"
                                value={deedDate}
                                // onBlur={() => setFieldTouched('reportDate')}
                                // onClose={() => setFieldTouched('reportDate')}
                                // onAccept={() => setFieldTouched('reportDate')}
                                onChange={(e) => setDeedDate(e)}
                                // error={Boolean( touched.reportDate && errors.reportDate)}
                                // helperText={touched.reportDate && errors.reportDate}
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Tiempo restante de post venta</Typography>
                        {property.remaining_days !== null ? 
                        <>{property.remaining_days > 0 ? 
                            <Typography variant="body1" color="textPrimary" style={{color: 'green'}}>
                                {property.remaining_days} días
                            </Typography>
                            :
                            <Typography variant="body1" color="textPrimary" style={{color: 'red'}}>
                                Plazo terminó hace {Math.abs(property.remaining_days)} días!
                            </Typography>
                        }</> : 
                        <Typography variant="body1" color="textPrimary">
                            Sin definir
                        </Typography>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Propiedad disponible para post venta - Marca esta casilla si la propiedad está desactivada</Typography>
                        <Grid
                            item
                            xs={11}
                            md={11}
                            lg={11}
                        >
                            <FormControlLabel
                                key='is-property-active'
                                control={
                                    <Checkbox 
                                        checked={propertyActive} 
                                        onChange={updatePropertyActive} 
                                        name='property-active'
                                    />}
                                label={
                                    <Typography variant="body1" color="textPrimary">Propiedad Desactivada</Typography>
                                    }
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Relación Cliente - Propiedad</Typography>
                        {property.client_user_relation ? 
                            <Grid 
                                container 
                                alignItems="center" 
                                justifyContent="space-between"
                                style={{
                                    paddingTop: 10,
                                }}
                            >
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" color="textPrimary">
                                        Nombre Cliente : {property.client_user_relation.client_name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" color="textPrimary">
                                        Rut Cliente : {property.client_user_relation.client_rut}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <Typography variant="body1" color="textPrimary">
                                        Tipo de Relación : {relationTypes[property.client_user_relation.relation_type]}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    xs={12}
                                    md={6}
                                    mb={3}
                                    style={{
                                        paddingBottom: 0
                                    }}
                                >
                                    <Paper className={classes.paper}>
                                        <Grid 
                                            item
                                            xs={12}
                                            style={{
                                                paddingBottom: "1%",
                                                paddingLeft: "1%",
                                                paddingTop: "1%"
                                            }}
                                        >
                                            <Typography variant="h6" color="textPrimary">Eliminar Relación Cliente - Propiedad</Typography>
                                        </Grid>
                                        <Grid 
                                            item 
                                            xs={12}
                                            style={{
                                                paddingBottom: "1%",
                                                paddingLeft: "1%",
                                                paddingTop: "1%"
                                            }}                                            
                                        >
                                            <Grid item xs={12} md="auto">
                                                <DangerButtons
                                                icon={<CancelTwoToneIcon />}
                                                onClickHandler={deleteClientRelation}
                                                text="Eliminar"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                            : <Typography variant="body1" color="textPrimary">
                                No hay Cliente asociado a esta Propiedad
                            </Typography>}
                    </TableCell>
                </TableRow>                
            </TableBody>
          </Table>
        </Grid>

        {/* <Grid item xs={12}>
            <Typography variant="h6" color="textPrimary">Eliminar Relación Cliente - Propiedad</Typography>
            <Grid 
                container 
                alignItems="center" 
                justifyContent="space-between"
                style={{
                    paddingTop: 10,
                }}
            >
                <Grid item xs={12} md="auto">
                    <DangerButtons
                    icon={<CancelTwoToneIcon />}
                    onClickHandler={() => {
                        deleteClientRelation(true);
                    }}
                    text="Eliminar"
                    />
                </Grid>
            </Grid>
        </Grid> */}
    
        <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                paddingTop: 10,
            }}
        >
            <Grid item xs={12} md="auto">
                <SuccessButtons
                icon={<CheckCircleTwoToneIcon />}
                onClickHandler={() => {
                    updateProperty();
                }}
                text="Actualizar Cambios"
                />
            </Grid>
        </Grid>

        {/* <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                paddingTop: 10,
            }}
        >
            <Grid item xs={12} md="auto">
                <DangerButtons
                icon={<CancelTwoToneIcon />}
                onClickHandler={() => {
                    deleteProperty();
                }}
                text="Eliminar Propiedad"
                />
            </Grid>
        </Grid> */}
    
      </Grid>

      </>
}

PropertyDetail.propTypes = {
    property: PropTypes.object,
    handleDeleteProperty: PropTypes.func,
    handleUpdateProperty: PropTypes.func,
    handleRemoveRelation: PropTypes.func,
    onClose: PropTypes.func,
}

PropertyDetail.defaultProps = {
    property: {},
    handleDeleteProperty: null,
    handleUpdateProperty: null,
    handleRemoveRelation: null,
    onClose: null,
}