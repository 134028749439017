import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys';
import axiosApi from './axiosApi';
import useIsMountedRef from '../hooks/useIsMountedRef';

const queryString = (params) => Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export default function useDataList({ url, queryParams, lazy, paginated }) {
  const isMountedRef = useIsMountedRef();
  const [state, setState] = useState({ items: paginated ? {results: []} : [], loading: Boolean(url) });

  const fetchItems = () => {
    setState({ ...state, loading: true });
    axiosApi.get(`${url}`, { params: queryParams}).then((response) => {
      setState({
        ...state,
        items: camelcaseKeys(response.data, { deep: true }),
        loading: false
      });
    });
  };

  useEffect(
    () => {
      if (url) fetchItems();
    },
    [isMountedRef, url, queryString(queryParams || {})]
  );
  const items = paginated ? state.items.results : state.items;
  return {
    ...state,
    fetchItems,
    addItem: (item) => {
      let newItems = [item, ...items];
      if (paginated) {
        newItems = {
          ...state.items,
          results: newItems
        }
      }
      setState({
        ...state,
        items: newItems
      });

    },
    updateItem: (item) => {
      const index = items.findIndex((i) => i.id === item.id);
      let newItems = [...items];
      items.splice(index, 1, item);
      if (paginated) {
        newItems = {
          ...state.items,
          results: newItems
        }
      }
      setState({ ...state, items: newItems });
    },
    removeItem: (id) => {
      let newItems = items.filter((i) => i.id !== id);
      console.log('removeItem __', id, newItems);
      if (paginated) {
        newItems = {
          ...state.items,
          results: newItems
        }
      }
      setState({
        ...state,
        items: newItems
      });
    }
  };
}

useDataList.propTypes = {
  queryParams: PropTypes.object,
  lazy: PropTypes.bool,
}

useDataList.propTypes = {
  queryParams: {},
  lazy: false
}

