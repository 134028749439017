import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import {useSnackbar} from "notistack";
import {LinearProgress} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import SuccessButtons from "../Tickets/Utils/Buttons/SuccessButtons";
import EmailInput from "../../components/Inputs/EmailInput";
import PhoneInput from "../../components/Inputs/PhoneInput";
import PasswordInput from "../../components/Inputs/PasswordInput";

import {
  updateUserClientData,
  getUserInformation,
  updateUserPassword,
} from "../../api/usersApi";

const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
  }

const ClientProfilePage = () => {
    
  const username = localStorage.getItem("username");
  const [userId, setUserId] = useState('')
  const [userFullName, setUserFullName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userPhone, setUserPhone] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const history = useHistory();
  const [dataReturned, setDataReturned] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const userType = localStorage.getItem('userType')

    if (userType !== 'C') {
        history.push('/perfil')
    }

  useEffect(() => {
        if (userType !== 'C') {
            history.push('/perfil')
        }
      getUserInformation({id: localStorage.getItem("userId")}).then((response) => {
          setUserId(response.id)
          setUserFullName(response.name)
          setUserEmail(response.email)
          setUserPhone(response.phone)
          setDataReturned(true)
      })
  }, [username])

  const useStyles = makeStyles((theme) => ({
          paper: {
              width: '100%',
              marginBottom: theme.spacing(2),
              maxHeight: '120vh'
          },
          titleTablet: {
              fontSize: '31px'
          },
          body2: {
              fontSize: '21px'
          },
          body1: {
              fontSize: '24px'
          },
          container: {
              maxHeight: '70vh'
          },
  }));
  const classes = useStyles();

  const isTablet = (window.innerWidth === 980 && window.innerHeight === 1294)    

  const updateUserData = async () => {
      // Validar inputs
      
      const response = await updateUserClientData({
          id: userId,
          username,
          email: userEmail,
          phone: userPhone,
      });
      if (response.data.error) {
        enqueueSnackbar(
          response.data.error,
          {
              variant: 'error'
          }
      );
      } else {
        enqueueSnackbar(
            'Se ha actualizado la información.',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 1500)
      }
      return true;
  };

  const updateUserPW = async () => {
      // Validar inputs
      
      const response = await updateUserPassword({
          id: userId,
          password,
          currentPassword
      });
      if (response.data.error){
          enqueueSnackbar(
              'La contraseña actual es incorrecta.',
              {
                  variant: 'error'
              }
          );
      } else {
          enqueueSnackbar(
              'Se ha actualizado la contraseña.',
              {
                  variant: 'success'
              }
          );
      }
      setTimeout( () => {
          window.location.reload();
      }, 1500)
      return true;
  };

if (!dataReturned){
  return <LinearProgress />
}

return <>
  <Grid
      container
      spacing={12}
      mb={3}
      style={{
        justifyContent:"center",
        paddingTop: '5%',
        paddingLeft: '1%',
        paddingRight: '1%',
      }}
  >
      <Grid
          item
          container
          xs={12}
          md={6}
          mb={3}
          style={{
              paddingBottom: 0
          }}
      >
          <Paper className={classes.paper}>
          <TableContainer>
          <Grid container>
              <Grid 
                item 
                xs={12} 
                container 
                mb={3} 
                alignItems="center" 
                style={{
                  justifyContent: 'center'
                }}
              >
                  <Typography className={isTablet ? classes.titleTablet : null} variant="h6"  color="textPrimary">
                      Actualizar Datos de Perfil
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Table>
                      <TableBody>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Nombre</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <Typography className={isTablet ? classes.body1 : null} variant="body1" color="textPrimary">{userFullName}</Typography>
                                  </Grid>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Rut</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <Typography className={isTablet ? classes.body1 : null} variant="body1" color="textPrimary">{username}</Typography>
                                  </Grid>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Email</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <EmailInput
                                          value={userEmail}
                                          onChange={(event) => {
                                              setUserEmail(event.target.value);
                                          }}
                                          id="user-email"
                                          error={false}
                                          placeholder="Email"
                                          label=""
                                          helperText=''
                                          labelFontSize={isTablet ? '23px' : 'default'}
                                      />
                                  </Grid>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Teléfono</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <PhoneInput
                                          value={userPhone}
                                          onChange={(event) => {
                                                const { value } = event.target
                                                const re = /^(\+?56)?(\s?)(0?9)[\s][9876543210\s]*$/;
                                                if (re.test(value)) {
                                                    setUserPhone(event.target.value);
                                                }
                                                // setUserPhone(event.target.value);
                                          }}
                                          id="user-phone"
                                          error={false}
                                          placeholder="Teléfono"
                                          label=""
                                          helperText=''
                                          labelFontSize={isTablet ? '23px' : 'default'}
                                      />
                                  </Grid>
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </Grid>
          
              <Grid 
                  container 
                  alignItems="center"
                  mb={3}
                  style={{
                      paddingTop: '2%',
                      paddingBottom: '2%',
                      paddingLeft: '1%',
                      paddingRight: '1%',
                      justifyContent: 'center'
                  }}
              >
                  <Grid item xs={12} md="auto">
                      <SuccessButtons
                      icon={<CheckCircleTwoToneIcon size='inherit' />}
                      onClickHandler={() => {
                          updateUserData();
                      }}
                      text="Actualizar Información"
                      />
                  </Grid>
              </Grid>
          
          </Grid>
          </TableContainer>
          </Paper>

          <Paper className={classes.paper}>
          <TableContainer>
          <Grid container>
              <Grid 
                item 
                xs={12} 
                container 
                mb={3} 
                alignItems="center" 
                style={{
                  justifyContent: 'center'
                }}
              >
                  <Typography className={isTablet ? classes.titleTablet : null} variant="h6"  color="textPrimary">
                      Actualizar Contraseña
                  </Typography>
              </Grid>
              <Grid item xs={12}>
                  <Table>
                      <TableBody>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Contraseña actual</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <PasswordInput
                                          value={currentPassword}
                                          onChange={(event) => {
                                              setCurrentPassword(event.target.value);
                                          }}
                                          id="user-current-password"
                                          error={false}
                                          placeholder="Contraseña actual"
                                          label=""
                                          helperText=''
                                          labelFontSize={isTablet ? '23px' : 'default'}
                                      />
                                  </Grid>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Nueva contraseña</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <PasswordInput
                                          value={password}
                                          onChange={(event) => {
                                              setPassword(event.target.value);
                                          }}
                                          id="user-password"
                                          error={false}
                                          placeholder="Contraseña"
                                          label=""
                                          helperText=''
                                          labelFontSize={isTablet ? '23px' : 'default'}
                                      />
                                  </Grid>
                              </TableCell>
                          </TableRow>
                          <TableRow>
                              <TableCell>
                                  <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Repetir contraseña</Typography>
                                  <Grid
                                      item
                                      xs={11}
                                      md={11}
                                      lg={11}
                                  >
                                      <PasswordInput
                                          value={repeatPassword}
                                          onChange={(event) => {
                                              setRepeatPassword(event.target.value);
                                          }}
                                          id="user-repeat-password"
                                          error={false}
                                          placeholder="Repetir Contraseña"
                                          label=""
                                          helperText=''
                                          labelFontSize={isTablet ? '23px' : 'default'}
                                      />
                                  </Grid>
                              </TableCell>
                          </TableRow>
                      </TableBody>
                  </Table>
              </Grid>
          
              <Grid 
                  container 
                  alignItems="center"
                  mb={3}
                  style={{
                      paddingTop: '2%',
                      paddingBottom: '2%',
                      paddingLeft: '1%',
                      paddingRight: '1%',
                      justifyContent: 'center'
                  }}
              >
                  <Grid item xs={12} md="auto">
                      <SuccessButtons
                      icon={<CheckCircleTwoToneIcon />}
                      onClickHandler={() => {
                          updateUserPW();
                      }}
                      text="Actualizar Contraseña"
                      disabled={password===''||repeatPassword===''||password!==repeatPassword}
                      />
                  </Grid>
              </Grid>
          
          </Grid>
          </TableContainer>
          </Paper>
      </Grid>

  </Grid>
  </>
};

ClientProfilePage.propTypes = {

};

export default ClientProfilePage;
