import { combineReducers } from "redux";
import auth from "./auth";
import snackbar from "./snackbar";
import user from "./user";
import report from "./report";

const rootReducer = combineReducers({
    auth,
    snackbar,
    user,
    report
});

export default rootReducer;