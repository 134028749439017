import snakecaseKeys from "snakecase-keys";
import axiosAPI from "./axiosApi";
import useDataList from "./useDataList";

export async function getProjects() {
    const response = await axiosAPI.get("projects/list")

    return response
}

export async function getStagesFromProject(projectId) {
    const response = await axiosAPI.get(`stages/list_for_project/${projectId}`)

    return response
}

export async function getPropertyTypes() {
    const response = await axiosAPI.get('types/property_types')

    return response
}

export async function getPropertiesByStageAndType(stageId, propertyType) {
    const response = await axiosAPI.get(`properties/list_by_stage_type/${stageId}/${propertyType}`)

    return response
}

export async function getCommuneList() {
    const response = await axiosAPI.get("clients/get_commune_list")

    return response
}


export async function getClientPropertyRelation(propertyId) {
    const response = await axiosAPI.get(`properties/get_property_relations/${propertyId}`)

    return response
}

export async function getPropertiesByProject(projectId) {
    const response = await axiosAPI.get(`properties/list_by_project/${projectId}`)

    return response
}

export async function getPropertyClient(propertyId) {
    const response = await axiosAPI.get(`properties/get_client_by_property/${propertyId}`)

    return response
}


export function useProjects(){
    const { items: projects, loading, removeItem} = useDataList({url: 'project/'} )
    return {projects, loading, removeProject: (projectId) => {
        removeItem(projectId);
        } }
}

export async function uploadClients(data) {
    const response = await axiosAPI.post("client/clients_load/", data)
    return response
}

export async function uploadProperties(data) {
    const response = await axiosAPI.post("project/properties_load/", data)
    return response
}

export async function deleteCPR(data) {
    const response = await axiosAPI.post("project/delete_cpr/", data)
    return response
}

export async function uploadRCP(data) {
    const response = await axiosAPI.post("project/cpr_load/", data)
    return response
}

export async function createProject(data) {
    const response = await axiosAPI.post("project/", data)
    return response
}

export async function updateProject(data) {
    const response = await axiosAPI.put(`project/${data.id}/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function deleteProject(id) {
    const response = await axiosAPI.delete(`project/${id}/`);
    return response;
}

export async function deleteStage(data) {
    const response = await axiosAPI.post(
        'project/delete_stage/',
        {
            projectId: data.projectId,
            stageName: data.stageName
        }
    )
    return response;
}

export async function filterProperties(data) {
    const response = await axiosAPI.post(`project/filter_properties/`, snakecaseKeys(data, {deep: true}))
    return response
}

export async function updateProperty(data) {
    const response = await axiosAPI.post(`project/update_property/`, snakecaseKeys(data, {deep: true}))
    return response
}
