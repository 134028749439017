import React, {useCallback, useState} from "react";
import {useSnackbar} from "notistack";
import camelcaseKeys from "camelcase-keys";
import PropTypes from 'prop-types';
import {
    destroySchedule,
    updateSchedule,
    useCalendars,
    useSchedules, visitCreate
} from "../../api/visitApi";
import CalendarNav from "../../components/Calendar/CalendarNav";
import CustomCalendar from "../../components/Calendar/CustomCalendar";


export default function VisitCalendar({
    // ticket, 
    // removeTicket, 
    defaultView, 
    defaultViews, 
    isReadOnly, 
    calendar, 
    calendarRef, 
    onShowCreationPopup, 
    // ticketForCreationPopup, 
    // setTicketForCreationPopup
    }){
    const handleVisitUpdated = () => null;
    const handleVisitRemoved = () => null;

    const { enqueueSnackbar } = useSnackbar();
    const { calendars } = useCalendars();
    const { schedules, addSchedule, removeSchedule, updateSchedule: handleUpdateSchedule } = useSchedules({});
    const [ selectedView, setSelectedView] = useState(defaultView);

    // const onSchedule = useCallback(async ({...values}) => {
    //     let response = null;
    //     if (ticketForCreationPopup){
    //         const finalValues = values;
    //         finalValues.location = ticketForCreationPopup.propertyAddress;
    //         finalValues.title = ticketForCreationPopup.propertyName;
    //         response = await visitCreate(ticketForCreationPopup.id, {...values});
    //         response = {data: response.data.schedule};
    //         removeTicket(ticketForCreationPopup.id)
    //         setTicketForCreationPopup(null)
    //     }

    //     enqueueSnackbar(
    //         '¡La visita ha sido agendada!',
    //         {
    //             variant: 'success'
    //         }
    //     );
    //     const data = camelcaseKeys(response.data, {deep: true});
    //     addSchedule({...data, calendar: data.calendarId.toString()});
    // }, [ticketForCreationPopup, schedules.length]);

    const onSchedule = () => {
        console.log('');
    }

    const onUpdate = useCallback(async (values) => {
        const { schedule, changes } = values;
        const response = await updateSchedule(values);
        const data = camelcaseKeys(response.data, {deep: true});
        handleUpdateSchedule(data);

        // Updating the schedule can just update the suggestedDate
        handleVisitUpdated({id: data.visitId, suggestedDate: data.start});

        enqueueSnackbar(
            '¡La visita ha sido actualizada!',
            {
                variant: 'success'
            }
        );
        calendar.current.calendarInst.updateSchedule(
          schedule.id,
          schedule.calendarId,
          changes
        );

    }, [schedules.length]);

    const onDelete = useCallback(async (values) => {
        const schedule = schedules.find(s => s.id === values.schedule.id);
        await destroySchedule(schedule.id);
        handleVisitRemoved(schedule.visitId);
        removeSchedule(schedule.id);
        enqueueSnackbar(
            '¡La visita ha sido eliminada!',
            {
                variant: 'success'
            }
        );
    }, [schedules.length]);

    let page = <></>; 
    if (calendar){
        page = <>
            {calendar.current && <CalendarNav
            calendar={calendar.current?.calendarInst}
            creationDefaultTitle=''
            creationDefaultLocation=''
            view={selectedView}
            views={defaultViews}
            onShowCreationPopup={onShowCreationPopup}
            />}
            <CustomCalendar
                ref={calendarRef}
                height="700px"
                calendars={calendars}
                isReadOnly={isReadOnly}
                schedules={schedules}
                useDetailPopup
                useCreationPopup={!isReadOnly}
                view={selectedView} // You can also set the `defaultView` option.
                onBeforeCreateSchedule={onSchedule}
                onBeforeUpdateSchedule={onUpdate}
                onBeforeDeleteSchedule={onDelete}
            />
        </>
    } 
    return page;

}

VisitCalendar.propTypes = {
    defaultView: PropTypes.string,
    defaultViews: PropTypes.array,
    isReadOnly: PropTypes.bool,
}

VisitCalendar.defaultProps = {
    defaultView: 'month',
    defaultViews: ['day', 'week', 'month'],
    isReadOnly: false
}