import {makeStyles} from "@material-ui/core/styles";

const useStylesPages = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
        overflowX: 'hidden',
        display: "flex",
        flexDirection: "column"
    },
    backgroundImage: {
        width: "92%",
        height: "100%",
        margin: "auto",
        marginTop: theme.spacing(1)
    },
    backgroundImageTabletLong: {
        width: "69%",
        height: "65%",
        margin: "auto",
        marginTop: theme.spacing(1)
    },
    disclaimerText: {
        textAlign: "center",
        paddingLeft: theme.spacing(5)
    }
}));

export default useStylesPages