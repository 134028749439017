import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import moment from "moment";

import ClientDetail from "./ClientDetail";
import {
  useUsers,
  updateUserAdmin,
  updateUserClient,
  deleteUser,
  getClientUsers
} from "../../api/usersApi";
import BaseDialog from "../../components/Dialogs/BaseDialog";
import BaseTable from "./Users/ClientBaseTable";

const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
  }

const ClientsTable = () => {
    
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [userQuery, setUserQuery] = useState('')

    const queryParams = {page: page + 1, maxResults, userQuery}

    // const { users, loading } = useUsers(queryParams)
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [user, setUser] = useState(null);
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true)
    const userType = localStorage.getItem('userType')
    const [total, setTotal] = useState(0)
    const history = useHistory();
    
    const { enqueueSnackbar } = useSnackbar()
    

    const handleDeleteUser = async (data) => {
        const response = await deleteUser(data.id);
        enqueueSnackbar(
            '¡El usuario ha sido eliminado!',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000);
    }


    const handleUpdateUser = async (data) => {
        const response = await updateUserAdmin(data);
        enqueueSnackbar(
            '¡El usuario ha sido actualizado!',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000);
    }

    const handleUpdateClient = async (data) => {
        const response = await updateUserClient(data);
        enqueueSnackbar(
            '¡El cliente ha sido actualizado!',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000);
    }

    const getUserList = () => {
        getClientUsers(page, maxResults, userQuery).then((response) => {
            setUsers(response.data.rows)
            setTotal(response.data.total)
            setLoading(false)
        })
    }

    const HandleUserQuery = (value) => {
        setUserQuery(value);
    }


    useEffect(() => {
        getUserList();
    }, [page, maxResults, userQuery])

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'rut', numeric: false, disablePadding: false, label: 'Rut' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
        { id: 'email', numeric: false, disablePadding: false, label: 'Email' },
        { id: 'is_rejected', numeric: false, disablePadding: false, label: 'Estado' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => {
                                        setUser(row);
                                        setSelectedUserId(row.id);
                                        }}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.rut }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.name }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.email || <i>Sin email</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            row.is_rejected ?
            <TableCell>
                <i>Rechazado</i> 
            </TableCell> :
            <>
            {row.is_validated ?
            <TableCell>
                <i>Validado</i> 
            </TableCell> : 
            <TableCell>
                <i>Sin validar</i> 
            </TableCell>}
            </>
        ),
    ]

  if (loading) return <LinearProgress />;
  if (userType !== 'A'){
    history.push('/')
  }

    return (
      <>
          <BaseDialog
            // hideCloseButton
            open={Boolean(selectedUserId&&user)}
            handleClose={() => {
                setSelectedUserId(null);
                setUser(null);
            }}
            handleSuccess={() => {
                setSelectedUserId(null);
                setUser(null);
            }}
            title="Detalles de cliente"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información del cliente seleccionado."
          >
              {user && <ClientDetail
                  user={user}
                  handleDeleteClient={handleDeleteUser}
                  handleUpdateClient={handleUpdateClient}
                  onClose={() => {
                    setSelectedUserId(null);
                    setUser(null);
                  }}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Lista de Clientes"
            headers={headCells}
            rows={users}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
            setUserQuery={HandleUserQuery}
        />
        </>
    );
};

ClientsTable.propTypes = {

};

export default ClientsTable;
