import React from 'react';
import Typography from "@material-ui/core/Typography";
import useStylesBanners from "./styles";
import logo from "../../images/magal_logo_chico.png";

const NoAuthBanner = () => {
    const classes = useStylesBanners()

    const getClassName = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return classes.baseBlueFrameTablet
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
            return classes.baseBlueFrameTabletLong
        }
        return classes.baseBlueFrame
    } 

    return (
        <div className={getClassName()}>
            <div className={classes.alignCenter}>
                <img
                    src={logo}
                    className={classes.image}
                    alt="Magal Logo"
                />
                <Typography
                    variant="h2"
                    component="h1"
                    className={classes.magalText}
                >
                    Magal postventa
                </Typography>
            </div>
        </div> 
    );
};

export default NoAuthBanner;