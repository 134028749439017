import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import SendIcon from '@material-ui/icons/Send';
import { useSnackbar } from "notistack";
import FormLabel from "@material-ui/core/FormLabel"
import useStylesPages from "../styles";
import {
    getVisitSurvey, sendVisitSurveyAnswers
} from "../../api/clientApi";
import TextAreaInput from '../../components/Inputs/TextAreaInput'
import SuccessButtons from "../../components/Buttons/SuccessButtons";

const SurveyQuestions = (props) => {
    const {
        questions,
        instructions,
        surveyId
    } = props

    const [data, setData] = useState({})
    const [disabled, setDisabled] = useState(true)
    const { enqueueSnackbar } = useSnackbar()

    const requiredFieldsAreFiled = () => {

        const allFiled = questions.every((question) => {
            if (question.required) {
                return data[question.order] !== "" && data[question.order] !== undefined && data[question.order] !== null
            }

            return true
        })

        setDisabled(!allFiled)
    }

    const getComponentForQuestion = (question) => {
        switch (question.type) {
            case "int":
                return "Number"

            case "str":
                return (
                    <ListItem
                        key={`${question.order}`}
                        divider
                        style={{
                            width: "100%",
                            flexDirection: "column",
                            justifyContent: 'center',
                            paddingTop: '1%',
                            paddingBottom: '1%',
                        }}
                    >
                        {
                            question.instructions ? (
                                <Typography
                                    variant="subtitle1"
                                    gutterBottom
                                    style={{justifyContent: 'center'}}
                                >
                                    { question.instructions }
                                </Typography>
                            ) : null
                        }
                        <FormControl
                            required={question.required}
                            style={{justifyContent: 'center'}}
                        >
                            <FormLabel 
                                component="legend"
                                style={{
                                    paddingTop: '1%',
                                    paddingBottom: '1%',
                                    justifyContent: 'center',
                                }}
                            >
                                { question.text }
                            </FormLabel>
                            <TextAreaInput
                                onChange={(event) => {
                                    data[question.order] = event.target.value

                                    setData({
                                        ...data
                                    })
                                }}
                                id={`${question.order}`}
                                value={data[question.order] || ""}
                                placeholder="Por favor comparta su opinión con nosotros"
                                label=""
                                name={`${question.order}`}
                                helperText=""
                            />
                        </FormControl>
                    </ListItem>
                )

            case "bool":
                return "True or False"

            case "opt":
                return (
                    <ListItem
                        key={`${question.order}`}
                        divider
                        style={{
                            width: "100%",
                            flexDirection: "column",
                            justifyContent: 'center',
                            paddingTop: '1%',
                            paddingBottom: '1%',
                        }}
                    >
                        {
                            question.instructions ? (
                                <Typography
                                    variant="subtitle2"
                                    color="textSecondary"
                                    gutterBottom
                                    style={{justifyContent: 'center'}}
                                >
                                    { question.instructions }
                                </Typography>
                            ) : null
                        }
                        <FormControl
                            component="fieldset"
                            required={question.required}
                            style={{justifyContent: 'center'}}
                        >
                            <Grid 
                                item
                                container
                                xs={12}
                                style={{
                                    justifyContent: 'center'
                                }}
                            >
                                
                                <Typography variant="subtitle1" color="textPrimary">
                                    {question.text.split('\n').map(function(item) {
                                            return (
                                                <span>
                                                {item}
                                                <br/>
                                                </span>
                                            )
                                            })}
                                    {/* { question.text } */}
                                </Typography>
                            </Grid>
                            <RadioGroup
                                row
                                style={{justifyContent: 'center'}}
                                aria-label="position"
                                name="position"
                                defaultValue="bottom"
                                value={data[question.order] || ""}
                                onChange={(event) => {
                                    data[question.order] = event.target.value

                                    setData({
                                        ...data
                                    })

                                    requiredFieldsAreFiled()
                                }}
                            >
                                {
                                    question.Options.map((option) => (
                                        <FormControlLabel
                                            key={`${question.text}-${option.text}`}
                                            value={ option.text }
                                            control={<Radio color="primary" />}
                                            label={ option.text }
                                            labelPlacement="bottom"
                                        />
                                    ))
                                }
                            </RadioGroup>
                        </FormControl>
                    </ListItem>
                )

            default:
                return null
        }
    }

    // questions.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)) // eslint-disable-line

    return questions ? (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={ instructions ? (
                    <ListSubheader component="div" id="nested-list-subheader">
                        { instructions }
                    </ListSubheader>
                ) : null
            }
            style={{
                width: "100%"
            }}
        >
            {
                questions.map((question) => (
                    getComponentForQuestion(question)
                ))
            }
            <ListItem
                style={{
                    paddingTop: 25,
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "flex-end"
                }}
            >
                <SuccessButtons
                    text="Enviar respuestas"
                    icon={<SendIcon />}
                    size="large"
                    onClickHandler={() => {
                        data.survey_id = surveyId

                        sendVisitSurveyAnswers(data).then((response) => {
                            enqueueSnackbar(
                                'Sus respuestas estan siendo procesadas. Gracias',
                                {
                                    variant: 'success'
                                }
                            )

                            setTimeout( () => window.location.reload(), 500)
                        })
                    }}
                    disabled={disabled}
                />
            </ListItem>
        </List>
    ) : <></>
}


const VisitSurveyPage = () => {
    const classes = useStylesPages();
    const { surveyUniqueId } = useParams();
    const [visitSurvey, setVisitSurvey] = useState(null)
    const [visitLoaded, setVisitLoaded] = useState(false)

    useEffect(() => {
        getVisitSurvey(surveyUniqueId).then((response) => {
            setVisitSurvey(response.data);
            setVisitLoaded(true);
        })
    }, [surveyUniqueId])

    document.title = "Magal PSEE | Encuestas"

    if (visitSurvey && visitSurvey.expired && !visitSurvey.completed) {
        return ( 
            <div
                className={classes.root}
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    height: '93vh',
                    padding: 30,
                }}
            >
                <Paper
                    style={{
                        padding: 15,
                        justifyContent: 'center'
                    }}
                    elevation={3}
                >
                    <Grid
                        container
                        spacing={2}
                        xs={12}
                        style={{
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            item
                            container
                            xs={12}  
                            style={{
                                justifyContent: 'center'
                            }}                      
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                La encuesta de esta Visita venció.
                            </Typography> 
                        </Grid>  
                        <Grid
                            item
                            container
                            xs={12}  
                            style={{
                                justifyContent: 'center'
                            }}                      
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                Fecha vencimiento : {moment(visitSurvey.expire_date).format('DD/MM/YY HH:mm')}
                            </Typography> 
                        </Grid>                                         
                    </Grid>
                </Paper>
        </div>
        )
    }

    if (visitSurvey && visitSurvey.completed) {
        return ( 
            <div
                className={classes.root}
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    height: '93vh',
                    padding: 30,
                }}
            >
                <Paper
                    style={{
                        padding: 15,
                        justifyContent: 'center'
                    }}
                    elevation={3}
                >
                    <Grid
                        container
                        spacing={2}
                        xs={12}
                        style={{
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            item
                            container
                            xs={12}  
                            style={{
                                justifyContent: 'center'
                            }}                      
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                La encuesta de esta Visita ya fue contestada.
                            </Typography> 
                        </Grid>  
                        <Grid
                            item
                            container
                            xs={12}  
                            style={{
                                justifyContent: 'center'
                            }}                      
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                Fecha contestada : {moment(visitSurvey.answered_date).format('DD/MM/YY HH:mm')}
                            </Typography> 
                        </Grid>                                         
                    </Grid>
                </Paper>
        </div>
        )
    }

    if (!visitLoaded) {
        return ( 
            <div
                className={classes.root}
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    height: '93vh',
                    padding: 30,
                }}
            >
                <Paper
                    style={{
                        padding: 15,
                        justifyContent: 'center'
                    }}
                    elevation={3}
                >
                    <Grid
                        container
                        spacing={2}
                        xs={12}
                        style={{
                            justifyContent: 'center'
                        }}
                    >
                        <Grid
                            item
                            container
                            xs={12}  
                            style={{
                                justifyContent: 'center'
                            }}                      
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                            >
                                Se está buscando la encuesta con el ID ingresado
                            </Typography> 
                        </Grid>                                       
                    </Grid>
                </Paper>
        </div>
        )
    }

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column',
                height: '93vh',
                padding: 30
            }}
        >
            <Paper
                style={{
                    padding: 15
                }}
                elevation={3}
            >
                {
                    visitSurvey ? (<div>
                            <div>
                                <Paper
                                    style={{
                                        padding: 15,
                                        margin: "15px 0px"
                                    }}
                                    elevation={3}
                                >    
                                    <Grid
                                        container
                                        spacing={2}
                                        xs={12}
                                        style={{
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <Grid
                                            item
                                            container
                                            xs={12}  
                                            style={{
                                                justifyContent: 'center'
                                            }}                      
                                        >
                                            <Typography
                                                variant="h6"
                                                gutterBottom
                                            >
                                                Encuesta de Visita Realizada
                                            </Typography> 
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            xs={12}  
                                            style={{
                                                justifyContent: 'center'
                                            }}                      
                                        >
                                            <Typography 
                                            >
                                                Por favor { visitSurvey.visit_sender_name } responde la encuesta a continuación.
                                            </Typography>
                                        </Grid>                                            
                                    </Grid>                               
                                    <SurveyQuestions
                                        questions={visitSurvey.questions}
                                        instructions={visitSurvey.survey_instructions}
                                        surveyId={visitSurvey.visit_survey_id}
                                    />
                                </Paper>
                            </div>
                        </div>
                    ) : (
                        <Grid
                            container
                            spacing={2}
                            xs={12}
                            style={{
                                justifyContent: 'center'
                            }}
                        >
                            <Grid
                                item
                                container
                                xs={12}  
                                style={{
                                    justifyContent: 'center'
                                }}                      
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                >
                                    No existe una encuesta para el ID ingresado en la URL.
                                </Typography> 
                            </Grid>                                           
                        </Grid>
                    )
                }
            </Paper>
        </div>
    );
};

export default VisitSurveyPage;