import React from 'react';
import PropTypes from 'prop-types';
import clsx from "clsx";
import logo from "../../images/magal_logo_chico.png";
import useStylesLogos from "./styles";


const Logo = (props) => {
    const { clickHandler } = props
    const classes = useStylesLogos()

    return (
        <div
            className={clsx(classes.root, classes.itemsAlignedCenter)}
            onClick={clickHandler}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
        >
            <img
                className={classes.logo}
                src={logo}
                alt="Logo magal"
            />
        </div>
    );
};

Logo.propTypes = {
    clickHandler: PropTypes.func.isRequired
}

export default Logo;