import { combineReducers } from "redux";
import { USER_NAME } from "../actions/constants";

function name(state = localStorage.getItem('userName') || "", action) {
    if (action.type === USER_NAME) {
        return action.name
    }

    return state
}

const user = combineReducers({
    name
})

export default user