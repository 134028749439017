import React from 'react';
import PropTypes from 'prop-types';
import Dialog from "@material-ui/core/Dialog";
import Draggable from 'react-draggable';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Box from "@material-ui/core/Box";
import SuccessButtons from "../Buttons/SuccessButtons";
import DangerButtons from "../Buttons/DangerButtons";
import {
    useStylesDialog,
    useStylesTooltip
} from "./styles";
import SecondaryButtons from "../Buttons/SecondaryButtons";

function PaperComponent(props) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} />{/* eslint-disable-line react/jsx-props-no-spreading */}
        </Draggable>
    );
}

const BaseDialog = (props) => {
    const {
        open,
        handleClose,
        handleSuccess,
        handleFailed,
        title,
        successButtonText,
        successButtonIcon,
        failedButtonText,
        failedButtonIcon,
        children,
        size,
        tooltipText,
        successButtonDisabled,
        failedButtonDisabled,
        closeButtonDisabled,
        actions,
        hideDefaultActions,
        hideCloseButton,
        cancelButtonText,
        disableDraggable,
        givenAction
    } = props

    const classes = useStylesDialog()
    const tooltipClasses = useStylesTooltip()

    return (

            <Dialog
                // disableBackdropClick
                // disableEscapeKeyDown
                fullWidth
                maxWidth={size}
                open={open}
                // onClose={handleClose}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick'){
                        handleClose()
                    }
                }}
                PaperComponent={!disableDraggable ? PaperComponent : undefined}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle
                    style={{ cursor: !disableDraggable ? 'move' : 'default' }}
                    id="draggable-dialog-title"
                    className={classes.dialogTitle}
                >
                    <b>{ title }</b>
                    <Tooltip
                        title={tooltipText}
                        arrow
                        leaveDelay={500}
                        classes={tooltipClasses}
                        className={classes.dialogTooltip}
                    >
                        <IconButton component="span">
                            <ContactSupportIcon />
                        </IconButton>
                    </Tooltip>
                </DialogTitle>
                <DialogContent
                    className={classes.dialogContent}
                >
                    { children }
                </DialogContent>
                <DialogActions
                    className={classes.dialogActions}
                >
                    {actions && actions()}
                    {!hideDefaultActions && <>
                        <Box flexGrow={1} />
                        {!hideCloseButton && <SecondaryButtons
                            disabled={closeButtonDisabled}
                            onClickHandler={givenAction || handleClose}
                            text={cancelButtonText}
                            size="medium"
                        />}
                        {handleFailed && <DangerButtons
                            disabled={failedButtonDisabled}
                            icon={failedButtonIcon}
                            onClickHandler={handleFailed}
                            text={failedButtonText}
                            size="medium"
                        />}
                        <SuccessButtons
                            disabled={successButtonDisabled}
                            icon={successButtonIcon}
                            onClickHandler={handleSuccess}
                            text={successButtonText}
                            size="medium"
                        />
                    </>}
                </DialogActions>
            </Dialog>
    );
};

BaseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    // children: PropTypes.node.isRequired,
    successButtonText: PropTypes.string.isRequired,
    // successButtonIcon: PropTypes.node.isRequired,
    cancelButtonText: PropTypes.string,
    size: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    successButtonDisabled: PropTypes.bool,
    closeButtonDisabled: PropTypes.bool,
    hideDefaultActions: PropTypes.bool,
    hideCloseButton: PropTypes.bool,
    actions: PropTypes.func
};

BaseDialog.defaultProps = {
    successButtonDisabled: false,
    closeButtonDisabled: false,
    hideDefaultActions: false,
    hideCloseButton: false,
    actions: undefined,
    cancelButtonText: 'Cancelar'
}

export default BaseDialog;
