import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import {useSnackbar} from "notistack";
import {Checkbox, FormControlLabel, FormGroup, TextField} from "@material-ui/core";

import moment from "moment";
import Lightbox from 'react-lightbox-component';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import SelectAutocompleteInput from "../../../components/Inputs/SelectAutocompleteInput";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";
import EmailInput from "../../../components/Inputs/EmailInput";
import PhoneInput from "../../../components/Inputs/PhoneInput";

import 'react-lightbox-component/build/css/index.css';

import {
    getProjects,
    getStagesFromProject,
    getPropertiesByStageAndType,
    getClientPropertyRelation
} from "../../../api/projectsApi";

import {
    getClientDocument
} from "../../../api/clientApi";

export default function ClientDetail({user, handleUpdateUser, handleRejectUser, onClose}){

//   const [clientValidated, setClientValidated] = useState(user.is_validated)

  const [userEmail, setUserEmail] = useState(user.email)
  const [userPhone, setUserPhone] = useState(user.phone)

  const [projectAdmin, setProjectAdmin] = useState(false)
  const [secondStepOpen, setSecondStepOpen] = useState(false)
  const [rejectStepOpen, setRejectStepOpen] = useState(false)

  const [rejectReason, setRejectReason] = useState('')

  const [project, setProject] = useState(null)
  const [projects, setProjects] = useState([])

  const [stage, setStage] = useState(null)
  const [stages, setStages] = useState([])

  const [property, setProperty] = useState(null)
  const [properties, setProperties] = useState([])

  const [propertySelected, setPropertySelected] = useState(false)

  const [relation, setRelation] = useState([])

  const { enqueueSnackbar } = useSnackbar()

  const getPropertyRelation = (propertyId) => {
    getClientPropertyRelation(propertyId).then((response) => {
        setRelation(response.data)
    })

}

  const getProperties = (stageId, propertyTypeCode) => {
    getPropertiesByStageAndType(stageId, propertyTypeCode).then((response) => {
        if (response.data !== properties) {
            setProperties(response.data)

            if (user.property_data[0] !== -1 
                && user.property_data[1] !== -1 
                && user.property_data[2] !== -1){
                for (let i = 0; i < response.data.length; i+=1) {
                    if (response.data[i].id === user.property_data[2]){
                        setProperty(response.data[i])
                        setPropertySelected(true)
                        getPropertyRelation(response.data[i].id)
                        break
                    }
                } 
            }
        }
    })
}

  const getStages = (projectId) => {
    getStagesFromProject(projectId).then((response) => {
        if (response.data !== stages) {
            setStages(response.data)

            if (response.data.length === 1) {
                setStage(response.data[0])
                getProperties(response.data[0].id, 'depa')
            } else if (user.property_data[0] !== -1 && user.property_data[1] !== -1){
                for (let i = 0; i < response.data.length; i+=1) {
                    if (response.data[i].id === user.property_data[1]){
                        setStage(response.data[i])
                        getProperties(response.data[i].id, 'depa')
                        break
                    }
                } 
            }
        }
    })
}

  const nextStep = () => {
    setSecondStepOpen(!secondStepOpen)

    getProjects().then((response) => {
        // TODO: Si ID coincide con el obtenido, dejar seleccionado con setProject
        // buscar de una las etapas, y lo mismo
        if (response.data !== projects) {
            setProjects(response.data)
        }
        if (user.property_data[0] !== -1) {
            for (let i = 0; i < response.data.length; i+=1) {
                if (response.data[i].id === user.property_data[0]){
                    setProject(response.data[i])
                    getStages(response.data[i].id)
                    break
                }
            } 
        }
    })
  }

  const rejectStep = () => {
    setRejectStepOpen(!rejectStepOpen)
  }


  const updateUser = () => {
    handleUpdateUser({
        id: user.id,
        projectAdmin,
        propertyId: property.id,
        userPhone,
        userEmail
    });
    onClose();
  };

  const rejectUser = () => {
    handleRejectUser({
        id: user.id,
        reason: rejectReason
    })
    onClose();
  }

  const handleChange = (event) => {
    setProjectAdmin(!projectAdmin);
  }

  const downloadFile = async () => {
    const documentDLname = `${user.file_name}`
    getClientDocument(user.id).then((response) => {
        window.open(response.data, "_blank", "noreferrer");
    })
};

  return <>
      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información del Cliente</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre</Typography>
                        <Typography variant="body1" color="textPrimary">{user.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Rut</Typography>
                        <Typography variant="body1" color="textPrimary">{user.rut}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Email</Typography>
                        <EmailInput
                            value={userEmail}
                            onChange={(event) => {
                                setUserEmail(event.target.value);
                            }}
                            id="user-email"
                            error={false}
                            placeholder="Email"
                            label=""
                            helperText=''
                        />
                        {/* <Typography variant="body1" color="textPrimary">{user.email}</Typography> */}
                    </TableCell>
                    <TableCell/>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Fecha de Registro</Typography>
                        {user.registered_date ? 
                            <Typography variant="body1" color="textPrimary">{moment(user.registered_date).format('DD/MM/YY HH:mm')}</Typography>
                        : <Typography variant="body1" color="textPrimary">Sin registro</Typography>
                        }
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Teléfono</Typography>
                        <PhoneInput
                            value={userPhone}
                            onChange={(event) => {
                                const { value } = event.target
                                const re = /^(\+?56)?(\s?)(0?9)[\s][9876543210\s]*$/;
                                if (re.test(value)) {
                                    setUserPhone(event.target.value);
                                }
                                // setUserPhone(event.target.value);
                            }}
                            id="user-phone"
                            error={false}
                            placeholder="Teléfono"
                            label=""
                            helperText=''
                        />
                        {/* <Typography variant="body1" color="textPrimary">{user.phone}</Typography> */}
                    </TableCell>
                    {user.document_file_uploaded ? 
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Documento proporcionado</Typography>
                        {user.is_image ? <Typography variant="body1" color="textPrimary">
                            <Lightbox 
                                showImageModifiers={false}
                                images={[{src : user.document_file}]}
                            />
                        </Typography> : <Typography 
                                onClick={downloadFile} 
                                component="span" 
                                variant="body1" 
                                color="textPrimary"
                                style={{
                                    cursor: 'pointer',
                                    '&:hover': {cursor: 'pointer'}
                                }}
                            >
                                {user.file_name}
                            </Typography>  }
                    </TableCell> : 
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Documento proporcionado</Typography>
                        <Typography variant="body1" color="textPrimary">Cliente no ha proporcionado documento aún</Typography>
                    </TableCell>}
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Motivos del Error</Typography>
                        <Typography variant="body1" color="textPrimary">
                            {user.client_status.map(msg => (
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    • {msg}
                                </div>
                            ))}
                        </Typography>
                    </TableCell>
                    <TableCell/>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Dirección proporcionada</Typography>
                        <Typography variant="body1" color="textPrimary">{user.data_address}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Número Depto proporcionado</Typography>
                        <Typography variant="body1" color="textPrimary">{user.data_aptnumber}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Comuna proporcionada</Typography>
                        <Typography variant="body1" color="textPrimary">{user.data_commune}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Número de Torre proporcionado</Typography>
                        <Typography variant="body1" color="textPrimary">{user.data_tower}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={12}>
                        <Typography variant="body2" color="textSecondary">Propiedades que coinciden con el rut</Typography>
                        {user.related_properties.length > 0 ? <Typography variant="body1" color="textPrimary">
                            {user.related_properties.map(ppt => (
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    • {ppt}
                                </div>
                            ))}
                        </Typography> : <Typography variant="body1" color="textPrimary"> No se encontraron propiedades </Typography>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={12}>
                        <Typography variant="body1" color="textPrimary">Asignar administrador</Typography>
                        <FormControlLabel
                            control={<Checkbox 
                                key='1'
                                size='medium' 
                                checked={projectAdmin} 
                                onChange={handleChange} 
                                name="admin" />}
                            label={<Typography variant="body1" color="textPrimary">Asignar usuario como administrador de edificio</Typography>}
                            // label="Hemos llegado al lugar a visitar"
                        />
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
    
        <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                marginTop: '1%'
            }}
        >
            <Grid item xs={12} md="auto">
                <SuccessButtons
                icon={<CheckCircleTwoToneIcon />}
                onClickHandler={() => {
                    nextStep();
                }}
                text="Proceder con la validación"
                />
            </Grid>
        </Grid>
        <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                marginTop: '1%'
            }}
        >
            <Grid item xs={12} md="auto">
                <DangerButtons
                icon={<CancelTwoToneIcon />}
                onClickHandler={() => {
                    rejectStep();
                }}
                text="Rechazar Usuario e ingresar motivo"
                />
            </Grid>
        </Grid>

        {secondStepOpen ?
            <Paper>
            <Grid 
                container 
                alignItems="left" 
                // justifyContent="space-between"
                style={{
                    marginTop: '1%'
                }}
            >
                <Grid 
                    item 
                    xs={12} 
                    style={{
                        marginTop: '1%', 
                        marginBottom: '1%',
                        marginLeft: '1%'
                    }}
                >
                    {user.client_exists ?
                        <Typography variant="body1" color="textPrimary">
                            • Cliente existe en la base de datos.
                        </Typography>  :
                        <Typography variant="body1" color="textPrimary">
                            • Se creará nuevo cliente en la base de datos.
                        </Typography> }
                </Grid> 

                <Grid
                    container
                    style={{
                        justifyContent: 'center',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                    }}
                >
                    <Grid
                        item
                        xs={4}
                    >
                        <SelectAutocompleteInput
                            value={project}
                            options={projects}
                            disabled={false}
                            multiple={false}
                            handleChange={(event, newValue) => {
                                setProperty('')
                                setStage('')
                                setPropertySelected(false)
                                setProject(newValue)
                                getStages(newValue.id)
                            }}
                            renderOptionFunction={(option) => {
                                return option.Name
                            }}
                            optionLabelFunction={(option) => {
                                return option.Name
                            }}
                            disableCloseOnSelect={false}
                            inputLabel="Proyecto"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <SelectAutocompleteInput
                            value={stage}
                            options={stages}
                            disabled={false}
                            multiple={false}
                            handleChange={(event, newValue) => {
                                setProperties([])
                                setProperty('')
                                setPropertySelected(false)
                                setStage(newValue)
                                getProperties(newValue.id, 'depa')
                            }}
                            renderOptionFunction={(option) => {
                                return option.name
                            }}
                            optionLabelFunction={(option) => {
                                return option.name
                            }}
                            disableCloseOnSelect={false}
                            inputLabel="Torre"
                        />
                    </Grid>
                    <Grid
                        item
                        xs={4}
                    >
                        <SelectAutocompleteInput
                            value={property}
                            options={properties}
                            disabled={false}
                            multiple={false}
                            handleChange={(event, newValue) => {
                                setProperty(newValue)
                                setPropertySelected(true)
                                getPropertyRelation(newValue.id)
                            }}
                            renderOptionFunction={(option) => {
                                return option.code
                            }}
                            optionLabelFunction={(option) => {
                                return option.code
                            }}
                            disableCloseOnSelect={false}
                            inputLabel="Número"
                        />
                    </Grid>
                </Grid>

                {propertySelected ? <Grid 
                    item 
                    xs={12} 
                    style={{
                        marginTop: '1%', 
                        marginBottom: '1%',
                        marginLeft: '1%'
                    }}
                >
                    {relation.length === 0 ?
                        <Typography variant="body1" color="textPrimary" style={{color: 'green'}}>
                            • La propiedad no tiene una relación previa. Se creará nueva relación con el cliente.
                        </Typography>  :
                        <Typography variant="body1" color="textPrimary" style={{color: 'red'}}>
                            • La propiedad tiene una relación existente con el cliente {relation[0].client.name}. Se reemplazará esta relación existente.
                        </Typography> }
                </Grid> : <></>}

                {user.related_properties.length > 0 ? <Grid 
                    item 
                    xs={12} 
                    style={{
                        marginTop: '1%', 
                        marginBottom: '1%',
                        marginLeft: '1%'
                    }}
                >
                    <Typography variant="body2" color="textSecondary">Detalle</Typography>
                    <Typography variant="body1" color="textPrimary">
                        Las demás propiedades que coincidan con el rut del usuario quedarán disponibles automáticamente al completar la validación.
                    </Typography>
                </Grid> : <></>}

                <Grid 
                    item 
                    xs={12} 
                    style={{
                        marginTop: '1%', 
                        marginBottom: '1%',
                        marginLeft: '1%'
                    }}
                >
                    <SuccessButtons
                        icon={<CheckCircleTwoToneIcon />}
                        onClickHandler={() => {
                            updateUser();
                        }}
                        disabled={!propertySelected}
                        text="Confirmar Validación"
                    />
                </Grid>
            </Grid></Paper> : <></>}
        
            {rejectStepOpen ?
            <Paper>
            <Grid 
                container 
                alignItems="left" 
                style={{
                    marginTop: '1%'
                }}
            >

                <Grid
                    container
                    style={{
                        justifyContent: 'center',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        style={{
                            paddingLeft: '1%',
                            paddingRight: '1%'
                        }}
                    >
                        <TextAreaInput
                            onChange={(event) => {
                                setRejectReason(event.target.value)
                            }}
                            id="reject-reason"
                            value={rejectReason}
                            placeholder="Ingresa el motivo del rechazo."
                            label="Motivo"
                            name="reason"
                            helperText="Se indicará este motivo al usuario y quedará registro en la base de datos."
                        />
                    </Grid>
                </Grid>

                <Grid 
                    item 
                    xs={12} 
                    style={{
                        marginTop: '1%', 
                        marginBottom: '1%',
                        marginLeft: '1%'
                    }}
                >
                    <DangerButtons
                        icon={<CancelTwoToneIcon />}
                        onClickHandler={() => {
                            rejectUser();
                        }}
                        disabled={rejectReason===''}
                        text="Confirmar Rechazo"
                    />
                </Grid>
            </Grid></Paper> : <></>}
        
      </Grid>
      </>
}

ClientDetail.propTypes = {
    user: PropTypes.object,
    handleUpdateUser: PropTypes.func,
    onClose: PropTypes.func,
}

ClientDetail.defaultProps = {
    user: {},
    handleUpdateUser: null,
    onClose: null,
}