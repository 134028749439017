import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import camelcaseKeys from 'camelcase-keys';
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import moment from "moment";
import DangerButtons from "../../../components/Buttons/DangerButtons";

import ClientDetail from "./ClientDetail";
import {
  useUsers,
  validateClientUser,
  rejectClientUser,
  filterUsers,
  getClients
} from "../../../api/usersApi";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
// import BaseTable from "../../components/Tables/BaseTable";
import BaseTable from "./BaseTable";

const ClientValidate = () => {
    
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refetchSwitch, setRefetchSwitch] = useState(false)
    const [userQuery, setUserQuery] = useState('')

    const queryParams = {page: page + 1, maxResults, userQuery}

    const { users, loading } = useUsers(queryParams)
    const [selectedUserId, setSelectedUserId] = useState(null)
    const [user, setUser] = useState(null);
    const userType = localStorage.getItem('userType')
    const history = useHistory();

    const fetchClients = () => {
        getClients(maxResults, page).then((response) => {
            setRows(response.data.rows)
            setTotal(response.data.total)
        })
    }
    
    useEffect(() => {
        fetchClients();
    }, [page, maxResults, refetchSwitch])

    const { enqueueSnackbar } = useSnackbar()

    const HandleUserQuery = async (value) => {
        // setUserQuery(value);
        
        const response = await filterUsers({userQuery: value})
        console.log(response.data)
        users.results = camelcaseKeys(response.data, { deep: true });
        setUserQuery(value);
    }

    const handleUpdateUser = async (data) => {
        const response = await validateClientUser(data);
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡El usuario ha sido validado!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const handleRejectUser = async (data) => {
        const response = await rejectClientUser(data);
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡El usuario ha sido rechazado!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'rut', numeric: false, disablePadding: false, label: 'Rut' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nombre' },
        { id: 'registered_date', numeric: false, disablePadding: false, label: 'Fecha de Registro' },
        { id: 'document_file_uploaded', numeric: false, disablePadding: false, label: 'Documento' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => {
                                        setUser(row);
                                        setSelectedUserId(row.id);
                                        }}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.rut || <i>Sin rut</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.name || <i>Sin nombre</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.registered_date ?
                moment(row.registered_date).format('DD/MM/YY HH:mm')
                :
                <i>No hay registro</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.document_file_uploaded ? <i>Proporcionado</i> : <i>Sin subir</i> }
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;
  if (userType !== 'A'){
    history.push('/')
  }

    return (
      <>
          <BaseDialog
            // hideCloseButton
            open={Boolean(selectedUserId&&user)}
            handleClose={() => {
                setSelectedUserId(null);
                setUser(null);
            }}
            handleSuccess={() => {
                setSelectedUserId(null);
                setUser(null);
            }}
            title="Detalles de cliente"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información del cliente seleccionado."
          >
              {user && <ClientDetail
                  user={user}
                  handleUpdateUser={handleUpdateUser}
                  handleRejectUser={handleRejectUser}
                  onClose={() => {
                    setSelectedUserId(null);
                    setUser(null);
                  }}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Lista de Clientes No Validados"
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
            setUserQuery={HandleUserQuery}
        />
        </>
    );
};

ClientValidate.propTypes = {

};

export default ClientValidate;
