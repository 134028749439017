import { makeStyles } from "@material-ui/core/styles";

const useStylesLogos = makeStyles((theme) => ({
    root: {
        display: 'flex',
        cursor: 'pointer'
    },
    logo: {
        width: theme.spacing(8),
        padding: 2,
        backgroundColor: '#ffffff',
        marginRight: theme.spacing(2),
        cursor: 'pointer'
    },
    itemsAlignedCenter: {
        alignItems: 'center'
    }
}))

export default useStylesLogos