import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";
import VisitListItem from "./VisitListItem";

const useStyles = makeStyles(theme => ({
    list: {
        maxHeight: 600,
        overflowY: 'auto'
    }
}))
export default function VisitList({schedules,
                                   setVisitId,
                                      loading,
                                      showVisitStartDate,
                                   }){
    const classes = useStyles();
    const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;

    return <>
        <List className={classes.list}>
            {schedules.map(t => <VisitListItem key={t.id} showVisitStartDate={showVisitStartDate} schedule={t} onClick={() => setVisitId(t.visitId, t.id)} />)}
        </List>
        {loading && <LinearProgress />}
        {!schedules.length && !loading && <Typography style={{fontSize: isTablet ? "24px" : "default"}}><i>No hay visitas.</i></Typography>}
        </>;
}
