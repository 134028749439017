import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";

import {
  useTicket,
  getTicketsHistory,
  useTickets,
} from "../../../api/ticketApi";
import TicketsHistoryDetail from "./TicketsHistoryDetail"
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import BaseTable from "../../../components/Tables/BaseTable";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";


const TicketHistory = ({projectId, propertyId, ogFaults}) => {
  
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refetchSwitch, setRefetchSwitch] = useState(false)

    const queryParams = {page: page + 1, maxResults}

    const { tickets, loading, removeTicket } = useTickets(queryParams)
    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()

    const fetchTicketPreApproved = () => {
        getTicketsHistory(projectId, propertyId, maxResults, page).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
      })
    }

    useEffect(() => {
        fetchTicketPreApproved();
    }, [page, maxResults, refetchSwitch])

    const isFaultInOgs = (fault) => {
      for (let key=0; key < ogFaults.length; key+=1){
        if (fault[0] === ogFaults[key].faultZoneName &&
            fault[1] === ogFaults[key].faultTargetName &&
            fault[2] === ogFaults[key].faultTypeName){
          return true;
        }
      }
      return false;
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problemas' },
        { id: 'iteration', numeric: false, disablePadding: false, label: 'Número de Requerimiento' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => setSelectedTicketId(row.id)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.state_name || <i>Sin nombre</i> }
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        // const fault = row.faults[0]
        const ticketFault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null
        

        if (ticketFault) {
          content = <>
            {row.faults.map(fault => (
                <div style={{ display: "flex", alignItems: 'center' }}>
                  { isFaultInOgs([fault.fault_zone_name,fault.fault_target_name,fault.fault_type_name]) ?
                    <div style={{ fontSize: "13px", marginRight: 20, color: "#269900" }}>
                    • {fault.fault_zone_name} 
                    - {fault.fault_target_name} 
                    - {fault.fault_type_name}
                  </div> :
                  <div style={{ fontSize: "13px", marginRight: 20 }}>
                    • {fault.fault_zone_name} 
                    - {fault.fault_target_name} 
                    - {fault.fault_type_name}
                  </div>
                  }
                  {
                  fault.solved ? (
                      <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                  ) : (
                      <CancelTwoToneIcon style={{ color: red[500] }} />
                  )
                }
                </div>
            ))}
          </>

        } else {
          content = <i>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell>
                {row.iteration}
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

  if (rows.length === 0) {
    return <Grid 
          container 
          alignItems="center" 
          justifyContent="space-between"
          style={{marginLeft: '20%'}}
        >
        <Typography variant="body1" color="textPrimary">No hay requerimientos asociados a esta Propiedad</Typography>
      </Grid>
  }

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de Requerimiento"
            successButtonIcon={<ExitToAppIcon />}
            successButtonText="Ok"
            size="lg"
            tooltipText="Se detalla la información reportada en el requerimiento."
          >
              {ticket && <TicketsHistoryDetail
                  ticket={ticket}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Historial de Requerimientos"
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
        />
        </>
    );
};

TicketHistory.propTypes = {

};

export default TicketHistory;
