import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import {green, red} from "@material-ui/core/colors";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from '@material-ui/icons/CancelTwoTone';
import AssignmentIcon from '@material-ui/icons/Assignment'

import {
    getTicketForClient,
} from "../../api/ticketApi";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import RUNInput from "../../components/Inputs/RUNInput"
import BaseInput from "../../components/Inputs/BaseInput"

const stateLabels = {
    P: 'El Requerimiento está siendo gestionado, pronto lo contactarán para agendar una visita.',
    A: 'El Requerimiento está siendo atendido, la próxima visita ya está agendada.',
    C: 'El Requerimiento está cerrado. Se envió una encuesta de satisfacción a su correo.',
    PA: 'El Requerimiento está siendo revisado para su aprobación.',
    NA: 'El Requerimiento fue rechazado. Motivo: No aplica postventa. Para mayor información, contactarse a postventa@magal.cl',
    EI: 'El Requerimiento fue rechazado. Motivo: Error en los datos ingresados. Para mayor información, contactarse a postventa@magal.cl',
    S: 'El Requerimiento fue solucionado.',
    CD: 'El Requerimiento está cerrado. Se envió una encuesta de satisfacción a su correo.'
  }


const TicketStatusPage = () => {
    const [rut, setRut] = useState('')
    const [ticketId, setTicketId] = useState('')


    const [ticket, setTicket] = useState(null)
    const [lastVisit, setLastVisit] = useState(null)
    const [nextVisit, setNextVisit] = useState(null)
    const [requested, setRequested] = useState(false)

    const getTicketByIDandRut = () => {
        getTicketForClient(rut, ticketId).then((response) => {
            if (response.data.error){
                setTicket(null)
                setLastVisit(null)
                setNextVisit(null)
            } else {
                setTicket(response.data.row)
                setLastVisit(response.data.last_visit)
                setNextVisit(response.data.next_visit)
            }
            setRequested(true)
        })
    }

    document.title = "Magal PSEE | Seguimiento de Requerimiento "


    return (
        <Box 
            textAlign="center" 
            direction="column"
            alignItems="center"
            mt={3}
            xs={6}
            style={{ paddingTop: '10%'}}
        >  
            <Paper
                style={{
                    padding: 15,
                    justifyContent: 'center'
                }}
                elevation={3}
                xs={12}
                
            >
                <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        item
                        container
                        xs={6}                        
                    >
                        <RUNInput
                            value={rut}
                            onChange={(event) => {
                                setRut(event.target.value)
                            }}
                            id='rut-input'
                            label="Rut del Cliente"
                            placeholder="Ingresa tu Rut"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        item
                        container
                        xs={6}
                    >
                        <BaseInput
                            startAdornment={<AssignmentIcon/>}
                            value={ticketId}
                            onChange={(event) => {
                                setTicketId(event.target.value)
                            }}
                            id='ticketId-input'
                            label="ID de Requerimiento"
                            placeholder="Ingresa el ID del Requerimiento"
                        />
                    </Grid>
                </Grid>
            
                <Grid 
                    item
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center',
                        paddingTop: '1%',
                    }} md="auto"
                >
                    <SuccessButtons
                    icon={<CheckCircleTwoToneIcon />}
                    onClickHandler={() => {
                        getTicketByIDandRut()
                    }}
                    text="Consultar estado de Requerimiento"
                    disabled={ticketId === '' || rut === ''}
                    />
                </Grid>
                {requested ?
                <>{ticket ? 
                    <Grid
                        container
                        spacing={3}
                        xs={12}
                        style={{
                            justifyContent: 'center',
                            paddingTop: '3%',
                            paddingBottom: '3%',
                        }}
                    >
                        <Paper
                            style={{
                                padding: 5,
                                justifyContent: 'center'
                            }}
                            elevation={3}
                        
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant="h6" color="textPrimary">Seguimiento del Requerimiento</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="body2" color="textSecondary">Estado del Requerimiento</Typography>
                                                    <Typography variant="body1" color="textPrimary">{stateLabels[ticket.state_name]}</Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                            <TableCell>
                                                <Typography variant="body2" color="textSecondary">Problemas</Typography>
                                                <Typography variant="body1" color="textPrimary">
                                                    {ticket.faults.map(fault => (
                                                        <div style={{ display: "flex" }}>
                                                        <div style={{ fontSize: "18px", marginRight: 20 }}>
                                                            • {fault.fault_zone_name} 
                                                            - {fault.fault_target_name} 
                                                            - {fault.fault_type_name}
                                                        </div> 
                                                        {
                                                        fault.solved ? (
                                                            <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                                                        ) : (
                                                            <CancelTwoToneIcon style={{ color: red[500] }} />
                                                        )
                                                        }
                                                        </div>
                                                    ))}
                                                </Typography>
                                            </TableCell>
                                            </TableRow>
                                            {(ticket.state_name === 'C'|| 
                                                ticket.state_name === 'P'||
                                                ticket.state_name === 'A'||
                                                ticket.state_name === 'CD'||
                                                ticket.state_name === 'S') ? <><TableRow>
                                                {lastVisit ? <TableCell>
                                                    Última Visita realizada el {moment(lastVisit.real_date).format('DD/MM/YY HH:mm')}
                                                </TableCell> : <TableCell>
                                                    No se han realizado visitas para este Requerimiento.
                                                </TableCell>}
                                            </TableRow>
                                            <TableRow>
                                                {nextVisit ? <TableCell>
                                                    Próxima visita agendada para el {moment(nextVisit.suggested_date).format('DD/MM/YY HH:mm')}
                                                </TableCell> : <TableCell>
                                                    No hay próximas visitas agendadas para este Requerimiento.
                                                </TableCell>}
                                            </TableRow></> : <></>}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                : <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center',
                        paddingTop: '3%',
                        paddingBottom: '3%',
                    }}
                    >
                        <Typography variant="h6" color="textPrimary">No existe un Requerimiento con los datos ingresados.</Typography>
                    </Grid>}</> : <></>
                }
            </Paper>
        </Box> 
    );
};

export default TicketStatusPage;