export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGOUT_USER = "LOGOUT_USER";

export const SNACKBAR_OPEN = "SNACKBAR_OPEN";
export const SNACKBAR_TEXT = "SNACKBAR_TEXT";
export const SNACKBAR_VARIANT = "SNACKBAR_VARIANT";

export const USER_NAME = "USER_NAME";

// Report part 1
export const REPORT_PROJECTS = "REPORT_PROJECTS"
export const REPORT_PROJECT = "REPORT_PROJECT"
export const REPORT_STAGES = "REPORT_STAGES"
export const REPORT_STAGE = "REPORT_STAGE"
export const REPORT_PROPERTY_TYPES = "REPORT_PROPERTY_TYPES"
export const REPORT_PROPERTY_TYPE = "REPORT_PROPERTY_TYPE"
export const REPORT_PROPERTIES = "REPORT_PROPERTIES"
export const REPORT_PROPERTY = "REPORT_PROPERTY"
export const REPORT_CLIENTINFO = "REPORT_CLIENTINFO"

// Report part 2
export const REPORT_RELATIONS = "REPORT_RELATIONS"
export const REPORT_RELATION = "REPORT_RELATION"
export const REPORT_CLIENTS = "REPORT_CLIENTS"
export const REPORT_CLIENT = "REPORT_CLIENT"
export const REPORT_NAME = "REPORT_NAME"
export const REPORT_RUN = "REPORT_RUN"
export const REPORT_EMAIL = "REPORT_EMAIL"
export const REPORT_PHONE = "REPORT_PHONE"
export const REPORT_ISOWNER = "REPORT_ISOWNER"
export const REPORT_OWNERNAME = "REPORT_OWNERNAME"
export const REPORT_OWNERRUN = "REPORT_OWNERRUN"
export const REPORT_OWNEREMAIL = "REPORT_OWNEREMAIL"
export const REPORT_OWNERPHONE = "REPORT_OWNERPHONE"

// Report part 3
export const REPORT_FAULTS = "REPORT_FAULTS"
export const REPORT_FAULTNUMBER = "REPORT_FAULTNUMBER"

// Report part 4
export const REPORT_IMAGES = "REPORT_IMAGES"
export const REPORT_COMMENT = "REPORT_COMMENT"