import React from "react";
import {ListItem, ListItemSecondaryAction, ListItemText, SvgIcon} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";

export default function VisitListItem({schedule, onClick, showVisitStartDate }){
    return <ListItem button onClick={() => onClick(schedule.id)}>
        <ListItemText primary={schedule.title} secondary={moment(showVisitStartDate ? schedule.visitRealDate : schedule.start).format('DD-MM-YYYY HH:mm')} />
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="details" onClick={() => onClick(schedule.id)}>
                <VisibilityIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
}
