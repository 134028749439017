import React, {useRef, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {useTicket, useTickets} from "../../api/ticketApi";
import TicketList from "./TicketList";
import VisitCalendar from "./VisitCalendar";


const getQueryParams = ({recalendarizeOnly, overdue}) => {
    if (recalendarizeOnly) return {state__state_category: 'A'}
    if (overdue) return {state__state_category: 'A', overdue: true}
    return {state__state_category: 'P'}
}

const CalendarReadOnly = ({recalendarizeOnly, overdue}) => {
    const queryParams = getQueryParams({recalendarizeOnly, overdue})

    const userType = localStorage.getItem('userType')

    if (userType === "ING") {
        queryParams.project__ingeneer__username = localStorage.getItem('username')
    }

    const { tickets, loading, removeTicket } = useTickets({page: 1, page_size: 100, ...queryParams})
    const calendarRef = useRef(null);

    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId});
    const [ ticketForCreationPopup, setTicketForCreation ] = useState(null);

    if (loading) return <LinearProgress />;

    return (
        <Grid 
            container 
            spacing={3} 
            style={{
                paddingLeft: '2%',
                paddingTop: '2%'
            }}
        >
            <Grid item xs={12} md={9} >
                <VisitCalendar
                    // ticket={ticketForCreationPopup}
                    // removeTicket={removeTicket}
                    // ticketForCreationPopup={ticketForCreationPopup}
                    calendar={calendarRef}
                    calendarRef={calendarRef}
                    isReadOnly={false}
                    onShowCreationPopup={null}
                    // setTicketForCreationPopup={setTicketForCreation}
                />
            </Grid>
        </Grid>
    );
};

export default CalendarReadOnly;