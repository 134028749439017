import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from "notistack";
import BaseDialog from "../Dialogs/BaseDialog";
// import RegisterForm from "./RegisterForm";
import RegisterFormClient from "./RegisterForm";
import { obtainToken } from "../../api/authenticationApi"
import { registerNewClient } from "../../api/usersApi";
import { setUserName } from "../../actions/userActions";

const ClientRegisterForm = (props) => {
    const {
        open,
        handleClose,
        communes,
        setUserName // eslint-disable-line no-shadow
    } = props

    const { enqueueSnackbar } = useSnackbar()

    const loginFormRef = useRef(null)
    const history = useHistory()
    const [disabledSuccess, setDisabledSuccess] = useState(true)

    const isDesktop = window.innerWidth > 980;

    // const checkDisabled = () => {
    //     const {
    //         username,
    //         userEmail,
    //         fullName,
    //         userPhone,
    //         propertyStreet,
    //         propertyCode,
    //         propertyTorre,
    //         propertyComuna
    //     } = loginFormRef.current.state

    //     if (username === ''){
    //         return true
    //     }
    //     return false
    // }

    const redirectOnUserPermissions = (typeCode) => {
        switch (typeCode) {
            case 'A':
            case 'EPV':
                history.push('/tickets')
                break
            case 'ING':
                history.push('/tickets/pendientes')
                break
            case 'ET':
                history.push('/equipo_tecnico')
                break
            case 'C':
                history.push('/cliente')
                break
            default:
                history.push('/')
        }
    }

    // const proceedWithRegister = (data) => {
    //     registerNewClient({
    //         username: data.username, 
    //         email: data.userEmail,
    //         fullName: data.fullName,
    //         phone: data.userPhone,
    //         propertyAddress: data.propertyStreet,
    //         propertyCode: data.propertyCode,
    //         propertyTorre: data.propertyTorre,
    //         propertyComuna: data.propertyComuna
    //     }).then((response) => {
    //         if (response.data.error) {
    //             enqueueSnackbar(
    //                 response.data.error,
    //                 {
    //                     variant: 'error'
    //                 }
    //             )
    //         } else {
    //             enqueueSnackbar(
    //                 'Su cuenta ha sido creada con éxito. Se ha enviado un mail con su contraseña al correo electrónico proporcionado.',
    //                 {
    //                     variant: 'success'
    //                 }
    //             )
    //             handleClose()
    //         }
    //     })
    // }

    return <>
        <BaseDialog
            open={open}
            handleClose={handleClose}
            handleSuccess={() => {
                const {
                    username,
                    usernameError,
                    userEmail,
                    fullName,
                    userPhone,
                    propertyStreet,
                    propertyCode,
                    propertyTorre,
                    propertyComuna
                } = loginFormRef.current.state

                // registerNewClient({
                //     username, 
                //     email: userEmail,
                //     fullName,
                //     phone: userPhone,
                //     propertyStreet,
                //     propertyCode,
                //     propertyTorre,
                //     propertyComuna
                // }).then(() => {

                //     handleClose()
                // }).catch(() => {
                //     enqueueSnackbar(
                //         'No se ha podido registrar el usuario. Revisa tus datos.',
                //         {
                //             variant: 'error'
                //         }
                //     )
                // })

                registerNewClient({
                    username, 
                    email: userEmail,
                    fullName,
                    phone: userPhone,
                    propertyAddress: propertyStreet,
                    propertyCode,
                    propertyTorre,
                    propertyComuna
                }).then((response) => {
                    if (response.data.error) {
                        enqueueSnackbar(
                            response.data.error,
                            {
                                variant: 'error'
                            }
                        )
                    } else {
                        enqueueSnackbar(
                            'Su cuenta ha sido creada con éxito. Se ha enviado un mail con su contraseña al correo electrónico proporcionado.',
                            {
                                variant: 'success'
                            }
                        )
                        handleClose()
                    }
                })
                // if (username === '' || usernameError || userEmail === '' || fullName === '') {
                //     enqueueSnackbar(
                //         'Completa todos los campos para el registro.',
                //         {
                //             variant: 'error'
                //         }
                //     )
                // } else {
                //     // aca función de envio para creacion de nuevo usuario
                // }
            }}
            title="Crear nueva cuenta"
            successButtonDisabled={disabledSuccess}
            successButtonText="Registrarse"
            successButtonIcon={<ExitToAppIcon />}
            size={isDesktop ? 'xs' : 'lg'}
            tooltipText={
                `En este cuadro se deben completar los 
                 datos para crear una nueva cuenta y 
                 acceder al sistema.`
            }
        >
            <Typography 
                variant="body1" 
                color="white"
                style={{ 
                    fontSize: '15px',
                    fontColor: 'white',
                    marginRight: '5%',
                    marginLeft: '5%',
                    marginBottom: '5%',
                }}
            >
                Completa los datos indicados a continuación para crear una nueva cuenta.                                  
            </Typography>
            <RegisterFormClient 
                communes={communes} 
                ref={loginFormRef} 
                successDisabledControl={setDisabledSuccess}
                // proceedWithRegister={proceedWithRegister}
            />
        </BaseDialog>
    </>
};

ClientRegisterForm.propTypes = {
    open: PropTypes.bool.isRequired,
    communes: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    setUserName: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setUserName
}

export default connect(null, mapDispatchToProps)(ClientRegisterForm);
