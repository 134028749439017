import React from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";
import ConsolidateTickets from "./ConsolidateTickets";
import NotClosedTickets from "./NotClosedTickets";
import SolvedTickets from "./Solved/SolvedTickets";
import ClosedTickets from "./ClosedTickets";
import RejectedTickets from "./Rejected/RejectedTickets";


const TicketsHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const { location } = history
    const { path, url } = useRouteMatch();
    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/tickets/pendientes':
                return 1

            case '/tickets/activos':
                return 2

            case '/tickets/solucionados':
                return 3

            case '/tickets/cerrados':
                return 4

            case '/tickets/':
                return 0

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Requerimientos"

    const userType = localStorage.getItem('userType')

    if (!isAuth || userType === 'ET' || userType === 'C') {
        history.push('/')
    } else if (userType === 'ING' || userType === 'SUP') {
        if (location.pathname === '/tickets/' || location.pathname === '/tickets') {
            history.push('/tickets/pendientes')
            setValue(1)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabTextSize = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return '22px'
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return '17px'
        }
        return 'default'
    }

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column',
                height: '90vh'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="tickets sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Consolidar"
                    style={
                        userType === "ING" || userType === "SUP" ? {
                            borderRight: '1px solid black',
                            display: 'none',
                            fontSize: getTabTextSize()
                        } : { borderRight: '1px solid black', fontSize: getTabTextSize() }
                    }
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Pendientes"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/pendientes`)}
                />
                <Tab
                    label="Activos"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/activos`)}
                />
                <Tab
                    label="Solucionados"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/solucionados`)}
                />
                <Tab
                    label="Cerrados"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/cerrados`)}
                />
                <Tab
                    label="Rechazados"
                    style={{fontSize: getTabTextSize()}}
                    onClick={() => history.push(`${url}/rechazados`)}
                />
            </Tabs>

            <Switch>
                <Route exact path={path} component={ConsolidateTickets} />
                <Route path={`${path}/pendientes`} component={NotClosedTickets} />
                <Route path={`${path}/activos`} component={() => <NotClosedTickets active />} />
                <Route path={`${path}/solucionados`} component={() => <SolvedTickets/>} />
                <Route path={`${path}/cerrados`} component={ClosedTickets} />
                <Route path={`${path}/rechazados`} component={RejectedTickets} />
            </Switch>
        </div>
    );
};

export default TicketsHomePage;