import React, {useCallback, useState} from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";

// import UsersTable from "./UsersTable";
// import UsersCreate from "./UsersCreate";
import MassiveLoad from "./MassiveLoad";
import ProjectsTable from "./Projects/ProjectsTable";
// import ClientValidate from "./Validate/ClientValidate"
// import ProjectCreate from './Projects/ProjectCreate';
import TeamsTable from "./Teams/TeamsTable"
// import TeamCreate from "./Teams/TeamCreate"

// const UserTable = () => <UsersTable />
// const ClientTable = () => <ClientValidate />
// const UserCreate = () => <UsersCreate />
const MassiveLoadTable = () => <MassiveLoad /> // to edit
const ProjectTable = () => <ProjectsTable />
// const ProjectAdd = () => <ProjectCreate />
const TeamTable = () => <TeamsTable />
// const TeamAdd = () => <TeamCreate />

const DataHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const location = useLocation()
    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/datos/':
                return 0

            case '/datos/proyectos':
                return 1

            case '/datos/equipos':
                return 2

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Manejo de Datos"

    const userType = localStorage.getItem('userType')

    if (!isAuth || userType !== 'A') {
        history.push('/')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="management sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Carga de Datos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(url)}
                />
                {/* <Tab
                    label="Carga de Datos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/carga`)}
                /> */}
                <Tab
                    label="Administrar Proyectos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/proyectos`)}
                />
                <Tab
                    label="Administrar Equipos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/equipos`)}
                />
            </Tabs>

            <Switch>
                {/* <Route exact path={path} component={UserTable} /> */}
                <Route exact path={path} component={MassiveLoadTable} />
                <Route path={`${path}/proyectos`} component={ProjectTable} />
                <Route path={`${path}/equipos`} component={TeamTable} />
            </Switch>
        </div>
    );
};

export default DataHomePage;