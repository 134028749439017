import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import DangerButtons from "../../components/Buttons/DangerButtons";
import FullNameInput from "../../components/Inputs/FullNameInput";
import EmailInput from "../../components/Inputs/EmailInput";
import UsernameInput from "../../components/Inputs/UsernameInput";
import PasswordInput from "../../components/Inputs/PasswordInput";
import 'react-lightbox-component/build/css/index.css';
import {
    createUser,
  } from "../../api/usersApi";


const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
    CA: 'Consulta'
  }

export default function UsersCreate(){

  const [userUsername, setUserUsername] = useState('')
  const [userFirstName, setUserFirstName] = useState('')
  const [userLastName, setUserLastName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [userTypeCode, setUserTypeCode] = useState('A')
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar()

  const [typeCodeError, setTypeCodeError] = useState(false)
  const [typeCodeMessage, setTypeCodeMessage] = useState('')

  const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        container: {
            maxHeight: '85vh'
        },
  }));
  const classes = useStyles();
  

  const createNewUser = async () => {
    // Validar inputs

    if (userTypeCode === ''){
        setTypeCodeError(true);
        setTypeCodeMessage('Selecciona un tipo de usuario');
        return false;
    }
    setTypeCodeError(false);
    setTypeCodeMessage('');
    
    const response = await createUser({
        username: userUsername,
        firstName: userFirstName,
        lastName: userLastName,
        email: userEmail,
        typeCode: userTypeCode,
    });
    setUserUsername('');
    setUserFirstName('');
    setUserLastName('');
    enqueueSnackbar(
        'El usuario ha sido agregado y se ha enviado un mensaje con la contraseña a su correo.',
        {
            variant: 'success'
        }
    );
    setTimeout( () => {
        history.push(`/administracion/`);
        window.location.reload();
    }, 1500)
    return true;
  };

  return <>
    <Grid
        container
        spacing={8}
        mb={3}
        style={{
            paddingTop: '1%',
            paddingLeft: '1%',
            paddingRight: '1%',
            justifyContent: "center"
        }}
        className={classes.container}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 0
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid 
                    item 
                    xs={12} 
                    container 
                    mb={3} 
                    style={{
                        justifyContent: "center"
                    }}
                >
                    <Typography variant="h6"  color="textPrimary">
                        Agregar nuevo Usuario
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Nombre de usuario</Typography>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <UsernameInput
                                            value={userUsername}
                                            onChange={(event) => {
                                                setUserUsername(event.target.value);
                                            }}
                                            id="user-username"
                                            error={false}
                                            placeholder="Nombre de Usuario"
                                            label=""
                                            helperText=''
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Nombre</Typography>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <FullNameInput
                                            value={userFirstName}
                                            onChange={(event) => {
                                                setUserFirstName(event.target.value);
                                            }}
                                            id="user-firstname"
                                            error={false}
                                            placeholder="Nombre"
                                            label=""
                                            helperText=''
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Apellido</Typography>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <FullNameInput
                                            value={userLastName}
                                            onChange={(event) => {
                                                setUserLastName(event.target.value);
                                            }}
                                            id="user-lastname"
                                            error={false}
                                            placeholder="Apellido"
                                            label=""
                                            helperText=''
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Email</Typography>
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <EmailInput
                                            value={userEmail}
                                            onChange={(event) => {
                                                setUserEmail(event.target.value);
                                            }}
                                            id="user-email"
                                            error={false}
                                            placeholder="Email"
                                            label=""
                                            helperText=''
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body2" color="textSecondary">Tipo de Usuario</Typography>
                                    <Typography variant="body1" color="textPrimary">
                                        <FormControl variant="outlined" >
                                            {/* <InputLabel htmlFor="outlined-age-native-simple">Tipo de Usuario</InputLabel> */}
                                            <Select
                                                native
                                                value={userTypeCode}
                                                onChange={(event) => {
                                                    setUserTypeCode(event.target.value);
                                                }}
                                                label=""
                                                error={typeCodeError}
                                                helperText={typeCodeMessage}
                                                inputProps={{
                                                    name: 'userCode',
                                                    id: 'user-typecode',
                                                }}
                                            >
                                            {
                                                ['A', 'EPV' , 'SUP', 'ET', 'ING', 'CA'].map(userType => (
                                                    <option value={userType} label={userTypes[userType]}  />
                                                ))
                                            }
                                            </Select>
                                        </FormControl>
                                    
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            
                <Grid 
                    container 
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    style={{
                        paddingTop: '2%',
                        paddingBottom: '2%',
                        paddingLeft: '1%',
                        paddingRight: '1%',
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<CheckCircleTwoToneIcon />}
                        onClickHandler={() => {
                            createNewUser();
                        }}
                        text="Crear Usuario"
                        />
                    </Grid>
                </Grid>
            
            </Grid>
            </TableContainer>
            </Paper>
        </Grid>

    </Grid>
    </>
}

UsersCreate.propTypes = {

}

UsersCreate.defaultProps = {
}