import Table from "@material-ui/core/Table";
import axios from "axios";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import camelcaseKeys from 'camelcase-keys';
import Divider from "@material-ui/core/Divider";
import TableContainer from '@material-ui/core/TableContainer';
import Paper from "@material-ui/core/Paper";
import {FormControlLabel, Checkbox} from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import QueueIcon from "@material-ui/icons/Queue";
import HomeIcon from "@material-ui/icons/Home";
import UsernameInput from "../../../components/Inputs/UsernameInput";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";

import BaseDialog from "../../../components/Dialogs/BaseDialog";
import PropertiesTable from "./PropertiesTable";
import PropertyDetail from "./PropertyDetail";
import {
    // getPropertiesByProject,
    uploadProperties,
    filterProperties,
    deleteCPR,
    updateProperty
} from "../../../api/projectsApi";
import 'react-lightbox-component/build/css/index.css';

const propertyTypes = {
    depa: 'Departamento',
    park: 'Estacionamiento',
    cell: 'Bodega',
    local: 'Local',
    ac: 'Areas Comunes',
    zc: 'Zonas Comunes'
  }

export default function ProjectDetail({project, properties, setProperties, reloadProperties, engineers, handleUpdateProject, onClose}){

    const [projectName, setProjectName] = useState(project.name)
    const [projectEngineer, setProjectEngineer] = useState(project.ingeneer)
    const [stageList, setStageList] = useState(project.stageList)
    const [rutAdmin, setRutAdmin] = useState(project.rutAdmin)
    const [projectActive, setProjectActive] = useState(project.active)

    const [propertyActQuery, setPropertyActQuery] = useState('all')

    const [ogProperties, setOgProperties] = useState(properties)


    // const [properties, setProperties] = useState(null)
    const [propertiesViewOpen, setPropertiesViewOpen] = useState(false)
    const [addPropertiesViewOpen, setAddPropertiesViewOpen] = useState(false)

    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)

    const [property, setProperty] = useState(null);
    const { enqueueSnackbar } = useSnackbar()

    const [propertiesQuery, setPropertiesQuery] = useState('')

    const [selectedPropertyId, setSelectedPropertyId] = useState(null)
    const [singlePropertyViewOpen, setSinglePropertyViewOpen] = useState(false)
    const [uploadResultsProperties, setUploadResultsProperties] = useState(null);

    const [projectStages, setProjectStages] = useState(project.stages)

    const downloadTemplate = async () => {
        const accessToken = localStorage.getItem("access_token");
        axios({
            url: `${process.env.API_URL}project/get_template_properties`,
            timeout: 5000,
            method: 'GET',
            headers: {
                Authorization: accessToken ? `JWT ${accessToken}` : null,
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template_Propiedades.xlsx');
            document.body.appendChild(link);
            link.click();
        })
    };

    const headCells = [
            { id: 'action', numeric: false, disablePadding: false, label: '' },
            { id: 'code', numeric: false, disablePadding: false, label: 'Código' },
            { id: 'remaining_days', numeric: false, disablePadding: false, label: 'Días restantes' },
            { id: 'is_complete', numeric: false, disablePadding: false, label: 'Estado' },
            { id: 'property_type', numeric: false, disablePadding: false, label: 'Tipo' },
            { id: 'stage_number', numeric: false, disablePadding: false, label: 'Etapa' },
        ];

    const rowComponents = [
        (row, isItemSelected, labelId) => (
            <TableCell align="left">
                <IconButton color="primary" onClick={e => {
                                          setProperty(row);
                                          setSelectedPropertyId(row.id);
                                          setSinglePropertyViewOpen(true);
                                          }}>
                    <VisibilityIcon />
                </IconButton>
            </TableCell>
          ),
          (row, isItemSelected, labelId) => (
              <TableCell>
                  {row.code || <i>Sin código</i>}
              </TableCell>
          ),
          (row, isItemSelected, labelId) => (
            // <TableCell>
            //     {row.remaining_days || <i>Sin definir</i>}
            // </TableCell>
            <>
            {row.remaining_days !== null ? 
            <TableCell>
                {row.remaining_days > 0 ? 
                <Typography variant="body2" color="textSecondary" style={{color: 'green'}}>
                    {row.remaining_days}
                </Typography> : 
                <Typography variant="body2" color="textSecondary" style={{color: 'red'}}>
                    Plazo vencido
                </Typography>}
            </TableCell> : 
            <TableCell>
                Sin definir
            </TableCell>
            }
            </>
          ),
          (row, isItemSelected, labelId) => (
            <TableCell>
                {!row.is_complete ? 
                <Typography variant="body2" color="textSecondary" style={{color: 'green'}}>
                    Activa
                </Typography> : 
                <Typography  variant="body2" color="textSecondary" style={{color: 'red'}}>
                    Desactivada
                </Typography>
                }
            </TableCell>
          ),
          (row, isItemSelected, labelId) => (
              <TableCell>
                  {propertyTypes[row.property_type] || <i>Sin tipo</i>}
              </TableCell>
          ),
          (row, isItemSelected, labelId) => (
              <TableCell>
                  { row.stage_number || <i>Sin Etapa</i> }
              </TableCell>
          ),
      ]

/* eslint no-var: off */
/* eslint no-param-reassign: "error" */
    const setDateChange = (e, id) => {
        var result = [...stageList];
        result = result.map((x) => {
            if (x.id === id){
                x.municipalReceptionDate = e;
            } 
            return x;
        });
        setStageList(result);
    }
    

    // const getProperties = () => {
    //     getPropertiesByProject(project.id).then((response) => {
    //         setProperties(response.data)
    //     })
    // }

    // useEffect(() => {
    //     let ignore = false;
        
    //     if (!ignore){
    //         getProperties()
    //     }        
    //     return () => { ignore = true; }
    //     },[]);

    const HandleActiveQuery = (value) => {
        if (ogProperties === null){
            setOgProperties(properties)
        }
        if (value !== propertyActQuery){
            setPropertyActQuery(value)
            if (value === 'all'){
                setProperties(ogProperties)
            } else if (value === 'active'){
                const propertiesFiltered = []
                if (ogProperties === null){
                    for (let i = 0; i < properties.length; i+=1){
          
                        if (!properties[i].is_complete){
                            propertiesFiltered.push(properties[i])
                        }
                      }
                } else{
                    for (let i = 0; i < ogProperties.length; i+=1){
              
                        if (!ogProperties[i].is_complete){
                            propertiesFiltered.push(ogProperties[i])
                        }
                      }
                }
                setProperties(propertiesFiltered)
            } else {
                const propertiesFiltered = []
                if (ogProperties === null){
                    for (let i = 0; i < properties.length; i+=1){
          
                        if (properties[i].is_complete){
                            propertiesFiltered.push(properties[i])
                        }
                      }
                } else{
                    for (let i = 0; i < ogProperties.length; i+=1){
              
                        if (ogProperties[i].is_complete){
                            propertiesFiltered.push(ogProperties[i])
                        }
                      }
                }
                setProperties(propertiesFiltered)
            }
        }
    }

    const HandleUserQuery = async (value) => {

        const response = await filterProperties({propertiesQuery: value, projectId: project.id})
        // setProjectProperties(camelcaseKeys(response.data, { deep: true }));
        setProperties(response.data)
        setOgProperties(response.data)
        setPropertiesQuery(value);
        HandleActiveQuery(propertyActQuery)

    }

    // const deleteProject = () => {
    //     handleDeleteProject({
    //         id: project.id
    //     });
    //     onClose();
    // };

    const updateProject = () => {
        handleUpdateProject({
            id: project.id,
            projectEngineer,
            stageList,
            rutAdmin,
            projectActive
        });
        onClose();
    };

    const hiddenFileInputProperties = React.useRef(null);

    const [selectedFileProperties, setSelectedFileProperties] = useState();
    const [selectedOnceProperties, setSelectedOnceProperties] = useState(false);
    const [fileNameProperties, setFileNameProperties] = useState(null);
    const [fileProcessedProperties, setFileProcessedProperties] = useState(false);

    const [projectSelectedStage, setProjectSelectedStage] = useState(project.stageList[0])

    const handleUploadClickProperties = event => {
        hiddenFileInputProperties.current.click();
    }

    const handleFileChangeProperties = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFileProperties(event.target.files[0]);
        setFileNameProperties(event.target.files[0].name);
        setSelectedOnceProperties(true);
    }

    const handleDeleteProperty = () => {
        console.log("Test delete property")
    }

    const handleUpdateProperty = async (data) => {
        const response = await updateProperty(data);
        if (response.data.error){
            enqueueSnackbar(
                'Hubo un error al ejecutar la acción.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡Se han actualizado los campos de la Propiedad!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const handleRemoveRelation = async (data) => {

        const response = await deleteCPR(data);
        if (response.error){
            enqueueSnackbar(
                'Hubo un error al ejecutar la acción.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡Se ha eliminado la Relación Cliente-Propiedad!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
        
    }

    const useStyles = makeStyles((theme) => ({
        paper: {
            width: '100%',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    }));

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleAccordionChange = (name) => () => {
        if (expanded !== name) setExpanded(name);
        else setExpanded('');
    };

    const propertiesLoadFile = async () => {
        // Validar inputs
    
        // Useful for other things
        const formData = new FormData();
        formData.append('file', selectedFileProperties);
        if (projectSelectedStage.id){
            formData.append('stage', projectSelectedStage.id);
        } else {
            formData.append('stage', projectSelectedStage);
        }
        // console.log(projectSelectedStage);
        // formData.append('stage', projectSelectedStage.id);
        formData.append('project', project.id);
    
        const response = await uploadProperties(formData);
    
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else{
            enqueueSnackbar(
                'Se ha realizado la carga de Propiedades con éxito.',
                {
                    variant: 'success'
                }
            );
            setUploadResultsProperties(response.data);
            setFileProcessedProperties(true);
        }
        
        return true;
      };


      const handleChangeStage = (event) => {
        if (event === undefined){
          return;
        }    
        const newValue = event.target.name;
        const rowsTest = [...stageList];
    
        for (let i = 0; i < rowsTest.length; i+=1){
          
          if (rowsTest[i].id === parseInt(newValue,10)){
            rowsTest[i].isActive = !rowsTest[i].isActive;
          }
        }
        setStageList(rowsTest);
      }


  return <>

        <BaseDialog
            // hideCloseButton
            open={Boolean(addPropertiesViewOpen)}
            handleClose={() => {
                setAddPropertiesViewOpen(false);
            }}
            handleSuccess={() => {
                setAddPropertiesViewOpen(false);
            }}
            title='Agregar Propiedades'
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Para cargar propiedades a este proyecto, sube el archivo Excel con una hoja llamada 'Propiedad' que siga el formato de carga masiva."
        >
            <Grid
                container
                spacing={8}
                mb={3}
                style={{
                    paddingTop: '1%',
                    paddingLeft: '1%',
                    paddingRight: '1%',
                    justifyContent: 'center'
                }}
            >
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    mb={3}
                    style={{
                        paddingBottom: 0
                    }}
                >
                    <Paper className={classes.paper}>
                    <TableContainer>
                    <Grid container>
                        <Grid 
                            item xs={12} 
                            container 
                            mb={12}
                            justifyContent="center" 
                            display="flex"
                            alignItems="center"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '1%',
                                marginTop: '1%'
                            }}
                        >
                            <Typography 
                                variant="h6"  
                                color="textPrimary"
                                component='div'
                            >
                                Carga de Propiedades
                            </Typography>
                        </Grid>
                        <Grid 
                            item xs={12} 
                            container 
                            mb={12}
                            display="flex"
                            alignItems="center"
                            style={{
                                display: 'flex',
                                paddingLeft: '2%',
                                paddingRight: '2%'
                            }}
                        >
                            <Typography 
                                variant="body2"
                                color="textSecondary"
                                component='div'
                            >
                                A continuación se pueden agregar nuevas Propiedades a la base de datos, para el Proyecto {project.name} . Para esto, se puede usar el template de carga masiva o descargar el template a continuación. La hoja debe llamarse &apos;Propiedad&apos;.
                            </Typography>
                        </Grid>
                        <Grid 
                            item xs={12} 
                            container 
                            mb={12}
                            display="flex"
                            alignItems="center"
                            style={{
                                display: 'flex',
                                paddingLeft: '2%',
                                paddingRight: '2%'
                            }}
                        >
                            <Grid item xs={12}>
                                <SuccessButtons
                                    onClickHandler={downloadTemplate}
                                    text="Descargar Template"
                                />                        
                            </Grid>
                        </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell key='projectStage'>
                                            <Typography component='div' variant="body2" color="textSecondary">Seleccionar Etapa</Typography>
                                                <Typography component='div' variant="body1" color="textPrimary">
                                                    <FormControl variant="outlined" >
                                                        <Select
                                                            native
                                                            value={projectSelectedStage.id}
                                                            onChange={(event) => {
                                                                setProjectSelectedStage(event.target.value);
                                                            }}
                                                            label=""
                                                            inputProps={{
                                                                name: 'stage',
                                                                id: 'project-stage',
                                                            }}
                                                        >
                                                        <option aria-label="None" value={projectSelectedStage.id} label={projectSelectedStage.name} selected={projectSelectedStage.id}/>
                                                        {
                                                            project.stageList.map(stage => (stage.id !== projectSelectedStage.id) ?
                                                                (<option value={stage.id} label={stage.name}  />) :
                                                                (<></>)
                                                            )
                                                        }
                                                        </Select>
                                                    </FormControl>
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Typography component='div' variant="body2" color="textSecondary">Carga el Archivo Excel</Typography>
                                                <Grid item xs={12} md="auto">
                                                    <Typography component='div' variant="body2" color="textPrimary">{fileNameProperties}</Typography>
                                                    <SuccessButtons
                                                        icon={<SearchIcon />}
                                                        onClickHandler={handleUploadClickProperties}
                                                        text="Seleccionar archivo"
                                                    />
                                                    <input 
                                                        type="file" 
                                                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                        ref={hiddenFileInputProperties}
                                                        onChange={handleFileChangeProperties}
                                                        style={{display: 'none'}}
                                                        />
                                                </Grid>
                                            </TableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </Grid>

                            <Grid 
                                container 
                                alignItems="center"
                                mb={3}
                                style={{
                                    paddingTop: '2%',
                                    paddingBottom: '2%',
                                    paddingLeft: '2%',
                                    paddingRight: '2%',
                                }}
                            >
                                <Grid item xs={12} md="auto">
                                    <SuccessButtons
                                    icon={<CheckCircleTwoToneIcon />}
                                    onClickHandler={() => {
                                        propertiesLoadFile();
                                    }}
                                    disabled={!selectedOnceProperties}
                                    text="Cargar Propiedades"
                                    />
                                </Grid>
                            </Grid>
                        
                        </Grid>
                        </TableContainer>
                        {fileProcessedProperties ? <Grid container>
                            <Grid 
                                item 
                                xs={12} 
                                container 
                                mb={3} 
                                alignItems="center" 
                                style={{
                                    justifyContent: 'center',
                                    paddingTop: 10,
                                    paddingLeft: 3
                                }}
                            >
                                <Typography 
                                    component='div'
                                    variant="h6"  
                                    color="textPrimary" 
                                    alignItems="center" 
                                    align="center"
                                >
                                    Resultados de la Carga
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        <TableRow colSpan='100%'>

                                            <TableCell>
                                                <Typography component='div' variant="body2" color="textPrimary">Propiedades Agregadas : {uploadResultsProperties.successful_amount}</Typography>
                                                <Typography component='div' variant="body2" color="textPrimary">Propiedades Existentes : {uploadResultsProperties.existing_amount}</Typography>
                                                {uploadResultsProperties.existing_detail.length !== 0 ? <Accordion key='properties_existing' expanded={expanded === 'properties_existing'} onChange={handleAccordionChange('properties_existing')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                    >
                                                        Detalle de Propiedades Existentes
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box style={{paddingRight: '36px'}} width="100%">
                                                                {uploadResultsProperties.existing_detail.map(row => (
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} md={12}>
                                                                            <Typography component='div' variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion> : <></>}
                                                <Typography component='div' variant="body2" color="textPrimary">Propiedades con Error : {uploadResultsProperties.failed_amount}</Typography>
                                                {uploadResultsProperties.failed_detail.length !== 0 ? <Accordion key='properties_failed' expanded={expanded === 'properties_failed'} onChange={handleAccordionChange('properties_failed')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1c-content"
                                                        id="panel1c-header"
                                                    >
                                                        Detalle de Propiedades con Error
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <Box style={{paddingRight: '36px'}} width="100%">
                                                                {uploadResultsProperties.failed_detail.map(row => (
                                                                    <Grid container spacing={3}>
                                                                        <Grid item xs={12} md={12}>
                                                                            <Typography component='div' variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                ))}
                                                        </Box>
                                                    </AccordionDetails>
                                                </Accordion> : <></>}
                                            </TableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </Grid>            
                        </Grid> : <></>}
                        
                    </Paper>
                </Grid>
            </Grid>
        </BaseDialog>

        <BaseDialog
            // hideCloseButton
            open={Boolean(singlePropertyViewOpen)}
            handleClose={() => {
                setSinglePropertyViewOpen(false);
            }}
            handleSuccess={() => {
                setSinglePropertyViewOpen(false);
            }}
            title='Propiedad Seleccionada'
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Información de la propiedad seleccionada."
        >
            {selectedPropertyId && <PropertyDetail
                property={property}
                handleDeleteProperty={handleDeleteProperty}
                handleUpdateProperty={handleUpdateProperty}
                handleRemoveRelation={handleRemoveRelation}
                onClose={() => {
                    setSelectedPropertyId(null);
                    setSinglePropertyViewOpen(false);
                    setProperty(null);
                }}
                />
            }
        </BaseDialog>

        <BaseDialog
            // hideCloseButton
            open={Boolean(propertiesViewOpen)}
            handleClose={() => {
                setPropertiesViewOpen(false);
                reloadProperties(project.id);
            }}
            handleSuccess={() => {
                setPropertiesViewOpen(false);
                reloadProperties(project.id);
            }}
            title={project.name}
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se listan las propiedades asociadas al proyecto seleccionado."
          >
            {propertiesViewOpen && properties && <PropertiesTable
                title="Lista de Propiedades"
                headers={headCells}
                rows={properties || []}
                maxResults={maxResults}
                changeMaxResults={setMaxResults}
                page={page}
                changePage={setPage}
                total={properties.count}
                rowComponents={rowComponents}
                setPropertiesQuery={HandleUserQuery}
                setActiveQuery={HandleActiveQuery}
            />}
              
          </BaseDialog>

        <Grid container>
            <Paper className={classes.paper}>
            <TableContainer>
                <Grid 
                    item 
                    xs={12}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '1%',
                        marginTop: '1%',
                        paddingLeft: '1%'
                    }}
                >
                    <Typography component='div' variant="h6" color="textPrimary">Información de Proyecto</Typography>
                    </Grid>
                <Grid item xs={12}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell key='projectName'>
                                <Typography component='div' variant="body2" color="textSecondary">Nombre de Proyecto</Typography>
                                <Typography component='div' variant="body1" color="textPrimary">{projectName}</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='projectName'>
                                <Typography component='div' variant="body2" color="textSecondary">Rut de Administrador de Edificio</Typography>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <UsernameInput
                                        value={rutAdmin}
                                        onChange={(event) => {
                                            setRutAdmin(event.target.value);
                                        }}
                                        id="rut-admin"
                                        error={false}
                                        placeholder="Rut"
                                        label=""
                                        helperText=''
                                    />
                                </Grid>
                                {/* <Typography component='div' variant="body1" color="textPrimary">{rutAdmin}</Typography> */}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='engineer'>
                                <Typography component='div' variant="body2" color="textSecondary">Ingeniero a cargo</Typography>
                                <Typography component='div' variant="body1" color="textPrimary">
                                    <FormControl variant="outlined" >
                                        <Select
                                            native
                                            value={projectEngineer}
                                            onChange={(event) => {
                                                setProjectEngineer(event.target.value);
                                            }}
                                            label=""
                                            inputProps={{
                                                name: 'engineer',
                                                id: 'project-engineer',
                                            }}
                                        >
                                        <option aria-label="None" value={projectEngineer} label={projectEngineer} selected={projectEngineer}/>
                                        {
                                            engineers.map(engineer => (engineer.username !== projectEngineer) ?
                                                (<option value={engineer.username} label={engineer.username}  />) :
                                                (<></>)
                                            )
                                        }
                                        </Select>
                                    </FormControl>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='stages'>
                                <Typography component='div' variant="body2" color="textSecondary">Etapas</Typography>
                                {/* <Typography variant="body1" color="textPrimary">{project.stageName}</Typography> */}
                                <Typography component='div' variant="body1" color="textPrimary">
                                    <Box style={{paddingRight: '36px'}} width="100%">
                                            {stageList.map(stage => (
                                                <Paper className={classes.paper}>
                                                    <Grid 
                                                        container 
                                                        spacing={1}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            marginBottom: '1%',
                                                            marginTop: '1%',
                                                            paddingLeft: '1%',
                                                            paddingTop: '1%',
                                                            paddingBottom: '1%'
                                                        }}
                                                    >
                                                        <Grid item xs={12} md={12}>
                                                            <Typography component='div' variant="body2" color="textPrimary">• Nombre : {stage.name} {'\n'}</Typography>
                                                            <Typography component='div' variant="body2" color="textPrimary">• Dirección : {stage.address} {'\n'}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={12}>
                                                            <Typography component='div' variant="body2" color="textPrimary">
                                                                • Fecha Recepción Municipal :
                                                            </Typography>
                                                            <KeyboardDatePicker
                                                                // fullWidth
                                                                // label="Fecha de reporte"
                                                                // format="DD/MM/YY"
                                                                format="YYYY/MM/DD"
                                                                name="reportDate"
                                                                inputVariant="outlined"
                                                                value={stage.municipalReceptionDate}
                                                                // onBlur={() => setFieldTouched('reportDate')}
                                                                // onClose={() => setFieldTouched('reportDate')}
                                                                // onAccept={() => setFieldTouched('reportDate')}
                                                                onChange={(e) => setDateChange(e, stage.id)}
                                                                // error={Boolean( touched.reportDate && errors.reportDate)}
                                                                // helperText={touched.reportDate && errors.reportDate}
                                                            />
                                                        </Grid>
                                                        {stage.remainingDays ? 
                                                        <Grid item xs={12} md={12}>
                                                            {stage.remainingDays > 0 ? 
                                                            <Typography variant="body2" color="textSecondary" style={{color: 'green'}}>
                                                                Días restantes de post venta para esta etapa : {stage.remainingDays}
                                                            </Typography>
                                                            :
                                                            <Typography variant="body2" color="textSecondary" style={{color: 'red'}}>
                                                                Plazo terminó hace {Math.abs(stage.remainingDays)} días!
                                                            </Typography>
                                                            }
                                                        </Grid>
                                                        : <></>}
                                                        <Grid>
                                                            <FormControlLabel
                                                                key={Math.random()}
                                                                style={{width: '100%'}}
                                                                // style={getFormStyle()}               
                                                                control={
                                                                    <Checkbox 
                                                                        key={stage.id}
                                                                        checked={stage.isActive} 
                                                                        onChange={handleChangeStage} 
                                                                        name={`${stage.id}`} 
                                                                    />}
                                                                label={
                                                                    <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                                                                        Etapa Activa
                                                                    </Typography>
                                                                }
                                                                />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            ))}
                                    </Box>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell key='projectName'>
                                <Typography component='div' variant="body2" color="textSecondary">Proyecto disponible para post venta</Typography>
                                <Grid
                                    item
                                    xs={3}
                                >
                                    <FormControlLabel
                                        key={Math.random()}
                                        style={{width: '100%'}}
                                        // style={getFormStyle()}               
                                        control={
                                            <Checkbox 
                                                key='project-active'
                                                checked={projectActive} 
                                                onChange={(event) => {
                                                    setProjectActive(!projectActive)
                                                }} 
                                                name='projectActive'
                                            />}
                                        label={
                                            <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                                                Proyecto Activo
                                            </Typography>
                                        }
                                    />
                                </Grid>
                                {/* <Typography component='div' variant="body1" color="textPrimary">{rutAdmin}</Typography> */}
                            </TableCell>
                        </TableRow>

                        <Grid 
                            container 
                            alignItems="center" 
                            justifyContent="space-between"
                            style={{
                                paddingTop: '1%',
                                paddingLeft: '1%',
                                paddingBottom: '1%'
                            }}
                        >
                            <Grid item xs={12} md="auto">
                                <SuccessButtons
                                icon={<CheckCircleTwoToneIcon />}
                                onClickHandler={() => {
                                    updateProject();
                                }}
                                text="Actualizar Datos del Proyecto"
                                />
                            </Grid>
                        </Grid>
                    </TableBody>
                </Table>
                </Grid>
            
            </TableContainer>
            </Paper>

        <Divider />

        <Paper className={classes.paper}>
            <Grid 
                item xs={12}
                style={{
                    paddingTop: 5,
                    paddingLeft: 3,
                    paddingBottom: 5
                }}
            >
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="space-between"
                    style={{
                        paddingTop: 10,
                        paddingLeft: 3,
                        paddingBottom: 5
                    }}
                >
                    <Typography component='div' variant="h6" color="textPrimary">Propiedades del Proyecto</Typography>
                </Grid>
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="space-between"
                    style={{
                        paddingTop: 10,
                        paddingLeft: 3,
                        paddingBottom: 5
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<HomeIcon />}
                        onClickHandler={() => {
                            setPropertiesViewOpen(true);
                        }}
                        style={{
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingBottom: 3
                        }}
                        text="Ver y Editar Propiedades"
                        />
                    </Grid>
                </Grid>
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="space-between"
                    style={{
                        paddingTop: 10,
                        paddingLeft: 3,
                        paddingBottom: 5
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<QueueIcon />}
                        onClickHandler={() => {
                            setAddPropertiesViewOpen(true);
                        }}
                        style={{
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingBottom: 3
                        }}
                        text="Cargar Propiedades"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>

        <Divider />

        {/* <Paper className={classes.paper}>
            <Grid 
                item xs={12}
                style={{
                    paddingTop: 5,
                    paddingLeft: 3,
                    paddingBottom: 5
                }}
            >
                <Typography component='div' variant="h6" color="textPrimary">Otras Acciones</Typography>
                <Grid 
                    container 
                    alignItems="center" 
                    justifyContent="space-between"
                    style={{
                        paddingTop: 10,
                        paddingLeft: 3,
                        paddingBottom: 5
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <DangerButtons
                        icon={<CancelTwoToneIcon />}
                        onClickHandler={() => {
                            deleteProject();
                        }}
                        style={{
                            paddingTop: 3,
                            paddingLeft: 3,
                            paddingBottom: 3
                        }}
                        text="Desactivar Proyecto"
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Paper> */}
    
      </Grid>

      </>
}

ProjectDetail.propTypes = {
    project: PropTypes.object,
    handleUpdateProject: PropTypes.func,
    onClose: PropTypes.func,
}

ProjectDetail.defaultProps = {
    project: {},
    handleUpdateProject: null,
    onClose: null,
}