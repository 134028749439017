import React from 'react';
import PropTypes from 'prop-types';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import BaseInput from "./BaseInput";


const RunInput = props => {
    const {
        value,
        onChange,
        error,
        helperText,
        placeholder,
        id,
        label,
    } = props

    return (
        <BaseInput
            id={id}
            placeholder={placeholder}
            label={label}
            name="run"
            type="text"
            helperText={helperText}
            required
            startAdornment={<FingerprintIcon />}
            onChange={onChange}
            value={value}
            error={error}
        />
    );
};

RunInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string.isRequired
};

export default RunInput;