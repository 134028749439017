import {makeStyles} from "@material-ui/core/styles";

export const useStylesDialog = makeStyles((theme) => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid #000000',
        marginBottom: theme.spacing(3),
        paddingBottom: 0,
        paddingTop: 0
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #000000',
        marginTop: theme.spacing(3)
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'visible'
    },
    dialogTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
}))

export const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 14,
    },
}))