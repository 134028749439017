import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import Typography from "@material-ui/core/Typography";
import SelectAutocompleteInput from "../../Inputs/SelectAutocompleteInput";
import {
    getProjects,
    getStagesFromProject,
    getPropertyTypes,
    getPropertiesByStageAndType
} from "../../../api/projectsApi";
import {
    getClientProperties,
    getClientProjects
  } from "../../../api/clientApi";
import {
    // setReportProject,
    // setReportProjects,
    setReportProperties,
    setReportProperty,
    // setReportPropertyType,
    // setReportPropertyTypes,
    // setReportStage,
    // setReportStages
} from "../../../actions/reportActions";

class ReportStepOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // projects: props.projects,
            // project: props.project,
            // stages: props.stages,
            // stage: props.stage,
            // propertyTypes: props.propertyTypes,
            // propertyType: props.propertyType,
            properties: props.properties,
            property: props.property
        }
    }

    componentDidMount() {
        const {
            // projects,
            // project,
            // stage,
            // propertyType,
            property,
            properties
        } = this.state
        const { setReportProperties, successDisabledControl } = this.props // eslint-disable-line no-shadow

        // getProjects().then((response) => {
        //     if (response.data !== projects) {
        //         console.log('koko')
        //         this.setState({projects: response.data})
        //         setReportProjects(response.data)
        //     }
        // })

        const userRut = localStorage.getItem('username')

        getClientProperties(userRut).then((response) => {
            if (response.data !== properties) {
                this.setState({properties: response.data})
                setReportProperties(response.data)

                getClientProjects(userRut).then((secondresponse) => {
                    // console.log(response.data)
                    // console.log(secondresponse.data)
                    // console.log([...response.data, ...secondresponse.data])
                    this.setState({properties: [...response.data, ...secondresponse.data]})
                    setReportProperties([...response.data, ...secondresponse.data])
                })

            }
        })

        if (property !== ""){
            successDisabledControl(true)
        }

        // if (project !== "" && stage !== "" && propertyType !== ""){
        //     if (propertyType.code === 'ac'){
        //         successDisabledControl(false)
        //     } else if (propertyType.code !== "ac" && property !== "") {
        //         successDisabledControl(false)
        //     } else {
        //         successDisabledControl(true)
        //     }
        // }
    }

    componentDidUpdate(prevProps, prevState){
        const {
            // projects,
            // project,
            // stage,
            // propertyType,
            property,
        } = this.state
        if (property !== prevState.property){
            const { successDisabledControl } = this.props // eslint-disable-line no-shadow
            if (property === ''){
                successDisabledControl(false)
            } else{
                successDisabledControl(true)
            }
        }
        // if (propertyType !== prevState.propertyType){
        // // if (prevState.count !== this.state.count){
        //     const { setReportPropertyType, successDisabledControl } = this.props // eslint-disable-line no-shadow
        //     if (project !== "" && stage !== "" && propertyType !== ""){
        //         if (propertyType.code === 'ac'){
        //             successDisabledControl(false)
        //         } else if (propertyType.code !== "ac" && property !== "") {
        //             successDisabledControl(false)
        //         } else {
        //             successDisabledControl(true)
        //         }
        //     }
        // }
    }

    // getPropertiesTypes = () => {
    //     const { propertyTypes } = this.state
    //     const { setReportPropertyTypes } = this.props // eslint-disable-line no-shadow

    //     getPropertyTypes().then((response) => {
    //         if (response.data !== propertyTypes) {
    //             this.setState({propertyTypes: response.data})
    //             setReportPropertyTypes(response.data)
    //         }
    //     })
    // }

    // getStages = (projectId) => {
    //     const { stages } = this.state
    //     const { setReportStages, setReportStage } = this.props // eslint-disable-line no-shadow

    //     getStagesFromProject(projectId).then((response) => {
    //         if (response.data !== stages) {
    //             this.setState({stages: response.data})
    //             setReportStages(response.data)

    //             if (response.data.length === 1) {
    //                 this.setState({stage: response.data[0]})
    //                 setReportStage(response.data[0])
    //                 this.getPropertiesTypes()
    //             }
    //         }
    //     })
    // }

    // getProperties = (stageId, propertyTypeCode) => {
    //     const { properties } = this.state
    //     const { setReportProperties } = this.props // eslint-disable-line no-shadow

    //     getPropertiesByStageAndType(stageId, propertyTypeCode).then((response) => {
    //         if (response.data !== properties) {
    //             this.setState({properties: response.data})
    //             setReportProperties(response.data)
    //         }
    //     })
    // }

    render() {
        const {
            successDisabledControl,
            // setReportProject, // eslint-disable-line no-shadow
            // setReportStage, // eslint-disable-line no-shadow
            // setReportPropertyType, // eslint-disable-line no-shadow
            setReportProperty // eslint-disable-line no-shadow
        } = this.props

        const {
            // projects,
            // project,
            // stages,
            // stage,
            // propertyTypes,
            // propertyType,
            properties,
            property
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black'
                    }}
                >
                    Selecciona la propiedad donde quieres reportar algún problema
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={property}
                        options={properties}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            this.setState({
                                property: newValue
                            }, () => successDisabledControl(false))
                            setReportProperty(newValue)
                        }}
                        renderOptionFunction={(option) => {
                            return option.stage_address
                        }}
                        optionLabelFunction={(option) => {
                            return option.stage_address
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Propiedad"
                    />
                </Grid>
                {/* <Grid
                    container
                    item
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={property}
                        options={properties}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            this.setState({
                                property: newValue
                            }, () => successDisabledControl(false))
                            setReportProperty(newValue)
                        }}
                        renderOptionFunction={(option) => {
                            return option.code
                        }}
                        optionLabelFunction={(option) => {
                            return option.code
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Número"
                    />
                </Grid> */}
            </Grid>
        );
    }
}

ReportStepOne.propTypes = {
    successDisabledControl: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    // projects: state.report.partOne.projects,
    // project: state.report.partOne.project,
    // stages: state.report.partOne.stages,
    // stage: state.report.partOne.stage,
    // propertyTypes: state.report.partOne.propertyTypes,
    // propertyType: state.report.partOne.propertyType,
    properties: state.report.partOne.properties,
    property: state.report.partOne.property
})

const mapDispatchToProps = {
    // setReportProjects,
    // setReportProject,
    // setReportStages,
    // setReportStage,
    // setReportPropertyTypes,
    // setReportPropertyType,
    setReportProperties,
    setReportProperty
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportStepOne);