import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import moment from "moment";
// import TicketDetail from "./Pending/TicketDetail";
import TicketDetailPreApproved from "./TicketDetailPreApproved";
import {
  useTicket,
  getTicketsPreApproved,
  useTickets,
  approveTicket,
  rejectTicketLacksInfo,
  rejectTicketNotAppliesPV
} from "../../../api/ticketApi";
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import BaseTable from "../../../components/Tables/BaseTable";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";


const TicketTablePreApprove = ({refetchTickets, title}) => {
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refetchSwitch, setRefetchSwitch] = useState(false)

    const queryParams = {page: page + 1, maxResults}

    const { tickets, loading, removeTicket } = useTickets(queryParams)
    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()

    const fetchTicketPreApproved = () => {
      getTicketsPreApproved(maxResults, page).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
      })
    }

    useEffect(() => {
        fetchTicketPreApproved();
    }, [page, maxResults, refetchSwitch])

    const refetchPreviews = () => {
      setRefetchSwitch(!refetchSwitch)
    }
    
    const refreshTickets = () => {
      getTicketsPreApproved(maxResults, page).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
      })
    }

    const handleApproveTicket = async (data) => {
      const response = await approveTicket(data);
      enqueueSnackbar(
        '¡El Requerimiento ha sido aprobado!',
        {
          variant: 'success'
        }
      );
      setTimeout(() => {
          setSelectedTicketId(null);
          refreshTickets();
      }, 500);

    }

    const handleRejectTicketLacksInfo = () => {
      const ticketId = ticket.id;
      rejectTicketLacksInfo(ticketId);
      enqueueSnackbar(
        'El Requerimiento ha sido rechazado por falta de información.',
        {
          variant: 'success'
        }
      );
      setSelectedTicketId(null);
      setTimeout(() => {
          refreshTickets();
      }, 500);

    }

    const handleRejectTicketNotAppliesPV = (data) => {
      // const ticketId = ticket.id;
      rejectTicketNotAppliesPV(data);
      enqueueSnackbar(
        'El Requerimiento ha sido rechazado porque no aplica postventa.',
        {
          variant: 'success'
        }
      );
      setSelectedTicketId(null);
      setTimeout(() => {
          refreshTickets();
      }, 500);

    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'client_name', numeric: false, disablePadding: false, label: 'Nombre' },
        { id: 'property_name', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'report_date', numeric: false, disablePadding: false, label: 'Fecha reporte' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problema' },
        { id: 'iteration', numeric: false, disablePadding: false, label: 'N° de Requerimiento' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => setSelectedTicketId(row.id)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.client_name || <i>Sin nombre</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.property_name || <i>Sin propiedad</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {moment(row.report_date).format('DD/MM/YY HH:mm') || <i>Sin fecha</i>}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        const fault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null

        if (fault) {
          content = <>
            <div>• {fault.fault_zone_name} - {fault.fault_target_name} - {fault.fault_type_name}</div>
            {leftCount > 0 && <Typography color="secondary" variant="inherit">+{leftCount} PROBLEMA{leftCount !== 1 ? 'S': ''}</Typography>}
          </>

        } else {
          content = <i>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell>
                {row.iteration}
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de requerimiento pre-aprobado"
            successButtonIcon={<ExitToAppIcon />}
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información reportada en el requerimiento."
          >
              {ticket && <TicketDetailPreApproved
                  ticket={ticket}
                  handleApproveTicket={handleApproveTicket}
                  // handleRejectTicketLacksInfo={handleRejectTicketLacksInfo}
                  handleRejectTicketNotAppliesPV={handleRejectTicketNotAppliesPV}
                  refetchTickets={refetchTickets}
                  refetchPreviews={refetchPreviews}
                />
              }
          </BaseDialog>
        <BaseTable
            title={title}
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
        />
        </>
    );
};

TicketTablePreApprove.propTypes = {

};

export default TicketTablePreApprove;
