import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import DeleteIcon from '@material-ui/icons/Delete';
import 'react-lightbox-component/build/css/index.css';
import Radio from '@material-ui/core/Radio';
import { FormControl, FormControlLabel, FormGroup, FormLabel, RadioGroup} from "@material-ui/core";
import VisitCalendar from "./VisitCalendar";
import DangerButtons from "../Utils/DangerButtons";

export default function NewScheduleStep({formik, visit}){
  const { values, setFieldValue, handleChange } = formik;
  const handleSchedule = ({start, end, title, location, calendarId, isAllDay}) => {
    setFieldValue('schedule', {start, end, title, location, calendarId, isAllDay});
  }

  const getClassDict = () => {
    if (window.innerWidth < 769) {
        return {
            paddingLeft: '1%',
            fontSize: '21px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {
            paddingLeft: '1%',
            fontSize: '25px',
            width: '100%'
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return {
            paddingLeft: '1%',
            fontSize: '20px',
        }
    }
    return {}
}

const getIconSize = () => {
  if (window.innerWidth < 769) {
      return 'large'
  }
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return 'large'
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
      return 'medium'
  }
  return 'default'
}

const isDesktop = window.innerWidth > 980 
  
  return <>
  <Grid container spacing={3}>
    {values.newSchedule ? <Grid item xs={12}>
      <Typography style={getClassDict()}>Se seleccionó agendar una nueva cita.</Typography>
    </Grid> : <Grid item xs={12}>
      <Typography style={getClassDict()}>No se agendará nueva cita. Haz click en continuar proceso.</Typography>
    </Grid>} 
    {/* <Grid item xs={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">¿Desea agendar una nueva visita?</FormLabel>
        <RadioGroup row name="scheduleRadio" value={values.scheduleRadio} onChange={handleChange}>
          <FormControlLabel value="no" control={<Radio />} label="No" disabled={pendingFaults} />
          <FormControlLabel value="yes" control={<Radio />} label="Agendar ahora" />
          <FormControlLabel value="later" control={<Radio />} label="Agendar más adelante" />
        </RadioGroup>
      </FormControl>
    </Grid> */}
    {Boolean(values.schedule) && <Grid item xs={12}>
        {!isDesktop ? <Grid container alignItems="center" justify="space-between">
          <Grid item xs={10}>
            <Typography style={getClassDict()} variant="body2" color="textSecondary">Fecha de futura visita</Typography>
            <Typography style={getClassDict()} variant="body1" color="textPrimary">{moment(values.schedule.start).format('DD-MM-YYYY HH:mm')}</Typography>
          </Grid>
          <Grid item xs={2}>
            <DangerButtons text='Eliminar' icon={<DeleteIcon size={getIconSize()} />} onClickHandler={() => setFieldValue('schedule', null)}/>
          </Grid>
          <Grid item xs={12}>
            <Typography style={getClassDict()} variant="caption">Esta visita será agendada una vez se finalice este proceso.</Typography>
          </Grid>
        </Grid> : 
        <Grid container alignItems="center" justify="space-between">
          <Grid item>
            <Typography style={getClassDict()} variant="body2" color="textSecondary">Fecha de futura visita</Typography>
            <Typography style={getClassDict()} variant="body1" color="textPrimary">{moment(values.schedule.start).format('DD-MM-YYYY HH:mm')}</Typography>
          </Grid>
          <Grid item>
            <DangerButtons text='Eliminar' icon={<DeleteIcon size={getIconSize()} />} onClickHandler={() => setFieldValue('schedule', null)}/>
          </Grid>
          <Grid item xs={12}>
            <Typography style={getClassDict()} variant="caption">Esta visita será agendada una vez se finalice este proceso.</Typography>
          </Grid>
        </Grid>}
    </Grid>}

    {visit && values.newSchedule && !values.schedule && <Grid item xs={12}>
      <VisitCalendar visit={visit} onSchedule={handleSchedule} />
    </Grid>}
  </Grid>
  </>
}
