import React from "react";
import {alpha, ListItem, ListItemSecondaryAction, SvgIcon} from "@material-ui/core";
import VisibilityIcon from '@material-ui/icons/Visibility';
import RoomIcon from '@material-ui/icons/Room';
import PersonIcon from '@material-ui/icons/Person';
import ScheduleIcon from '@material-ui/icons/Schedule';
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    alert: {
        padding: "0px 8px"
    },
    activeTicket : {
        backgroundColor: 'rgba(74, 201, 255, .15)'
    }
}));

export default function TicketListItem({ticket, ticketId, onClick, onSchedule}){
    const classes = useStyles();

    const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;
    
    return <ListItem button classes={(ticketId !== null && ticketId.id===ticket.id) ? {root: classes.activeTicket} : {}} onClick={() => onSchedule(ticket)}>
        <Grid container style={{paddingBottom: '2%'}}>
            <Grid item xs={12}>
                <Box mb={2}>
                    <Grid container alignItems="center" spacing={3}>
                        <Grid item><Typography variant="h5" color="textPrimary">{ticket.propertyData || ticket.scheduleTitle}</Typography></Grid>
                        <Grid item><Alert variant="outlined" severity="warning" classes={{root: classes.alert}} >{ticket.stateName}</Alert></Grid>
                    </Grid>
                </Box>


            </Grid>
            <Grid item xs={12}>
                {ticket.propertyName && <Grid container alignItems="flex-end" spacing={1}>

                    <Grid item>
                        <SvgIcon fontSize={isTablet ? "large" : "small"} >
                            <RoomIcon />
                        </SvgIcon>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: isTablet ? "22px" : "default"}} variant="body2" color="textSecondary">{ticket.propertyName || <i>Sin propiedad</i>}</Typography>
                    </Grid>
                </Grid>}
                {ticket.clientName && <Grid container alignItems="flex-end" spacing={1}>
                    <Grid item>
                        <SvgIcon fontSize={isTablet ? "large" : "small"} >
                            <PersonIcon />
                        </SvgIcon>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: isTablet ? "22px" : "default"}} variant="body2" color="textSecondary">{ticket.clientName || <i>Sin cliente</i>}</Typography>
                    </Grid>
                </Grid>}
                <Grid container alignItems="flex-end" spacing={1}>
                    <Grid item>
                        <SvgIcon fontSize={isTablet ? "large" : "small"} >
                            <ScheduleIcon />
                        </SvgIcon>
                    </Grid>
                    <Grid item>
                        <Typography style={{fontSize: isTablet ? "22px" : "default"}} variant="body2" color="textSecondary">{moment(ticket.reportDate).format('DD-MM-YYYY')} - {ticket.daysOpenned} días abierto</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="comments" onClick={() => onSchedule(ticket)}>
                <VisibilityIcon />
            </IconButton>
        </ListItemSecondaryAction>
    </ListItem>
}