import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {Route, Switch} from "react-router-dom";
import MainAppBar from "./Navbars/MainAppBar";
import LandingNoAuth from "../pages/LandingNoAuth";
import TicketsHomePage from "../pages/Tickets/TicketsHomePage"
import VisitsHomePage from "../pages/Visits/VisitsHomePage";
import ManagementHomePage from "../pages/Management/ManagementHomePage";
import StatisticsHomePage from "../pages/Statistics/StatisticsHomePage";
import TechHomePage from "../pages/Tech/TechHomePage";
import ClientsHomePage from "../pages/Clients/ClientsHomePage";
import MagalUserHomePage from "../pages/Management/MagalUserHomePage";
import DataHomePage from "../pages/Management/DataHomePage";
import RequirementHomePage from "../pages/Management/RequirementHomePage"
import TicketStatusPage from "../pages/Clients/TicketStatusPage";
import TicketSurveyPage from "../pages/Surveys/TicketSurveyPage";
import VisitSurveyPage from "../pages/Surveys/VisitSurveyPage";
import PasswordReset from "../pages/Management/PasswordReset";
import PasswordResetConfirm from "../pages/Management/PasswordResetConfirm";
import ProfileEdit from "../pages/Management/ProfileEdit"

function App(props) {
    const {
        token // eslint-disable-line no-shadow
    } = props

    const [width, setWidth] = useState(window.innerWidth)
    const [height, setHeight] = useState(window.innerHeight)

    useLayoutEffect(() => {
        function updateDimensions() {
            setWidth(window.innerWidth)
            setHeight(window.innerHeight)
        }

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return function cleanup() {
            window.removeEventListener("resize", updateDimensions);
        };
    })

    localStorage.setItem('token', token)

    return (
        <MainAppBar>
            <div style={{ width: '100%' }}>
                <Switch>
                    <Route exact path="/" component={LandingNoAuth}/>
                    <Route path="/tickets" component={TicketsHomePage}/>
                    <Route path="/visitas" component={VisitsHomePage}/>
                    <Route path="/estadisticas" component={StatisticsHomePage}/>
                    <Route path="/administracion" component={ManagementHomePage}/>
                    <Route path="/equipo_tecnico" component={TechHomePage}/>
                    <Route path="/cliente" component={ClientsHomePage}/>
                    <Route path="/usuariomagal" component={MagalUserHomePage}/>
                    <Route path="/datos" component={DataHomePage}/>
                    <Route path="/seguimiento" component={TicketStatusPage}/>
                    <Route path="/encuestas/tickets/:surveyUniqueId" component={TicketSurveyPage}/>
                    <Route path="/encuestas/visitas/:surveyUniqueId" component={VisitSurveyPage}/>
                    <Route path="/resetpassword" component={PasswordReset}/>
                    <Route path="/confirmresetpassword/:id" component={PasswordResetConfirm}/>
                    <Route path="/perfil/" component={ProfileEdit}/>
                    <Route path="/enviar_requerimiento" component={RequirementHomePage}/>
                </Switch>
            </div>
        </MainAppBar>
    );
}

App.propTypes = {
    token: PropTypes.string.isRequired
}

const mapStateToProps = (state) => ({
    token: state.auth.accessToken
})

export default connect(mapStateToProps)(App);
