import snakeCaseKeys from "snakecase-keys";
import axiosAPI from "./axiosApi";
import useDataList from "./useDataList";
// import useData from "./useData";

export async function getClientPropertyRelations(propertyType) {
    const response = await axiosAPI.get(`clients/get_relations_list/${propertyType}`)

    return response
}

export async function getClientsByRelationForProperty(relationCode, propertyId) {
    const response = await axiosAPI.get(`clients/get_by_relation_property/${relationCode}/${propertyId}`)

    return response
}

export async function getClientsGenders() {
    const response = await axiosAPI.get("clients/get_genders_list")

    return response
}

export async function createClient(gender, name, rut, phone, email) {
    const response = await axiosAPI.post("clients/create_client", {
        gender,
        name,
        rut,
        phone,
        email,
    })

    return response
}

export async function createClientPropertyRelation(clientId, relationCode, propertyId) {
    const response = await axiosAPI.post("clients/create_property_relation", {
        client: clientId,
        relation_type: relationCode,
        property: propertyId
    })

    return response
}

export async function sendSurveyAnswers(data) {
    const response = await axiosAPI.post("client/send_surveys/", {
        surveyData: data
    })

    return response
}

export async function getClients(queryFields) {
    const response = await axiosAPI.get("client/", snakeCaseKeys(queryFields, {deep: true}))

    return response
}

export async function getClientSurveys(uuid) {
    const response = await axiosAPI.get(`client/surveys?uuid=${uuid}`)

    return response
}

export async function getClient(uuid) {
    const response = await axiosAPI.get(`client/info?uuid=${uuid}`)

    return response
}

export function useClients(queryParams) {
    const { items: clients, loading } = useDataList({url: "client/", queryParams})
    return { clients, loading }
}

export function useClientProperties({id}) {
/* eslint-disable-next-line */
    const { items: properties, loading, fetchItems: fetchProperties } = useDataList({url: id ? `client/${id}/properties/` : null})
    return { properties, loading }
}

export async function createClientRelation(data) {
    const response = await axiosAPI.post("property/", snakeCaseKeys(data, {deep: true}))
    return response
}

export async function getClientsByProperty(propertyId) {
    const response = await axiosAPI.get(`clients/get_by_property/${propertyId}`)

    return response
}

export async function getSummaryByClient(clientRut) {
    const response = await axiosAPI.get(`clients/get_summary/${clientRut}`)

    return response
}


export async function getTicketSurvey(surveyUniqueId) {
    const response = await axiosAPI.post(`ticketsurveys/get_survey_by_id/?survey=${surveyUniqueId}`)

    return response
}

export async function sendTicketSurveyAnswers(data) {
    const response = await axiosAPI.post("ticketsurveys/send_survey/", {
        surveyData: data
    })

    return response
}

export async function getVisitSurvey(surveyUniqueId) {
    const response = await axiosAPI.post(`visitsurveys/get_survey_by_id/?survey=${surveyUniqueId}`)

    return response
}

export async function sendVisitSurveyAnswers(data) {
    const response = await axiosAPI.post("visitsurveys/send_survey/", {
        surveyData: data
    })

    return response
}

export async function getClientProperties(username) {
    const response = await axiosAPI.get(`clients/get_properties_by_rut/${username}`)

    return response
}

export async function getClientProjects(username) {
    const response = await axiosAPI.get(`clients/get_projects_by_rut/${username}`)

    return response
}


export async function getClientData(rut) {
    const response = await axiosAPI.get(`clients/get_user_info/${rut}`)

    return response
}

export async function getClientDocument(filename) {
    // const response = await axiosAPI.get(`clients/get_client_file/${filename}`, {responseType: 'blob'})
    const response = await axiosAPI.get(`clients/get_client_file/${filename}`)

    return response
}

export async function updateUserDocument(data) {
    const response = await axiosAPI.post("client/update_document/", data)

    return response
}

export async function getClientSurveyList(maxResults, page) {
    const response = await axiosAPI.get(
        `clients/get_client_surveys?max_results=${maxResults}&page=${page}`)

    return response
}