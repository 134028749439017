import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import moment from "moment";
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import FormControl from "@material-ui/core/FormControl";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import DangerButtons from "../../components/Buttons/DangerButtons";
import FullNameInput from "../../components/Inputs/FullNameInput";
import EmailInput from "../../components/Inputs/EmailInput";
import 'react-lightbox-component/build/css/index.css';
import {
    sendMailToResetPassword,
} from "../../api/authenticationApi";

const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
  }

export default function SurveyDetail({survey, onClose}){

    const [expanded, setExpanded] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar()

    const handleAccordionChange = (name) => () => {
        if (expanded !== name) setExpanded(name);
        else setExpanded('');
    };

  return <>
      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información de la Encuesta</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre Cliente</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.client_name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Rut</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.rut}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Email</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.client_email}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Estado del Cliente</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.client_state}</Typography>
                    </TableCell>
                    <TableCell/>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Proyecto</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.project}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Propiedad</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.project_property}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Tipo de Encuesta</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.survey_type}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nota</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.mark ? survey.mark : 'Sin Nota'}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Comentario</Typography>
                        <Typography variant="body1" color="textPrimary">{survey.survey_comment}</Typography>
                    </TableCell>
                    <TableCell/>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
    
      </Grid>
      </>
}

SurveyDetail.propTypes = {
    survey: PropTypes.object,
    onClose: PropTypes.func,
}

SurveyDetail.defaultProps = {
    survey: {},
    onClose: null,
}