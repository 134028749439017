import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import camelcaseKeys from 'camelcase-keys';
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {LinearProgress} from "@material-ui/core";
import moment from "moment";
import DangerButtons from "../../components/Buttons/DangerButtons";

import BaseTable from "../../components/Tables/BaseTable";

import {
  getClientSurveyList,
} from "../../api/clientApi";

const surveyType = {
  V: 'Encuesta de Visita',
  T: 'Encuesta de Requerimiento',
}

const ClientSurveysPage = () => {
    
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)
    const [refetchSwitch, setRefetchSwitch] = useState(false)
    const [loading, setLoading] = useState(true)

    const fetchSurveys = () => {
      getClientSurveyList(maxResults, page).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
          setLoading(false)
      })
    }

    useEffect(() => {
        fetchSurveys();
    }, [page, maxResults, refetchSwitch])


    const openSurveyPage = (url) => {
      window.open(url, "_blank", "noreferrer");
    } 

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'survey_type', numeric: false, disablePadding: false, label: 'Tipo de Encuesta' },
        { id: 'status', numeric: false, disablePadding: false, label: 'Estado' },
        { id: 'created_date', numeric: false, disablePadding: false, label: 'Fecha creada' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              {/* <IconButton color="primary" onClick={e => setSelectedTicketId(row.id)}> */}
              <IconButton color="primary" onClick={e => openSurveyPage(row.survey_url)}>
                  <OpenInNewIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {surveyType[row.survey_type] || <i>Sin propiedad</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
              <TableCell>
                  {row.status}
              </TableCell>
          ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {moment(row.created_date).format('DD/MM/YY HH:mm') || <i>Sin fecha</i>}
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
        <BaseTable
            title='Mis encuestas'
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
        />
        </>
    );
};

ClientSurveysPage.propTypes = {

};

export default ClientSurveysPage;