import React, {useCallback, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import SignatureCanvas from 'react-signature-canvas';
import {Paper} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import SecondaryButtons from "../Utils/SecondaryButtons";


export default function SignatureStep({formik}){
  const [canvas, setCanvas] = useState(null);

  const canvasRef = useCallback(node => {
    if (node !== null) {
      setCanvas(node);
    }
  }, []);

  const getClassDict = () => {
    if (window.innerWidth < 769) {
        return {
            fontSize: '18px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {
            fontSize: '19px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return {
            fontSize: '18px',
        }
    }
    return {}
}

const getTextTopDict = () => {
  if (window.innerWidth < 769) {
      return {
          fontSize: '18px',
          marginBottom: 20
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {
          fontSize: '19px',
          marginBottom: 20
      }
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
      return {
          fontSize: '18px',
          marginBottom: 20
      }
  }
  return {marginBottom: 20}
}

  const { setFieldValue } = formik;
  return <>
  <Grid container justify="center" spacing={3}>
    <Grid item xs={12}>
      <Typography gutterBottom variant="body1" style={getTextTopDict()}>Estimado(a) Propietario(a),</Typography>
      <Typography gutterBottom variant="body1" style={getClassDict()}>Le pedimos su firma como registro de que la visita se realizó y la misma concluyó.</Typography>
      <Typography gutterBottom variant="body1" style={getTextTopDict()}>Posterior a su firma, el equipo de postventa se retirará de su propiedad y en instantes llegará un mail a su correo electrónico. Este, contendrá muy pocas preguntas y un espacio, optativo, para que escriba cualquier comentario. Es este el espacio ideal para que realice sugerencias, reclamos y felicitaciones.</Typography>
      <Typography gutterBottom variant="body1" style={getClassDict()}>¡Muchas gracias!</Typography>
    <Divider />
    </Grid>
    <Grid item >
      <Paper variant="outlined">
        <SignatureCanvas
          ref={canvasRef}
          penColor='black'
          canvasProps={{width: 500, height: 200, className: 'sigCanvas'}}
          onEnd={() => {
            if (canvas.isEmpty())
              setFieldValue('sign', null);
            else setFieldValue('sign', canvas.toDataURL());
          }}
        />
      </Paper>
      <Box textAlign="center">
        <Typography style={getClassDict()} variant="overline" color="textPrimary">Firma del cliente</Typography>
        <br />
        <SecondaryButtons
          onClickHandler={() => {
            canvas.clear();
            setFieldValue('sign', null);
          }}
          text="Borrar firma"
          size="medium"
        />
      </Box>
    </Grid>
  </Grid>
  </>
}
