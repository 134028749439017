import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import ReportStepOne from "./FormSteps/ReportStepOne";
import ReportStepTwo from "./FormSteps/ReportStepTwo";
import ReportStepThree from "./FormSteps/ReportStepThree";
import ReportStepFour from "./FormSteps/ReportStepFour";
import { createTicketManagement } from "../../api/ticketApi";

const ReportForm = (props) => {
    const {
        successDisabledControl,
        step,
        controlStep,
        handleClose,
        sendData
    } = props

    const { enqueueSnackbar } = useSnackbar()

    const formRef = useRef(null)

    const [state, setState] = useState({})
    const [enabled, setEnabled] = useState(true)

    const prepareNextStep = (controlValue) => {
        setState(prevState => {

            return {
                ...prevState,
                ...formRef.current.state
            }
        })

        successDisabledControl(controlValue)
    }

    let formComponent
    if (sendData && enabled) {
        if (step === 3){
            setEnabled(false)
            const reportData = new FormData()
            if (state.project && state.propertyType){
                if (state.images){
                    state.images.forEach((image) => reportData.append('files[]', image))
                }
                if (state.faults){
                    state.faults.forEach((fault) => reportData.append('faults[]', fault.id))
                }
                reportData.append('comment', state.comment)
                reportData.append('project', state.project.id)
                if (state.property){
                    reportData.append('property', state.property.id)
                }
                reportData.append('propertyType', state.propertyType.code)
        
                // Nuevos datos
        
                createTicketManagement(reportData).then((response) => {
                    if(response.data) {
                        enqueueSnackbar(
                            response.data.message,
                            {
                                variant: 'success'
                            }
                        )
                    } else if (response.error) {
                        enqueueSnackbar(
                            response.error.message,
                            {
                                variant: 'error'
                            }
                        )
                    }
                })
            }
            handleClose()
            
        }
    }

    switch (step) {
        case 1:
            formComponent = <ReportStepOne
                ref={formRef}
                successDisabledControl={prepareNextStep}
            />
            break

        // case 2:
        //     formComponent = <ReportStepTwo
        //         ref={formRef}
        //         successDisabledControl={prepareNextStep}
        //         propertyId={state.property.id}
        //         relationCode={state.relation || ''}
        //         controlStep={controlStep}
        //         propertyType={state.propertyType.code}
        //     />
        //     break

        case 2:
            formComponent = <ReportStepThree
                ref={formRef}
                successDisabledControl={prepareNextStep}
                propertyType={state.propertyType.code}
            />
            break

        case 3:
            formComponent = <ReportStepFour
                ref={formRef}
                successDisabledControl={prepareNextStep}
                faults={state.faults}
                controlStep={controlStep}
            />
            break

        default:
            formComponent = <p>Under Construction</p>
    }

    return (
        formComponent
    );
};

ReportForm.propTypes = {
    successDisabledControl: PropTypes.func.isRequired,
    // step: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
    step: PropTypes.number.isRequired,
    controlStep: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    sendData: PropTypes.bool.isRequired
};

export default ReportForm;