import { USER_NAME } from "./constants";


export function setUserName(name) {
    return {
        type: USER_NAME,
        name
    }
}

export function useSpace() {
    return true
}