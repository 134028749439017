import React, {useCallback, useState} from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";
import DailyVisits from "./DailyVisits";
import ActiveVisits from "./ActiveVisits";

const TechHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const location = useLocation()
    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/':
                return 0

            case '/en_proceso':
                return 1

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Equipo técnico"

    if (!isAuth) {
        history.push('/')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabTextSize = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return '22px'
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return '17px'
        }
        return 'default'
    }

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="tickets sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Actividades del día"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Visitas en proceso"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/en_proceso`)}
                />
            </Tabs>

            <Switch>
                <Route exact path={path} component={DailyVisits} />
                <Route path={`${path}/en_proceso`} component={ActiveVisits} />
            </Switch>
        </div>
    );
};

export default TechHomePage;
