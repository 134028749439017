import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Radio from '@material-ui/core/Radio';
import {FormControlLabel, Checkbox, RadioGroup} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from '@material-ui/core/Paper';
import Divider from "@material-ui/core/Divider";
import Lightbox from 'react-lightbox-component';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";
import BaseInput from "../../../components/Inputs/BaseInput"
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import 'react-lightbox-component/build/css/index.css';
// import {
//   getClientsByProperty,
// } from "../../../api/clientApi";
import {
  getSummaryByClient,
} from "../../../api/clientApi";
import TicketsHistory from "./TicketsHistory"


const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

export default function TicketDetailPreApproved({
  ticket, 
  handleApproveTicket, 
  handleRejectTicketNotAppliesPV, 
  refetchTickets, 
  refetchPreviews}){
                             
  const [clientSummary, setClientSummary] = useState([])
  const [ firstFetch, setFirstFetch] = useState(true)
  const [ticketFaults, setTicketFaults] = useState(ticket.faults)

  const [ actionValue, setActionValue ] = useState(null)
  const userType = localStorage.getItem('userType')
  
  const [ gravity, setGravity] = useState('')
  const [ forceReason, setForceReason] = useState('')


  if (firstFetch){
    getSummaryByClient(ticket.clientRut).then((response) => {
      setClientSummary(response.data)
      setFirstFetch(false)
    })
  }

  const approveTicket = () => {
    if (actionValue==='approve'){
      handleApproveTicket({
        id: ticket.id,
        ticketFaults,
        gravity
      });
      refetchPreviews();
      refetchTickets();
    }
  };

  const rejectTicket = () => {
    handleRejectTicketNotAppliesPV({forceReason, ticketFaults, ticketId: ticket.id});
    refetchPreviews();
    refetchTickets();
    //
  };

  const handleChangeFault = (event) => {
    if (event === undefined){
      return;
    }    
    const newValue = event.target.name;
    const rowsTest = [...ticketFaults];

    for (let i = 0; i < rowsTest.length; i+=1){
      
      if (rowsTest[i].id === parseInt(newValue,10)){
        rowsTest[i].isApproved = !rowsTest[i].isApproved;
      }
    }
    setTicketFaults(rowsTest);
  }

  const handleRejectReason = (event) => {
    const newValue = event.target.name;
    const newText = event.target.value;

    const rowsTest = [...ticketFaults];

    for (let i = 0; i < rowsTest.length; i+=1){
      
      if (rowsTest[i].id === parseInt(newValue,10)){
        rowsTest[i].rejectReason = newText;
      }
      
    }
    setTicketFaults(rowsTest);
  }

  const currentFaults = () => {
    const faultsDict = {};
    for (let i = 0; i < ticket.faults.length; i+=1){
      const faultsArray = [];
      faultsArray.push(ticket.faults[i].faultZoneName, ticket.faults[i].faultTargetName, ticket.faults[i].faultTypeName);
      faultsDict[i] = (faultsArray)
    }
    return faultsDict;
  }

  

  const useStyles = makeStyles((theme) => ({
      root: {
          width: '100%',
      },
      paper: {
          width: '100%',
          marginBottom: theme.spacing(2),
      },
      table: {
          minWidth: 750,
      },
      container: {
          maxHeight: '70vh'
      },
      visuallyHidden: {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 1,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          top: 20,
          width: 1,
      },
  }));
  const classes = useStyles();

  
  return <>
  <Grid
      container
      spacing={5}
      mb={3}
      style={{
          paddingTop: '1%',
          paddingLeft: '1%',
          paddingRight: '1%',
      }}
  >
      <Grid
          item
          container
          xs={12}
          md={6}
          mb={3}
          style={{
              paddingBottom: 0
          }}
      >
          <Grid container mb={3}>
            <Grid 
              item xs={12}
              style={{
                paddingTop: '1%',
                paddingLeft: '1%',
                paddingRight: '1%',
              }}
            >
              <Typography 
                variant="h6" 
                color="textPrimary" 
              >Información del Requerimiento</Typography>
            </Grid>
            <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
            <Grid 
              item xs={12}
              mb={3}
            >
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography 
                        variant="body2" 
                        color="textSecondary" 
                        colSpan={6}
                      >
                        Datos del Requerimiento
                      </Typography>
                    </TableCell>
                    <TableCell/>
                    <TableCell/>
                  </TableRow>

                  <TableRow>
                      <TableCell>
                        <Typography variant="body1" color="textPrimary" colSpan="100%">
                        Nombre : {ticket.clientName}
                        </Typography>
                        <Typography variant="body1" color="textPrimary" colSpan="100%">
                        Rut : {ticket.clientRut}
                        </Typography>
                        <Typography variant="body1" color="textPrimary" colSpan="100%">
                        Teléfono : {ticket.clientPhone}
                        </Typography>
                        <Typography variant="body1" color="textPrimary" colSpan="100%">
                        Correo : {ticket.clientEmail}
                        </Typography>
                      </TableCell>
                      <TableCell/>
                  </TableRow>

                  <TableRow colSpan="100%">
                    <TableCell colSpan="100%">                      
                      {ticket.clientIsValidated ? 
                        <span style={{color: "#3fb102", fontSize: "14px"}}> 
                        El Cliente está validado.
                        </span> : 
                        <span style={{color: "#fc0303", fontSize: "14px"}}> 
                        El Cliente no está validado.
                        </span>
                      }    
                    </TableCell>
                  </TableRow>

                  {ticket.sentByOwner ? 
                    <TableRow colSpan="100%">
                      <TableCell colSpan="100%">    
                        <span style={{color: "#3fb102", fontSize: "14px"}}>                   
                          El Requerimiento fue enviado por el propietario.   
                        </span>
                      </TableCell>
                    </TableRow> : 

                    <TableRow colSpan="100%">
                      <TableCell colSpan="100%">    
                        <span style={{color: "#fc0303", fontSize: "14px"}}>                   
                          El Requerimiento fue enviado por el usuario {ticket.senderUser}   
                        </span>
                      </TableCell>
                    </TableRow>}

                  <TableRow colSpan="100%">
                    <TableCell colSpan="100%">
                      <Typography variant="body2" color="textSecondary">Gravedad</Typography>
                      <Typography variant="body1" color="textPrimary">
                          <FormControl variant="outlined" >
                            <Select
                                native
                                value={gravity}
                                onChange={(event) => {
                                  setGravity(event.target.value)
                                }}
                                label="Gravedad"
                                inputProps={{
                                  name: 'gravity',
                                  id: 'outlined-gravity',
                                }}
                            >
                              <option aria-label="None" label='Seleccionar' value='' selected={gravity}/>
                              {
                                ["low", "moderate", "high", "urgent"].map(gravityOptions => (
                                    <option value={gravityOptions} label={gravityLabels[gravityOptions]}  />
                                ))
                              }
                            </Select>
                          </FormControl>
                      </Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow colSpan={12}>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">Edificio</Typography>
                        <Typography variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">Propiedad</Typography>
                        <Typography variant="body1" color="textPrimary">{ticket.propertyCode}</Typography>
                      </TableCell>
                      <TableCell/>
                  </TableRow>

                  <TableRow colSpan={12}>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">Tiempo restante de post venta</Typography>
                        <Typography variant="body1" color="textPrimary">
                            {ticket.remainingDays}
                        </Typography>
                      </TableCell>
                      <TableCell/>
                      <TableCell/>
                  </TableRow>

                  <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">Dirección</Typography>
                        <Typography variant="body1" color="textPrimary">{ticket.propertyAddress}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">Fecha Reporte</Typography>
                        <Typography variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
                      </TableCell>
                      <TableCell/>
                  </TableRow>

                  <TableRow>
                      <TableCell>
                        <Typography variant="body2" color="textSecondary">N° Días abierto</Typography>
                        <Typography variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
                      </TableCell>
                      <TableCell/>
                      <TableCell/>
                  </TableRow>

                  <TableRow colSpan="42">
                     <TableCell colSpan="42">
                      <Typography variant="body2" color="textSecondary">Problemas</Typography>
                      {ticketFaults.map((fault,i) => {
                        const index = i.toString();
                        return <Grid xs={12}> 
                                <FormControlLabel
                                  key={Math.random()}
                                  style={{width: '75%'}}
                                  // style={getFormStyle()}               
                                  control={
                                    <Checkbox 
                                      key={fault.id}
                                      checked={fault.isApproved} 
                                      onChange={handleChangeFault} 
                                      name={`${fault.id}`} 
                                      />}
                                  label={
                                    <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                                      {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}
                                    </Typography>
                                  }
                                />
                                {!fault.isApproved ? 
                                  <TextAreaInput
                                      onChange={handleRejectReason}
                                      id={`${fault.id} reason`} 
                                      value={fault.rejectReason}
                                      placeholder="Escribe el motivo por el que se rechaza este problema."
                                      label="Razón"
                                      name={`${fault.id}`}
                                      helperText=""
                                  /> : <></>}
                              </Grid>
                          // <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                          //   • {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}
                          // </Typography>
                      }
                      )}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={4}>
                      <Typography variant="body2" color="textSecondary">Explicación del cliente</Typography>
                      <Typography variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell colSpan={4}>
                      {ticket.images.length!==0 ? <div>
                        <Lightbox showImageModifiers={false}
                                  images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                                  }/>
                      </div> : <><p> No hay imágenes adjuntas.</p></>}
                    </TableCell>
                  </TableRow>

                </TableBody>
              </Table>
            </Grid>
            </TableContainer>
            </Paper>
          </Grid>

      </Grid>

      <Grid
          item
          container
          xs={12}
          md={6}
          mb={3}
          style={{
            paddingBottom: 0
          }}
      >
          {!firstFetch ? <Paper className={classes.paper}>
            <TableContainer className={classes.container}>
            <Grid 
              item xs={12}
              mb={3}
              style={{
                paddingLeft: 5,
                paddingTop: 3
              }}
            >
              <Typography variant="body2" color="textSecondary">Resumen de requerimientos del Cliente</Typography>
              {clientSummary.map(line => (
                  <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                    • {line.ppt}
                  </Typography>
              ))}
            </Grid>
            </TableContainer>
          </Paper> : <></>}
          <TicketsHistory projectId={ticket.projectId} propertyId={ticket.propertyId} ogFaults={ticket.faults}/>
      </Grid>
  </Grid>

  <Box m={2} pt={3}>
    <Divider />
    <Typography 
      variant="body1" 
      color="textPrimary"
      style={{
        marginTop: '2%',
      }}
    >
      Selecciona un estado para desbloquear una acción:
    </Typography>
  </Box>  

  <Grid item xs={12} style={{paddingLeft: '1%',}} >
    <RadioGroup 
      column 
      name="actionRadio" 
      value={actionValue} 
      onChange={(event) => {
        setActionValue(event.target.value)
    }}>
            <FormControlLabel 
              value="approve" 
              control={<Radio />} 
              label="El Requerimiento cumple con lo requerido para ser aprobado." 
              // disabled={pendingFaults} 
            />
            {/* <FormControlLabel 
              value="lacksInfo" 
              control={<Radio />} 
              label="Hay un error de información con el Requerimiento." 
            /> */}
            <FormControlLabel 
              value="notApplyPV" 
              control={<Radio />} 
              label="El Requerimiento no aplica post venta." 
            />
    </RadioGroup>
    
    {actionValue === 'approve' && gravity==='' ?
              <Grid
                  container
                  item
                  xs={12}
              >
                  <Typography variant="body1" color="textPrimary" style={{color: "red"}}>
                    Debes seleccionar la gravedad antes de aprobar el requerimiento.
                  </Typography>
              </Grid> : <></>}
    {actionValue === 'notApplyPV' ?
              <Grid
                  container
                  item
                  xs={12}
              >
                  <TextAreaInput
                      onChange={(event) => {
                          setForceReason(event.target.value)
                      }}
                      id="comment-closed"
                      value={forceReason}
                      placeholder="Escribe la razón."
                      label="Razón"
                      name="Comment"
                      helperText=""
                  />
              </Grid> : <></>}
  </Grid>

  <Grid item xs={12}>
    <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="right" justifyContent="space-between">

            <Grid item xs={12} md="auto">
              <DangerButtons
                icon={<CancelTwoToneIcon />}
                onClickHandler={() => {
                  rejectTicket()
                }}
                text="Rechazar Requerimiento"
                disabled={actionValue===null || actionValue==='approve' || forceReason===''}
              />
            </Grid>
            <Grid item xs={12} md="auto">
              <SuccessButtons
                icon={<CheckCircleTwoToneIcon />}
                onClickHandler={() => {
                  approveTicket()
                }}
                text="Aprobar Requerimiento"
                disabled={actionValue===null || gravity==='' || actionValue==='notApplyPV'}
              />
            </Grid>

          </Grid>
        </Grid>
      </Grid>
    </Box>
  </Grid>
  </>
}
