import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import { connect } from 'react-redux'
import { useHistory } from "react-router-dom"
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from "notistack";
import BaseDialog from "./BaseDialog";
import ClientLoginForm from "../Forms/ClientLoginForm";
import { obtainToken } from "../../api/authenticationApi"
import { getUserData } from "../../api/usersApi";
import { setUserName } from "../../actions/userActions";

const ClientLoginDialog = (props) => {
    const {
        open,
        registerFormOpen,
        handleClose,
        setUserName // eslint-disable-line no-shadow
    } = props

    const { enqueueSnackbar } = useSnackbar()

    const loginFormRef = useRef(null)
    const history = useHistory()

    const isDesktop = window.innerWidth > 980;

    const redirectOnUserPermissions = (typeCode) => {
        switch (typeCode) {
            case 'A':
            case 'CA':
            case 'EPV':
                history.push('/tickets')
                break
            case 'ING':
                history.push('/tickets/pendientes')
                break
            case 'ET':
                history.push('/equipo_tecnico')
                break
            case 'C':
                history.push('/cliente')
                break
            default:
                history.push('/')
        }
    }

    return (
        <BaseDialog
            open={open}
            handleClose={handleClose}
            handleSuccess={(event) => {
                const {
                    username,
                    password
                } = loginFormRef.current.state

                event.preventDefault();

                obtainToken(username, password).then(() => {
                    // handleClose()
                    getUserData().then((response) => {
                        setUserName(`${response.first_name} ${response.last_name}`)
                        localStorage.setItem('userName', `${response.first_name} ${response.last_name}`)
                        localStorage.setItem('userType', `${response.type_code}`)
                        localStorage.setItem('username', username)
                        localStorage.setItem('userId', `${response.id}`)
                        enqueueSnackbar(
                            `${response.first_name} has iniciado sesión, bienvenido!`,
                            {
                                variant: 'success'
                            }
                        )
                        redirectOnUserPermissions(response.type_code)
                    }).catch((error) => {
                        console.error(error)
                    })
                }).catch(() => {
                    enqueueSnackbar(
                        'No se ha podido iniciar sesión. Revisa tus credenciales',
                        {
                            variant: 'error'
                        }
                    )
                })
                

            }}
            title="Iniciar Sesión"
            successButtonText="Ingresar"
            successButtonIcon={<ExitToAppIcon />}
            size={isDesktop ? 'xs' : 'lg'}
            tooltipText={
                `En este cuadro se debe ingresar su rut y 
                 contraseña para poder acceder al contenido 
                 interno del sistema.`
            }
        >
            <Typography 
                variant="body1" 
                color="white"
                style={{ 
                    fontSize: '15px',
                    fontColor: 'white',
                    marginRight: '5%',
                    marginLeft: '5%',
                    marginBottom: '5%',
                }}
            >
                Para reportar un problema, primero inicia sesión.                                   
            </Typography>
            <ClientLoginForm ref={loginFormRef} />
            <Grid
                item
                container
                xs={12}
            >
                <Typography 
                    variant="body1" 
                    color="white"
                    style={{ 
                        // cursor: "pointer",
                        fontSize: '15px',
                        fontColor: 'white',
                        marginTop: '2%',
                        // color: 'blue'
                    }}
                    // onClick={registerFormOpen}
                >
                    Si no tienes una cuenta, <span 
                                        role="button" 
                                        tabIndex={0} 
                                        onKeyDown={registerFormOpen} 
                                        key='regform' 
                                        style={{
                                            color: 'green', 
                                            cursor: "pointer"
                                        }} 
                                        onClick={registerFormOpen}
                                        > 
                                        regístrate aquí. 
                                        </span>                                  
                </Typography>
            </Grid>
            <Grid
                item
                container
                xs={12}
            >
                <Typography 
                    variant="body1" 
                    color="white"
                    style={{ 
                        // cursor: "pointer",
                        fontSize: '15px',
                        fontColor: 'white',
                        marginTop: '2%',
                        // color: 'red'
                    }}
                    // onClick={() => {
                    //     history.push('/resetpassword')
                    // }}
                >
                    Si olvidaste tu contraseña, <span 
                                        role="button" 
                                        tabIndex={0} 
                                        onKeyDown={() => {
                                            history.push('/resetpassword')
                                        }} 
                                        key='regform' 
                                        style={{
                                            color: 'red', 
                                            cursor: "pointer"
                                        }} 
                                        onClick={() => {
                                            history.push('/resetpassword')
                                        }}
                                        > 
                                        haz click aquí. 
                                        </span>                           
                </Typography>
            </Grid>
        </BaseDialog>
    );
};

ClientLoginDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setUserName: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setUserName
}

export default connect(null, mapDispatchToProps)(ClientLoginDialog);
