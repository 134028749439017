import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStylesButtons from "./styles";

const SecondaryButtons = (props) => {
    const {
        text,
        icon,
        onClickHandler,
        disabled,
        size,
    } = props

    const classes = useStylesButtons()

    return (
        <Button
            className={clsx(classes.general, classes.secondary)}
            variant="outlined"
            startIcon={icon}
            onClick={onClickHandler}
            disabled={disabled}
            size={size || 'small'}
        >
            { text }
        </Button>
    );
};

SecondaryButtons.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    size: PropTypes.string
};

SecondaryButtons.defaultProps = {
    disabled: false,
    size: 'small'
}

export default SecondaryButtons;