import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import 'react-lightbox-component/build/css/index.css';
import CameraIcon from "@material-ui/icons/Camera";
import {MenuItem, TextField} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import {makeStyles} from "@material-ui/core/styles";
import {useVisitStates, useVisitTypes, uploadVisitImage, deleteVisitImage, updateVisitData} from "../../../api/visitApi";
import CustomWebcam from "../../../components/Webcam/CustomWebcam";
import SuccessButtons from "../Utils/SuccessButtons";


const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

const stateLabels = {
  P: "Pendiente",
  A: "Activa",
  C: "Completada",
}


const visitStateColors = {
  P: "inherit",
  A: "warning",
  C: "success",
}

const useStyle = makeStyles((theme) => ({
  image: {
    objectFit: "contain"
  },
}))

export default function ActiveVisitDetail({visit, formik}){
  const classes = useStyle();
  const [ expanded, setExpanded ] = useState('');
  const { visitStates, loading: loadingStates } = useVisitStates();
  const { visitTypes, loading: loadingTypes } = useVisitTypes();
  const [ cameraOpen, setCameraOpen ] = useState(false);
  const { values, handleChange, setFieldValue } = formik;

  const isTablet = window.innerWidth === 980 && window.innerHeight === 1294

  const setUploadImage = async (imageSrc) => {
    const response = await uploadVisitImage({
      id: visit.id,
      images: [{image: imageSrc, message: ''}]
    })
    window.location.reload();
  }

  const setDeleteImage = async (imageSrc) => {
    const response = await deleteVisitImage({
      id: visit.id,
      images: [{image: imageSrc.src}]
    })
    window.location.reload();
  }

  async function setVisitType(event) {
    const response = await updateVisitData({
      id: visit.id,
      visitType: event.target.value
    })
    window.location.reload();
  }

  return <>
  <Grid container>
    <Grid item xs={12}>
      <Table>
        {!isTablet ? <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography variant="body1" color="textPrimary">{visit.propertyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Ubicación</Typography>
              <Typography variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Tipo de visita</Typography>
              <TextField select fullWidth onChange={(e) => {setVisitType(e)}} name="visitType" value={values.visitType} error={!values.visitType} helperText={!values.visitType && 'Selecciona un tipo para continuar'}>
                <MenuItem value="" disabled><i>Selecciona un tipo de visita</i></MenuItem>
                {visitTypes.map(state => <MenuItem key={state.id} value={state.id}>{state.label}</MenuItem> )}
              </TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha calendarizada</Typography>
              <Typography variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha iniciada</Typography>
              <Typography variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="body2" color="textSecondary">Problemas</Typography>
              <Typography variant="body1" color="textPrimary">
                {visit.faults.map(fault => (
                    fault.isApproved ?
                    <div key={fault.id} style={{ display: "flex" }}>
                      <div style={{ marginRight: 20 }}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon style={{ color: red[700] }} />
                      )
                    }
                    </div>
                    : <></>
                ))}
                {!visit.faults.length && <i>No hay problemas asociados a esta visita.</i>}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography gutterBottom variant="body2" color="textSecondary">Fotografías</Typography>
                  <Typography>Añade más fotografías para dejar registro de la situación encontrada y los problemas resueltos</Typography>
                </Grid>
                <Grid item>
                  <SuccessButtons
                    icon={<CameraIcon />}
                    onClickHandler={() => setCameraOpen(true)}
                    text="Tomar una fotografía"
                    size="large"
                  />
                </Grid>
              </Grid>

              {/* <Lightbox showImageModifiers={false} */}
              {/*          images={[...visit.images, ...values.images].map(img => ({src: img.src, title: ' ', description: ' '})) */}
              {/*          }/> */}

              {cameraOpen && <CustomWebcam onCapture={(imageSrc) => {
                setUploadImage(imageSrc);
                setCameraOpen(false);
                // setFieldValue('images', [...values.images, {src: imageSrc}]);
                // setCameraOpen(false);
              }} onClose={() => setCameraOpen(false)} style={{display: 'fixed', top: 0, left: 0 }}/>}
              <Grid container spacing={2}>
                {values.images.map((image, index) => (
                  <Grid item xs={12} md={4} key={image.src}>
                    <Card variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt='photo'
                          height="140"
                          image=""
                          src={image.src}
                          title={image.message}
                          className={classes.image}

                        />
                        <CardContent>
                          <Grid container>
                            <Grid item xs>
                              <TextField
                                multiline
                                rows={4}
                                fullWidth
                                onChange={handleChange}
                                label="Descripción de imagen"
                                variant="outlined"
                                value={image.message}
                                name={`images.${index}.message`} />
                            </Grid>
                            <Grid item xs="auto">
                              <IconButton
                                title="Eliminar"
                                aria-label="delete"
                                onClick={e => {
                                  setDeleteImage(image);
                                  setFieldValue('images', values.images.filter((v, _i) => _i !== index))
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )) }
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody> :
        <TableBody>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.propertyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Ubicación</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Tipo de visita</Typography>
              <TextField 
                select 
                fullWidth 
                onChange={(e) => {setVisitType(e)}}
                name="visitType" 
                value={values.visitType} 
                error={!values.visitType} 
                helperText={!values.visitType && <Typography style={{ fontSize: '21px', color: 'red'}} variant="body1" color="textPrimary">Selecciona un tipo para continuar</Typography>}
                style={{ width: '100%'}} 
              >
                <MenuItem style={{ fontSize: '24px'}} value="" disabled><i>Selecciona un tipo de visita</i></MenuItem>
                {visitTypes.map(state => <MenuItem style={{ fontSize: '26px'}} key={state.id} value={state.id}>{state.label}</MenuItem> )}
              </TextField>
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Fecha calendarizada</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Fecha iniciada</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell colSpan={3}>
              <Typography style={{ fontSize: '23px'}} variant="body2" color="textSecondary">Problemas</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">
                {visit.faults.map(fault => (
                    fault.isApproved ?
                    <div key={fault.id} style={{ display: "flex" }}>
                      <div style={{ marginRight: 20 }}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon fontSize='large' style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon fontSize='large' style={{ color: red[700] }} />
                      )
                    }
                    </div>
                    : <></>
                ))}
                {!visit.faults.length && <i>No hay problemas asociados a esta visita.</i>}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell colSpan={3}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography style={{ fontSize: '20px'}} gutterBottom variant="body2" color="textSecondary">Fotografías</Typography>
                  <Typography style={{ fontSize: '21px'}}>Añade más fotografías para dejar registro de la situación encontrada y los problemas resueltos</Typography>
                </Grid>
                <Grid item>
                  <SuccessButtons
                    icon={<CameraIcon fontSize='large' />}
                    onClickHandler={() => setCameraOpen(true)}
                    text="Tomar una fotografía"
                    size="md"
                  />
                </Grid>
              </Grid>

              {/* <Lightbox showImageModifiers={false} */}
              {/*          images={[...visit.images, ...values.images].map(img => ({src: img.src, title: ' ', description: ' '})) */}
              {/*          }/> */}

              {cameraOpen && <CustomWebcam onCapture={(imageSrc) => {
                setUploadImage(imageSrc);
                // setFieldValue('images', [...values.images, {src: imageSrc}]);
                setCameraOpen(false);
              }} onClose={() => setCameraOpen(false)} style={{display: 'fixed', top: 0, left: 0 }}/>}
              <Grid container spacing={2}>
                {values.images.map((image, index) => (
                  <Grid item xs={12} md={4} key={image.src}>
                    <Card variant="outlined">
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          alt='photo'
                          height="220"
                          image=""
                          src={image.src}
                          title={image.message}
                          className={classes.image}

                        />
                        <CardContent>
                          <Grid container>
                            <Grid item xs>
                              <TextField
                                multiline
                                rows={4}
                                fullWidth
                                onChange={handleChange}
                                label="Descripción de imagen"
                                variant="outlined"
                                value={image.message}
                                name={`images.${index}.message`} />
                            </Grid>
                            <Grid item xs="auto">
                              <IconButton
                                title="Eliminar"
                                aria-label="delete"
                                onClick={e => {
                                  setDeleteImage(image);
                                  setFieldValue('images', values.images.filter((v, _i) => _i !== index))
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                )) }
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
        }
      </Table>
    </Grid>
  </Grid>
  </>
}
