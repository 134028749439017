import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import {
  useTicket,
  useTickets,
  closeTicket,
  closeTicketNC,
  reactivateTicket,
} from "../../../api/ticketApi";
import BaseDialog from "../Utils/BaseDialog";
import BaseTable from "../Utils/BaseTable";
import SolvedTicketDetail from "./SolvedTicketDetail";


const SolvedTicketTable = ({title}) => {
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)

    const userType = localStorage.getItem('userType')
    const queryParams = {page: page + 1, maxResults, state__state_category: 'S'}

    if (userType === "ING") {
        queryParams.project__ingeneer__username = localStorage.getItem('username')
    }

    const { tickets, loading, removeTicket } = useTickets(queryParams)
    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const { ticket, loading: loadingTicketDetails, addVisit, removeVisit, updateVisit } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()

    const handleCloseTicket = () => {
      closeTicket(ticket.id);
      const ticketId = ticket.id;
      enqueueSnackbar(
        '¡El Requerimiento ha sido cerrado!',
        {
          variant: 'success'
        }
      );
      setTimeout(() => {
        setSelectedTicketId(null);
        removeTicket(ticketId);
      }, 500);
  
    }

    const handleCloseTicketNonConformity = (reason) => {
      closeTicketNC(ticket.id, reason);
      enqueueSnackbar(
        'El Requerimiento ha sido cerrado con el cliente disconforme.',
        {
          variant: 'success'
        }
      );
      const ticketId = ticket.id;
      setTimeout(() => {
        setSelectedTicketId(null);
        removeTicket(ticketId);
      }, 500);

  }

    const handleReActivateTicket = (ticketFaultsUpdated, reason) => {
      reactivateTicket(ticket.id, ticketFaultsUpdated, reason);
      enqueueSnackbar(
        'El Requerimiento ha sido reactivado.',
        {
          variant: 'success'
        }
      );
      const ticketId = ticket.id;
      setTimeout(() => {
        setSelectedTicketId(null);
        removeTicket(ticketId);
      }, 500);  
    }

    const getTextClass = () => {
      if (window.innerWidth === 980 && window.innerHeight === 1294){
          return {fontSize: '21px'}
      }
      if (window.innerWidth === 980 && window.innerHeight === 441){
        return {fontSize: '18px'}
      }
      return {}
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'clientName', numeric: false, disablePadding: false, label: 'Cliente' },
        { id: 'propertyName', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problema' },
        { id: 'iteration', numeric: false, disablePadding: false, label: 'Número de Requerimiento' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton size='inherit' color="primary" onClick={e => setSelectedTicketId(row.id)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                { row.clientName || <i>Sin cliente</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.propertyName || <i>Sin propiedad</i>}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        const fault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null



        if (fault) {
          content = <>
            <div style={getTextClass()}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div>
            {leftCount > 0 && <Typography style={getTextClass()} color="secondary" variant="inherit">+{leftCount} PROBLEMA{leftCount !== 1 ? 'S': ''}</Typography>}
          </>

        } else {
          content = <i>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.iteration}
            </TableCell>
        )
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de Requerimiento"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información reportada en el requerimiento, junto con su numero de visitas y posibilidad de ver el listado y situación de las mismas"
          >
              {ticket && <SolvedTicketDetail
                  ticket={ticket}
                  handleCloseTicket={handleCloseTicket}
                  handleCloseTicketNC={handleCloseTicketNonConformity}
                  handleReActivateTicket={handleReActivateTicket}
                />
              }
          </BaseDialog>
        <BaseTable
            title={title}
            headers={headCells}
            rows={tickets.results || []}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={tickets.count}
            rowComponents={rowComponents}
        />
        </>
    );
};

SolvedTicketTable.propTypes = {

};

export default SolvedTicketTable;
