import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import Paper from '@material-ui/core/Paper';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../Tickets/Utils/Buttons/SuccessButtons";
import DangerButtons from "../../components/Buttons/DangerButtons";
import FullNameInput from "../../components/Inputs/FullNameInput";
import EmailInput from "../../components/Inputs/EmailInput";
import UsernameInput from "../../components/Inputs/UsernameInput";
import PasswordInput from "../../components/Inputs/PasswordInput";
import 'react-lightbox-component/build/css/index.css';
import {
    updateUser,
    getUserInfo,
    updateUserPassword,
  } from "../../api/usersApi";


const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
  }

export default function ProfileEdit(){

    const username = localStorage.getItem("username");
    const [userId, setUserId] = useState('')
    const [userFirstName, setUserFirstName] = useState('')
    const [userLastName, setUserLastName] = useState('')
    const [userEmail, setUserEmail] = useState('')
    const [userTypeCode, setUserTypeCode] = useState('')
    const [currentPassword, setCurrentPassword] = useState('')
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')
    const history = useHistory();
    const [dataReturned, setDataReturned] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        getUserInfo({id: localStorage.getItem("userId")}).then((response) => {
            setUserId(response.id)
            setUserFirstName(response.first_name)
            setUserLastName(response.last_name)
            setUserEmail(response.email)
            setUserTypeCode(response.type_code)
            setDataReturned(true)
        })
    }, [username])

    const useStyles = makeStyles((theme) => ({
            paper: {
                width: '100%',
                marginBottom: theme.spacing(2),
                maxHeight: '120vh'
            },
            titleTablet: {
                fontSize: '31px'
            },
            body2: {
                fontSize: '21px'
            },
            body1: {
                fontSize: '24px'
            },
            container: {
                maxHeight: '70vh'
            },
    }));
    const classes = useStyles();

    const isTablet = (window.innerWidth === 980 && window.innerHeight === 1294)    

    const updateUserData = async () => {
        // Validar inputs
        
        const response = await updateUser({
            id: userId,
            username,
            firstName: userFirstName,
            lastName: userLastName,
            email: userEmail,
            typeCode: userTypeCode,
        });
        enqueueSnackbar(
            'Se ha actualizado la información.',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            localStorage.setItem('userName', `${userFirstName} ${userLastName}`)
            window.location.reload();
        }, 1500)
        return true;
    };

    const updateUserPW = async () => {
        // Validar inputs
        
        const response = await updateUserPassword({
            id: userId,
            password,
            currentPassword
        });
        if (response.data.error){
            enqueueSnackbar(
                'La contraseña actual es incorrecta.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                'Se ha actualizado la contraseña.',
                {
                    variant: 'success'
                }
            );
        }
        setTimeout( () => {
            window.location.reload();
        }, 1500)
        return true;
    };

  if (!dataReturned){
    return <></>
  }

  return <>
    <Grid
        container
        spacing={12}
        alignItems="center"
        justifyContent="center"
        mb={3}
        style={{
            paddingTop: 120,
            paddingLeft: '1%',
            paddingRight: '1%',
        }}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 0
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid item xs={12} container mb={3} alignItems="center" justifyContent="center">
                    <Typography className={isTablet ? classes.titleTablet : null} variant="h6"  color="textPrimary">
                        Actualizar Datos de Perfil
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Nombre de usuario</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <Typography className={isTablet ? classes.body1 : null} variant="body1" color="textPrimary">{username}</Typography>
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Nombre</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <FullNameInput
                                            value={userFirstName}
                                            onChange={(event) => {
                                                setUserFirstName(event.target.value);
                                            }}
                                            id="user-firstname"
                                            error={false}
                                            placeholder="Nombre"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Apellido</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <FullNameInput
                                            value={userLastName}
                                            onChange={(event) => {
                                                setUserLastName(event.target.value);
                                            }}
                                            id="user-lastname"
                                            error={false}
                                            placeholder="Apellido"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Email</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <EmailInput
                                            value={userEmail}
                                            onChange={(event) => {
                                                setUserEmail(event.target.value);
                                            }}
                                            id="user-email"
                                            error={false}
                                            placeholder="Email"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Tipo de Usuario</Typography>
                                    <Typography className={isTablet ? classes.body1 : null} variant="body1" color="textPrimary">{userTypes[userTypeCode]}</Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            
                <Grid 
                    container 
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    style={{
                        paddingTop: '2%',
                        paddingBottom: '2%',
                        paddingLeft: '1%',
                        paddingRight: '1%',
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<CheckCircleTwoToneIcon size='inherit' />}
                        onClickHandler={() => {
                            updateUserData();
                        }}
                        text="Actualizar Información"
                        />
                    </Grid>
                </Grid>
            
            </Grid>
            </TableContainer>
            </Paper>

            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid item xs={12} container mb={3} alignItems="center" justifyContent="center">
                    <Typography className={isTablet ? classes.titleTablet : null} variant="h6"  color="textPrimary">
                        Actualizar Contraseña
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Contraseña actual</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <PasswordInput
                                            value={currentPassword}
                                            onChange={(event) => {
                                                setCurrentPassword(event.target.value);
                                            }}
                                            id="user-current-password"
                                            error={false}
                                            placeholder="Contraseña actual"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Nueva contraseña</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <PasswordInput
                                            value={password}
                                            onChange={(event) => {
                                                setPassword(event.target.value);
                                            }}
                                            id="user-password"
                                            error={false}
                                            placeholder="Contraseña"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography className={isTablet ? classes.body2 : null} variant="body2" color="textSecondary">Repetir contraseña</Typography>
                                    <Grid
                                        item
                                        xs={11}
                                        md={11}
                                        lg={11}
                                    >
                                        <PasswordInput
                                            value={repeatPassword}
                                            onChange={(event) => {
                                                setRepeatPassword(event.target.value);
                                            }}
                                            id="user-repeat-password"
                                            error={false}
                                            placeholder="Repetir Contraseña"
                                            label=""
                                            helperText=''
                                            labelFontSize={isTablet ? '23px' : 'default'}
                                        />
                                    </Grid>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            
                <Grid 
                    container 
                    alignItems="center"
                    justifyContent="center"
                    mb={3}
                    style={{
                        paddingTop: '2%',
                        paddingBottom: '2%',
                        paddingLeft: '1%',
                        paddingRight: '1%',
                    }}
                >
                    <Grid item xs={12} md="auto">
                        <SuccessButtons
                        icon={<CheckCircleTwoToneIcon />}
                        onClickHandler={() => {
                            updateUserPW();
                        }}
                        text="Actualizar Contraseña"
                        disabled={password===''||repeatPassword===''||password!==repeatPassword}
                        />
                    </Grid>
                </Grid>
            
            </Grid>
            </TableContainer>
            </Paper>
        </Grid>

    </Grid>
    </>
}

ProfileEdit.propTypes = {

}

ProfileEdit.defaultProps = {
}