import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import moment from "moment";
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";
import FormControl from "@material-ui/core/FormControl";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Select from "@material-ui/core/Select";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import DangerButtons from "../../components/Buttons/DangerButtons";
import FullNameInput from "../../components/Inputs/FullNameInput";
import EmailInput from "../../components/Inputs/EmailInput";
import 'react-lightbox-component/build/css/index.css';
import {
    sendMailToResetPassword,
} from "../../api/authenticationApi";

const userTypes = {
    ING: 'Ingeniero',
    A: 'Administrador',
    EPV: 'Encargado postventa',
    ET: 'Equipo Técnico',
    SUP: 'Supervisor',
    C: 'Cliente',
  }

export default function ClientDetail({user, handleDeleteClient, handleUpdateClient, onClose}){

    const [clientProjectAdmin, setClientProjectAdmin] = useState(user.is_project_admin)
    const [expanded, setExpanded] = React.useState(false);

    const { enqueueSnackbar } = useSnackbar()

    const deleteUser = () => {
        handleDeleteClient({
            id: user.id
        });
        onClose();
    };

    const updateUser = () => {
        handleUpdateClient({
            id: user.id,
            clientProjectAdmin
        });
        onClose();
    };

    const handleChange = (event) => {
        setClientProjectAdmin(!clientProjectAdmin)
    }

    const handleAccordionChange = (name) => () => {
        if (expanded !== name) setExpanded(name);
        else setExpanded('');
    };

  return <>
      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información de Cliente</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre</Typography>
                        <Typography variant="body1" color="textPrimary">{user.name}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Rut</Typography>
                        <Typography variant="body1" color="textPrimary">{user.rut}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Email</Typography>
                        <Typography variant="body1" color="textPrimary">{user.email}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Teléfono</Typography>
                        <Typography variant="body1" color="textPrimary">{user.phone}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Estado</Typography>
                        {user.is_rejected ?
                            <Typography variant="body1" color="textPrimary">
                                Rechazado
                            </Typography>
                            :
                            <Typography variant="body1" color="textPrimary">
                                {user.is_validated ? 'Validado' : 'Sin validar'}
                            </Typography>
                        }
                    </TableCell>
                    <TableCell/>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Rol</Typography>
                        <FormControlLabel
                            control={<Checkbox 
                                key='1'
                                size='medium' 
                                checked={clientProjectAdmin} 
                                onChange={handleChange} 
                                name="admin" />}
                            label={<Typography variant="body1" color="textPrimary">Asignar Rol de Administrador de Edificios</Typography>}
                            // label="Hemos llegado al lugar a visitar"
                        />
                    </TableCell>
                    <TableCell/>
                    <TableCell/>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Propiedades vinculadas</Typography>
                        {user.related_properties.length > 0 ? <Typography variant="body1" color="textPrimary">
                            {user.related_properties.map(ppt => (
                                <div style={{ display: "flex", alignItems: 'center' }}>
                                    • {ppt}
                                </div>
                            ))}
                        </Typography> : <Typography variant="body1" color="textPrimary"> No se encontraron propiedades </Typography>}
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Requerimientos creados</Typography>
                        <Typography variant="body1" color="textPrimary">{user.tickets_amount}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Requerimientos creados por Magal a este usuario</Typography>
                        <Typography variant="body1" color="textPrimary">{user.reported_tickets}</Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Encuestas de Requerimiento</Typography>
                        {user.ticket_surveys_list.length !== 0 ? 
                        <Box style={{paddingRight: '36px'}} width="100%">
                                {user.ticket_surveys_list.map(row => (
                                    <Accordion key={`ticket ${row.id}`} expanded={expanded === `ticket ${row.id}`} onChange={handleAccordionChange(`ticket ${row.id}`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1c-content"
                                            id={`summt ${row.id}`}
                                        >
                                        Encuesta {moment(row.created_date).format('DD/MM/YY HH:mm')}
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} md={12}>
                                                    <Typography variant="body2" color="textSecondary">Estado</Typography>
                                                    {row.expired ? <Typography variant="body1" color="textPrimary">Vencida</Typography> :
                                                    <Typography variant="body1" color="textPrimary">{row.completed ? 'Respondida' : 'Sin responder'}</Typography>}
                                                </Grid>
                                                {row.completed ? <Grid item xs={12} md={12}>
                                                    <Typography variant="body2" color="textSecondary">Fecha respondida</Typography>
                                                    <Typography variant="body1" color="textPrimary">{moment(row.answered_date).format('DD/MM/YY HH:mm')}</Typography>
                                                </Grid> : <></> }
                                                {row.mark ? <Grid item xs={12} md={12}>
                                                    <Typography variant="body2" color="textSecondary">Nota</Typography>
                                                    <Typography variant="body1" color="textPrimary">{row.mark}</Typography>
                                                </Grid> : <></>}
                                                {row.answers.length > 0 ? <Grid item xs={12} md={12}>
                                                    <Typography variant="body2" color="textSecondary">Preguntas y respuestas</Typography>
                                                    <Typography variant="body1" color="textSecondary">
                                                        {row.answers.map(ans => (
                                                            <>
                                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                                • {ans.pregunta}
                                                            </div>
                                                            <div style={{ display: "flex", alignItems: 'center' }}>
                                                                {ans.respuesta ? 'Respuesta' : 'Nota'} : {ans.respuesta ? ans.respuesta : ans.opcion}
                                                            </div>
                                                            </>
                                                        ))}
                                                    </Typography>
                                                </Grid> : <></>}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                        </Box>
                        // <Accordion key='ticket_surveys' expanded={expanded === 'ticket_surveys'} onChange={handleAccordionChange('ticket_surveys')}>
                        //     <AccordionSummary
                        //         expandIcon={<ExpandMoreIcon />}
                        //         aria-controls="panel1c-content"
                        //         id="panel1c-header"
                        //     >
                        //         Detalle de Encuestas de Ticket
                        //     </AccordionSummary>
                        //     <AccordionDetails>
                        //         <Box style={{paddingRight: '36px'}} width="100%">
                        //                 {user.ticket_surveys_list.map(row => (
                        //                     <Grid container spacing={3}>
                        //                         <Grid item xs={12} md={12}>
                        //                             <Typography variant="body2" color="textSecondary">Estado</Typography>
                        //                             {row.expired ? <Typography variant="body1" color="textPrimary">Vencida</Typography> :
                        //                             <Typography variant="body1" color="textPrimary">{row.completed ? 'Respondida' : 'Sin responder'}</Typography>}
                        //                         </Grid>
                        //                         <Grid item xs={12} md={12}>
                        //                             <Typography variant="body2" color="textSecondary">Fecha creada</Typography>
                        //                             <Typography variant="body1" color="textPrimary">{moment(row.created_date).format('DD/MM/YY HH:mm')}</Typography>
                        //                         </Grid>
                        //                         {row.mark ? <Grid item xs={12} md={12}>
                        //                             <Typography variant="body2" color="textSecondary">Nota</Typography>
                        //                             <Typography variant="body1" color="textPrimary">{row.mark}</Typography>
                        //                         </Grid> : <></>}
                        //                         {row.answers.length > 0 ? <Grid item xs={12} md={12}>
                        //                             <Typography variant="body2" color="textSecondary">Preguntas y respuestas</Typography>
                        //                             <Typography variant="body1" color="textSecondary">
                        //                                 {row.answers.map(ans => (
                        //                                     <>
                        //                                     <div style={{ display: "flex", alignItems: 'center' }}>
                        //                                         • {ans.pregunta}
                        //                                     </div>
                        //                                     <div style={{ display: "flex", alignItems: 'center' }}>
                        //                                         {ans.respuesta ? 'Respuesta' : 'Nota'} : {ans.respuesta ? ans.respuesta : ans.opcion}
                        //                                     </div>
                        //                                     </>
                        //                                 ))}
                        //                             </Typography>
                        //                         </Grid> : <></>}
                        //                     </Grid>
                        //                 ))}
                        //         </Box>
                        //     </AccordionDetails>
                        // </Accordion> 
                        : <Typography variant="body1" color="textPrimary">Sin encuestas</Typography>}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Encuestas de Visita</Typography>
                        {user.visit_surveys_list.length !== 0 ? 
                        <Box style={{paddingRight: '36px'}} width="100%">
                            {user.visit_surveys_list.map(row => (
                                <Accordion key={`visit ${row.id}`} expanded={expanded === `visit ${row.id}`} onChange={handleAccordionChange(`visit ${row.id}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1c-content"
                                        id={`summv ${row.id}`}
                                    >
                                    Encuesta {moment(row.created_date).format('DD/MM/YY HH:mm')}
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12}>
                                                <Typography variant="body2" color="textSecondary">Estado</Typography>
                                                {row.expired ? <Typography variant="body1" color="textPrimary">Vencida</Typography> :
                                                <Typography variant="body1" color="textPrimary">{row.completed ? 'Respondida' : 'Sin responder'}</Typography>}
                                            </Grid>
                                            {row.completed ? <Grid item xs={12} md={12}>
                                                <Typography variant="body2" color="textSecondary">Fecha respondida</Typography>
                                                <Typography variant="body1" color="textPrimary">{moment(row.answered_date).format('DD/MM/YY HH:mm')}</Typography>
                                            </Grid> : <></> }
                                            {row.mark ? <Grid item xs={12} md={12}>
                                                <Typography variant="body2" color="textSecondary">Nota</Typography>
                                                <Typography variant="body1" color="textPrimary">{row.mark}</Typography>
                                            </Grid> : <></>}
                                            {row.answers.length > 0 ? <Grid item xs={12} md={12}>
                                                <Typography variant="body2" color="textSecondary">Preguntas y respuestas</Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {row.answers.map(ans => (
                                                        <>
                                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                                            • {ans.pregunta}
                                                        </div>
                                                        <div style={{ display: "flex", alignItems: 'center' }}>
                                                            {ans.respuesta ? 'Respuesta' : 'Nota'} : {ans.respuesta ? ans.respuesta : ans.opcion}
                                                        </div>
                                                        </>
                                                    ))}
                                                </Typography>
                                            </Grid> : <></>}
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                    </Box>
                        // <Accordion key='visit_surveys' expanded={expanded === 'visit_surveys'} onChange={handleAccordionChange('visit_surveys')}>
                        //     <AccordionSummary
                        //         expandIcon={<ExpandMoreIcon />}
                        //         aria-controls="panel1c-content"
                        //         id="visit-survey-header"
                        //     >
                        //         Detalle de Encuestas de Visita
                        //     </AccordionSummary>
                        //     <AccordionDetails>
                        //         <Box style={{paddingRight: '36px'}} width="100%">
                        //                 {user.visit_surveys_list.map(row => (
                        //                     <Accordion key={`visit ${row.id}`} expanded={expanded === `visit ${row.id}`} onChange={handleAccordionChange(`visit ${row.id}`)}>
                        //                         <AccordionSummary
                        //                             expandIcon={<ExpandMoreIcon />}
                        //                             aria-controls="panel1c-content"
                        //                             id={`summv ${row.id}`}
                        //                         >
                        //                            Encuesta {moment(row.created_date).format('DD/MM/YY HH:mm')}
                        //                         </AccordionSummary>
                        //                         <AccordionDetails>
                        //                             <Grid container spacing={3}>
                        //                                 <Grid item xs={12} md={12}>
                        //                                     <Typography variant="body2" color="textSecondary">Estado</Typography>
                        //                                     {row.expired ? <Typography variant="body1" color="textPrimary">Vencida</Typography> :
                        //                                     <Typography variant="body1" color="textPrimary">{row.completed ? 'Respondida' : 'Sin responder'}</Typography>}
                        //                                 </Grid>
                        //                                 {/* <Grid item xs={12} md={12}>
                        //                                     <Typography variant="body2" color="textSecondary">Fecha creada</Typography>
                        //                                     <Typography variant="body1" color="textPrimary">{moment(row.created_date).format('DD/MM/YY HH:mm')}</Typography>
                        //                                 </Grid> */}
                        //                                 {row.mark ? <Grid item xs={12} md={12}>
                        //                                     <Typography variant="body2" color="textSecondary">Nota</Typography>
                        //                                     <Typography variant="body1" color="textPrimary">{row.mark}</Typography>
                        //                                 </Grid> : <></>}
                        //                                 {row.answers.length > 0 ? <Grid item xs={12} md={12}>
                        //                                     <Typography variant="body2" color="textSecondary">Preguntas y respuestas</Typography>
                        //                                     <Typography variant="body1" color="textSecondary">
                        //                                         {row.answers.map(ans => (
                        //                                             <>
                        //                                             <div style={{ display: "flex", alignItems: 'center' }}>
                        //                                                 • {ans.pregunta}
                        //                                             </div>
                        //                                             <div style={{ display: "flex", alignItems: 'center' }}>
                        //                                                 {ans.respuesta ? 'Respuesta' : 'Nota'} : {ans.respuesta ? ans.respuesta : ans.opcion}
                        //                                             </div>
                        //                                             </>
                        //                                         ))}
                        //                                     </Typography>
                        //                                 </Grid> : <></>}
                        //                             </Grid>
                        //                         </AccordionDetails>
                        //                     </Accordion>
                        //                 ))}
                        //         </Box>
                        //     </AccordionDetails>
                        // </Accordion> 
                        : <Typography variant="body1" color="textPrimary">Sin encuestas</Typography>}
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
    
        <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
                <SuccessButtons
                icon={<CheckCircleTwoToneIcon />}
                onClickHandler={() => {
                    updateUser();
                }}
                text="Actualizar Cliente"
                />
            </Grid>
            {/* <Grid item xs={12} md="auto">
                <DangerButtons
                icon={<CancelTwoToneIcon />}
                onClickHandler={() => {
                    deleteUser();
                }}
                text="Desactivar Cliente"
                />
            </Grid> */}
        </Grid>
    
      </Grid>
      </>
}

ClientDetail.propTypes = {
    user: PropTypes.object,
    handleDeleteClient: PropTypes.func,
    handleUpdateClient: PropTypes.func,
    onClose: PropTypes.func,
}

ClientDetail.defaultProps = {
    user: {},
    handleDeleteClient: null,
    handleUpdateClient: null,
    onClose: null,
}