import {makeStyles} from "@material-ui/core/styles";

export const useStylesDialog = makeStyles((theme) => ({
    dialogTitle: {
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '1px solid #000000',
        marginBottom: theme.spacing(3),
        paddingBottom: 0,
        paddingTop: 0
    },
    dialogPaper: {
        minHeight: '60vh',
        maxHeight: '80vh',
    },
    dialogActions: {
        display: 'flex',
        justifyContent: 'space-between',
        borderTop: '1px solid #000000',
        marginTop: theme.spacing(3)
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'visible'
    },
    dialogTooltipDesktop: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 22,
    },
    dialogTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        fontSize: 11,
    },
}))

export const deskTopStyles = makeStyles((theme) => ({
    body1Class: {
    },
    body2Class: {
    },
    detailsClass: {
        color: "#666666", 
        fontSize: "14px"
    },
    clientOwnerClass: {
        color: "#3fb102", 
        fontSize: "14px"
    },
    clientNotOwnerClass: {
        color: "#fc0303", 
        fontSize: "14px"
    },
    infoColSClass: {
        color: "#666666",
        fontSize: "13px" , 
        marginRight: 20
    },
    infoSClass: {
        fontSize: "13px" , 
        marginRight: 20
    },
}))

export const useStylesTooltip = makeStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        backgroundColor: theme.palette.common.black,
        fontSize: 14,
    },
}))