import React from 'react';
import PropTypes from 'prop-types';
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

/*
 * id: <string>
 * label: <string>
 * helperText: <string>
 * disabled: <boolean>
 * error: <boolean>
 * name: <string>
 * onChange: <function (event) => { event.target.value }>
 * placeholder: <string>
 * required: <boolean>
 * rows: <number>
 * rowsMax: <number>
 * type: <string>
 * value: <string> || <number>
 * startAdornment: <node> || <React.Fragment>
 * endAdornment: <node> || <React.Fragment>
 * multiline: <boolean>
 */

const BaseInput = (props) => {
    const {
        id,
        label,
        helperText,
        disabled,
        error,
        name,
        onChange,
        placeholder,
        required,
        rows,
        rowsMax,
        type,
        value,
        startAdornment,
        endAdornment,
        multiline,
        labelFontSize
    } = props

    return (
        <TextField
            fullWidth
            multiline={multiline}
            id={id}
            label={label}
            helperText={helperText}
            variant="outlined"
            size="small"
            disabled={disabled}
            error={error}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            rows={rows}
            rowsMax={rowsMax}
            type={type}
            value={value || ''}
            InputProps={{
                startAdornment: startAdornment ? (
                    <InputAdornment position="start">
                        { startAdornment }
                    </InputAdornment>
                ) : null
                ,
                style: {fontSize: labelFontSize},
                endAdornment: endAdornment ? (
                    <InputAdornment position="end">
                        { endAdornment }
                    </InputAdornment>
                ) : null
            }}
            InputLabelProps={{style: {fontSize: labelFontSize}}}
        />
    );
};

BaseInput.propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    startAdornment: PropTypes.node,
    endAdornment: PropTypes.node,
    multiline: PropTypes.bool,
    rowsMax: PropTypes.number,
    rows: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    helperText: PropTypes.string,
    labelFontSize: PropTypes.string
};

BaseInput.defaultProps = {
    startAdornment: null,
    endAdornment: null,
    multiline: false,
    rowsMax: 1,
    rows: 1,
    required: false,
    error: false,
    disabled: false,
    helperText: '',
    labelFontSize: 'default'
}

export default BaseInput;