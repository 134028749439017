import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";

import {
  getProjects,
  useProjects,
  deleteProject,
  updateProject,
  deleteStage,
  getPropertiesByProject,
  getPropertiesByStageAndType,
} from "../../../api/projectsApi";
import {
    getEngineers,
} from "../../../api/usersApi"
import BaseDialog from "../../../components/Dialogs/BaseDialog";
// import BaseTable from "../../../components/Tables/BaseTable";
import BaseTable from "./BaseTable";
import ProjectDetail from "./ProjectDetail"

const ProjectsTable = () => {
    
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)

    const queryParams = {page: page + 1, maxResults}

    const { projects, loading, removeProject } = useProjects(queryParams)
    const [selectedProjectId, setSelectedProjectId] = useState(null)
    const [project, setProject] = useState(null);
    const [engineers, setEngineers] = useState(null)
    const [received, setReceived] = useState(false)
    const [properties, setProperties] = useState(null)
    
    const { enqueueSnackbar } = useSnackbar();

    const fetchEngineers = () => {
        getEngineers().then((response) => {
            setEngineers(response)
        })
    }

    useEffect(() => {
        if (!received){
            fetchEngineers();
            setReceived(true)
        }
    })

    const handleDeleteProject = async (data) => {
        const response = await deleteProject(data.id);
        enqueueSnackbar(
            '¡El proyecto ha sido eliminado!',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000);
    }


    const handleUpdateProject = async (data) => {
        const response = await updateProject(data);
        if (response.error){
            enqueueSnackbar(
                'Hubo un error al actualizar la información.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡El proyecto ha sido actualizado!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const handleDeleteStage = async (data) => {
        const response = await deleteStage(data);
        if (response.error){
            enqueueSnackbar(
                'Hubo un error al procesar la solicitud.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                'La Etapa ha sido desactivada',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nombre Proyecto' },
        { id: 'active', numeric: false, disablePadding: false, label: 'Estado' },
        { id: 'remainingDays', numeric: false, disablePadding: false, label: 'Tiempo restante' },
        { id: 'ingeneer', numeric: false, disablePadding: false, label: 'Encargado' },
        { id: 'stageList', numeric: false, disablePadding: false, label: 'Dirección' },
    ];

    const getProperties = (id) => {
        getPropertiesByProject(id).then((response) => {
            setProperties(response.data)
        })
    }

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => {
                                        setProject(row);
                                        getProperties(row.id);
                                        setSelectedProjectId(row.id);
                                        }}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.name || <i>Sin nombre</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.active ? 
                <Typography variant="body2" color="textSecondary" style={{color: 'green'}}>
                    Activo
                </Typography> : 
                <Typography  variant="body2" color="textSecondary" style={{color: 'red'}}>
                    Desactivado
                </Typography>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <>
            {row.remainingDays !== null ? 
            <TableCell>
                {row.remainingDays > 0 ? 
                <Typography variant="body2" color="textSecondary" style={{color: 'green'}}>
                    Quedan {row.remainingDays} días de post venta
                </Typography> : 
                <Typography variant="body2" color="textSecondary" style={{color: 'red'}}>
                    Post venta terminó hace {Math.abs(row.remainingDays)} días!
                </Typography>}
            </TableCell> : 
            <TableCell>
                Sin definir
            </TableCell>
            }
            </>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.ingeneer || <i>Sin ingeniero</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.stageList[0].address || <i>Sin dirección</i> }
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            // hideCloseButton
            open={Boolean(selectedProjectId&&project)}
            handleClose={() => {
                setSelectedProjectId(null);
                setProject(null);
            }}
            handleSuccess={() => {
                setSelectedProjectId(null);
                setProject(null);
            }}
            title="Detalles de Proyecto"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información del proyecto seleccionado."
          >
              {project && <ProjectDetail
                  project={project}
                  properties={properties}
                  setProperties={setProperties}
                  reloadProperties={getProperties}
                  handleUpdateProject={handleUpdateProject}
                  engineers={engineers}
                  onClose={() => {
                    setSelectedProjectId(null);
                    setProject(null);
                  }}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Lista de Proyectos"
            headers={headCells}
            rows={projects.results || []}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={projects.count}
            rowComponents={rowComponents}
        />
        </>
    );
};

ProjectsTable.propTypes = {

};

export default ProjectsTable;
