import React, {useCallback, useState} from "react";
import {useSnackbar} from "notistack";
import Calendar from "@toast-ui/react-calendar";
import PropTypes from 'prop-types';
import {
    useCalendars,
} from "../../api/visitApi";
import CalendarNav from "../../components/Calendar/CalendarNav";

const bgColors = [
    '#9e5fff',
    '#00a9ff',
    '#80ff00',
]

const borderColors = [
    '#9e5fff',
    '#00a9ff',
    '#80ff00',
]

export default function VisitCalendar({ defaultView, defaultViews, schedules }){

    const { enqueueSnackbar } = useSnackbar();
    const { calendars, loading: loadingCalendars } = useCalendars();
    const [ selectedView, setSelectedView] = useState(defaultView);
    const [ calendar, setCalendar] = useState(null);

    const calendarRef = useCallback(node => {
        if (node !== null) {
            setCalendar(node.getInstance());
        }
    }, []);

    return <>

        <CalendarNav
            calendar={calendar}
            view={selectedView}
            views={defaultViews}
        />
        <Calendar
            ref={calendarRef}
            height="600px"
            calendars={calendars.map((t, i) => (
              {...t,
                  id: t.id.toString(),
                  calendarId: t.id,
                  borderColor: t.borderColor,
                  bgColor: t.bgColor,
              }))}
            disableDblClick
            disableClick={false}
            isReadOnly
            month={{
                startDayOfWeek: 1,
                daynames: [
                    'Domingo',
                    'Lunes',
                    'Martes',
                    'Miércoles',
                    'Jueves',
                    'Viernes',
                    'Sábado'
                ],
                narrowWeekend: true,
            }}
            week={{
                startDayOfWeek: 1,
                daynames: [
                    'Domingo',
                    'Lunes',
                    'Martes',
                    'Miércoles',
                    'Jueves',
                    'Viernes',
                    'Sábado'
                ],
                narrowWeekend: true,
            }}
            // eslint-disable-next-line no-template-curly-in-string
            schedules={schedules.map(({visitId, ...s}) => {
                if (visitId)
                    return {...s, body: `<a href='?visitId=${visitId}'>Ver detalles</a>`};
                return {...s, visitId };
            })}
            scheduleView={['time' ,'allday']}
            taskView={false}
            template={{
                alldayTitle() {
                    return '<span class="tui-full-calendar-left-content">Día completo</span>';
                },
                popupIsAllDay() {
                    return 'Todo día';
                },
                popupStateFree() {
                    return 'Libre';
                },
                popupStateBusy() {
                    return 'Ocupado';
                },
                titlePlaceholder() {
                    return 'Tema';
                },
                locationPlaceholder() {
                    return 'Ubicación';
                },
                startDatePlaceholder() {
                    return 'Fecha inicio';
                },
                endDatePlaceholder() {
                    return 'Fecha termino';
                },
                popupSave() {
                    return 'Agendar';
                },
                popupUpdate() {
                    return 'Actualizar';
                },
                popupDetailState(schedule) {
                    return `Estado : ${  schedule.state}` || 'Busy';
                },
                popupDetailBody(schedule) {
                    return `Descripción : <br/>${  schedule.body}`;
                },
                popupEdit() {
                    return 'Editar';
                },
                popupDelete() {
                    return 'Eliminar';
                }
            }}
            useDetailPopup
            view={selectedView} // You can also set the `defaultView` option.
        />
    </>
}

VisitCalendar.propTypes = {
    defaultView: PropTypes.string,
    defaultViews: PropTypes.array,
}

VisitCalendar.defaultProps = {
    defaultView: 'month',
    defaultViews: ['day', 'week', 'month']
}
