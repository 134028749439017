import React, { useCallback, useRef, useState } from "react";

// @material-ui/core
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";

// @material-ui/icons
import CameraAlt from "@material-ui/icons/CameraAlt";
import Close from "@material-ui/icons/Close";
// Core
import Webcam from "react-webcam";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles(theme => ({
  fullScreen: {},
  cameraContainer: {
    backgroundColor: "#cccccc",
    width: "100%",
    height: "100%"
  },
  textCenter: {
    textAlign: "center"
  },
  dummyItem: {
    padding: "10px",
    zIndex: 2
  },
  actions: {
    padding: "10px"
  }
}));

export default function CustomWebcam({ onCapture, onClose, style }) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
  }, [webcamRef]);
  const content = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      className={classes.cameraContainer}
      wrap="nowrap"
      style={style}
    >
      {error && (
        <>
          <Grid className={classes.textCenter}>
            <Typography variant="body2" color="textSecondary">
              <i>
                <strong>No se puede acceder a la cámara.</strong> <br />
                Revisa que esté correctamente configurada y de otorgar los
                permisos necesarios.
              </i>
            </Typography>
            {onClose && (
              <Button onClick={onClose} color="default" size="large">
                Volver
              </Button>
            )}
          </Grid>
        </>
      )}
      {!error && (
        <>
          <div
            style={{
              width: "100vw",
              height: "100vh",
              position: "absolute"
            }}
          >
            <Webcam
              width="100%"
              height="100%"
              audio={false}
              videoConstraints={{
                facingMode: "environment"
              }}
              ref={webcamRef}
              onUserMediaError={() => {
                setError(true);
              }}
              screenshotFormat="image/jpeg"
            />
          </div>
          <Grid className={classes.dummyItem} />
          <div
            style={{
              width: "40vh",
              height: "80vh",
              position: "relative",
            }}
          />
          <Grid item className={classes.actions}>
            <Grid container>
              <Fab color="default" aria-label="capturar" onClick={capture}>
                <CameraAlt />
              </Fab>
            {onClose && (
              <Fab color="default" aria-label="cerrar" onClick={onClose}>
                <Close />
              </Fab>
            )}
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        left: 0,
        top: 0,
        zIndex: 1
      }}
    >
      {content}
    </div>
  );
}
