import axios from "axios";
import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import {useSnackbar} from "notistack";
import { withStyles } from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper"
import WarningIcon from '@material-ui/icons/Warning';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import {LinearProgress} from "@material-ui/core";
import Lightbox from 'react-lightbox-component';
// import querystring from "querystring";
// import axiosAPI from "../../api/axiosApi";
// import {
//     getClientData,
//     getClientDocument,
//     updateUserDocument
// } from "../../api/clientApi";

const MagalUserHome = () => {
    
    const history = useHistory();

    const userName = localStorage.getItem('userName')

    // const { enqueueSnackbar } = useSnackbar()

    // const [dialogOpen, setDialogOpen] = useState(false)
    
    // const [loading, setLoading] = useState(true)


    // if (loading) return <LinearProgress />;}

    return (
      <>
        <Paper
            style={{
                padding: 15,
                marginTop: '2%',
                justifyContent: 'center'
            }}
            elevation={3}
            xs={12}
            
        >
          <Grid
              container
              spacing={3}
            //   xs={12}
              style={{
                  marginTop: '1%',
                  marginBottom: '1%',
                  marginLeft: '1%',
                  justifyContent: 'left'
              }}
            >
              <Typography variant='h6'>
                          Bienvenido {userName} , para ver tus requerimientos, cambia de pestaña usando la barra superior.
              </Typography>
            </Grid>

        </Paper>
    </>
    );
};

MagalUserHome.propTypes = {

};

export default MagalUserHome;
