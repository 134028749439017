import React, {useRef, useState} from 'react';
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import {LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import EventIcon from "@material-ui/icons/Event";
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import {useTicket, useTickets} from "../../api/ticketApi";
import TicketList from "./TicketList";
import 'moment/src/locale/es'
import VisitCalendar from "./VisitCalendar";
import SelectAutocompleteInput from "../../components/Inputs/SelectAutocompleteInput";
import SuccessButtons from "../Tech/Utils/SuccessButtons"
import {
    useCalendars,
    visitCreate
  } from "../../api/visitApi";


const getQueryParams = ({recalendarizeOnly, overdue}) => {
    if (recalendarizeOnly) return {state__state_category: 'A'}
    if (overdue) return {state__state_category: 'A', overdue: true}
    return {state__state_category: 'P'}
}
const CalendarizeVisits = ({recalendarizeOnly, overdue}) => {
    const queryParams = getQueryParams({recalendarizeOnly, overdue})
    const { enqueueSnackbar } = useSnackbar();

    const userType = localStorage.getItem('userType')

    if (userType === "ING") {
        queryParams.project__ingeneer__username = localStorage.getItem('username')
    }

    const { tickets, loading, removeTicket } = useTickets({page: 1, page_size: 100, ...queryParams})
    const calendarRef = useRef(null);

    const [selectedTicketId, setSelectedTicketId] = useState(null);
    const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId});
    const { calendars, loading: loadingCalendars } = useCalendars();
    const [ ticketForCreationPopup, setTicketForCreation ] = useState(null);

    const [ team, setTeam ] = useState(null)
    const [fromDate, setFromDate] = React.useState(new Date())
    const [toDate, setToDate] = React.useState(new Date())

    const newFromDate = (e) => {
        if (e.diff(toDate, 'minutes') >= 0){
          const newDate = moment(e, "DD/MM/yyyy hh:mm").add(1, 'hours')
          setToDate(newDate)
        }
        setFromDate(e)
      }
    
      const newToDate = (e) => {
        if (e.diff(fromDate, 'minutes') <= 0){
          const newDate = moment(e, "DD/MM/yyyy hh:mm").subtract(1, 'hours')
          setFromDate(newDate)
        }
        setToDate(e)
      }

    const createSchedule = async () => {
        const response = await visitCreate(ticketForCreationPopup.id, {
          team,
          fromDate,
          toDate
        });
        enqueueSnackbar(
          '¡La visita ha sido agendada!',
          {
            variant: 'success'
          }
        );
        setTimeout(() => {
            window.location.reload();
        }, 1500);
      }

    moment.locale('es');

    if (loading) return <LinearProgress />;

    return (
        <Grid 
            container 
            spacing={3} 
            style={{
                paddingLeft: '2%',
                paddingTop: '2%'
            }}
        >
            <Grid item xs={12} md={3}>
                <TicketList
                  recalendarizeOnly={recalendarizeOnly}
                  overdue={overdue}
                  selectedTicketId={selectedTicketId}
                  setSelectedTicketId={setSelectedTicketId}
                  creationTicket={ticketForCreationPopup}
                  onOpenPopupForTicket={setTicketForCreation}
                  ticket={ticket}
                  tickets={tickets.results} calendar={calendarRef} scheduleOnClick />
            </Grid>
                {!ticketForCreationPopup ? 
                <Grid 
                    item
                    xs={12} 
                    md={9} 
                    style={{
                        justifyContent: 'center',
                        paddingTop: '10%',
                        paddingBottom: '3%',
                    }}
                >
                    <Typography variant="body1" color="textPrimary" style={{fontSize: '30px'}}>
                        Selecciona un Requerimiento para agendar una cita.
                    </Typography>           
                </Grid>
                : 
                <Grid item xs={12} md={9} style={{paddingLeft: '15%', paddingTop: '10%', justifyContent: 'center'}} >
                    {/* <Paper style={{ paddingTop: '1%', paddingLeft: '1%', display: 'flex'}}> */}
                    <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
                        <Typography variant="body2" color="textSecondary">
                        Propiedad del Requerimiento
                        </Typography>
                        <Typography variant="body1" color="textPrimary">
                        {ticketForCreationPopup.propertyName} - {ticketForCreationPopup.propertyData}
                        </Typography>
                    </Grid>
                    <Grid item xs={4} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
                    <Typography variant="body2" color="textSecondary">
                        Seleccionar Equipo a realizar la visita
                    </Typography>
                    <SelectAutocompleteInput
                        value={team}
                        options={calendars}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            setTeam(newValue)
                        }}
                        renderOptionFunction={(option) => {
                            return option.name
                        }}
                        optionLabelFunction={(option) => {
                            return option.name
                        }}
                        disableCloseOnSelect={false}
                        // inputLabel="Equipo"
                    />
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
                        <KeyboardDateTimePicker 
                            label="Fecha y hora de inicio"
                            inputVariant="outlined"
                            ampm={false}
                            margin="normal"
                            id="from-date-picker"
                            format="DD/MM/yyyy HH:mm"
                            value={fromDate}
                            onChange={e => newFromDate(e)}
                            KeyboardButtonProps={{
                            "aria-label": "change date",
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
                        <KeyboardDateTimePicker 
                            label="Fecha y hora de término"
                            inputVariant="outlined"
                            margin="normal"
                            ampm={false}
                            id="to-date-picker"
                            format="DD/MM/yyyy HH:mm"
                            value={toDate}
                            onChange={e => newToDate(e)}
                        />
                    </Grid>
                    <Grid item style={{paddingBottom: '1%', paddingLeft: '1%'}}>
                    <SuccessButtons
                        icon={<EventIcon fontSize="large" />}
                        disabled={team===null}
                        onClickHandler={() => {
                        createSchedule()
                        }}
                        text="Confirmar visita"
                    />
                    </Grid>
                    {/* <VisitCalendar
                        ticket={ticketForCreationPopup}
                        removeTicket={removeTicket}
                        ticketForCreationPopup={ticketForCreationPopup}
                        calendar={calendarRef}
                        calendarRef={calendarRef}
                        isReadOnly={false}
                        onShowCreationPopup={null}
                        setTicketForCreationPopup={setTicketForCreation}
                    /> */}
                    {/* </Paper> */}
                </Grid>
            }
        </Grid>
    );
};

export default CalendarizeVisits;