import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";

import {
    useTeams,
} from "../../../api/visitApi";
import {
    getEngineers,
    deleteTeam,
    updateTeam,
} from "../../../api/usersApi"
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import BaseTable from "./BaseTable";
import TeamDetail from "./TeamDetail"
import TeamCreate from "./TeamCreate"

const TeamsTable = () => {
    
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)

    const queryParams = {page: page + 1, maxResults}

    const { teams, loading, removeProject } = useTeams(queryParams)
    const [selectedTeamId, setSelectedTeamId] = useState(null)
    const [team, setTeam] = useState(null);
    const [engineers, setEngineers] = useState(null)
    const [received, setReceived] = useState(false)

    const [teamAddModal, setTeamAddModal] = useState(false)
    
    const { enqueueSnackbar } = useSnackbar();

    const fetchEngineers = () => {
        getEngineers().then((response) => {
            setEngineers(response)
        })
    }

    useEffect(() => {
        if (!received){
            fetchEngineers();
            setReceived(true)
        }
    })

    const handleDeleteTeam = async (data) => {
        const response = await deleteTeam(data);
        enqueueSnackbar(
            'El equipo ha sido desactivado!',
            {
                variant: 'success'
            }
        );
        setTimeout( () => {
            window.location.reload();
        }, 2000);
    }


    const handleUpdateTeam = async (data) => {
        const response = await updateTeam(data);
        if (response.data.error){
            enqueueSnackbar(
                'Hubo un error al actualizar la información.',
                {
                    variant: 'error'
                }
            );
        } else {
            enqueueSnackbar(
                '¡El equipo ha sido actualizado!',
                {
                    variant: 'success'
                }
            );
            setTimeout( () => {
                window.location.reload();
            }, 2000);
        }
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'name', numeric: false, disablePadding: false, label: 'Nombre Equipo' },
        { id: 'responsableUsername', numeric: false, disablePadding: false, label: 'Responsable' },
        { id: 'teamMembers', numeric: false, disablePadding: false, label: 'N° miembros' },
        { id: 'isActive', numeric: false, disablePadding: false, label: 'Estado' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => {
                                        setTeam(row);
                                        setSelectedTeamId(row.id);
                                        }}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.name || <i>Sin nombre</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                {row.responsableUsername || <i>Sin responsable</i>}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.teamMembers.length || <i>0</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.isActive ? <i>Activo</i> : <i>Desactivado</i> }
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            // hideCloseButton
            open={Boolean(teamAddModal)}
            handleClose={() => {
                setTeamAddModal(false);
            }}
            handleSuccess={() => {
                setTeamAddModal(false);
            }}
            title="Agregar un nuevo Equipo"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Para agregar un nuevo equipo, escribir un nombre y asignar Ingeniero a cargo. Una vez creado, editar para agregar miembros."
          >
              {teamAddModal && <TeamCreate
                onClose={() => {
                    setTeamAddModal(false);
                }}
              />
              }
          </BaseDialog>
          <BaseDialog
            // hideCloseButton
            open={Boolean(selectedTeamId&&team)}
            handleClose={() => {
                setSelectedTeamId(null);
                setTeam(null);
            }}
            handleSuccess={() => {
                setSelectedTeamId(null);
                setTeam(null);
            }}
            title="Detalles de Equipo"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información del equipo seleccionado."
          >
              {team && <TeamDetail
                  team={team}
                  handleDeleteTeam={handleDeleteTeam}
                  handleUpdateTeam={handleUpdateTeam}
                  engineers={engineers}
                  onClose={() => {
                    setSelectedTeamId(null);
                    setTeam(null);
                  }}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Lista de Equipos"
            headers={headCells}
            rows={teams || []}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={teams.length}
            rowComponents={rowComponents}
            setTeamAddModal={setTeamAddModal}
        />
        </>
    );
};

TeamsTable.propTypes = {

};

export default TeamsTable;
