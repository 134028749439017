import snakecaseKeys from "snakecase-keys";
import axiosAPI from "./axiosApi";

export async function getStatistics(data) {
    const response = await axiosAPI.post(`tickets/get_stats/`, snakecaseKeys(data, {deep: true}));

    return response
}

export async function getStats(data) {
    const response = await axiosAPI.post(`tickets/get_stats/`, snakecaseKeys(data, {deep: true}));

    return response
}