import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red, orange} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import 'react-lightbox-component/build/css/index.css';


const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

const stateLabels = {
  P: "Pendiente",
  A: "Activa",
  C: "Completada",
}


const visitStateColors = {
  P: "inherit",
  A: "warning",
  C: "success",
}

export default function VisitDetail({visit}){
  const [ expanded, setExpanded ] = useState('')

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

  // const isDesktop = window.innerWidth > 980

  return (getClassNum() === 1) ? <>
  <Grid container>
    <Grid item xs={12}>
      <Table>
        <TableBody>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.propertyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Ubicación</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Fecha calendarizada</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Estado: {visit.closedDate ? <>Finalizada</> : <>Sin realizar</>}</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.stateLabel}</Typography>
            </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Problemas del Requerimiento</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">
                {!visit.faults.length && <i>No hay problemas asociados</i>}
                {visit.faults.map(fault => (
                    fault.isApproved ? 
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div style={{ marginRight: 20 }}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon fontSize='large' style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon fontSize='large' style={{ color: red[700] }} />
                      )
                    }
                    </div>
                    : <></>
                ))}
              </Typography>
            </TableCell>
          </TableRow>
          {visit.realDate ? <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Fecha iniciada</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
            {visit.delay || visit.extremeDelay ? <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{ 
                  visit.delay ? 
                  <>Atraso normal <ReportProblemIcon fontSize='large' style={{ color: orange[500] }} /></> : 
                  <>Atraso extremo <ReportIcon fontSize='large' style={{ color: red[700] }}/></>
              }</Typography>
            </TableCell> : <> {visit.realDate ? <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">Sin atrasos <CheckCircleTwoToneIcon style={{ color: green[500] }}/></Typography>
            </TableCell> : <TableCell/> }</>}
          </TableRow> : <></>}
          <TableRow colSpan='100%'>
            {visit.clientName ? <TableCell>
                <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Cliente</Typography>
                <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.clientName} </Typography>
                {visit.clientEmail && <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">- {visit.clientEmail} </Typography>}
                {visit.clientPhone && <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">- {visit.clientPhone}</Typography>}
              </TableCell> : <TableCell/>}
            {visit.closedDate ? <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Fecha finalizada</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{moment(visit.closedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>}
          </TableRow>
          {visit.realizedByName ? <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Realizada por</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">{visit.realizedByName}</Typography>
            </TableCell>
          </TableRow> : <></>}

          {visit.stateLabel === 'Completada' ? 
            <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={{ fontSize: '24px'}} variant="body2" color="textSecondary">Problemas atendidos durante esta Visita</Typography>
              <Typography style={{ fontSize: '26px'}} variant="body1" color="textPrimary">
                {!visit.faultsViewed.length && <i>No hay problemas asociados</i>}
                {visit.faultsViewed.map(fault => (
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div style={{ marginRight: 20 }}>• {fault.action} : {fault.fault}</div>
                    </div>
                ))}
              </Typography>
            </TableCell>
            </TableRow> : <></>}
          
        </TableBody>
      </Table>
    </Grid>
  </Grid>
  </> :
  <>
  <Grid container>
    <Grid item xs={12}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography variant="body1" color="textPrimary">{visit.propertyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Ubicación</Typography>
              <Typography variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha calendarizada</Typography>
              <Typography variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="body2" color="textSecondary">Problemas del Requerimiento</Typography>
              <Typography variant="body1" color="textPrimary">
                {!visit.faults.length && <i>No hay problemas asociados</i>}
                {visit.faults.map(fault => (
                    fault.isApproved ?
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div style={{ marginRight: 20 }}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon style={{ color: red[700] }} />
                      )
                    }
                    </div>
                    : <></>
                ))}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Estado: {visit.closedDate ? <>Finalizada</> : <>Sin realizar</>}</Typography>
              <Typography variant="body1" color="textPrimary">{visit.stateLabel}</Typography>
            </TableCell>
            {visit.realDate ? <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha iniciada</Typography>
              <Typography variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>}
            {visit.delay || visit.extremeDelay ? <TableCell>
              <Typography variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography variant="body1" color="textPrimary">{ 
                  visit.delay ? 
                  <>Atraso normal <ReportProblemIcon style={{ color: orange[500] }} /></> : 
                  <>Atraso extremo <ReportIcon style={{ color: red[700] }}/></>
              }</Typography>
            </TableCell> : <> {visit.realDate ? <TableCell>
              <Typography variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography variant="body1" color="textPrimary">Sin atrasos <CheckCircleTwoToneIcon style={{ color: green[500] }}/></Typography>
            </TableCell> : <TableCell/> }</>}
          </TableRow>
          <TableRow>
            {visit.clientName ? <TableCell>
                <Typography variant="body2" color="textSecondary">Cliente</Typography>
                <Typography variant="body1" color="textPrimary">{visit.clientName} </Typography>
                {visit.clientEmail && <Typography variant="body1" color="textPrimary">- {visit.clientEmail} </Typography>}
                {visit.clientPhone && <Typography variant="body1" color="textPrimary">- {visit.clientPhone}</Typography>}
              </TableCell> : <TableCell/>}
            {visit.closedDate ? <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha finalizada</Typography>
              <Typography variant="body1" color="textPrimary">{moment(visit.closedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>}
            {visit.realizedByName ? <TableCell>
              <Typography variant="body2" color="textSecondary">Realizada por</Typography>
              <Typography variant="body1" color="textPrimary">{visit.realizedByName}</Typography>
            </TableCell> : <TableCell/>}
          </TableRow>

          {visit.stateLabel === 'Completada' ? 
            <TableRow>
            <TableCell colSpan={3}>
              <Typography variant="body2" color="textSecondary">Problemas atendidos durante esta visita</Typography>
              <Typography variant="body1" color="textPrimary">
                {!visit.faultsViewed.length && <i>No hay problemas asociados</i>}
                {visit.faultsViewed.map(fault => (
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div style={{ marginRight: 20 }}>• {fault.action} : {fault.fault}</div>
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow> : <></>}
          
        </TableBody>
      </Table>
    </Grid>
  </Grid>
  </>
}
