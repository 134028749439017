import axios from "axios";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useHistory} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {LinearProgress} from "@material-ui/core";
import {useSnackbar} from "notistack";
import Paper from '@material-ui/core/Paper';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import SearchIcon from '@material-ui/icons/Search';
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import 'react-lightbox-component/build/css/index.css';
import {
    createProject,
    uploadClients,
    uploadRCP,
  } from "../../api/projectsApi";


export default function MassiveLoad(){

    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileClient, setSelectedFileClient] = useState();
    const [selectedFileRCP, setSelectedFileRCP] = useState();
    
    const [fileName, setFileName] = useState(null);
    const [fileNameClient, setFileNameClient] = useState(null);
    const [fileNameRCP, setFileNameRCP] = useState(null);
    
    const [uploadResults, setUploadResults] = useState(null);
    const [uploadResultsClient, setUploadResultsClient] = useState(null);
    const [uploadResultsRCP, setUploadResultsRCP] = useState(null);
    
    const [fileProcessed, setFileProcessed] = useState(false);
    const [fileProcessedClient, setFileProcessedClient] = useState(false);
    const [fileProcessedRCP, setFileProcessedRCP] = useState(false);
    
    const [selectedOnce, setSelectedOnce] = useState(false);
    const [selectedOnceClient, setSelectedOnceClient] = useState(false);
    const [selectedOnceRCP, setSelectedOnceRCP] = useState(false);

    const [loadingFile, setLoadingFile] = useState(false)
    const [loadingFileClient, setLoadingFileClient] = useState(false)
    const [loadingFileRCP, setLoadingFileRCP] = useState(false)
    
    const [expanded, setExpanded] = React.useState(false);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar()

    const useStyles = makeStyles((theme) => ({
            paper: {
                width: '100%',
                marginBottom: '5%',
                marginTop: '1%'
            },
            container: {
                maxHeight: '100vh'
            },
    }));
    const classes = useStyles();

    const handleAccordionChange = (name) => () => {
        if (expanded !== name) setExpanded(name);
        else setExpanded('');
    };

    const downloadTemplate = async () => {
        const accessToken = localStorage.getItem("access_token");
        axios({
            url: `${process.env.API_URL}project/get_template`,
            timeout: 5000,
            method: 'GET',
            headers: {
                Authorization: accessToken ? `JWT ${accessToken}` : null,
                "Content-Type": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
            responseType: 'blob',
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Template.xlsx');
            document.body.appendChild(link);
            link.click();
        })
    };

    const hiddenFileInput = React.useRef(null);
    const hiddenFileInputClient = React.useRef(null);
    const hiddenFileInputRCP = React.useRef(null);

    const handleFileChange = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        setSelectedOnce(true);
    }

    const handleFileChangeRCP = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFileRCP(event.target.files[0]);
        setFileNameRCP(event.target.files[0].name);
        setSelectedOnceRCP(true);
    }

    const handleFileChangeClient = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFileClient(event.target.files[0]);
        setFileNameClient(event.target.files[0].name);
        setSelectedOnceClient(true);
    }

    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    }

    const handleUploadClickClient = event => {
        hiddenFileInputClient.current.click();
    }

    const handleUploadClickRCP = event => {
        hiddenFileInputRCP.current.click();
    }

    const massiveLoadFile = async () => {
        // Validar inputs
    
        // Useful for other things
        const formData = new FormData();
        formData.append('file', selectedFile);
    
        // const response = await createProject(formData);
        const response = await createProject(formData);
    
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else{
            enqueueSnackbar(
                'Se ha realizado la carga masiva con éxito.',
                {
                    variant: 'success'
                }
            );
            setUploadResults(response.data);
            setFileProcessed(true);
            
            // setTimeout( () => {
            //     history.push(`/administracion/proyectos/`);
            //     window.location.reload();
            // }, 1500)
            // onClose();
        }

        setLoadingFile(false);        
        return true;
      };
    
      const clientLoadFile = async () => {
        // Validar inputs
    
        // Useful for other things
        const formData = new FormData();
        formData.append('file', selectedFileClient);
    
        // const response = await createProject(formData);
        const response = await uploadClients(formData);
    
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else{
            enqueueSnackbar(
                'Se ha realizado la carga de Clientes con éxito.',
                {
                    variant: 'success'
                }
            );
            setUploadResultsClient(response.data);
            setFileProcessedClient(true);
        }

        setLoadingFileClient(false);

        return true;
      };

      const rcpLoadFile = async () => {
        // Validar inputs
    
        // Useful for other things
        const formData = new FormData();
        formData.append('file', selectedFileRCP);
    
        // const response = await createProject(formData);
        const response = await uploadRCP(formData);
    
        if (response.data.error){
            enqueueSnackbar(
                response.data.error,
                {
                    variant: 'error'
                }
            );
        } else{
            enqueueSnackbar(
                'Se ha realizado la carga de Clientes con éxito.',
                {
                    variant: 'success'
                }
            );
            setUploadResultsRCP(response.data);
            setFileProcessedRCP(true);
        }

        setLoadingFileRCP(false);    
        return true;
      };


  return <>
    <Grid
        container
        spacing={8}
        mb={3}
        style={{
            paddingTop: '1%',
            paddingLeft: '1%',
            paddingRight: '1%',
            justifyContent: 'center'
        }}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 0
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    justifyContent="center" 
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '1%',
                        marginTop: '1%'
                    }}
                >
                    <Typography 
                        variant="h6"  
                        color="textPrimary"
                    >
                        Carga Masiva de Datos
                    </Typography>
                </Grid>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        paddingLeft: '2%',
                        paddingRight: '2%',
                        marginBottom: '1%',
                        marginTop: '1%'
                    }}
                >
                    <Grid item xs={12}>
                        <Typography 
                            variant="body2"
                            color="textSecondary"
                        >
                            A continuación se pueden agregar de forma masiva datos de nuevos Proyectos, Propiedades, Clientes y Relaciones Cliente-Propiedad. Para esto se debe subir un archivo que siga el formato correcto. 
                            {/* El Template se puede descargar <button type='button' styling="link" onClick={downloadTemplate} aria-hidden="true">aquí</button>. */}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <SuccessButtons
                            onClickHandler={downloadTemplate}
                            text="Descargar Template"
                        />                        
                    </Grid>
                </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" color="textSecondary">Carga el Archivo Excel</Typography>
                                        <Grid item xs={12} md="auto">
                                            <Typography variant="body2" color="textPrimary">{fileName}</Typography>
                                            <SuccessButtons
                                                icon={<SearchIcon />}
                                                onClickHandler={handleUploadClick}
                                                text="Seleccionar archivo"
                                            />
                                            <input 
                                                type="file" 
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                ref={hiddenFileInput}
                                                onChange={handleFileChange}
                                                style={{display: 'none'}}
                                                />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid 
                        container 
                        alignItems="center"
                        justifyContent="center"
                        mb={3}
                        style={{
                            paddingTop: '1%',
                            paddingBottom: '1%',
                            paddingLeft: '1%',
                            paddingRight: '1%',
                            marginBottom: '1%',
                            marginTop: '1%'
                        }}
                    >
                        <Grid 
                            item 
                            xs={12} 
                            md="auto"
                            style={{
                                paddingLeft: '1%',
                                paddingRight: '1%',
                            }}
                        >
                            <SuccessButtons
                            icon={<CheckCircleTwoToneIcon />}
                            onClickHandler={() => {
                                setLoadingFile(true);
                                massiveLoadFile();
                            }}
                            disabled={!selectedOnce}
                            text="Cargar Datos"
                            />
                        </Grid>
                    </Grid>
                
                </Grid>
                </TableContainer>
                {loadingFile ? <LinearProgress /> : <></> }
                {fileProcessed ? <Grid container>
                    <Grid 
                        item 
                        xs={12} 
                        container 
                        mb={3} 
                        alignItems="center" 
                        justifyContent="center"
                        style={{
                            justifyContent: 'center',
                            marginBottom: '1%',
                            marginTop: '1%'
                        }}
                    >
                        <Typography 
                            variant="h6"  
                            color="textPrimary" 
                            alignItems="center" 
                            align="center"
                        >
                            Resultados de la Carga 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow colSpan='100%'>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hoja Proyectos</Typography>
                                        <Typography variant="body2" color="textPrimary">Agregados : {uploadResults.project_results.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">Existentes : {uploadResults.project_results.existing_amount}</Typography>
                                        {uploadResults.project_results.existing_detail.length !== 0 ? <Accordion key='existing_project' expanded={expanded === 'existing_project'} onChange={handleAccordionChange('existing_project')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Proyectos Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* {uploadResults.project_results.existing_detail.map(row => (
                                                    <div></div>
                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                ))} */}
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.project_results.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {/* <Grid item xs={12} md={4}>
                                                            <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                        </Grid> */}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">Con Error : {uploadResults.project_results.failed_amount}</Typography>
                                        {uploadResults.project_results.failed_detail.length !== 0 ? <Accordion key='failed_project' expanded={expanded === 'failed_project'} onChange={handleAccordionChange('failed_project')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Proyectos con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* {uploadResults.project_results.existing_detail.map(row => (
                                                    <div></div>
                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                ))} */}
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.project_results.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {/* <Grid item xs={12} md={4}>
                                                            <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                        </Grid> */}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>
                                <TableRow colSpan='100%'>

                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hoja Propiedades</Typography>
                                        <Typography variant="body2" color="textPrimary">Agregadas : {uploadResults.properties_results.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">Existentes : {uploadResults.properties_results.existing_amount}</Typography>
                                        {uploadResults.properties_results.existing_detail.length !== 0 ? <Accordion key='existing_properties' expanded={expanded === 'existing_properties'} onChange={handleAccordionChange('existing_properties')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Propiedades Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                {/* {uploadResults.project_results.existing_detail.map(row => (
                                                    <div></div>
                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                ))} */}
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.properties_results.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                        {/* <Grid item xs={12} md={4}>
                                                            <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                        </Grid> */}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">Con Error : {uploadResults.properties_results.failed_amount}</Typography>
                                        {uploadResults.properties_results.failed_detail.length !== 0 ? <Accordion key='failed_properties' expanded={expanded === 'failed_properties'} onChange={handleAccordionChange('failed_properties')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Propiedades con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.properties_results.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>
                                <TableRow colSpan='100%'>

                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hoja Clientes</Typography>
                                        <Typography variant="body2" color="textPrimary">Agregados : {uploadResults.clients_results.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">Existentes : {uploadResults.clients_results.existing_amount}</Typography>
                                        {uploadResults.clients_results.existing_detail.length !== 0 ? <Accordion key='existing_clients' expanded={expanded === 'existing_clients'} onChange={handleAccordionChange('existing_clients')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Clientes Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.clients_results.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">Con Error : {uploadResults.clients_results.failed_amount}</Typography>
                                        {uploadResults.clients_results.failed_detail.length !== 0 ? <Accordion key='failed_clients' expanded={expanded === 'failed_clients'} onChange={handleAccordionChange('failed_clients')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Clientes con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.clients_results.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>
                                <TableRow colSpan='100%'>

                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hoja Relaciones Cliente - Propiedad</Typography>
                                        <Typography variant="body2" color="textPrimary">Agregadas : {uploadResults.rcp_results.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">Existentes : {uploadResults.rcp_results.existing_amount}</Typography>
                                        {uploadResults.rcp_results.existing_detail.length !== 0 ? <Accordion key='existing_rcp' expanded={expanded === 'existing_rcp'} onChange={handleAccordionChange('existing_rcp')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Relaciones Cliente-Propiedad Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.rcp_results.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">Con Error : {uploadResults.rcp_results.failed_amount}</Typography>
                                        {uploadResults.rcp_results.failed_detail.length !== 0 ? <Accordion key='failed_rcp' expanded={expanded === 'failed_rcp'} onChange={handleAccordionChange('failed_rcp')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Relaciones Cliente-Propiedad con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResults.rcp_results.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>

                                <TableRow colSpan='100%'>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hojas Procesadas : </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            <Box style={{paddingRight: '36px'}} width="100%">
                                                    {uploadResults.processed_sheets.map(row => (
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography variant="body2" color="textPrimary">- {row} {'\n'}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </Box>
                                        </Typography>
                                    </TableCell>

                                </TableRow>

                                <TableRow colSpan='100%'>
                                    <TableCell>
                                        <Typography variant="body1" color="textPrimary">Hojas Ignoradas : </Typography>
                                        <Typography variant="body2" color="textPrimary">
                                            <Box style={{paddingRight: '36px'}} width="100%">
                                                    {uploadResults.ignored_sheets.map(row => (
                                                        <Grid container spacing={3}>
                                                            <Grid item xs={12} md={12}>
                                                                <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                            </Grid>
                                                        </Grid>
                                                    ))}
                                            </Box>
                                        </Typography>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>            
                </Grid> : <></>}
                
            </Paper>
        </Grid>

    </Grid>

    <Grid
        container
        spacing={8}
        mb={3}
        style={{
            paddingTop: '1%',
            paddingLeft: '1%',
            paddingRight: '1%',
            justifyContent: 'center'
        }}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 0
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    justifyContent="center" 
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '1%',
                        marginTop: '1%'
                    }}
                >
                    <Typography 
                        variant="h6"  
                        color="textPrimary"
                    >
                        Carga de Clientes
                    </Typography>
                </Grid>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        paddingLeft: '2%',
                        paddingRight: '2%'
                    }}
                >
                    <Typography 
                        variant="body2"
                        color="textSecondary"
                    >
                        A continuación se pueden agregar nuevos Clientes a la base de datos. Se debe utilizar el mismo formato del Template que se puede descargar arriba. Solo se considerará la hoja Clientes.
                    </Typography>
                </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" color="textSecondary">Carga el Archivo Excel</Typography>
                                        <Grid item xs={12} md="auto">
                                            <Typography variant="body2" color="textPrimary">{fileNameClient}</Typography>
                                            <SuccessButtons
                                                icon={<SearchIcon />}
                                                onClickHandler={handleUploadClickClient}
                                                text="Seleccionar archivo"
                                            />
                                            <input 
                                                type="file" 
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                ref={hiddenFileInputClient}
                                                onChange={handleFileChangeClient}
                                                style={{display: 'none'}}
                                                />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid 
                        container 
                        alignItems="center"
                        mb={3}
                        style={{
                            paddingTop: '2%',
                            paddingBottom: '2%',
                            paddingLeft: '2%',
                            paddingRight: '2%',
                        }}
                    >
                        <Grid item xs={12} md="auto">
                            <SuccessButtons
                            icon={<CheckCircleTwoToneIcon />}
                            onClickHandler={() => {
                                setLoadingFileClient(true);
                                clientLoadFile();
                            }}
                            disabled={!selectedOnceClient}
                            text="Cargar Clientes"
                            />
                        </Grid>
                    </Grid>
                
                </Grid>
                </TableContainer>
                {loadingFileClient ? <LinearProgress /> : <></> }
                {fileProcessedClient ? <Grid container>
                    <Grid 
                        item 
                        xs={12} 
                        container 
                        mb={3} 
                        alignItems="center" 
                        style={{
                            justifyContent: 'center',
                            paddingTop: 10,
                            paddingLeft: 3
                        }}
                    >
                        <Typography 
                            variant="h6"  
                            color="textPrimary" 
                            alignItems="center" 
                            align="center"
                        >
                            Resultados de la Carga
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow colSpan='100%'>

                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary">Clientes Agregados : {uploadResultsClient.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">Clientes Existentes : {uploadResultsClient.existing_amount}</Typography>
                                        {uploadResultsClient.existing_detail.length !== 0 ? <Accordion key='clients_existing' expanded={expanded === 'clients_existing'} onChange={handleAccordionChange('clients_existing')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Clientes Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResultsClient.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">Clientes con Error : {uploadResultsClient.failed_amount}</Typography>
                                        {uploadResultsClient.failed_detail.length !== 0 ? <Accordion key='clients_failed' expanded={expanded === 'clients_failed'} onChange={handleAccordionChange('clients_failed')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de Clientes con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResultsClient.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </Grid>            
                </Grid> : <></>}
                
            </Paper>
        </Grid>

    </Grid>


    <Grid
        container
        spacing={8}
        mb={3}
        style={{
            paddingTop: '1%',
            paddingLeft: '1%',
            paddingRight: '1%',
            justifyContent: 'center'
        }}
    >
        <Grid
            item
            container
            xs={12}
            md={6}
            mb={3}
            style={{
                paddingBottom: 0
            }}
        >
            <Paper className={classes.paper}>
            <TableContainer>
            <Grid container>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    justifyContent="center" 
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: '1%',
                        marginTop: '1%'
                    }}
                >
                    <Typography 
                        variant="h6"  
                        color="textPrimary"
                    >
                        Carga de Relaciones Cliente-Propiedad
                    </Typography>
                </Grid>
                <Grid 
                    item xs={12} 
                    container 
                    mb={12}
                    display="flex"
                    alignItems="center"
                    style={{
                        display: 'flex',
                        paddingLeft: '2%',
                        paddingRight: '2%'
                    }}
                >
                    <Typography 
                        variant="body2"
                        color="textSecondary"
                    >
                        A continuación se pueden agregar nuevas Relaciones Cliente-Propiedad a la base de datos. Se debe utilizar el mismo formato del Template que se puede descargar arriba. Solo se considerará la hoja Relación Clientes x Propiedades .
                    </Typography>
                </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        <Typography variant="body2" color="textSecondary">Carga el Archivo Excel</Typography>
                                        <Grid item xs={12} md="auto">
                                            <Typography variant="body2" color="textPrimary">{fileNameRCP}</Typography>
                                            <SuccessButtons
                                                icon={<SearchIcon />}
                                                onClickHandler={handleUploadClickRCP}
                                                text="Seleccionar archivo"
                                            />
                                            <input 
                                                type="file" 
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                ref={hiddenFileInputRCP}
                                                onChange={handleFileChangeRCP}
                                                style={{display: 'none'}}
                                                />
                                        </Grid>
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </Grid>

                    <Grid 
                        container 
                        alignItems="center"
                        mb={3}
                        style={{
                            paddingTop: '2%',
                            paddingBottom: '2%',
                            paddingLeft: '2%',
                            paddingRight: '2%',
                        }}
                    >
                        <Grid item xs={12} md="auto">
                            <SuccessButtons
                            icon={<CheckCircleTwoToneIcon />}
                            onClickHandler={() => {
                                setLoadingFileRCP(true); 
                                rcpLoadFile();
                            }}
                            disabled={!selectedOnceRCP}
                            text="Cargar Relaciones"
                            />
                        </Grid>
                    </Grid>
                
                </Grid>
                </TableContainer>
                {loadingFileRCP ? <LinearProgress /> : <></> }
                {fileProcessedRCP ? <Grid container>
                    <Grid 
                        item 
                        xs={12} 
                        container 
                        mb={3} 
                        alignItems="center" 
                        style={{
                            justifyContent: 'center',
                            paddingTop: 10,
                            paddingLeft: 3
                        }}
                    >
                        <Typography 
                            variant="h6"  
                            color="textPrimary" 
                            alignItems="center" 
                            align="center"
                        >
                            Resultados de la Carga
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Table>
                            <TableBody>
                                <TableRow colSpan='100%'>

                                    <TableCell>
                                        <Typography variant="body2" color="textPrimary">RCP Agregados : {uploadResultsRCP.successful_amount}</Typography>
                                        <Typography variant="body2" color="textPrimary">RCP Existentes : {uploadResultsRCP.existing_amount}</Typography>
                                        {uploadResultsRCP.existing_detail.length !== 0 ? <Accordion key='rcp_existing' expanded={expanded === 'rcp_existing'} onChange={handleAccordionChange('rcp_existing')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de RCP Existentes
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResultsRCP.existing_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                        <Typography variant="body2" color="textPrimary">RCP con Error : {uploadResultsRCP.failed_amount}</Typography>
                                        {uploadResultsRCP.failed_detail.length !== 0 ? <Accordion key='rcp_failed' expanded={expanded === 'rcp_failed'} onChange={handleAccordionChange('rcp_failed')}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1c-content"
                                                id="panel1c-header"
                                            >
                                                Detalle de RCP con Error
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Box style={{paddingRight: '36px'}} width="100%">
                                                        {uploadResultsRCP.failed_detail.map(row => (
                                                            <Grid container spacing={3}>
                                                                <Grid item xs={12} md={12}>
                                                                    <Typography variant="body2" color="textPrimary">{row} {'\n'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                </Box>
                                            </AccordionDetails>
                                        </Accordion> : <></>}
                                    </TableCell>
                                </TableRow>
                                
                            </TableBody>
                        </Table>
                    </Grid>            
                </Grid> : <></>}
                
            </Paper>
        </Grid>

    </Grid>
</>
}

MassiveLoad.propTypes = {

}

MassiveLoad.defaultProps = {
}