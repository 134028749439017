import {
    LOGIN_USER_SUCCESS,
    LOGOUT_USER
} from "./constants";
import {
    obtainToken,
    logout
} from "../api/authenticationApi";

export function loginUserSuccess(token) {
    return {
        type: LOGIN_USER_SUCCESS,
        accessToken: token
    };
}

export function loginUser(username, password) {
    return async function getAsyncToken(dispatch) {
        try {
            const response = await obtainToken(username, password);
            dispatch(loginUserSuccess(response.data.access));
        } catch (error) {
            console.log(`Error obtaining token ${error}`);
        }
    };
}

export function logoutUserSuccess() {
    return { type: LOGOUT_USER };
}

export function logoutUser() {
    return async function asyncLogOut(dispatch) {
        await logout();
        dispatch(logoutUserSuccess());
    };
}