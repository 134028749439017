import React from 'react';
import SolvedTicketTable from "./SolvedTicketTable";

const SolvedTickets = props => {
    return (
        <div>
            <SolvedTicketTable title="Requerimientos Solucionados"/>
        </div>
    );
};

SolvedTickets.propTypes = {

};

export default SolvedTickets;