import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState, useEffect} from "react";
import {useSnackbar} from "notistack";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import FormControl from "@material-ui/core/FormControl";
import {FormControlLabel, Checkbox} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import Divider from "@material-ui/core/Divider";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";
import UsernameInput from "../../../components/Inputs/UsernameInput";
import 'react-lightbox-component/build/css/index.css';
import {
    getAvailableMembers,
    addNewMemberToTeam,
    deleteTeamMember
} from "../../../api/usersApi"

export default function TeamDetail({team, engineers, handleDeleteTeam, handleUpdateTeam, onClose}){

  const [teamName, setTeamName] = useState(team.name)
  const [teamResponsable, setTeamResponsable] = useState(team.responsableUsername)
  const [teamColor, setTeamColor] = useState(team.calendarColor)
  const [availableMembers, setAvailableMembers] = useState({})
  const [received, setReceived] = useState(false)

  const [teamStatus, setTeamStatus] = useState(team.isActive)

  const { enqueueSnackbar } = useSnackbar();

  const [membersToAdd, setMembersToAdd] = useState([])
  const [membersToDelete, setMembersToDelete] = useState([])

  const [memberToDelete, setMemberToDelete] = useState('')
  const [memberToAdd, setMemberToAdd] = useState('')

//   const deleteTeam = () => {
//     handleDeleteTeam({
//         id: team.id,
//         status: teamStatus
//     });
//     onClose();
//   };

  const handleChangeTeamStatus = (event) => {
    setTeamStatus(!teamStatus)
  }

  const updateTeam = () => {
    handleUpdateTeam({
        id: team.id,
        name: teamName,
        teamResponsable,
        teamColor,
        membersToDelete,
        membersToAdd,
        teamStatus
    });
    onClose();
  };

  const updateMembersToDelete = (member) => {
    const prevList = [...membersToDelete];
    setMemberToDelete(member);
    for (let i = 0; i < prevList.length; i+=1){
        if (prevList[i].id === parseInt(member,10)){
            const spliced = prevList.splice(i, 1);
            setMembersToDelete(prevList);
            return
        }
    }
    for (let i = 0; i < team.teamMembers.length; i+=1){
        if (team.teamMembers[i].id === parseInt(member,10)){
            prevList.push(team.teamMembers[i]);
            setMembersToDelete(prevList);
            return
        }
    }
  }

  const updateMembersToAdd = (member) => {
    const oldList = [...membersToAdd];
    setMemberToAdd(member);
    for (let i = 0; i < oldList.length; i+=1){
        if (oldList[i].id === parseInt(member,10)){
            const spliced = oldList.splice(i, 1);
            setMembersToAdd(oldList);
            return
        }
    }
    for (let i = 0; i < availableMembers.length; i+=1){
        if (availableMembers[i].id === parseInt(member,10)){
            oldList.push(availableMembers[i]);
            setMembersToAdd(oldList);

            return
        }
    }
  }

  const fetchMembers = () => {
    getAvailableMembers({id: team.id}).then((response) => {
        setAvailableMembers(response)
        setReceived(true)
    })
}

  useEffect(() => {
        if (!received){
            fetchMembers();
        }
    },[received])

    if (!received){
        return <></>
    }


  return <>
      <Grid container>
        <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Información de Equipo</Typography>
            </Grid>
        <Grid item xs={12}>
          <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Nombre del Equipo</Typography>
                        <Grid
                            container
                            item
                            xs={12}
                            style={{
                                justifyContent: 'flex-start'
                            }}
                        >
                            <UsernameInput
                                value={teamName}
                                onChange={(event) => {
                                    setTeamName(event.target.value);
                                }}
                                id="user-username"
                                error={false}
                                placeholder="Nombre del Equipo"
                                label=""
                                helperText=''
                            />
                        </Grid>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Ingeniero a cargo</Typography>
                        <Typography variant="body1" color="textPrimary">
                            <FormControl variant="outlined" >
                                <Select
                                    native
                                    value={teamResponsable}
                                    onChange={(event) => {
                                        setTeamResponsable(event.target.value);
                                    }}
                                    label=""
                                    inputProps={{
                                        name: 'engineer',
                                        id: 'team-engineer',
                                    }}
                                >
                                <option aria-label="None" value={teamResponsable} label={teamResponsable} selected={teamResponsable}/>
                                {
                                    engineers.map(engineer => (engineer.username !== teamResponsable) ?
                                        (<option value={engineer.username} key={engineer.username} label={engineer.username}  />) :
                                        (<></>)
                                    )
                                }
                                </Select>
                            </FormControl>
                        </Typography>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                        <Typography variant="body2" color="textSecondary">Miembros del Equipo</Typography>
                        {team.teamMembers.map(member => (
                            <Typography variant="body1" color="textPrimary">
                                - {member.name} 
                            </Typography>
                        ))}
                        
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>
                    <Typography variant="body2" color="textSecondary">Editar color del equipo</Typography>
                    <input type="color" value={teamColor} onChange={(event) => {
                        setTeamColor(event.target.value);
                    }}/>
                    </TableCell>
                </TableRow>
            </TableBody>
          </Table>
        </Grid>
        <Divider />

        <Grid
            style={{paddingTop: '2%'}}
        >
            <Typography variant="h6" color="textPrimary">Eliminar miembros del Equipo</Typography>
            <Typography variant="body2" color="textSecondary">Selecciona una opción para agregar/quitar de la lista</Typography>
        </Grid>
        <Grid
            container
            item
            xs={12}
            style={{
                justifyContent: 'flex-start',
                paddingTop: '1%',
                paddingBottom: '1%',
                paddingLeft: '1%',
            }}
        >
            <Typography variant="body1" color="textPrimary">
                <FormControl variant="outlined" >
                    <Select
                        native
                        value={memberToDelete}
                        onChange={(event) => {
                            updateMembersToDelete(event.target.value);
                        }}
                        label=""
                        inputProps={{
                            name: 'member-delete',
                            id: 'member-to-delete',
                        }}
                        style={{
                            height: '35px'
                        }}
                    >
                    <option aria-label="None" value='' label=''/>
                    {
                        team.teamMembers.map(member =>
                            (<option value={member.id} key={member.id} label={member.name}  />)
                        )
                    }
                    </Select>
                </FormControl>
                {membersToDelete.length > 0 ? <Grid
                    style={{
                        paddingTop: '3%',
                        paddingLeft: '2%'
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Miembros a eliminar : 
                    </Typography>
                    {membersToDelete.map(mem => (
                        <div key={mem.id} style={{ display: "flex"}}>
                            <span style={{color: "#666666"}}> 
                                - {mem.name}
                            </span> 
                        </div>
                    ))}
                </Grid> : <></>}
            </Typography>
        </Grid>

        <Divider />

        <Grid
            style={{paddingTop: '2%'}}
        >
            <Typography variant="h6" color="textPrimary">Agregar nuevos miembros al Equipo</Typography>
            <Typography variant="body2" color="textSecondary">Selecciona una opción para agregar/quitar de la lista</Typography>
        </Grid>
        
        <Grid
            container
            item
            xs={12}
            style={{
                justifyContent: 'flex-start',
                paddingTop: '1%',
                paddingBottom: '1%',
                paddingLeft: '1%',
            }}
        >
            <Typography variant="body1" color="textPrimary">
                <FormControl variant="outlined" >
                    <Select
                        native
                        value={memberToAdd}
                        onChange={(event) => {
                            updateMembersToAdd(event.target.value);
                            // setMemberToAdd(event.target.value);
                        }}
                        label=""
                        inputProps={{
                            name: 'member-add',
                            id: 'member-to-add',
                        }}
                        style={{
                            height: '35px'
                        }}
                    >
                    <option aria-label="None" value='' label=''/>
                    {
                        availableMembers.map(member =>
                            (<option value={member.id} key={member.id} label={member.full_name}  />)
                        )
                    }
                    </Select>
                </FormControl>
                {membersToAdd.length > 0 ? <Grid
                    style={{
                        paddingTop: '3%',
                        paddingLeft: '2%'
                    }}
                >
                    <Typography variant="body2" color="textSecondary">
                        Miembros a agregar : 
                    </Typography>
                    {membersToAdd.map(mem => (
                        <div key={mem.id} style={{ display: "flex"}}>
                            <span style={{color: "#666666"}}> 
                                - {mem.full_name}
                            </span> 
                        </div>
                    ))}
                </Grid> : <></>}
            </Typography>
        </Grid>

        <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                paddingTop: 10,
            }}
        >
            <Typography variant="body1" color="textPrimary" display="inline-block">
                Marca o desmarca la casilla siguiente para activar o desactivar el equipo.
            </Typography>
            <FormControlLabel
                key={Math.random()}
                style={{width: '100%'}}
                // style={getFormStyle()}               
                control={
                    <Checkbox 
                        key={team.id}
                        checked={teamStatus} 
                        onChange={handleChangeTeamStatus} 
                        name={`${team.id}`} 
                    />}
                label={
                    <Typography variant="body1" color="textPrimary" colSpan="42" display="inline-block">
                        Equipo Activo
                    </Typography>
                }
            />
        </Grid>

        <Grid 
            container 
            alignItems="center" 
            justifyContent="space-between"
            style={{
                paddingTop: 10,
            }}
        >
            <Grid item xs={12} md="auto">
                <SuccessButtons
                    icon={<CheckCircleTwoToneIcon />}
                    onClickHandler={() => {
                        updateTeam();
                    }}
                    text="Actualizar Cambios del Equipo"
                />
            </Grid>
        </Grid>
    
      </Grid>
      </>
}

TeamDetail.propTypes = {
    team: PropTypes.object,
    handleDeleteTeam: PropTypes.func,
    handleUpdateTeam: PropTypes.func,
    onClose: PropTypes.func,
}

TeamDetail.defaultProps = {
    team: {},
    handleDeleteTeam: null,
    handleUpdateTeam: null,
    onClose: null,
}