import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import PropTypes from 'prop-types';
import moment from "moment";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import Lightbox from 'react-lightbox-component';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import 'react-lightbox-component/build/css/index.css';
import VisitList from "../Pending/VisitList";
import baja from "../../../images/baja.png";
import moderada from "../../../images/moderada.png";
import grave from "../../../images/grave.png";
import urgente from "../../../images/urgente.png";

const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

export default function ClosedTicketDetail({ticket}){
  const [ expanded, setExpanded ] = useState('')

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

const getBody1Class = () => {
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {fontSize: '25px'}
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
    return {fontSize: '18px'}
  }
  return {}
}

const getBody2Class = () => {
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {fontSize: '23px'}
  }
  if (window.innerWidth === 980 && window.innerHeight === 441){
    return {fontSize: '16px'}
  }
  return {}
}

const getDetailsClass = () => {
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {color: "#666666", fontSize: "21px"}
  }
  return {color: "#666666", fontSize: "14px"}
}

const getClientClass = (col) => {
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      return {color: col, fontSize: "21px"}
  }
  return {color: col, fontSize: "14px"}
}

const getInfoSClass = (col) => {
  if (window.innerWidth === 980 && window.innerHeight === 1294){
      if (col) return {color: col, fontSize: "21px" , marginRight: 20}
      return {fontSize: "21px" , marginRight: 20}
  }
  if (col) return {color: col, fontSize: "13px" , marginRight: 20}
  return {fontSize: "13px" , marginRight: 20}
}

const getGravityImg = (label) => {
  if (label === 'low') {
    return <img
              src={baja}
              // className={classes.image}
              alt="Gravedad Baja"
          />
  } 
  if (label === 'moderate') {
    return <img
              src={moderada}
              // className={classes.image}
              alt="Gravedad Moderada"
          />
  } 
  if (label === 'high') {
    return <img
              src={grave}
              // className={classes.image}
              alt="Gravedad Alta"
          />
  } 
  
  return <img
            src={urgente}
            // className={classes.image}
            alt="Gravedad Urgente"
        />
  
}

  return <>
  <Grid container>
    <Grid item xs={12}>
          <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">Información de requerimiento</Typography>
        </Grid>
    <Grid item xs={12}>
      { (getClassNum() === 1) ?
      <Table>
        <TableBody>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Datos Cliente</Typography>
              <span style={getDetailsClass()}> 
                Nombre:&nbsp;  
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.clientName}
              </span>
              
              <Typography variant="body1">
              <span style={getDetailsClass()}> 
                Rut:&nbsp; 
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.clientRut}
              </span>
              </Typography>
              <Typography variant="body1">
              <span style={getDetailsClass()}> 
                Email:&nbsp;  
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.clientEmail}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span style={getDetailsClass()}> 
              Teléfono:&nbsp;  
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.clientPhone}
              </span>
              </Typography>
              {ticket.clientIsValidated ? 
                <span style={getClientClass("#3fb102")}> 
                  El Cliente está validado.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Cliente no está validado.
                </span>
              }
              {ticket.sentByOwner ? 
                <span style={getClientClass("#3fb102")}> 
                  El Requerimiento fue enviado por el propietario.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Requerimiento fue enviado por el usuario {ticket.senderUser}
                </span>
              }
            </TableCell>
            <TableCell/>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              <span style={getBody1Class()} className="textPrimary">
                {ticket.propertyCode}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span style={getDetailsClass()}> 
              Dirección:&nbsp;  
              </span> 
              <span style={getBody1Class()} className="textPrimary">
                {ticket.propertyAddress}
              </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>
          

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
            </TableCell>            
          </TableRow>

          <TableRow colSpan='100%'>
             <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                {ticket.faults.map(fault => (
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div>
                        <span style={getInfoSClass("#666666")}> 
                          Iteración:&nbsp;{fault.iteration}&nbsp;  
                        </span> 
                      </div>
                      {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          {
                          fault.solved ? (
                              <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                          ) : (
                              <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                          )
                          }
                        </div> 
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic', paddingRight: '1%'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red', paddingLeft: '1%'}}>
                            &nbsp;   (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
            </TableCell>
            {ticket.uniqueId ? 
              <TableCell>
                <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
                <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
              </TableCell> :
              <TableCell/>
            }            
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            {ticket.closedBy ? <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Cerrado por</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.closedBy}</Typography>
            </TableCell> : <TableCell/>}
            {ticket.closedDate ? 
              <TableCell>
                <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de cierre</Typography>
                <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.closedDate).format('DD/MM/YY HH:mm')}</Typography>
              </TableCell> : <TableCell/>
            }            
          </TableRow>

          {ticket.closedNonconformityReason ? 
            <TableRow colSpan='100%'>
              <TableCell colSpan='100%'> 
                <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Disconformidad</Typography>
                <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.closedNonconformityReason}</Typography>
              </TableCell> 
            </TableRow> : <></>
          }


          {ticket.wasReactivated ? 
          <TableRow colSpan='100%'>     
            <TableCell>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado : {ticket.timesReactivated}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
            </TableCell>
          </TableRow> : <></>}

          {ticket.surveyAnswered==='True' ? <TableRow colSpan='100%'>     
            <TableCell >
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Nota de la Encuesta</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketScore}</Typography>
            </TableCell>    
            <TableCell >
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario de la Encuesta</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.finalComment}</Typography>
            </TableCell>
          </TableRow> : 
          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Encuesta</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">Sin Responder</Typography>
            </TableCell>
          </TableRow>}

        </TableBody>
      </Table> : 

      <Table>
      <TableBody>
        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Datos Cliente</Typography>
            <span style={getDetailsClass()}> 
              Nombre:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientName}
            </span>
            
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Rut:&nbsp; 
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientRut}
            </span>
            </Typography>
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Email:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientEmail}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={getDetailsClass()}> 
            Teléfono:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientPhone}
            </span>
            </Typography>
            {ticket.clientIsValidated ? 
              <span style={getClientClass("#3fb102")}> 
                El Cliente está validado.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Cliente no está validado.
              </span>
            }
            {ticket.sentByOwner ? 
              <span style={getClientClass("#3fb102")}> 
                El Requerimiento fue enviado por el propietario.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Requerimiento fue enviado por el usuario {ticket.senderUser}
              </span>
            }
          </TableCell>
          <TableCell/>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
            <span className="textPrimary">
              {ticket.propertyCode}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={getDetailsClass()}> 
            Dirección:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.propertyAddress}
            </span>
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
          </TableCell>            
        </TableRow>

        <TableRow>
           <TableCell colSpan='100%'>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              {ticket.faults.map(fault => (
                  <div style={{ display: "flex", alignItems: 'center' }}>
                    <div>
                      <span style={getInfoSClass("#666666")}> 
                        Iteración:&nbsp;{fault.iteration}&nbsp;  
                      </span> 
                    </div>
                    {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName}
                        </div> 
                        {
                        fault.solved ? (
                            <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                        ) : (
                            <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                        )
                        }
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red'}}>
                            (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                  </div>
              ))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
          </TableCell>
          <TableCell/>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
          </TableCell>
          {ticket.uniqueId ? 
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
            </TableCell> :
            <TableCell/>
          }            
          <TableCell colSpan={5}>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          {ticket.closedBy ? <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Cerrado por</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.closedBy}</Typography>
          </TableCell> : <TableCell/>}
          {ticket.closedDate ? 
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de cierre</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.closedDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>
          }            
          {ticket.closedNonconformityReason ? 
            <TableCell> 
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Disconformidad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.closedNonconformityReason}</Typography>
            </TableCell> : <TableCell/>
            }
        </TableRow>

        {ticket.wasReactivated ? <TableRow>     
          <TableCell>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
          </TableCell>    
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.timesReactivated}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
          </TableCell>
        </TableRow> : <></>}

        <TableRow>     
          {ticket.surveyAnswered ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Nota de la Encuesta</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketScore}</Typography>
          </TableCell> : <TableCell/>}    
          {ticket.surveyAnswered ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario de la Encuesta</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.finalComment}</Typography>
          </TableCell> : <TableCell/>}
          {ticket.surveyAnswered ? <TableCell/> : <TableCell/>}
        </TableRow>

      </TableBody>
    </Table> }

    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Imágenes
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {(ticket.images.length > 0) ? 
            <TableCell colSpan={5}>
              <div>
                <Lightbox showImageModifiers={false}
                          images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                          }/></div>
            </TableCell> : 
            <>
              {'\n'}
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                No hay imágenes adjuntas
              </Typography>
            </>
            }
        </Grid>
      </Grid>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography style={{fontSize: (getClassNum() === 1) ? '36px' : 'default'}} variant="h6" color="textPrimary">
                Visitas
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List
              dense
              style={{
                  width: "100%"
              }}
            >
          <VisitList visits={ticket.visits} expanded={expanded} setExpanded={setExpanded} />
          </List>
        </Grid>
      </Grid>
      </Box>
    </Grid>

  </Grid>
  </>
}

ClosedTicketDetail.propTypes = {
    ticket: PropTypes.object,
};

ClosedTicketDetail.defaultProps = {
    ticket: {},
}