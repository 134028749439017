import React, {useState} from "react";
import {
    useHistory
} from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from '@material-ui/core/styles';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import DangerButtons from "../../components/Buttons/DangerButtons";
import ReportDialog from "../../components/Dialogs/ReportDialog";
import { isAuthenticated } from "../../api/authenticationApi";
import 'react-lightbox-component/build/css/index.css';

export default function RequirementHomePage(){

    const [dialogOpen, setDialogOpen] = useState(false);
    const isAuth = isAuthenticated();
    const history = useHistory();
    const userType = localStorage.getItem('userType')

    if (!isAuth && userType !== 'A' && userType !== 'EPV' && userType !== 'ING') {
        history.push('/')
    }
    
    const useStyles = makeStyles((theme) => ({
            paper: {
                width: '60%',
                marginBottom: theme.spacing(2),
            },
            container: {
                maxHeight: '85vh'
            },
    }));
    const classes = useStyles();
    const mobile = window.innerWidth < 769
    const tablet = window.innerWidth <= 980


  return <Grid
            container
            spacing={8}
            alignItems="center"
            justifyContent="center"
            mb={3}
            style={{
                paddingTop: '15%',
                justifyContent: 'center'
            }}
            className={classes.container}
        >
            <Paper className={classes.paper}>
                <Grid 
                    container
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid 
                        item 
                        xs={12} 
                        container 
                        style={{
                            marginTop: '5%',
                            justifyContent: 'center'
                        }}
                    >
                        <Typography variant="body1"  color="textPrimary">
                            Para ingresar un requerimiento al sistema, utiliza el botón a continuación.
                        </Typography>
                    </Grid>
                    <Grid 
                        item 
                        xs={12} 
                        container 
                        style={{
                            marginTop: '5%',
                            marginBottom: '5%',
                            justifyContent: 'center'
                        }}
                    >
                        <DangerButtons
                            icon={
                                <NotificationImportantIcon
                                    style={{
                                        fontSize: 28
                                    }}
                                />
                            }
                            onClickHandler={() => {
                                setDialogOpen(true)
                            }}
                            text="Enviar Requerimiento"
                            size="large"
                            Large
                        />
                    </Grid>
                    <ReportDialog
                        open={dialogOpen}
                        handleClose={() => {
                            setDialogOpen(false)
                        }}
                    />
                </Grid>
            </Paper>
        </Grid>
}

RequirementHomePage.propTypes = {

}

RequirementHomePage.defaultProps = {
}