import snakecaseKeys from "snakecase-keys";
import {Date} from "tui-calendar/src/js/common/timezone";
import axiosAPI from "./axiosApi";
import useData from "./useData";
import useDataList from "./useDataList";

export function useVisitTypes(){
    const { items: visitTypes, loading} = useDataList({url: 'visit-type/'} )
    return {visitTypes, loading}
}

export function useVisitStates(){
    const { items: visitStates, loading} = useDataList({url: 'visit-state/'} )
    return {visitStates, loading}
}

export async function visitCreate(ticketId, data){
    const response = await axiosAPI.post(`tickets/${ticketId}/visits/`, snakecaseKeys(data, {deep: true}));
    return response
}

export function useTeams(){
    const { items: teams, loading} = useDataList({url: 'team/'} )
    return {teams, loading}
}

export function useCalendars(){
    const { items: calendars, loading } = useDataList({url: 'calendar/'} );
    return {calendars, loading}
}

export function useSchedules(queryParams){
    const { items: schedules, loading, addItem, removeItem, updateItem } = useDataList({url: 'schedule/', queryParams} );
    return {
        schedules: schedules.map(s => ({...s, calendarId: s.calendarId.toString()})),
        loading,
        addSchedule: addItem,
        removeSchedule: removeItem,
        updateSchedule: updateItem
    }
}

export function useVisits(queryParams){
    const { items: visits, loading, addItem, removeItem, updateItem } = useDataList({url: 'visit/', queryParams} );
    return {
        visits,
        loading,
        addVisit: addItem,
        removeVisit: removeItem,
        updateVisit: updateItem
    }
}

export function useVisit({id}){
    const {item , loading, updateItem } = useData({id, url: 'visit/'})
    return {
        visit: item,
        loading,
        updateVisit: (visit) => {
            const visits = [...item.visits];
            const index = visits.findIndex(v => v.id === visit.id);
            visits.splice(index, 1, {...visits[index], ...visit});
            updateItem({...item, visits});
        }
    };
}

export async function createSchedule(data){
    // Creates an schedule with no visit/ticket associated
    const response = await axiosAPI.post(`schedule/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function destroySchedule(id) {
    const response = await axiosAPI.delete(`schedule/${id}/`);
    return response;
}

export async function updateSchedule(data) {
    const response = await axiosAPI.put(`schedule/${data.id}/`, snakecaseKeys(data, {deep: true}));
    return response;
}

export async function startVisit(data){
    const response = await axiosAPI.post(`visit/${data.id}/start/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function endVisitNoPeople(data){
    const response = await axiosAPI.post(`visit/${data.id}/no_residents/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function updateVisitData(data){
    const response = await axiosAPI.post(`visit/${data.id}/save_visit_data/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function uploadVisitImage(data){
    const response = await axiosAPI.post(`visit/${data.id}/upload_visit_image/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function deleteVisitImage(data){
    const response = await axiosAPI.post(`visit/${data.id}/delete_visit_image/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function finishVisit(data){
    const response = await axiosAPI.post(`visit/${data.id}/finish/`, snakecaseKeys(data, {deep: true}));
    return response
}


export function useVisitClientInfo({id}){
    const {item , loading, updateItem } = useData({id, url: 'visit/{{id}}/client'})
    return {
        client: item,
        loading,
        updateClient: updateItem
    };
}

export async function visitClientCancel(data){
    const response = await axiosAPI.post(`visit/${data.id}/cancel_client/`, snakecaseKeys(data, {deep: true}));
    return response
}

export async function visitMagalCancel(data){
    const response = await axiosAPI.post(`visit/${data.id}/cancel_magal/`, snakecaseKeys(data, {deep: true}));
    return response
}