import React, {useState, useEffect} from "react";
import {Formik} from "formik";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import {LinearProgress} from "@material-ui/core";
import BaseDialog from "./BaseDialog";
import {finishVisit, startVisit, useVisitClientInfo} from "../../../api/visitApi";
import ActiveVisitDetail from "./ActiveVisitDetail";
import NewScheduleStep from "./NewScheduleStep";
import SignatureStep from "./SignatureStep";
import FinishStep from "./FinishStep";
import ProblemsStep from "./ProblemsStep";
import ProblemsViewedStep from "./ProblemsViewedStep"

const getDialogTitle = (step) => {
  return {
    0: 'Detalles de la visita',
    1: 'Problemas atendidos',
    2: 'Problemas resueltos',
    3: 'Agendar nueva visita',
    4: 'Firma del cliente',
    5: 'Visita finalizada con éxito',
  }[step];
}

const getDialogHelptext = (step) => {
  return {
    0: "Se muestra la información básica ya registrada de la visita, junto a las imágenes ya capturadas por el equipo. Se permite completar la información faltante y capturar imágenes. Cuando se termine de atender presionar 'Terminar visita'",
    1: 'Se solicita especificar los problemas que fueron atendidos durante la visita.',
    2: 'Se solicita especificar los problemas que fueron resueltos durante la visita.',
    3: 'Se agenda una cita la cual será registrada al finalizar el proceso.',
    4: 'Se exige la firma del cliente antes de cerrar la visita para dar a conocer su conformidad sobre el trabajo realizado.',
    5: 'Finalización de una visita',
  }[step];
}

const getPrimaryButtonText = step => {
  return {
    0: 'Terminar visita',
    1: 'Continuar proceso',
    2: 'Continuar proceso',
    3: 'Continuar proceso',
    4: 'Finalizar visita',
    5: 'Salir',
  }[step];
}

const getFailButtonText = step => {
  return {
    0: '',
    1: '',
    2: 'Continuar proceso y agendar nueva cita',
    3: '',
    4: '',
    5: '',
  }[step];
}

const getFailButtonIcon = step => {
  return {
    0: null,
    1: null,
    2: <MeetingRoomIcon fontSize='large' />,
    3: null,
    4: null,
    5: null,
  }[step];
}


const getSecondaryButtonText = step => {
  return {
    0: 'Cerrar',
    1: 'Volver',
    2: 'Volver',
    3: 'Volver',
    4: 'Volver',
    5: '',
  }[step];
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export default function ManageVisitForm({visit, open, onClose, onCloseVisit}){
  const { enqueueSnackbar } = useSnackbar();
  const [ step, setStep ] = useState(0);
  const { client, loading: loadingClient } = useVisitClientInfo({id: visit?.id});

  const filterFaults = () => {
    const faultList = [];
    if (visit?.faults){
      for (let i=0; i < visit.faults.length; i+=1){
        if (!visit.faults[i].solved){
          faultList.push(visit.faults[i])
        }
      }
    } 
    return faultList;
  }

  return <Formik
    enableReinitialize
    initialValues={{
      visitType: visit?.visitType || '',

      schedule: null,
      newSchedule: false,
      images: visit?.images || [],
      sign: [],
      faults: filterFaults(),
      senderPhone : null,
      senderEmail : null,
      client: client || {},

      // scheduling
      scheduleRadio: '',
  }}
  onSubmit={async (values, {setSubmitting, setValues}) => {
    if ([0, 1, 2, 3].includes(step)) {
      setStep(step + 1);
    } else if (step === 4) {
      try {
         const response = await finishVisit({
          id: visit.id,
          // visitType: values.visitType,
          faults: values.faults,
          schedule: values.schedule ? {
            ...values.schedule,
            calendar: values.schedule.calendarId
          } : null,
          images: [...values.images].map(img => ({image: img.src, message: img.message})),
          clientSignature: values.sign,
        });
        setSubmitting(false);
        // setValues({...values, arrived: false, images: []})
        enqueueSnackbar(
          '¡Se ha finalizado la visita!',
          {
            variant: 'success'
          }
        )
        setStep(step + 1);
        onCloseVisit();
        setTimeout(() => {
          setStep(0);
          onClose(false);
          window.location.reload();
        }, 3000);
        
      } catch (e) {
        console.log(e);
      }
    }else{
        onClose(false);
        setStep(0);
        onCloseVisit();
        window.location.reload();
    }

  }}
  >
    {(formik) => {
      const { values, setFieldValue, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting } = formik;

      const getActions = _step => {
        if (_step === 0){
          return null;
        }
        return <></>;
      };
      return <BaseDialog
        disableDraggable
        open={open}
        handleClose={() => {
          if (step === 0) {
            onClose(false);
            window.location.reload();
          }else{
            setStep(step - 1);
          }
        }}
        handleSuccess={() => {
          if (step===2){
            setFieldValue('newSchedule', false);
            setFieldValue('schedule', null);
          }
          handleSubmit()
        }}
        handleFailed={() => {
          setFieldValue('newSchedule', true);
          handleSubmit();
        }}
        failedButtonDisabled={step!==2||!values.faults.some(p => !p.solved)}
        failedButtonText={getFailButtonText(step)}
        failedButtonIcon={getFailButtonIcon(step)}
        title={getDialogTitle(step)}
        successButtonText={getPrimaryButtonText(step)}
        cancelButtonText={getSecondaryButtonText(step)}
        successButtonIcon={<MeetingRoomIcon fontSize='large' />}
        size="lg"
        successButtonDisabled={isSubmitting ||
        (step === 0 && !values.visitType || values.images.length < 1) ||
        (step === 3 && (values.newSchedule && !values.schedule) ) ||
        (step === 4 && !values.sign) ||
        (step === 5)}
        tooltipText={getDialogHelptext(step)}
        actions={() => getActions(step)}
        hideCloseButton={step === 5}
      >
        {!visit && <LinearProgress />}
        {step === 0 && visit && <ActiveVisitDetail visit={visit} formik={formik} />}
        {step === 1 && visit && <ProblemsViewedStep visit={visit} formik={formik} />}
        {step === 2 && visit && <ProblemsStep visit={visit} formik={formik} />}
        {step === 3 && visit && <NewScheduleStep visit={visit} formik={formik} />}
        {step === 4 && <SignatureStep formik={formik} />}
        {step === 5 && <FinishStep formik={formik} />}
      </BaseDialog>
    }}
  </Formik>
}
