import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import 'react-lightbox-component/build/css/index.css';

export default function FinishStep(){

  const getClassDict = () => {
    if (window.innerWidth < 769) {
        return {
            paddingLeft: '1%',
            fontSize: '21px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {
            paddingLeft: '1%',
            fontSize: '23px',
        }
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return {
            paddingLeft: '1%',
            fontSize: '20px',
        }
    }
    return {}
}

  return <>
  <Grid container>
    <Grid item xs={12}>
      <Typography style={getClassDict()} gutterBottom variant="h5">Gracias por su colaboración.</Typography>
      <Typography style={getClassDict()} gutterBottom variant="body1">Una encuesta de satisfacción esta siendo enviada a su correo electrónico. Este cuadro se cerrará automaticamente.</Typography>
    </Grid>
  </Grid>
  </>
}
