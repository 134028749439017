import { makeStyles } from "@material-ui/core/styles"

const useStylesButtons = makeStyles(() => ({
    general: {
        textTransform: "none",
    },
    success: {
        color: '#5cb85c',
        backgroundColor: '#ffffff',
        border: '1px solid #000000',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#5cb85c',
        }
    },
    danger: {
        color: '#d9534f',
        backgroundColor: '#ffffff',
        border: '1px solid #000000',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#d9534f',
        }
    },
    warning: {
        color: '#c5850d',
        backgroundColor: '#ffffff',
        border: '1px solid #000000',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#c5850d',
        }
    },
    secondary: {
        color: '#868e96',
        backgroundColor: '#ffffff',
        border: '1px solid #000000',
        '&:hover': {
            color: '#ffffff',
            backgroundColor: '#868e96',
        }
    },
    extraLarge: {
        fontSize: '1.5rem'
    }
}))

export default  useStylesButtons