import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import {connect} from "react-redux";
import SuccessButtons from "../../Buttons/SuccessButtons";
import TextAreaInput from "../../Inputs/TextAreaInput";
import {
    setReportComment,
    setReportFaults,
    setReportImages,
    setResportFaultNumber
} from "../../../actions/reportActions";

function previewImage(image, id) {
    const reader = new FileReader();

    reader.onload = function() {
        document.getElementById(id).src = reader.result
    }

    reader.readAsDataURL(image); // convert to base64 string
}

class ReportStepFour extends Component {
    constructor(props) {
        super(props);

        this.state = {
            images: props.images,
            comment: props.comment,
            faults: props.faults
        }
    }

    componentDidMount() {
        const {
            successDisabledControl,
            images,
            comment
        } = this.props

        if (images.length > 0 || comment !== "") {
            successDisabledControl(false)
        }
    }

    render() {
        const {
            successDisabledControl,
            controlStep,
            faultNumber,
            setReportFaults, // eslint-disable-line no-shadow
            setResportFaultNumber, // eslint-disable-line no-shadow
            setReportImages, // eslint-disable-line no-shadow
            setReportComment // eslint-disable-line no-shadow
        } = this.props

        const  {
            images,
            comment,
            faults
        } = this.state

        return (
            <Grid
                container
                spacing={3}
            >
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        borderBottom: '1px solid black'
                    }}
                >
                    Estos son los problemas que quieres reportar
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <List
                        dense
                        style={{
                            width: "100%"
                        }}
                    >
                        {
                            faults.map((fault) => {
                                return (
                                    <ListItem key={fault.number}>
                                        <ListItemAvatar
                                            style={{
                                                marginTop: 5
                                            }}
                                        >
                                            <ArrowForwardIosIcon />
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={fault.name || ''}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => {
                                                    const newFaults = faults.filter((option) => {
                                                        return option.number !== fault.number
                                                    })

                                                    setReportFaults(newFaults)
                                                    setResportFaultNumber(faultNumber - 1)

                                                    if (newFaults.length === 0) {
                                                        this.setState({faults: newFaults})
                                                        successDisabledControl(true)
                                                        controlStep(3)
                                                    } else {
                                                        this.setState({faults: newFaults})
                                                        successDisabledControl(true)
                                                    }
                                                }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                </Grid>
                <Typography
                    style={{
                        marginBottom: '2%',
                        marginRight: 'auto',
                        marginLeft: 'auto',
                        textDecoration: 'underline',
                        textAlign: 'center'
                    }}
                >
                    Agrega un comentario describiendo tu problema, tambien recomendamos añadir imagenes para clarificar
                </Typography>
                <Grid
                    container
                    item
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <label htmlFor="contained-button-file">
                        <SuccessButtons
                            icon={<PhotoCamera />}
                            onClickHandler={() => document.getElementById('contained-button-file').click()}
                            text="Cargar imagen"
                            size="medium"
                        />
                        <input
                            style={{
                                display: 'none'
                            }}
                            accept="image/*"
                            id="contained-button-file"
                            multiple
                            type="file"
                            onChange={(event) => {
                                images.push(...event.target.files)
                                setReportImages(images)

                                this.setState({
                                    images
                                }, () => successDisabledControl(false))
                            }}
                        />
                    </label>
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                >
                    <TextAreaInput
                        onChange={(event) => {
                            setReportComment(event.target.value)
                            this.setState({
                                comment: event.target.value
                            }, () => successDisabledControl(false))
                        }}
                        id="comment-textarea"
                        value={comment}
                        placeholder="Ayudenos a compreder mejor su problema"
                        label="Descripción del problema"
                        name="Comment"
                        helperText="Esta explicación ayudara a nuestro equipo tecnico a buscar una solución a sus problemas de forma mas eficiente"
                    />
                </Grid>
                <Grid
                    container
                    item
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    { images.map((image, index) => (
                        <Grid
                            container
                            item
                            xs={6}
                            key={image.name}
                        >
                            <Card>
                                <CardActionArea
                                    style={{
                                        cursor: 'default'
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        alt={image.name}
                                        height="140"
                                        image=""
                                        id={`preview_image_${index}`}
                                        title={image.name}
                                    />
                                    <CardContent>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                            component="p"
                                            style={{
                                                textAlign: 'center'
                                            }}
                                        >
                                            { image.name }
                                            { previewImage(image, `preview_image_${index}`) }
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    )) }
                </Grid>
            </Grid>
        );
    }
}

ReportStepFour.propTypes = {
    successDisabledControl: PropTypes.func.isRequired,
    controlStep: PropTypes.func.isRequired,
    faults: PropTypes.arrayOf(
        PropTypes.objectOf(
            PropTypes.oneOf(
                [PropTypes.string, PropTypes.number]
            )
        )
    ).isRequired
};

const mapStateToProps = (state) => ({
    faults: state.report.partThree.faults,
    faultNumber: state.report.partThree.faultNumber,
    images: state.report.partFour.images,
    comment: state.report.partFour.comment
})

const mapDispatchToProps = {
    setReportFaults,
    setReportImages,
    setResportFaultNumber,
    setReportComment,
}

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ReportStepFour);