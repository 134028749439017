import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";
import {LinearProgress} from "@material-ui/core";
import TicketListItem from "./TicketListItem";
import {useTicket} from "../../api/ticketApi";
import TicketDetail from "./TicketDetail";
import BaseDialog from "../Tickets/Utils/BaseDialog";

const useStyles = makeStyles(theme => ({
    list: {
        maxHeight: 600,
        overflowY: 'auto'
    },
    titleTablet: {
        fontSize: '28px',
    },
    textTablet: {
        fontSize: '22px',
        paddingBottom: '2%',
    }
}))
export default function TicketList({tickets, calendar, scheduleOnClick,
                                   selectedTicketId,
                                   setSelectedTicketId,
                                   creationTicket,
                                   onOpenPopupForTicket,
                                   ticket,
                                   recalendarizeOnly,
  overdue
                                   }){
    const classes = useStyles();
    const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;

    const handleSchedule = (_ticket) => {
        if (scheduleOnClick){
            onOpenPopupForTicket(_ticket);
            setSelectedTicketId(_ticket.id);

            if (calendar!=null && calendar.current!=null){
                calendar.current.calendarInst.openCreationPopup({
                });
                document.querySelector('#tui-full-calendar-schedule-title').value = (_ticket.propertyName);
                document.querySelector('#tui-full-calendar-schedule-location').value = (_ticket.propertyAddress);
            }
        }
    }
    const getDescription = () => {
      if (recalendarizeOnly) return 'Requerimientos visitados que requieren recalendarizar'
      if (overdue) return 'Requerimientos con visita atrasada que requieren recalendarizar'
      return 'Requerimientos sin visita agendada'
    }
  const getTitle = () => {
    if (recalendarizeOnly) return 'Requerimientos activos'
    if (overdue) return 'Requerimientos activos'
    return 'Requerimientos Pendientes'
  }
    return <>
        <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de Requerimiento"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información reportada, junto con su numero de visitas y posibilidad de ver el listado y situación de las mismas"
        >
            {!ticket && <LinearProgress />}
            {ticket && <TicketDetail ticket={ticket} />}
        </BaseDialog>
        <Typography className={isTablet ? classes.titleTablet : null} variant="h5">{getTitle()}</Typography>
      <Typography className={isTablet ? classes.textTablet : null} variant="body2" color="textSecondary">{getDescription()}</Typography>
        <List className={classes.list}>
            {tickets.map(t => <TicketListItem 
                                key={t.id} 
                                ticket={t} 
                                ticketId={creationTicket}
                                onClick={setSelectedTicketId} 
                                onSchedule={handleSchedule} 
                                />)}
        </List>
        {!tickets.length && <Typography className={isTablet ? classes.textTablet : null}><i>No hay Requerimientos.</i></Typography>}
        </>;
}