import { combineReducers } from "redux";
import {
    REPORT_CLIENT,
    REPORT_CLIENTS, REPORT_COMMENT, REPORT_FAULTNUMBER, REPORT_FAULTS, REPORT_IMAGES,
    REPORT_PROJECT,
    REPORT_PROJECTS,
    REPORT_PROPERTIES,
    REPORT_PROPERTY,
    REPORT_PROPERTY_TYPE,
    REPORT_PROPERTY_TYPES,
    REPORT_RELATION,
    REPORT_RELATIONS,
    REPORT_STAGE,
    REPORT_STAGES
} from "../actions/constants";

function projects(state = [], action) {
    if (action.type === REPORT_PROJECTS){
        return action.projects
    }

    return state
}

function project(state = '', action) {
    if (action.type === REPORT_PROJECT){
        return action.project
    }

    return state
}

function stages(state = [], action) {
    if (action.type === REPORT_STAGES){
        return action.stages
    }

    return state
}

function stage(state = '', action) {
    if (action.type === REPORT_STAGE){
        return action.stage
    }

    return state
}

function propertyTypes(state = [], action) {
    if (action.type === REPORT_PROPERTY_TYPES){
        return action.propertyTypes
    }

    return state
}

function propertyType(state = '', action) {
    if (action.type === REPORT_PROPERTY_TYPE){
        return action.propertyType
    }

    return state
}

function properties(state = [], action) {
    if (action.type === REPORT_PROPERTIES){
        return action.properties
    }

    return state
}

function property(state = '', action) {
    if (action.type === REPORT_PROPERTY){
        return action.property
    }

    return state
}

const partOne = combineReducers({
    projects,
    project,
    stages,
    stage,
    propertyTypes,
    propertyType,
    properties,
    property
})

function relations (state = [], action) {
    if (action.type === REPORT_RELATIONS) {
        return action.relations
    }

    return state
}

function relation (state = "", action) {
    if (action.type === REPORT_RELATION) {
        return action.relation
    }

    return state
}

function clients (state = [], action) {
    if (action.type === REPORT_CLIENTS) {
        return action.clients
    }

    return state
}

function client (state = "", action) {
    if (action.type === REPORT_CLIENT) {
        return action.client
    }

    return state
}

const partTwo = combineReducers({
    relations,
    relation,
    clients,
    client
})

function faults (state = [], action) {
    if (action.type === REPORT_FAULTS) {
        return action.faults
    }

    return state
}

function faultNumber (state = 0, action) {
    if (action.type === REPORT_FAULTNUMBER) {
        return action.faultNumber
    }

    return state
}

const partThree = combineReducers({
    faults,
    faultNumber
})

function images (state = [], action) {
    if (action.type === REPORT_IMAGES) {
        return action.images
    }

    return state
}

function comment (state = "", action) {
    if (action.type === REPORT_COMMENT) {
        return action.comment
    }

    return state
}

const partFour = combineReducers({
    images,
    comment
})

const report = combineReducers({
    partOne,
    partTwo,
    partThree,
    partFour
})

export default report