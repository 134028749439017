import React from 'react';
import PropTypes from 'prop-types';
import BaseInput from "./BaseInput";

const TextAreaInput = (props) => {
    const {
        onChange,
        id,
        value,
        placeholder,
        label,
        name,
        helperText,
        rows
    } = props

    return (
        <BaseInput
            onChange={onChange}
            id={id}
            value={value}
            placeholder={placeholder}
            label={label}
            name={name}
            type="text"
            multiline
            helperText={helperText}
            rowsMax={100}
            rows={rows}
        />
    );
};

TextAreaInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    helperText: PropTypes.string.isRequired,
    rows: PropTypes.number,
};

TextAreaInput.defaultProps = {
    rows: 2,
}

export default TextAreaInput;