import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Box from "@material-ui/core/Box";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import List from "@material-ui/core/List";
import EventIcon from "@material-ui/icons/Event";
import Lightbox from 'react-lightbox-component';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../../../components/Buttons/SuccessButtons";
import DangerButtons from "../../../components/Buttons/DangerButtons";
import 'react-lightbox-component/build/css/index.css';
import VisitList from "../Pending/VisitList";


const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

export default function TicketsHistoryDetail({ticket}){
  const [ expanded, setExpanded ] = useState('')


  return <>
  <Grid container>
    <Grid item xs={12}>
          <Typography variant="h6" color="textPrimary">Detalle del Requerimiento del Historial</Typography>
        </Grid>
    <Grid item xs={12}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Datos Cliente</Typography>
              <span style={{color: "#666666", fontSize: "14px"}}> 
                Nombre:&nbsp;  
              </span> 
              <span className="textPrimary">
                {ticket.clientName}
              </span>
              
              <Typography variant="body1">
              <span style={{color: "#666666", fontSize: "14px"}}> 
                Rut:&nbsp; 
              </span> 
              <span className="textPrimary">
                {ticket.clientRut}
              </span>
              </Typography>
              <Typography variant="body1">
              <span style={{color: "#666666", fontSize: "14px"}}> 
                Email:&nbsp;  
              </span> 
              <span className="textPrimary">
                {ticket.clientEmail}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span style={{color: "#666666", fontSize: "14px"}}> 
              Teléfono:&nbsp;  
              </span> 
              <span className="textPrimary">
                {ticket.clientPhone}
              </span>
              </Typography>
              {ticket.clientIsValidated ? 
                <span style={{color: "#3fb102", fontSize: "14px"}}> 
                  El Cliente está validado.
                </span> : 
                <span style={{color: "#fc0303", fontSize: "14px"}}> 
                  El Cliente no está validado.
                </span>
              }
              {ticket.sentByOwner ? 
                <span style={{color: "#3fb102", fontSize: "14px"}}> 
                  El Requerimiento fue enviado por el propietario.
                </span> : 
                <span style={{color: "#fc0303", fontSize: "14px"}}> 
                  El Requerimiento fue enviado por el usuario {ticket.senderUser}
                </span>
              }
            </TableCell>
            <TableCell/>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
              <Typography variant="body1" color="textPrimary">
              <span className="textPrimary">
                {ticket.propertyCode}
              </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
              <span style={{color: "#666666", fontSize: "14px"}}> 
              Dirección:&nbsp;  
              </span> 
              <span className="textPrimary">
                {ticket.propertyAddress}
              </span>
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Fecha de recepción</Typography>
              <Typography variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Estado</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Gravedad</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.gravity ? gravityLabels[ticket.gravity] : <>Sin asignar</>}</Typography>
            </TableCell>           
          </TableRow>
          <TableRow>
             <TableCell>
              <Typography variant="body2" color="textSecondary">Problemas</Typography>
              <Typography variant="body1" color="textPrimary">
                {ticket.faults.map(fault => (
                    <div style={{ display: "flex", alignItems: 'center' }}>
                      <div>
                        <span style={{color: "#666666", fontSize: "13px" , marginRight: 20}}> 
                          Iteración:&nbsp;{fault.iteration}&nbsp;  
                        </span> 
                      </div>
                      <div style={{ fontSize: "13px", marginRight: 20 }}>
                        • {fault.faultZoneName} 
                        - {fault.faultTargetName} 
                        - {fault.faultTypeName}
                      </div> 
                      {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon style={{ color: red[500] }} />
                      )
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Responsable</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body2" color="textSecondary">Número de Requerimiento</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.iteration}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography variant="body2" color="textSecondary">N° Días abierto</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
            </TableCell>
            {ticket.uniqueId ? 
              <TableCell>
                <Typography variant="body2" color="textSecondary">ID</Typography>
                <Typography variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
              </TableCell> :
              <TableCell/>
            }            
            <TableCell colSpan={5}>
              <Typography variant="body2" color="textSecondary">Explicación del cliente</Typography>
              <Typography variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Grid>
    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography variant="h6" color="textPrimary">Imágenes</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {(ticket.images.length > 0) ? 
            <TableCell colSpan={5}>
              <div>
                <Lightbox showImageModifiers={false}
                          images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                          }/></div>
            </TableCell> : 
            <>
              {'\n'}
              <Typography variant="body1" color="textPrimary">
                No hay imágenes adjuntas
              </Typography>
            </>
            }
        </Grid>
      </Grid>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              <Typography variant="h6" color="textPrimary">Visitas</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List
              dense
              style={{
                  width: "100%"
              }}
            >
          <VisitList visits={ticket.visits} expanded={expanded} setExpanded={setExpanded} />
          </List>
        </Grid>
      </Grid>
      </Box>
    </Grid>

  </Grid>
  </>
}
