import React, { Component, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import UsernameInput from "../Inputs/UsernameInput";
import SelectAutocompleteInput from "../Inputs/SelectAutocompleteInput";
import SuccessButtons from "../Buttons/SuccessButtons";
// import {
//     isValidUsername,
//     usernameMinimalConditions
// } from "../../utils/loginValidations";
import BaseInput from "../Inputs/BaseInput";
import FullNameInput from "../Inputs/FullNameInput";
import EmailInput from "../Inputs/EmailInput";
import PhoneInput from "../Inputs/PhoneInput";

// const RegisterFormClient = ({communes, successDisabledControl, proceedWithRegister}) => {

//     const [username, setUsername] = useState('')
//     const [usernameError, setUsernameError] = useState(false)
//     const [usernameHelperText, setUsernameHelperText] = useState('')
//     const [userEmailError, setUserEmailError] = useState(false)
//     const [userEmailHelpText, setUserEmailHelpText] = useState('')
//     const [userEmail, setUserEmail] = useState('')
//     const [fullName, setFullName] = useState('')
//     const [userPhone, setUserPhone] = useState('+569 ')
//     const [propertyStreet, setPropertyStreet] = useState('')
//     const [propertyCode, setPropertyCode] = useState('')
//     const [propertyTorre, setPropertyTorre] = useState('I')
//     const [propertyComuna, setPropertyComuna] = useState(null)

//     const [registerAvailable, setRegisterAvailable] = useState(false)

//     const updateDisable = () => {
//         // console.log(username)
//         if (username !== '' && 
//         userEmail !== '' && !usernameError && !userEmailError &&
//         fullName !== '' && userPhone !== '' &&
//         propertyStreet !== '' && propertyCode !== '' &&
//         propertyTorre !== '' && propertyComuna !== null) {
//             successDisabledControl(false)
//             registerAvailable(true)
//         } else{
//             successDisabledControl(true)
//             registerAvailable(false)
//         }
//     }

//     const setNewPropertyComuna = (value) => {
//         setPropertyComuna(value)
//         updateDisable()
//     }

//     const sendData = () => {
//         proceedWithRegister({
//             username, 
//             userEmail,
//             fullName,
//             userPhone,
//             propertyStreet,
//             propertyCode,
//             propertyTorre,
//             propertyComuna
//         })
//     }

//     return <>
//         <Grid
//             container
//             spacing={3}
//         >
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <UsernameInput
//                     value={username}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         const re = /^[K0-9\b]+$/;
//                         if (value === '' || re.test(value)) {
//                             setUsername(value);
//                             setUsernameError(false)
//                             setUsernameHelperText('')
//                         }
                        
//                          const bodyRut = value.slice(0, -1);
//                          let dv = value.slice(-1).toUpperCase();
//                          if (bodyRut.length < 7) {
//                             setUsername(value);
//                             setUsernameError(true)
//                             setUsernameHelperText('El rut ingresado es muy corto. Debe tener al menos 8 dígitos.')

//                             updateDisable();
//                          } else {
//                             setUsername(value);
//                             setUsernameError(false)
//                             setUsernameHelperText('')
//                             updateDisable();

//                             let suma = 0;
//                             let multiplo = 2;

//                             for (let i = 1; i <= bodyRut.length; i+=1) {
//                                // Obtener su Producto con el Multiplo Correspondiente
//                                const index = multiplo * value.charAt(bodyRut.length - i);
                           
//                                // Sumar al Contador General
//                                suma += index;
                           
//                                // Consolidar Múltiplo dentro del rango [2,7]
//                                if (multiplo < 7) {
//                                  multiplo += 1;
//                                } else {
//                                  multiplo = 2;
//                                }
//                              }

//                              // Calcular Dígito Verificador en base al Módulo 11
//                             const dvEsperado = 11 - (suma % 11);

//                             // Casos Especiales (0 y K)
//                             dv = dv === "K" ? 10 : dv;
//                             dv = dv === 0 ? 11 : dv;

//                             // Validar que el Cuerpo coincide con su Dígito Verificador
//                             if (dvEsperado.toString() !== dv.toString()) {
//                                 setUsername(value);
//                                 setUsernameError(true)
//                                 setUsernameHelperText('El rut ingresado es inválido.')

//                                 updateDisable()
//                             } else {
//                                 setUsername(value);
//                                 setUsernameError(false)
//                                 setUsernameHelperText('')
//                                 updateDisable();
//                             }
//                          }

//                         // updateDisable();

//                     }}
//                     id='username-input'
//                     name='userRut'
//                     label="Rut"
//                     placeholder="Ingresa tu rut sin puntos ni guión"
//                     error={usernameError}
//                     helperText={usernameHelperText}
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <FullNameInput
//                     value={fullName}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         setFullName(value)
//                         updateDisable();
//                     }}
//                     id='user-fullname'
//                     name='userFullName'
//                     label="Nombre Completo"
//                     placeholder="Nombres y Apellidos"
//                     error={false}
//                     helperText=''
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <EmailInput
//                     value={userEmail}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         // eslint-disable-next-line
//                         const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
//                         if (!mailRegex.test(value)) {
//                             setUserEmail(value)
//                             setUserEmailError(true)
//                             setUserEmailHelpText('Ingresa un email válido')
//                         } else{
//                             setUserEmail(value)
//                             setUserEmailError(false)
//                             setUserEmailHelpText('')
//                         }
//                         updateDisable();
//                     }}
//                     id='user-email'
//                     name='userEmail'
//                     label="Email"
//                     placeholder="Ingresa tu correo electrónico"
//                     error={userEmailError}
//                     helperText={userEmailHelpText}
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <PhoneInput
//                     value={userPhone}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         const re = /^(\+?56)?(\s?)(0?9)[\s][9876543210]*$/;
//                         if (re.test(value)) {
//                             setUserPhone(value)
//                         }
//                         updateDisable();
//                     }}
//                     id='user-phone'
//                     name='userPhone'
//                     label="Teléfono"
//                     placeholder="Ingresa tu teléfono"
//                     error={false}
//                     helperText=''
//                 />
//             </Grid>
            
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <Typography variant="body1">
//                     Ingresa los datos de tu propiedad. Si posees más de una propiedad, ingresa los datos de una de ellas.
//                 </Typography>
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <BaseInput
//                     value={propertyStreet}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         setPropertyStreet(value)
//                         updateDisable();
//                     }}
//                     id='property-street'
//                     label="Dirección"
//                     name='propertyStreet'
//                     type="text"
//                     placeholder="Ejemplo : Las Hualtatas 5099"
//                     startAdornment={<HomeIcon />}
//                     error={false}
//                     helperText=''
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <BaseInput
//                     value={propertyCode}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         setPropertyCode(value)
//                         updateDisable();
//                     }}
//                     id='property-code'
//                     label="Número Depto"
//                     name='propertyCode'
//                     type="text"
//                     placeholder="Ingresa el número de tu departamento"
//                     error={false}
//                     startAdornment={<HomeIcon />}
//                     helperText=''
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <BaseInput
//                     value={propertyTorre}
//                     onChange={(event) => {
//                         const { value } = event.target
//                         setPropertyTorre(value)
//                         updateDisable();
//                     }}
//                     id='property-torre'
//                     label="Torre"
//                     name='propertyTorre'
//                     type="text"
//                     placeholder="Torre"
//                     startAdornment={<HomeIcon />}
//                     error={false}
//                     helperText=''
//                 />
//             </Grid>
//             <Grid
//                 item
//                 container
//                 xs={12}
//             >
//                 <SelectAutocompleteInput
//                     value={propertyComuna}
//                     options={communes}
//                     disabled={false}
//                     multiple={false}
//                     handleChange={(event, newValue) => {
//                         setNewPropertyComuna(newValue)
//                     }}
//                     renderOptionFunction={(option) => {
//                         return option.Name
//                     }}
//                     optionLabelFunction={(option) => {
//                         return option.Name
//                     }}
//                     disableCloseOnSelect={false}
//                     inputLabel="Comuna"
//                 />
//             </Grid>
//         </Grid>
//         <Grid item xs={12} md="auto" style={{paddingTop: '2%'}}>
//             <SuccessButtons
//                 icon={<ExitToAppIcon />}
//                 disabled={registerAvailable}
//                 onClickHandler={() => {
//                     sendData()
//                 }}
//                 text="Registrarse"
//             />
//         </Grid>
//     </>
    
// };

// export default RegisterFormClient;

class RegisterForm extends Component {
    constructor(props) {
        super(props)

        this.state = {
            username: '',
            usernameError: false,
            usernameHelperText: '',
            userEmailError: false,
            userEmailHelpText: '',
            userEmail: '',
            fullName: '',
            userPhone: '+569 ',
            propertyStreet: '',
            propertyCode: '',
            propertyTorre: 'I',
            propertyComuna: ''
        }
    }

    componentDidUpdate(prevProps, prevState){
        const {
            username,
            usernameError,
            userEmail,
            userEmailError,
            fullName,
            userPhone,
            propertyStreet,
            propertyCode,
            propertyTorre,
            propertyComuna
        } = this.state
        if (propertyComuna !== prevState.propertyComuna){
            const { successDisabledControl } = this.props // eslint-disable-line no-shadow
            if (username !== '' && 
                userEmail !== '' && !usernameError && !userEmailError &&
                fullName !== '' && userPhone !== '' &&
                propertyStreet !== '' && propertyCode !== '' &&
                propertyTorre !== '' && propertyComuna !== '') {
                successDisabledControl(false)
            } else{
                successDisabledControl(true)
            }
        }
    }

    render() {
        const {
            successDisabledControl,
            communes
        } =this.props

        const {
            username,
            usernameError,
            usernameHelperText,
            userEmail,
            userEmailError,
            userEmailHelpText,
            fullName,
            userPhone,
            propertyStreet,
            propertyCode,
            propertyTorre,
            propertyComuna
        } = this.state

        const updateDisable = () => {
            // console.log(username)
            if (username !== '' && 
            userEmail !== '' && !usernameError && !userEmailError &&
            fullName !== '' && userPhone !== '' &&
            propertyStreet !== '' && propertyCode !== '' &&
            propertyTorre !== '' && propertyComuna !== '') {
                successDisabledControl(false)
            } else{
                successDisabledControl(true)
            }
        }

        return (
            <Grid
                container
                spacing={3}
            >
                <Grid
                    item
                    container
                    xs={12}
                >
                    <UsernameInput
                        value={username}
                        onChange={(event) => {
                            const { value } = event.target
                            const re = /^[K0-9\b]+$/;
                            if (value === '' || re.test(value)) {
                                this.setState({
                                    username: value,
                                    usernameError: false,
                                    usernameHelperText: ''
                                })
                            }
                            
                             const bodyRut = value.slice(0, -1);
                             let dv = value.slice(-1).toUpperCase();
                             if (bodyRut.length < 7) {
                                this.setState({
                                    username: value,
                                    usernameError: true,
                                    usernameHelperText: 'El rut ingresado es muy corto. Debe tener al menos 8 dígitos.'
                                }, () => {updateDisable();})
                             } else {
                                this.setState({
                                    username: value,
                                    usernameError: false,
                                    usernameHelperText: ''
                                }, () => {updateDisable();})
                                let suma = 0;
                                let multiplo = 2;
   
                                for (let i = 1; i <= bodyRut.length; i+=1) {
                                   // Obtener su Producto con el Multiplo Correspondiente
                                   const index = multiplo * value.charAt(bodyRut.length - i);
                               
                                   // Sumar al Contador General
                                   suma += index;
                               
                                   // Consolidar Múltiplo dentro del rango [2,7]
                                   if (multiplo < 7) {
                                     multiplo += 1;
                                   } else {
                                     multiplo = 2;
                                   }
                                 }
   
                                 // Calcular Dígito Verificador en base al Módulo 11
                               const dvEsperado = 11 - (suma % 11);
   
                               // Casos Especiales (0 y K)
                               dv = dv === "K" ? 10 : dv;
                               dv = dv === 0 ? 11 : dv;
   
                               // Validar que el Cuerpo coincide con su Dígito Verificador
                               if (dvEsperado.toString() !== dv.toString()) {
                                   this.setState({
                                       username: value,
                                       usernameError: true,
                                       usernameHelperText: 'El rut ingresado es inválido.'
                                   }, () => {updateDisable();})
                               } else {
                                   this.setState({
                                        username: value,
                                        usernameError: false,
                                        usernameHelperText: ''
                                   }, () => {updateDisable();})
                                //    successDisabledControl(false)
                               }
                             }

                            // updateDisable();

                        }}
                        id='username-input'
                        name='userRut'
                        label="Rut"
                        placeholder="Ingresa tu rut sin puntos ni guión"
                        error={usernameError}
                        helperText={usernameHelperText}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <FullNameInput
                        value={fullName}
                        onChange={(event) => {
                            const { value } = event.target
                            this.setState({
                                fullName: value
                            })
                            updateDisable();
                        }}
                        id='user-fullname'
                        name='userFullName'
                        label="Nombre Completo"
                        placeholder="Nombres y Apellidos"
                        error={false}
                        helperText=''
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <EmailInput
                        value={userEmail}
                        onChange={(event) => {
                            const { value } = event.target
                            // eslint-disable-next-line
                            const mailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
                            if (!mailRegex.test(value)) {
                                this.setState({
                                    userEmail: value,
                                    userEmailError: true,
                                    userEmailHelpText: 'Ingresa un email válido'
                                })
                            } else{
                                this.setState({
                                    userEmail: value,
                                    userEmailError: false,
                                    userEmailHelpText: ''
                                })
                            }
                            updateDisable();
                        }}
                        id='user-email'
                        name='userEmail'
                        label="Email"
                        placeholder="Ingresa tu correo electrónico"
                        error={userEmailError}
                        helperText={userEmailHelpText}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <PhoneInput
                        value={userPhone}
                        onChange={(event) => {
                            const { value } = event.target
                            const re = /^(\+?56)?(\s?)(0?9)[\s][9876543210]*$/;
                            if (re.test(value)) {
                                this.setState({
                                    userPhone: value
                                })
                            }
                            updateDisable();
                        }}
                        id='user-phone'
                        name='userPhone'
                        label="Teléfono"
                        placeholder="Ingresa tu teléfono"
                        error={false}
                        helperText=''
                    />
                </Grid>
                
                <Grid
                    item
                    container
                    xs={12}
                >
                    <Typography variant="body1">
                        Ingresa los datos de tu propiedad. Si posees más de una propiedad, ingresa los datos de una de ellas.
                    </Typography>
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <BaseInput
                        value={propertyStreet}
                        onChange={(event) => {
                            const { value } = event.target
                            this.setState({
                                propertyStreet: value
                            })
                            updateDisable();
                        }}
                        id='property-street'
                        label="Dirección"
                        name='propertyStreet'
                        type="text"
                        placeholder="Ejemplo : Las Hualtatas 5099"
                        startAdornment={<HomeIcon />}
                        error={false}
                        helperText=''
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <BaseInput
                        value={propertyCode}
                        onChange={(event) => {
                            const { value } = event.target
                            this.setState({
                                propertyCode: value
                            })
                            updateDisable();
                        }}
                        id='property-code'
                        label="Número Depto"
                        name='propertyCode'
                        type="text"
                        placeholder="Ingresa el número de tu departamento"
                        error={false}
                        startAdornment={<HomeIcon />}
                        helperText=''
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <BaseInput
                        value={propertyTorre}
                        onChange={(event) => {
                            const { value } = event.target
                            this.setState({
                                propertyTorre: value
                            })
                            updateDisable();
                        }}
                        id='property-torre'
                        label="Torre"
                        name='propertyTorre'
                        type="text"
                        placeholder="Torre"
                        startAdornment={<HomeIcon />}
                        error={false}
                        helperText=''
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                >
                    <SelectAutocompleteInput
                        value={propertyComuna}
                        options={communes}
                        disabled={false}
                        multiple={false}
                        handleChange={(event, newValue) => {
                            // const { value } = event.target
                            // this.setState((state, props) => {
                            //     return {propertyComuna: value}
                            // });
                            this.setState({
                                propertyComuna: newValue
                            })
                            // this.setState({
                            //     propertyComuna: value
                            // })
                            updateDisable();
                        }}
                        renderOptionFunction={(option) => {
                            return option.Name
                        }}
                        optionLabelFunction={(option) => {
                            return option.Name
                        }}
                        disableCloseOnSelect={false}
                        inputLabel="Comuna"
                    />
                    {/* <BaseInput
                        value={propertyComuna}
                        onChange={(event) => {
                            const { value } = event.target
                            this.setState({
                                propertyComuna: value
                            })
                            updateDisable();
                        }}
                        id='project-comuna'
                        label="Comuna"
                        name='propertyComuna'
                        type="text"
                        placeholder="Ingresa tu comuna"
                        startAdornment={<HomeIcon />}
                        error={false}
                        helperText=''
                    /> */}
                </Grid>
            </Grid>
        );
    }
};

export default RegisterForm;