import React, {useState} from "react";
import {Formik} from "formik";
import {useSnackbar} from "notistack";
import {useHistory} from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CloseIcon from '@material-ui/icons/Close';
import VisitStepForm1 from "./VisitStepForm1";
import BaseDialog from "../BaseDialog";
import {startVisit, endVisitNoPeople} from "../../../api/visitApi";

export default function StartVisitForm({visit, open, onClose}){
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [ step, setStep ] = useState(0);

  return <Formik
    initialValues={{
      arrived: false,
      noPeople: false,
      images: [],
  }}
  onSubmit={async (values, {setSubmitting, setValues}) => {
    if (values.noPeople && values.arrived){
      const response = await endVisitNoPeople({id: visit.id, images: values.images});
      setSubmitting(false);
      setValues({...values, arrived: false, noPeople: false, images: []})
      enqueueSnackbar(
        'Se ha finalizado la visita debido a que no hay moradores.',
        {
          variant: 'error'
        }
      )
      onClose(false)
    } else {
      const response = await startVisit({id: visit.id, images: values.images});
      setSubmitting(false);
      setValues({...values, arrived: false, noPeople: false, images: []})
      enqueueSnackbar(
        '¡Se ha iniciado la visita!',
        {
          variant: 'success'
        }
      )
      setTimeout(() => history.push(`/equipo_tecnico/en_proceso?visitId=${visit.id}`));
    }
  }}
  >
    {(formik) => {
      const { values, setFieldValue, errors, touched, handleChange, handleSubmit, handleBlur, isSubmitting } = formik;
      return <BaseDialog
        disableDraggable
        open={open}
        handleClose={() => onClose(false)}
        handleSuccess={handleSubmit}
        handleFailed={handleSubmit}
        title="Iniciar visita"
        successButtonText="Iniciar visita"
        successButtonIcon={<ArrowForwardIcon />}
        failedButtonText="No hay moradores"
        failedButtonIcon={<CloseIcon />}
        failedButtonDisabled={!values.noPeople || !values.arrived || values.images.length < 1 || isSubmitting} 
        size="lg"
        successButtonDisabled={!values.arrived || values.images.length < 1 || isSubmitting || values.noPeople}
        tooltipText="Inicia una visita añadiendo toda la información solicitada paso a paso"
      >
        {step === 0 && <VisitStepForm1 formik={formik} />}
      </BaseDialog>
    }}
  </Formik>
}
