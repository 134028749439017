import {Formik, useFormik} from "formik";
import React, {useCallback, useRef, useState} from "react";
import {useSnackbar} from "notistack";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CloseIcon from '@material-ui/icons/Close';
import camelcaseKeys from "camelcase-keys";
import Calendar from '@toast-ui/react-calendar';
import EventIcon from "@material-ui/icons/Event";
import moment from "moment";
import 'moment/src/locale/es'
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import { DateTimePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import BaseDialog from "../../../components/Dialogs/BaseDialog";
import {
  destroySchedule,
  updateSchedule,
  useCalendars,
  useSchedules,
  useVisitStates,
  useVisitTypes,
  visitCreate
} from "../../../api/visitApi";
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import SelectAutocompleteInput from "../../../components/Inputs/SelectAutocompleteInput";
import SuccessButtons from "../../Tech/Utils/SuccessButtons"
import CalendarNav from "../../../components/Calendar/CalendarNav";
import CustomCalendar from "../../../components/Calendar/CustomCalendar";

// const bgColors = [
//   '#9e5fff',
//   '#00a9ff',
//   '#80ff00',
// ]

// const borderColors = [
//   '#9e5fff',
//   '#00a9ff',
//   '#80ff00',
// ]

export default function VisitForm({ticket, handleClose, open, handleVisitAdded, handleVisitRemoved, handleVisitUpdated}){
  const { enqueueSnackbar } = useSnackbar();
  const { visitTypes, loading: loadingTypes } = useVisitTypes();
  const { visitStates, loading: loadingStates } = useVisitStates();
  const { calendars, loading: loadingCalendars } = useCalendars();
  const { schedules, loading: loadingSchedules, addSchedule, removeSchedule, updateSchedule: handleUpdateSchedule } = useSchedules({});
  const [ selectedView, setSelectedView] = useState('month');
  const [ calendar, setCalendar] = useState(null);

  const [ team, setTeam ] = useState(null)
  const [fromDate, setFromDate] = React.useState(new Date())
  const [toDate, setToDate] = React.useState(new Date())

  const calendarRef = useCallback(node => {
    if (node !== null) {
      setCalendar(node.getInstance());
    }
  }, []);

  const onSchedule = useCallback(async ({...values}) => {
    const response = await visitCreate(ticket.id, {...values});
    enqueueSnackbar(
      '¡La visita ha sido agendada!',
      {
        variant: 'success'
      }
    );
    const data = camelcaseKeys(response.data, {deep: true})
    handleVisitAdded(data.visit);
    addSchedule({...data.schedule, calendarId: data.schedule.calendarId.toString()});
    handleClose();
  }, [schedules])

  const onUpdate = useCallback(async (values) => {
    const { schedule, changes } = values;
    const response = await updateSchedule(values);
    const data = camelcaseKeys(response.data, {deep: true});
    handleUpdateSchedule(data);

    // Updating the schedule can just update the suggestedDate
    handleVisitUpdated({id: data.visitId, suggestedDate: data.start});

    enqueueSnackbar(
      '¡La visita ha sido actualizada!',
      {
        variant: 'success'
      }
    );
    calendar.updateSchedule(
      schedule.id,
      schedule.calendarId,
      changes
    );
    handleClose();

  }, [schedules]);
  const onDelete = useCallback(async (values) => {
    const schedule = schedules.find(s => s.id === values.schedule.id);
    await destroySchedule(schedule.id);
    handleVisitRemoved(schedule.visitId);
    removeSchedule(schedule.id);
    enqueueSnackbar(
      '¡La visita ha sido eliminada!',
      {
        variant: 'success'
      }
    );
    calendar.deleteSchedule(schedule.id, schedule.calendarId);
    handleClose();
  }, [schedules])

  const newFromDate = (e) => {
    if (e.diff(toDate, 'minutes') >= 0){
      const newDate = moment(e, "DD/MM/yyyy hh:mm").add(1, 'hours')
      setToDate(newDate)
    }
    setFromDate(e)
  }

  const newToDate = (e) => {
    if (e.diff(fromDate, 'minutes') <= 0){
      const newDate = moment(e, "DD/MM/yyyy hh:mm").subtract(1, 'hours')
      setFromDate(newDate)
    }
    setToDate(e)
  }

  const createSchedule = async () => {
    const response = await visitCreate(ticket.id, {
      team,
      fromDate,
      toDate
    });
    enqueueSnackbar(
      '¡La visita ha sido agendada!',
      {
        variant: 'success'
      }
    );
    const data = camelcaseKeys(response.data, {deep: true})
    handleVisitAdded(data.visit);
    addSchedule({...data.schedule, calendarId: data.schedule.calendarId.toString()});
    handleClose();
  }

  moment.locale('es');


  return <Formik initialValues={{
    ticketId: ticket.id,
    suggestedDate: '',
    visitType: '',
  }}
                 onSubmit={async (values, form) => {

                 }} >{(formik) => {
    const {handleSubmit, values, handleChange, errors, setFieldValue, touched, handleBlur, setFieldTouched, isSubmitting} = formik;
    const submitDisabled = !values.suggestedDate || !values.visitType;
    if (loadingStates || loadingTypes || loadingCalendars) return null;
    return <BaseDialog
      // hideDefaultActions
      open={open}
      handleClose={handleClose}
      handleSuccess={handleSubmit}

      failedButtonIcon={<CloseIcon />}
      // successButtonIcon={<ArrowForwardIcon />}
      successButtonDisabled={ isSubmitting || submitDisabled}
      closeButtonDisabled={ isSubmitting }
      title="Agendar visita"
      cancelButtonText="Regresar"
      // successButtonText="Continuar"
      size="lg"
      tooltipText="Llena los campos para agendar una visita para resolver uno o más problemas del requerimiento seleccionado"
    >
      <Grid container>
        <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
            <Typography variant="body2" color="textSecondary">
              Propiedad del Requerimiento
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {ticket.propertyName} - {ticket.propertyCode}
            </Typography>
        </Grid>
        <Grid item xs={4} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
          <Typography variant="body2" color="textSecondary">
            Seleccionar Equipo a realizar la visita
          </Typography>
          <SelectAutocompleteInput
              value={team}
              options={calendars}
              disabled={false}
              multiple={false}
              handleChange={(event, newValue) => {
                setTeam(newValue)
              }}
              renderOptionFunction={(option) => {
                  return option.name
              }}
              optionLabelFunction={(option) => {
                return option.name
              }}
              disableCloseOnSelect={false}
              // inputLabel="Equipo"
          />
        </Grid>
        <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
            <KeyboardDateTimePicker 
                label="Fecha y hora de inicio"
                inputVariant="outlined"
                ampm={false}
                margin="normal"
                id="from-date-picker"
                format="DD/MM/yyyy HH:mm"
                value={fromDate}
                onChange={e => newFromDate(e)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
            />
        </Grid>
        <Grid item xs={12} style={{paddingBottom: '1%', paddingLeft: '1%'}}>
            <KeyboardDateTimePicker 
                label="Fecha y hora de término"
                inputVariant="outlined"
                margin="normal"
                ampm={false}
                id="to-date-picker"
                format="DD/MM/yyyy HH:mm"
                value={toDate}
                onChange={e => newToDate(e)}
            />
        </Grid>
        <Grid item style={{paddingBottom: '1%', paddingLeft: '1%'}}>
          <SuccessButtons
            icon={<EventIcon fontSize="large" />}
            disabled={team===null}
            onClickHandler={() => {
              createSchedule()
            }}
            text="Confirmar visita"
          />
        </Grid>
      </Grid>
      {/* <CalendarNav
        calendar={calendar}
        // onSchedule={onSchedule}
        // onUpdate={onUpdate}
        // onDelete={onDelete}
        creationDefaultTitle={ticket.propertyName}
        creationDefaultLocation={ticket.propertyAddress}
      />
      <CustomCalendar
        ref={calendarRef}
        calendars={calendars}
        schedules={schedules}
        useDetailPopup
        useCreationPopup
        view={selectedView} // You can also set the `defaultView` option.
        onBeforeCreateSchedule={onSchedule}
        onBeforeUpdateSchedule={onUpdate}
        onBeforeDeleteSchedule={onDelete}
      /> */}
    </BaseDialog>
  }}
  </Formik>;
}
