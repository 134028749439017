import React, { useState, useEffect } from 'react';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import DangerButtons from "../Buttons/DangerButtons";
import useStylesCompound from "./styles";
import ClientRegisterForm from "../Forms/ClientRegisterForm";
import ClientLoginDialog from "../Dialogs/ClientLoginDialog";
import {
    getCommuneList
  } from "../../api/projectsApi";

const ReportCompound = () => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const [registerOpen, setRegisterOpen] = useState(false)
    const classes = useStylesCompound()
    const mobile = window.innerWidth < 769
    const tablet = window.innerWidth <= 980
    const [communes, setCommunes] = useState([])
    const [fetchOnce, setFetchOnce] = useState(false)

    const fetchCommune = () => {
        getCommuneList().then((response) => {
            setCommunes(response.data)
        })
      }
  
      useEffect(() => {
        fetchCommune();
        setFetchOnce(true);
      }, [fetchOnce])

    const getClassName = () => {
        if (window.innerWidth < 769) {
            return classes.reportButtonMobile
        }
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return classes.reportButtonTablet
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
            return classes.reportButtonTabletLong
        }
        return classes.reportButtonDesktop
    } 

    return (
        <div
            className={getClassName()}
        >
            <DangerButtons
                icon={
                    <NotificationImportantIcon
                        style={{
                            fontSize: 28
                        }}
                    />
                }
                onClickHandler={() => {
                    setDialogOpen(true)
                }}
                text="Reportar postventa"
                size="large"
                extraLarge
            />
            <ClientLoginDialog
                open={dialogOpen}
                registerFormOpen={() => {
                    setRegisterOpen(true)
                }}
                handleClose={() => {
                    setDialogOpen(false)
                }}
            />
            <ClientRegisterForm
                open={registerOpen}
                communes={communes}
                handleClose={() => {
                    setRegisterOpen(false)
                }}
            />
        </div>
    );
};

export default ReportCompound;