import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red, orange} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import Box from "@material-ui/core/Box";
import Radio from '@material-ui/core/Radio';
import {Checkbox, FormControlLabel, RadioGroup} from "@material-ui/core";
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import DangerButtons from "../../Tickets/Utils/Buttons/DangerButtons";
import 'react-lightbox-component/build/css/index.css';


const useStyles = makeStyles(theme => ({
  body1: {
    fontSize: '25px'
  },
  body2: {
    fontSize: '23px'
  },
  details: {
    color: "#666666", 
    fontSize: "21px"
  },
  clientOwner: {
    color: "#3fb102", 
    fontSize: "21px"
  },
  clientNotOwner: {
    color: "#fc0303", 
    fontSize: "21px"
  },
  infoSColor: {
    color: "#666666", 
    fontSize: "21px" , 
    marginRight: 20
  },
  infoS:{
    fontSize: "21px" , 
    marginRight: 20
  },
  textTablet: {
      fontSize: '22px',
  },
  titleTablet: {
    fontSize: '25px'
  }
}))

export default function PendingVisitDetail({visit, cancelVisitClient, cancelVisitMagal}){
  const [ expanded, setExpanded ] = useState('')
  const classes = useStyles();

  const [ cancelClient, setCancelClient ] = useState(false)
  const [ cancelMagal, setCancelMagal ] = useState(false)
  const [ showVisitCancel, setShowVisitCancel] = useState(false)
  const [ actionValue, setActionValue ] = useState(null)

  const userType = localStorage.getItem('userType')

  const cancelVisit = () => {
    if (actionValue==='cancelClient'){
        cancelVisitClient(visit.id);
    } else if (actionValue==='cancelMagal'){
        cancelVisitMagal(visit.id);
    }
  };

  const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;

  return <>
  <Grid container>
    <Grid 
      item 
      xs={12}
      mb={3}
      style={{
        paddingBottom: '1%',
      }}
    >
      {isTablet ? <Table>
        <TableBody>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{visit.propertyName}</Typography>
            </TableCell>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Ubicación</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Fecha calendarizada</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell>

          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan={3}>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Problemas</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">
                {!visit.faults.length && <i>No hay problemas asociados</i>}
                {visit.faults.map(fault => (
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div className={classes.infoS}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                      fault.solved ? (
                          <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                      ) : (
                          <CancelTwoToneIcon size='inherit' style={{ color: red[700] }} />
                      )
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Estado: {visit.closedDate ? <>Finalizada</> : <>Sin realizar</>}</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{visit.stateLabel}</Typography>
            </TableCell>
            {visit.delay || visit.extremeDelay ? <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{ 
                  visit.delay ? 
                  <>Atraso normal <ReportProblemIcon size='inherit' style={{ color: orange[500] }} /></> : 
                  <>Atraso extremo <ReportIcon size='inherit' style={{ color: red[700] }}/></>
              }</Typography>
            </TableCell> : <> {visit.realDate ? <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Atrasos:</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">Sin atrasos <CheckCircleTwoToneIcon style={{ color: green[500] }}/></Typography>
            </TableCell> : <TableCell/> }</>}
          </TableRow>

          <TableRow colSpan='100%'>
            {visit.realDate ? <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Fecha iniciada</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>}
            {visit.closedDate ? <TableCell>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Fecha finalizada</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{moment(visit.closedDate).format('DD-MM-YY HH:mm')}</Typography>
            </TableCell> : <TableCell/>}
          </TableRow>

          <TableRow colSpan='100%'>
            {visit.realizedByName ? <TableCell colSpan='100%'>
              <Typography className={classes.body2} variant="body2" color="textSecondary">Realizada por</Typography>
              <Typography className={classes.body1} variant="body1" color="textPrimary">{visit.realizedByName}</Typography>
            </TableCell> : <TableCell colSpan='100%'/>}
          </TableRow>
          
          <TableRow colSpan='100%'>
            {visit.clientName ? <TableCell colSpan='100%'>
                <Typography className={classes.body2} variant="body2" color="textSecondary">Cliente</Typography>
                <Typography className={classes.body1} variant="body1" color="textPrimary">{visit.clientName} </Typography>
                {visit.clientEmail && <Typography className={classes.body2} variant="body1" color="textPrimary">- {visit.clientEmail} </Typography>}
                {visit.clientPhone && <Typography className={classes.body1} variant="body1" color="textPrimary">- {visit.clientPhone}</Typography>}
              </TableCell> : <TableCell colSpan='100%'/>}
          </TableRow>

        </TableBody>
      </Table> : 

      <Table>
      <TableBody>

        <TableRow>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography variant="body1" color="textPrimary">{visit.propertyName}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Ubicación</Typography>
            <Typography variant="body1" color="textPrimary">{visit.propertyAddress}</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Fecha calendarizada</Typography>
            <Typography variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={3}>
            <Typography variant="body2" color="textSecondary">Problemas</Typography>
            <Typography variant="body1" color="textPrimary">
              {!visit.faults.length && <i>No hay problemas asociados</i>}
              {visit.faults.map(fault => (
                  <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                    <div style={{ marginRight: 20 }}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div> {
                    fault.solved ? (
                        <CheckCircleTwoToneIcon style={{ color: green[500] }} />
                    ) : (
                        <CancelTwoToneIcon style={{ color: red[700] }} />
                    )
                  }
                  </div>
              ))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography variant="body2" color="textSecondary">Estado: {visit.closedDate ? <>Finalizada</> : <>Sin realizar</>}</Typography>
            <Typography variant="body1" color="textPrimary">{visit.stateLabel}</Typography>
          </TableCell>
          {visit.delay || visit.extremeDelay ? <TableCell>
            <Typography variant="body2" color="textSecondary">Atrasos:</Typography>
            <Typography variant="body1" color="textPrimary">{ 
                visit.delay ? 
                <>Atraso normal <ReportProblemIcon style={{ color: orange[500] }} /></> : 
                <>Atraso extremo <ReportIcon style={{ color: red[700] }}/></>
            }</Typography>
          </TableCell> : <> {visit.realDate ? <TableCell>
            <Typography variant="body2" color="textSecondary">Atrasos:</Typography>
            <Typography variant="body1" color="textPrimary">Sin atrasos <CheckCircleTwoToneIcon style={{ color: green[500] }}/></Typography>
          </TableCell> : <TableCell/> }</>}
          {visit.realDate ? <TableCell>
            <Typography variant="body2" color="textSecondary">Fecha iniciada</Typography>
            <Typography variant="body1" color="textPrimary">{moment(visit.realDate).format('DD-MM-YY HH:mm')}</Typography>
          </TableCell> : <TableCell/>}
        </TableRow>
        
        <TableRow>
          {visit.clientName ? <TableCell>
              <Typography variant="body2" color="textSecondary">Cliente</Typography>
              <Typography variant="body1" color="textPrimary">{visit.clientName} </Typography>
              {visit.clientEmail && <Typography variant="body1" color="textPrimary">- {visit.clientEmail} </Typography>}
              {visit.clientPhone && <Typography variant="body1" color="textPrimary">- {visit.clientPhone}</Typography>}
            </TableCell> : <TableCell/>}
          {visit.closedDate ? <TableCell>
            <Typography variant="body2" color="textSecondary">Fecha finalizada</Typography>
            <Typography variant="body1" color="textPrimary">{moment(visit.closedDate).format('DD-MM-YY HH:mm')}</Typography>
          </TableCell> : <TableCell/>}
          {visit.realizedByName ? <TableCell>
            <Typography variant="body2" color="textSecondary">Realizada por</Typography>
            <Typography variant="body1" color="textPrimary">{visit.realizedByName}</Typography>
          </TableCell> : <TableCell/>}
        </TableRow>

      </TableBody>
      </Table>}
    </Grid>

    {(userType==='EPV'||userType==='A'||userType==='ING') ? <Grid 
        item 
        xs={12}
        spacing={5}
        mb={3}
        style={{
          paddingTop: '1%',
          paddingLeft: '1%',
          paddingRight: '1%',
        }}
      >
      <Grid item xs={12} md="auto">
        <DangerButtons
          icon={null}
          onClickHandler={() => {
            setShowVisitCancel(!showVisitCancel)
          }}
          text="Cancelar esta visita"
        />
      </Grid>
    </Grid> : <></>}
    
    {(showVisitCancel && (userType==='EPV'||userType==='A'||userType==='ING')) ? <Paper>
      <Grid 
        item 
        xs={12}
        spacing={5}
        mb={3}
        style={{
          paddingTop: '1%',
          paddingLeft: '3%',
          paddingRight: '1%',
        }}
      >
        <Box mt={3}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container alignItems="center" justifyContent="space-between">
              <Grid item xs={12} md="auto">
                <Typography className={isTablet ? classes.titleTablet : null} variant="h6" color="textPrimary">Seleccionar motivo de la cancelación</Typography>
              </Grid>
            </Grid>
          </Grid>

          <RadioGroup 
            column 
            name="actionRadio" 
            value={actionValue} 
            onChange={(event) => {
              setActionValue(event.target.value)
          }}>
                  <FormControlLabel 
                    value="cancelClient" 
                    control={<Radio />} 
                    label={<Typography className={ isTablet ? classes.body2 : null}>El cliente solicita cancelar la visita.</Typography>}
                    // disabled={pendingFaults} 
                  />
                  <FormControlLabel 
                    value="cancelMagal" 
                    control={<Radio />} 
                    label={<Typography className={ isTablet ? classes.body2 : null}>La visita debe ser cancelada por parte de Magal.</Typography>}
                  />
          </RadioGroup>
        </Grid>
        </Box>
      </Grid>
    


      <Grid 
        item 
        xs={12}
        spacing={5}
        mb={3}
        style={{
          paddingTop: '1%',
          paddingLeft: '2%',
          paddingBottom: '2%',
          paddingRight: '1%',
        }}
      >
        <Box mt={3}>
          <Grid container>
            <Grid item xs={12}>
              <Grid container alignItems="right" justifyContent="space-between">

                <Grid item xs={12} md="auto">
                  <DangerButtons
                    icon={<CancelTwoToneIcon size='inherit' />}
                    onClickHandler={() => {
                      cancelVisit()
                    }}
                    text="Confirmar Cancelación"
                    disabled={actionValue === null}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Paper> : <></>}

  </Grid>
  </>
}
