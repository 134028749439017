import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import Box from "@material-ui/core/Box";
import Radio from '@material-ui/core/Radio';
import {Checkbox, FormControlLabel, RadioGroup, FormGroup} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Paper from '@material-ui/core/Paper';
import List from "@material-ui/core/List";
import EventIcon from "@material-ui/icons/Event";
import Lightbox from 'react-lightbox-component';
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import SuccessButtons from "../Utils/Buttons/SuccessButtons";
import DangerButtons from "../Utils/Buttons/DangerButtons";
import 'react-lightbox-component/build/css/index.css';
import VisitList from "../Pending/VisitList";
import TextAreaInput from "../../../components/Inputs/TextAreaInput";
import useStylesForm from "./styles";
import {
  updateTicketMagalText
} from "../../../api/ticketApi"
import baja from "../../../images/baja.png";
import moderada from "../../../images/moderada.png";
import grave from "../../../images/grave.png";
import urgente from "../../../images/urgente.png";

const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

export default function SolvedTicketDetail({ticket, handleCloseTicket, handleCloseTicketNC, handleReActivateTicket}){
  const [ expanded, setExpanded ] = useState('')
  const [ ticketReactivate, setTicketReactivate ] = useState(false)
  const [ faultsToReactivate, setFaultsToReactivate] = useState(ticket.faults)
  const [ magalText, setMagalText] = useState(ticket.magalComment)
  const { enqueueSnackbar } = useSnackbar()

  const [actionValue, setActionValue] = useState(null)

  const toReactivateFaults = faultsToReactivate.some(p => !p.solved);
  const [ reActivateComment, setReActivateComment ] = useState('');
  const [ reClosedComment, setReClosedComment ] = useState('');

  const userType = localStorage.getItem('userType')
  const classes = useStylesForm()


  const closeTicket = () => {
    if (actionValue==='close'){
      handleCloseTicket();
    } else if (actionValue==='closeNC'){
      handleCloseTicketNC(reClosedComment);
    }
  };

  const reactivateTicket = () => {
    if (actionValue==='reactivate'){
      handleReActivateTicket(faultsToReactivate,reActivateComment);
    }
  };

  const setMagalTextUpdate = async () => {
    const response = await updateTicketMagalText({id : ticket.id, magalText});
    enqueueSnackbar(
      'Se ha actualizado el comentario.',
      {
        variant: 'success'
      }
    );
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

  const getBody1Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '25px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '18px'}
    }
    return {}
  }

  const getBody2Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '23px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '16px'}
    }
    return {}
  }

  const getDetailsClass = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {color: "#666666", fontSize: "21px"}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {color: "#666666", fontSize: "16px"}
    }
    return {color: "#666666", fontSize: "14px"}
  }

  const getClientClass = (col) => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {color: col, fontSize: "21px"}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {color: col, fontSize: "16px"}
    }
    return {color: col, fontSize: "14px"}
  }

  const getInfoSClass = (col) => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        if (col) return {color: col, fontSize: "21px" , marginRight: 20}
        return {fontSize: "21px" , marginRight: 20}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      if (col) return {color: col, fontSize: "16px" , marginRight: 20}
        return {fontSize: "16px" , marginRight: 20}
    }
    if (col) return {color: col, fontSize: "13px" , marginRight: 20}
    return {fontSize: "13px" , marginRight: 20}
  }

  const TicketActionClass = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {marginTop: '2%', fontSize: '25px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {marginTop: '2%', fontSize: '18px'}
    }
    return {marginTop: '2%'}
  }

  const textAreaLabel = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
      return classes.textAreaTablet
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {marginTop: '2%', fontSize: '18px'}
    }
    return {marginTop: '2%'}
  }

  const textAreaSize = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
      return 5
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return 4
    }
    return 3
  }

  const getGravityImg = (label) => {
    if (label === 'low') {
      return <img
                src={baja}
                // className={classes.image}
                alt="Gravedad Baja"
            />
    } 
    if (label === 'moderate') {
      return <img
                src={moderada}
                // className={classes.image}
                alt="Gravedad Moderada"
            />
    } 
    if (label === 'high') {
      return <img
                src={grave}
                // className={classes.image}
                alt="Gravedad Alta"
            />
    } 
    
    return <img
              src={urgente}
              // className={classes.image}
              alt="Gravedad Urgente"
          />
    
  }

  return <>
  <Grid container>
    <Grid item xs={12}>
          {(getClassNum() === 1) ? <Typography style={{fontSize: '30px'}} variant="h6" color="textPrimary">
            Información de Requerimiento
          </Typography> : <Typography variant="h6" color="textPrimary">
            Información de Requerimiento
          </Typography>}
    </Grid>
    <Grid item xs={12}>
      { (getClassNum() === 1) ?
      <Table>
        <TableBody>
        <TableRow colSpan='100%'>
            <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Datos Cliente</Typography>
            <span style={getDetailsClass()}> 
              Nombre:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientName}
            </span>
            
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Rut:&nbsp; 
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientRut}
            </span>
            </Typography>
            <Typography variant="body1">
            <span style={getDetailsClass()}> 
              Email:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientEmail}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={getDetailsClass()}> 
            Teléfono:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.clientPhone}
            </span>
            </Typography>
            {ticket.clientIsValidated ? 
              <span style={getClientClass("#3fb102")}> 
                El Cliente está validado.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Cliente no está validado.
              </span>
            }
            {ticket.sentByOwner ? 
              <span style={getClientClass("#3fb102")}> 
                El Requerimiento fue enviado por el propietario.
              </span> : 
              <span style={getClientClass("#fc0303")}> 
                El Requerimiento fue enviado por el usuario {ticket.senderUser}
              </span>
            }
          </TableCell>
          </TableRow>
          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.propertyCode}
                </span>
                </Typography>
                <Typography variant="body1" color="textPrimary">
                <span style={getDetailsClass()}> 
                Dirección:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.propertyAddress}
                </span>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
            </TableCell>
          </TableRow>  

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
             <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
              {/* <span style={{color: "#666666", fontSize: "13px" , marginRight: 20}}></span> */}
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                {ticket.faults.map(fault => (
                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                      <div>
                        <span style={getInfoSClass("#666666")}> 
                          Iteración:&nbsp;{fault.iteration}&nbsp;  
                        </span> 
                      </div>
                      {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          {
                          fault.solved ? (
                              <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                          ) : (
                              <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                          )
                          }
                        </div> 
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic', paddingRight: '1%'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red', paddingLeft: '1%'}}>
                            &nbsp;   (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                    </div>
                ))}
              </Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
            </TableCell>
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
            </TableCell>
            {ticket.uniqueId ? 
              <TableCell>
                <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
                <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
              </TableCell> :
              <TableCell/>
            }            
          </TableRow>

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
            </TableCell>
          </TableRow>

          {ticket.wasReactivated ? 
          <TableRow colSpan='100%'>     
            <TableCell>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado : {ticket.timesReactivated}</Typography>
            </TableCell>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
            </TableCell>
          </TableRow> : <></>}

          <TableRow colSpan='100%'>
            <TableCell colSpan='100%'>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario Empresa</Typography>
              <TextAreaInput
                  onChange={(event) => {
                    setMagalText(event.target.value)
                  }}
                  id="magal-comment"
                  value={magalText}
                  placeholder=""
                  label="Comentario"
                  name="MagalComment"
                  helperText=""
              />
              <SuccessButtons
                icon={null}
                onClickHandler={() => {
                  setMagalTextUpdate()
                }}
                text="Actualizar comentario"
              />
            </TableCell>
          </TableRow>
          
        </TableBody>
      </Table> : 
      <Table>
      <TableBody>
      <TableRow>
          <TableCell>
          <Typography style={getBody2Class()}  variant="body2" color="textSecondary">Datos Cliente</Typography>
                <span style={getDetailsClass()}> 
                  Nombre:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientName}
                </span>
              <Typography variant="body1">
                <span style={getDetailsClass()}> 
                  Rut:&nbsp; 
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientRut}
                </span>
              </Typography>
              <Typography variant="body1">
                <span style={getDetailsClass()}> 
                  Email:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientEmail}
                </span>
              </Typography>
              <Typography variant="body1" color="textPrimary">
                <span style={getDetailsClass()}> 
                Teléfono:&nbsp;  
                </span> 
                <span style={getBody1Class()} className="textPrimary">
                  {ticket.clientPhone}
                </span>
              </Typography>
              {ticket.clientIsValidated ? 
                <span style={getClientClass("#3fb102")}> 
                  El Cliente está validado.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Cliente no está validado.
                </span>
              }
              {ticket.sentByOwner ? 
                <span style={getClientClass("#3fb102")}> 
                  El Requerimiento fue enviado por el propietario.
                </span> : 
                <span style={getClientClass("#fc0303")}> 
                  El Requerimiento fue enviado por el usuario {ticket.senderUser}
                </span>
              }
            </TableCell>
            <TableCell/>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.propertyName}</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
            <span className="textPrimary">
              {ticket.propertyCode}
            </span>
            </Typography>
            <Typography variant="body1" color="textPrimary">
            <span style={getDetailsClass()}> 
            Dirección:&nbsp;  
            </span> 
            <span style={getBody1Class()} className="textPrimary">
              {ticket.propertyAddress}
            </span>
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.reportDate).format('DD/MM/YY HH:mm')}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.stateLabel}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Gravedad</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.gravity ? getGravityImg(ticket.gravity) : <>Sin asignar</>}</Typography>
          </TableCell>
        </TableRow>

        <TableRow colSpan='100%'>
           <TableCell colSpan='100%'>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              {ticket.faults.map(fault => (
                  <div style={{ display: "flex", alignItems: 'center' }}>
                    <div>
                      <span style={getInfoSClass("#666666")}> 
                        Iteración:&nbsp;{fault.iteration}&nbsp;  
                      </span> 
                    </div>
                    {fault.isApproved ? 
                        <>
                        <div>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName}
                        </div> 
                        {
                        fault.solved ? (
                            <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                        ) : (
                            <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                        )
                        }
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic'}}>
                          • {fault.faultZoneName} &nbsp;
                          - {fault.faultTargetName} &nbsp;
                          - {fault.faultTypeName} &nbsp;
                          <span style={{color: 'red'}}>
                            (Rechazado)
                          </span> 
                        </div> 
                      </>
                    }
                  </div>
              ))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Responsable</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.responsableName || <i>Sin asignar</i>}</Typography>
          </TableCell>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Número de Requerimiento</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.iteration}</Typography>
          </TableCell>
          <TableCell/>
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">N° Días abierto</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.daysOpenned}</Typography>
          </TableCell>
          {ticket.uniqueId ? 
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">ID</Typography>
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.uniqueId}</Typography>
            </TableCell> :
            <></>
          }            
          <TableCell colSpan={5}>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación del cliente</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticketText}</Typography>
          </TableCell>
        </TableRow>

        <TableRow>     
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">Requerimiento fue Reactivado</Typography>
          </TableCell> : <></>}    
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Veces reactivado</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.timesReactivated}</Typography>
          </TableCell> : <></>}
          {ticket.wasReactivated ? <TableCell >
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Razón de Reactivación</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.reactivationReason}</Typography>
          </TableCell> : <></>}
        </TableRow>

        <TableRow>
            <TableCell>
              <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentario Empresa</Typography>
              <TextAreaInput
                  onChange={(event) => {
                    setMagalText(event.target.value)
                  }}
                  id="magal-comment"
                  value={magalText}
                  placeholder=""
                  label=""
                  name="MagalComment"
                  helperText=""
              />
              <SuccessButtons
                icon={null}
                onClickHandler={() => {
                  setMagalTextUpdate()
                }}
                text="Actualizar comentario"
              />
            </TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        
      </TableBody>
    </Table>}
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              {(getClassNum() === 1) ? <Typography style={{fontSize: '30px'}} variant="h6" color="textPrimary">
                Imágenes
              </Typography> : <Typography variant="h6" color="textPrimary">
                Imágenes
              </Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          {(ticket.images.length > 0) ? 
            <TableCell colSpan={5}>
              <div>
                <Lightbox showImageModifiers={false}
                          images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                          }/></div>
            </TableCell> : 
            <>
              {'\n'}
              <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                No hay imágenes adjuntas
              </Typography>
            </>
            }
        </Grid>
      </Grid>
      </Box>
    </Grid>

    <Grid item xs={12}>
      <Box mt={3}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={12} md="auto">
              {(getClassNum() === 1) ? <Typography style={{fontSize: '30px'}} variant="h6" color="textPrimary">
                Visitas
              </Typography> : <Typography variant="h6" color="textPrimary">
                Visitas
              </Typography>}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <List
              dense
              style={{
                  width: "100%"
              }}
            >
          <VisitList visits={ticket.visits} expanded={expanded} setExpanded={setExpanded} />
          </List>
        </Grid>
      </Grid>
      </Box>
    </Grid>

    
    {(userType === 'ING' || userType === 'A') ? <>
    <Box m={2} pt={3} xs={12}>
      <Divider />
      <Typography 
        variant="body1" 
        color="textPrimary"
        style={TicketActionClass()}
      >
        Selecciona un estado para desbloquear una acción:
      </Typography>
    </Box>  
    <Grid item xs={12} style={{paddingLeft: '1%',}} >
      <RadioGroup 
        column 
        name="actionRadio" 
        value={actionValue} 
        className={classes.formRadioClass}
        onChange={(event) => {
          setActionValue(event.target.value)
      }}>
              <FormControlLabel 
                value="reactivate" 
                control={<Radio />} 
                label={<Typography style={getBody2Class()}>El Requerimiento necesita ser re-activado.</Typography>}
                // disabled={pendingFaults} 
              />
              {actionValue==='reactivate' ? 
                <Paper 
                  spacing={5}
                  mb={3}
                  style={{
                      paddingTop: '3%',
                      paddingBottom: '2%',
                      paddingLeft: '2%',
                      paddingRight: '2%',
                  }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography style={getBody2Class()}>Marca los problemas que necesitan ser revisados de nuevo:</Typography>

                  </Grid>
                  <Grid item xs={12}>
                      {faultsToReactivate.map((fault, i) => (
                          fault.isApproved ?
                          <FormGroup column>
                            <FormControlLabel
                              key={fault.id}
                              control={
                                <Checkbox 
                                  checked={!fault.solved} 
                                  onChange={() => {
                                    faultsToReactivate[i].solved = !faultsToReactivate[i].solved;
                                    setTicketReactivate(false);
                                    setTimeout( () => {
                                      setTicketReactivate(true);
                                  }, 100);
                                  }} 
                                  name={`faults.${i}.solved`} 
                                  />}
                              label={
                                <span style={getBody2Class()}>
                                  {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}
                                </span>
                              }
                            />
                          </FormGroup>
                          : <></>
                      ))}
                  </Grid>
                  <Grid
                      container
                      item
                      xs={12}
                  >
                      <TextAreaInput
                          onChange={(event) => {
                              setReActivateComment(event.target.value)
                          }}
                          id="comment-reactivate"
                          value={reActivateComment}
                          placeholder="Describe la razón por la que se debe reactivar el requerimiento."
                          label={
                            <span style={getDetailsClass()}>
                              Razón
                            </span>
                          }
                          name="Comment"
                          rows={textAreaSize()}
                          helperText={
                            <span style={getBody2Class()}>
                              Esta razón servirá para tener registro del por qué se necesitó reactivar este requerimiento.
                            </span>
                          }
                      />
                  </Grid>
                
                </Grid>
                </Paper> : <></>}
              <FormControlLabel 
                value="close" 
                control={<Radio />} 
                label={<Typography style={getBody2Class()}>El Requerimiento está listo para ser cerrado.</Typography>}
              />
              <FormControlLabel 
                value="closeNC" 
                control={<Radio />} 
                label={<Typography style={getBody2Class()}>El Requerimiento está listo para ser cerrado pero el cliente queda disconforme.</Typography>}
              />
              {actionValue==='closeNC' ? 
                <Paper 
                  spacing={5}
                  mb={3}
                  style={{
                      paddingTop: '2%',
                      paddingBottom: '2%',
                      paddingLeft: '1%',
                      paddingRight: '1%',
                  }}>
                <Grid container spacing={3}>
                  <Grid
                      container
                      item
                      xs={12}
                  >
                      <TextAreaInput
                          onChange={(event) => {
                              setReClosedComment(event.target.value)
                          }}
                          id="comment-closed"
                          value={reClosedComment}
                          placeholder="Describe la razón por la que el cliente queda disconforme."
                          label={
                            <span style={getDetailsClass()}>
                              Razón
                            </span>
                          }
                          name="Comment"
                          rows={textAreaSize()}
                          helperText={
                            <span style={getBody2Class()}>
                              Esta razón servirá para tener registro del por qué se cerró con disconformidad.
                            </span>
                          }
                      />
                  </Grid>
                
                </Grid>
                </Paper> : <></>}
      </RadioGroup>
    </Grid>
    </> : <></>}

    {(userType === 'ING' || userType === 'A') ? 
    <Grid 
      container 
      alignItems="center" 
      justifyContent="space-between" 
      mt={3}
      style={{
        marginTop: '2%',
      }}
    >
        <Grid item xs={12} md="auto">
            <DangerButtons
            icon={<EventIcon />}
            onClickHandler={() => {
                reactivateTicket()
            }}
            text="Reactivar Requerimiento"
            disabled={actionValue !== 'reactivate' || reActivateComment==='' || !toReactivateFaults}
            />
        </Grid>
        <Grid item xs={12} md="auto">
            <SuccessButtons
            icon={<CheckCircleTwoToneIcon />}
            onClickHandler={() => {
                closeTicket()
            }}
            text="Cerrar Requerimiento"
            disabled={actionValue === 'reactivate' || (actionValue!=='close'&&actionValue!=='closeNC') || (actionValue==='closeNC' && reClosedComment==='')}
            />
        </Grid>
    </Grid> : <></>}

  </Grid>
  </>
}
