import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconButton from "@material-ui/core/IconButton";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LockIcon from '@material-ui/icons/Lock';
import BaseInput from "./BaseInput";

const PasswordInput = (props) => {
    const {
        value,
        onChange,
        error,
        helperText,
        placeholder,
        id,
        label,
        labelFontSize,
    } = props

    const [visibility, setVisibility] = useState(false)

    return (
        <BaseInput
            id={id}
            value={value}
            placeholder={placeholder}
            label={label}
            name="password"
            type={visibility ? "text" : "password"}
            onChange={onChange}
            required
            error={error}
            labelFontSize={labelFontSize}
            helperText={helperText}
            startAdornment={<LockIcon />}
            endAdornment={
                <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                        setVisibility(!visibility)
                    }}
                >
                    {visibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            }
        />
    );
};

PasswordInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string.isRequired,
    labelFontSize: PropTypes.string,
};

PasswordInput.defaultProps = {
    labelFontSize: 'default'
}

export default PasswordInput;