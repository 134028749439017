import { makeStyles } from "@material-ui/core/styles";

const useStylesBanners = makeStyles((theme) => ({
    baseBlueFrame: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: theme.spacing(22),
        position: "absolute",
        top: "50%",
        left: 0,
        transform: "translateY(-50%)",
        backgroundColor: "rgba(0, 118, 202, 0.6)",
        zIndex: 20
    },
    baseBlueFrameTablet: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: theme.spacing(22),
        position: "absolute",
        top: "20%",
        left: 0,
        transform: "translateY(-20%)",
        backgroundColor: "rgba(0, 118, 202, 0.6)",
        zIndex: 20
    },
    baseBlueFrameTabletLong: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        height: theme.spacing(22),
        position: "absolute",
        top: "46%",
        left: 0,
        transform: "translateY(-46%)",
        backgroundColor: "rgba(0, 118, 202, 0.6)",
        zIndex: 20
    },
    alignCenter: {
        textAlign: "center"
    },
    image: {
        padding: 3,
        marginRight: theme.spacing(3),
        backgroundColor: "#ffffff",
        verticalAlign: "middle"
    },
    magalText: {
        display: "inline-block",
        color: "#ffffff",
        verticalAlign: "middle"
    }
}))

export default useStylesBanners