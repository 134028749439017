import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import {
  useTicket,
  getTicketsClosed,
} from "../../../api/ticketApi";
import BaseDialog from "../Utils/BaseDialog";
import BaseTable from "../Utils/BaseTable";
import ClosedTicketDetail from "./ClosedTicketDetail";


const ClosedTicketTable = ({title}) => {
    const [rows, setRows] = useState([])
    const [maxResults, setMaxResults] = useState(15)
    const [page, setPage] = useState(0)
    const [total, setTotal] = useState(0)

    const userType = localStorage.getItem('userType')
    const queryParams = {page: page + 1, maxResults, project__ingeneer__username: ''}
    const [loading, setLoading] = useState(true)
    const [filter, setFilter] = useState('A')


    if (userType === "ING") {
        queryParams.project__ingeneer__username = localStorage.getItem('username')
    }


    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const { ticket, loading: loadingTicketDetails } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()

    const fetchTicketClosed = () => {
      if (userType === 'ING'){
        getTicketsClosed(maxResults, page, queryParams.project__ingeneer__username).then((response) => {
            setRows(response.data.rows)
            setTotal(response.data.total)
            setLoading(false)
        })
      } else {
        getTicketsClosed(maxResults, page, null).then((response) => {
          setRows(response.data.rows)
          setTotal(response.data.total)
          setLoading(false)
        })
      }
    }
    
    useEffect(() => {
        fetchTicketClosed();
    }, [page, maxResults])

    // const applyFilter = (value) => {
    //   console.log(value);
    //   if (value === 'A'){
    //     fetchTicketClosed();
    //   } else{
    //     const tempRows = [];
    //     queryParamsFilter.state__state_category = value
    //     useTickets(queryParams)
    //     console.log(tickets);
    //     // setUsedRows(tempRows);
    //   }
    // }

    const getTextClass = () => {
      if (window.innerWidth === 980 && window.innerHeight === 1294){
          return {fontSize: '20px'}
      }
      if (window.innerWidth === 980 && window.innerHeight === 441){
        return {fontSize: '18px'}
      }
      return {}
    }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'client_name', numeric: false, disablePadding: false, label: 'Cliente' },
        { id: 'property_name', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problema' },
        { id: 'state_name', numeric: false, disablePadding: false, label: 'Estado' },
        { id: 'survey_answered', numeric: false, disablePadding: false, label: 'Encuesta' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => setSelectedTicketId(row.id)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                { row.client_name || <i>Sin cliente</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.property_name || <i>Sin propiedad</i>}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        const fault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null



        if (fault) {
          content = <>
            <div style={getTextClass()}>• {fault.fault_zone_name} - {fault.fault_target_name} - {fault.fault_type_name}</div>
            {leftCount > 0 && <Typography style={getTextClass()} color="secondary" variant="inherit">+{leftCount} PROBLEMA{leftCount !== 1 ? 'S': ''}</Typography>}
          </>

        } else {
          content = <i style={getTextClass()}>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.state_name}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => (
          <TableCell style={getTextClass()}>
              {(row.survey_answered && row.survey_answered === "True") ? <>Contestada</> : <>Sin contestar</>}
          </TableCell>
      )
    ]

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de ticket"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información reportada en el requerimiento, junto con su numero de visitas y posibilidad de ver el listado y situación de las mismas"
          >
              {ticket && <ClosedTicketDetail
                  ticket={ticket}
                />
              }
          </BaseDialog>
        
        {/* <Grid 
          container 
          spacing={5}
          mb={3}
          style={{
              paddingTop: '3%',
              paddingLeft: '2%',
              paddingRight: '1%',
          }}
        >
          <Grid item xs={12}>
              <Typography variant="h6" color="textPrimary">Tickets Cerrados</Typography>
          </Grid>
          <Grid item xs={12}>
              <FormControl variant="outlined" >
                <Select
                    native
                    value={filter}
                    onChange={(event) => {
                        setFilter(event.target.value);
                        // applyFilter(event.target.value);
                    }}
                    label="Filtrar"
                    inputProps={{
                        name: 'status',
                        id: '',
                    }}
                >
                {
                    ['A', 'C' , 'CD',].map(ticketStatusType => (
                        <option value={ticketStatusType} label={ticketStatus[ticketStatusType]}  />
                    ))
                }
                </Select>
              </FormControl>
          </Grid>
        </Grid> */}

        <BaseTable
            title={title}
            headers={headCells}
            rows={rows}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
        />
        </>
    );
};

ClosedTicketTable.propTypes = {

};

export default ClosedTicketTable;
