import Calendar from "@toast-ui/react-calendar";
import React from "react";
import moment from "moment";
import 'moment/src/locale/es'
import { Grid } from "@material-ui/core";
import { DateTimePicker } from '@material-ui/pickers';

const bgColors = [
  '#00af0a',
  '#00a9ff',
  '#80ff00',
]

const borderColors = [
  '#00af0a',
  '#00a9ff',
  '#80ff00',
]

const CustomCalendar = React.forwardRef(({calendars, schedules, view, defaultViews, onBeforeUpdateSchedule, onBeforeCreateSchedule, ...props }, ref) => {

  moment.locale('es');
  const handleBeforeUpdateSchedule = (values) => {
    console.log('update')
    // const data = {
    //   schedule: values.schedule,
    //   changes: values.changes,
    //   ...values.schedule,
    //   ...values.changes,
    //   calendarId: values.schedule.calendarId,
    //   calendar: values.schedule.calendarId
    // };
    // return onBeforeUpdateSchedule({...data, start: data.start.toDate().toISOString(), end: data.end.toDate().toISOString()});
  }

  const handleBeforeCreateSchedule = values => {
    console.log('create')
    // onBeforeCreateSchedule({
    //   ...values,
    //   calendar: values.calendarId,
    //   start: values.start.toDate().toISOString(),
    //   end: values.end.toDate().toISOString()})
  }

  const [fromDate, setFromDate] = React.useState(new Date())

  // return <Grid>
  //   <DateTimePicker 
  //       label="Fecha y hora de inicio"
  //       format="DD-MM-yyyy"
  //       value={fromDate}
  //       onChange={(e) => setFromDate(e)}
  //   />
  // </Grid>

  return <Calendar
    ref={ref}
    height="600px"
    calendars={calendars.map((t, i) => ({...t, id: t.id.toString(), calendarId: t.id, borderColor: t.borderColor, bgColor: t.bgColor}))}
    disableDblClick
    disableClick
    isReadOnly
    // disableClick={false}
    // isReadOnly={false}
    month={{
      startDayOfWeek: 1,
      daynames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      narrowWeekend: true,
    }}
    week={{
      startDayOfWeek: 1,
      daynames: [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado'
      ],
      narrowWeekend: true,
    }}
    schedules={schedules}
    scheduleView={['time' ,'allday']}
    taskView={[false]}
    template={{
      alldayTitle() {
        return '<span class="tui-full-calendar-left-content">Día completo</span>';
      },
      popupIsAllDay() {
        return 'Todo día';
      },
      popupStateFree() {
        return 'Libre';
      },
      popupStateBusy() {
        return 'Ocupado';
      },
      titlePlaceholder() {
        return 'Tema';
      },
      locationPlaceholder() {
        return 'Ubicación';
      },
      startDatePlaceholder() {
        return 'Fecha inicio';
      },
      endDatePlaceholder() {
        return 'Fecha termino';
      },
      popupSave() {
        return 'Agendar';
      },
      popupUpdate() {
        return 'Actualizar';
      },
      popupDetailState(schedule) {
        return `Estado : ${  schedule.state}` || 'Busy';
      },
      popupDetailBody(schedule) {
        return `Descripción : <br/>${  schedule.body}`;
      },
      popupEdit() {
        return 'Editar';
      },
      popupDelete() {
        return 'Eliminar';
      }
    }}
    locale="es"
    useDetailPopup
    // useCreationPopup
    useCreationPopup={false}
    view={view} // You can also set the `defaultView` option.
    {...props}
    onBeforeCreateSchedule={handleBeforeCreateSchedule}
    onBeforeUpdateSchedule={handleBeforeUpdateSchedule}
  />
});


export default CustomCalendar;