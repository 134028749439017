import React from 'react';
import PropTypes from 'prop-types';
import FaceIcon from '@material-ui/icons/Face';
import BaseInput from "./BaseInput";

const FullNameInput = (props) => {
    const {
        value,
        onChange,
        error,
        helperText,
        placeholder,
        id,
        name,
        label,
        labelFontSize,
    } = props

    return (
        <BaseInput
            id={id}
            placeholder={placeholder}
            label={label}
            name={name}
            type="text"
            helperText={helperText}
            required
            startAdornment={<FaceIcon />}
            onChange={onChange}
            value={value}
            error={error}
            labelFontSize={labelFontSize}
        />
    );
};

FullNameInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool.isRequired,
    helperText: PropTypes.string.isRequired,
    labelFontSize: PropTypes.string,
};

FullNameInput.defaultProps = {
    labelFontSize: 'default'
}

export default FullNameInput;