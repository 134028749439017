import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

/*
* value: <any> Select value
* options: <array> Contains select options
* disabled: <boolean>
* multiple: <boolean> Enables multiple selection
* handleChange: <func> (event, newValue) => { }
* renderOptionFunction: <func> (option) => { return <ReactNode> }
* optionLabelFunction: <func> (option) => { return <string> }
* disableCloseOnSelect: <bool> Should be activated when multiple is activated
* inputLabel: <string> Label on input
* */

const SelectAutocompleteInput = (props) => {
    const {
        value,
        options,
        disabled,
        multiple,
        handleChange,
        renderOptionFunction,
        optionLabelFunction,
        disableCloseOnSelect,
        inputLabel
    } = props

    return (
        <Autocomplete
            autoHighlight
            fullWidth
            size="small"
            closeText="Cerrar"
            openText="Ver opciones"
            clearText="Borrar selección"
            noOptionsText="No hay alternativas"
            value={value}
            options={options}
            disabled={disabled}
            multiple={multiple}
            onChange={handleChange}
            renderOption={renderOptionFunction}
            getOptionLabel={optionLabelFunction}
            disableCloseOnSelect={disableCloseOnSelect}
            renderInput={(params) => (
                <TextField
                    {...params} // eslint-disable-line react/jsx-props-no-spreading
                    label={inputLabel}
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,  // eslint-disable-line react/jsx-props-no-spreading
                        autoComplete: 'new-password',
                    }}
                />
            )}
        />
    );
};

SelectAutocompleteInput.propTypes = {
    value: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
    options: PropTypes.arrayOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool.isRequired,
    multiple: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    renderOptionFunction: PropTypes.func.isRequired,
    optionLabelFunction: PropTypes.func.isRequired,
    disableCloseOnSelect: PropTypes.bool.isRequired,
    inputLabel: PropTypes.string.isRequired
};

export default SelectAutocompleteInput;