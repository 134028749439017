import React from 'react';
import RejectedTicketTable from "./RejectedTicketTable";

const RejectedTickets = props => {
    return (
        <div>
            <RejectedTicketTable title="Requerimientos Rechazados"/>
        </div>
    );
};

RejectedTickets.propTypes = {

};

export default RejectedTickets;