import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStylesButtons from "./styles";

const SuccessButtons = ({
    text,
    icon,
    onClickHandler,
    disabled,
    size,
    href,
    target
    }) => {

    const classes = useStylesButtons()
    const getClassDict = () => {
        if (window.innerWidth < 769) {
            return {
                fontSize: '21px',
                minWidth: '180px',
                minHeight: '50px',
                maxWidth: '180px',
                maxHeight: '64px',
                paddingTop: '2%',
                marginTop: '1%',
                marginBottom: '1%'
            }
        }
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return {
                fontSize: '22px',
                minWidth: '150px',
                minHeight: '50px',
                maxWidth: '700px',
                maxHeight: '64px',
                marginTop: '1%',
                marginBottom: '1%'
            }
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
            return {
                fontSize: '18px',
                minWidth: '140px',
                minHeight: '54px',
                maxWidth: '600px',
                maxHeight: '54px',
                marginTop: '1%',
                marginBottom: '1%'
            }
        }
        return {}
    }
    
    return (
        <Button
            className={clsx(classes.general, classes.success)}
            variant="outlined"
            startIcon={icon}
            onClick={onClickHandler}
            disabled={disabled}
            size={size || 'small'}
            href={href}
            target={target}
            style={getClassDict()}
        >
            { text }
        </Button>
    );
};

SuccessButtons.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    size: PropTypes.string
};

SuccessButtons.defaultProps = {
    disabled: false,
    size: 'small'
}

export default SuccessButtons;