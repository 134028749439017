import { makeStyles } from "@material-ui/core/styles";

const useStylesCompound = makeStyles((theme) => ({
    reportButtonDesktop: {
        position: "absolute",
        top: "70%",
        left: "43%",
        transform: "translateY(80%)",
        zIndex: 21,
    },
    reportButtonTablet: {
        position: "absolute",
        top: "30%",
        left: "35%",
        transform: "translateY(30%)",
        zIndex: 21,
    },
    reportButtonTabletLong: {
        position: "absolute",
        top: "58%",
        left: "35%",
        transform: "translateY(58%)",
        zIndex: 21,
    },
    reportButtonMobile: {
        position: "absolute",
        top: "26%",
        left: "30%",
        transform: "translateY(26%)",
        zIndex: 21,
    }
}))

export default useStylesCompound