import axios from "axios";
import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import {useSnackbar} from "notistack";
import { withStyles } from "@material-ui/core/styles";
import {red} from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper"
import WarningIcon from '@material-ui/icons/Warning';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import PublishIcon from '@material-ui/icons/Publish';
import SearchIcon from '@material-ui/icons/Search';
import {LinearProgress} from "@material-ui/core";
import Lightbox from 'react-lightbox-component';
import querystring from "querystring";
import axiosAPI from "../../api/axiosApi";
import ReportDialogClient from "../../components/Dialogs/ReportDialogClient";
import DangerButtons from "../../components/Buttons/DangerButtons";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import {
    getClientData,
    getClientDocument,
    updateUserDocument
} from "../../api/clientApi";

const ClientHomeView = () => {
    
    const history = useHistory();

    const userName = localStorage.getItem('userName')
    const userRut = localStorage.getItem('username')

    const { enqueueSnackbar } = useSnackbar()

    const [isRejected, setIsRejected] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const [rejectReason, setRejectReason] = useState('')

    const [fileName, setFileName] = useState('')
    const [documentFileUploaded, setDocumentFileUploaded] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [documentFileName, setDocumentFileName] = useState('Archivo')

    const [dialogOpen, setDialogOpen] = useState(false)

    const hiddenFileInput = React.useRef(null);

    const [selectedFile, setSelectedFile] = useState();
    const [selectedOnce, setSelectedOnce] = useState(false);

    const [refetchData, setRefetchData] = useState(false)
    const [loading, setLoading] = useState(true)
    const [loadingFile, setLoadingFile] = useState(false)

    const handleUploadClick = event => {
        hiddenFileInput.current.click();
    }

    const handleFileChange = event => {
        const fileUploaded = event.target.files[0];
        setSelectedFile(event.target.files[0]);
        setFileName(event.target.files[0].name);
        setSelectedOnce(true);
    }

    const getUserClientData = () => {
        getClientData(userRut).then((response) => {
            if (response.data.length > 0){
                setDocumentFileUploaded(response.data[0].document_file_uploaded)
                setFileName(response.data[0].file_name)
                setDocumentFileName(response.data[0].document_name)
                setIsImage(response.data[0].is_image)
                setIsValidated(response.data[0].is_validated)
                setIsRejected(response.data[0].is_rejected)
                setRejectReason(response.data[0].reject_reason)
            }
            setLoading(false)
            setRefetchData(true)
        })
      }
  
      useEffect(() => {
          getUserClientData();
      }, [refetchData])

    const RedTextTypography = withStyles({
      root: {
        color: "#ed1607"
      }
    })(Typography);

    const downloadFile = async () => {
        // const documentDLname = `${user.file_name}`
        getClientDocument(userRut).then((response) => {
            window.open(response.data, "_blank", "noreferrer");
        })
    };

    const documentLoadFile = async () => {
      // Validar inputs
  
      // Useful for other things
      const formData = new FormData()
      formData.append('document_file', selectedFile);

      const response = await updateUserDocument(formData);
  
      if (response.data.error){
          enqueueSnackbar(
              response.data.error,
              {
                  variant: 'error'
              }
          );
      } else{
          enqueueSnackbar(
              'Se ha realizado la carga del archivo con éxito.',
              {
                  variant: 'success'
              }
          );
          
          setTimeout( () => {
              window.location.reload();
          }, 1500)
      }

      setLoadingFile(false);
      
      return true;
    };

    if (loading) return <LinearProgress />;

    return (
      <>
        <Paper
            style={{
                padding: 15,
                marginTop: '2%',
                justifyContent: 'center'
            }}
            elevation={3}
            xs={12}
            
        >
          <Grid
              container
              spacing={3}
            //   xs={12}
              style={{
                  marginTop: '1%',
                  marginBottom: '1%',
                  marginLeft: '1%',
                  justifyContent: 'left'
              }}
            >
              <Typography variant='h6'>
                Bienvenido {userName}
              </Typography>
            </Grid>
          {isRejected ? 
          <Grid
            container
            spacing={3}
            style={{
                marginTop: '1%',
                marginBottom: '1%',
                marginLeft: '1%',
                justifyContent: 'left'
            }}
            >
                <Grid item xs={12}>
                    <RedTextTypography variant='h6' style={{marginLeft: '1%', paddingRight: '3%'}}>
                        <WarningIcon style={{ color: red[500] }} />
                        Importante : Tu cuenta ha sido rechazada. El motivo se describe a continuación:
                    </RedTextTypography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="textPrimary">{rejectReason}</Typography>
                </Grid>
                <Grid item xs={12}>
                <Typography variant="body2" color="textSecondary">Ya no es posible reportar problemas con esta cuenta.</Typography>
                </Grid>
          </Grid>
        :
        <>
          {!isValidated ?
            <Grid
              container
              spacing={3}
            //   xs={12}
              style={{
                  marginTop: '1%',
                  marginBottom: '1%',
                  marginLeft: '1%',
                  justifyContent: 'left'
              }}
            >
              <WarningIcon style={{ color: red[500] }} />
              <RedTextTypography variant='h6' style={{marginLeft: '1%'}}>
                          Importante : Tu cuenta no está validada aún. Por favor envía un documento que respalde que eres propietario.
              </RedTextTypography>
              </Grid>
            : <></>}
          {!isValidated ?
            <Grid
              container
              spacing={3}
            //   xs={12}
              style={{
                  marginTop: '1%',
                  marginBottom: '1%',
                  marginLeft: '1%',
                  justifyContent: 'left'
              }}
            >
              <RedTextTypography variant='h6' style={{marginLeft: '1%'}}>
                          De todas formas, aún puedes continuar con el proceso y reportar problemas.
              </RedTextTypography>
              </Grid>
            : <></>}

            {!isValidated ? <><Grid 
                item 
                xs={12} 
                container 
                style={{
                    marginTop: '1%',
                    marginLeft: '1%',
                    marginBottom: '2%',
                    justifyContent: 'left'
                }}
            >
                <SuccessButtons
                    icon={<SearchIcon />}
                    onClickHandler={handleUploadClick}
                    text="Seleccionar archivo"
                />
                <input 
                    type="file" 
                    accept=".png, .pdf"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{display: 'none'}}
                    />
            </Grid>
            {selectedOnce ? 
                <Grid
                    item 
                    xs={12} 
                    md="auto"
                    style={{
                        paddingLeft: '1%',
                        paddingRight: '1%',
                    }}
                >
                    {fileName}
                </Grid> : <></>}
            <Grid 
                item 
                xs={12} 
                md="auto"
                style={{
                    paddingLeft: '1%',
                    paddingRight: '1%',
                }}
            >
                <SuccessButtons
                icon={<PublishIcon />}
                onClickHandler={() => {
                    setLoadingFile(true);
                    documentLoadFile();
                }}
                disabled={!selectedOnce}
                text="Cargar Documento"
                />
            </Grid>
            {loadingFile ? <LinearProgress /> : <></> }
            {documentFileUploaded ? <>
                <Grid 
                    item 
                    xs={12} 
                    container 
                    style={{
                        marginTop: '1%',
                        marginLeft: '1%',
                        marginBottom: '1%',
                        justifyContent: 'left'
                    }}
                >
                    <Typography variant="body2" color="textSecondary">Documento proporcionado</Typography>
                </Grid>
                {isImage ? <Grid 
                    item 
                    xs={12} 
                    container 
                    style={{
                        marginLeft: '1%',
                        marginBottom: '1%',
                        justifyContent: 'left'
                    }}
                >
                    <Typography variant="body1" color="textPrimary">
                        <Lightbox 
                            showImageModifiers={false}
                            images={[{src : fileName}]}
                        />
                    </Typography>
                </Grid> :
                <Grid 
                    item 
                    xs={12}
                    style={{
                        marginLeft: '1%',
                        marginBottom: '1%',
                        justifyContent: 'left'
                    }}
                >
                    <Typography 
                        onClick={downloadFile} 
                        component="span" 
                        variant="body1" 
                        color="textPrimary"
                        style={{
                            cursor: 'pointer',
                            '&:hover': {cursor: 'pointer'}
                        }}
                    >
                        {documentFileName}
                    </Typography>                     
                </Grid>}
                
                </> : <></>}
            
            </> : <></>}

            {/* Seccion boton y reporte */}
            <Grid
              container
              spacing={3}
              style={{
                  marginTop: '1%',
                  marginBottom: '1%',
                  marginLeft: '1%',
                  justifyContent: 'left'
              }}
            >
              <Typography variant='bodyPrimary'>
                Para reportar problemas en tu propiedad, por favor utiliza el botón a continuación. Para más acciones, utiliza las pestañas en la barra superior.
              </Typography>
              </Grid>
            <Grid 
                item 
                xs={12} 
                container 
                style={{
                    marginTop: '5%',
                    marginBottom: '5%',
                    justifyContent: 'center'
                }}
            >
                <DangerButtons
                    icon={
                        <NotificationImportantIcon
                            style={{
                                fontSize: 28
                            }}
                        />
                    }
                    onClickHandler={() => {
                        setDialogOpen(true)
                    }}
                    text="Enviar Requerimiento"
                    size="large"
                    Large
                />
            </Grid>
            <ReportDialogClient
                open={dialogOpen}
                handleClose={() => {
                    setDialogOpen(false)
                }}
            />
        </>
        }
        </Paper>
    </>
    );
};

ClientHomeView.propTypes = {

};

export default ClientHomeView;
