import React, {useCallback, useState} from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";
import CalendarizeVisits from "./CalendarizeVisits";
import DailyVisits from "../Tech/DailyVisits";
import PendingVisits from "./Pending/PendingVisits";
import CalendarReadOnly from "./CalendarReadOnly"


const ReadOnlyCalendar = () => <CalendarReadOnly />
const ReadOnlyDailyVisits = () => <DailyVisits readOnly anyStatus />
const Pendingvisits = () => <PendingVisits />
// const RecalendarizeVisits = () => <CalendarizeVisits overdue />

const VisitsHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const location = useLocation()
    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/visitas/':
                return 0

            case '/visitas/calendario':
                return 1

            case '/visitas/diarias':
                return 2

            case '/visitas/pendientes':
                return 3

            // case '/visitas/atrasadas':
            //     return 3
            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Visitas"

    const userType = localStorage.getItem('userType')

    if (!isAuth || userType === 'ET' || userType === 'C') {
        history.push('/')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getTabTextSize = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return '21px'
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return '17px'
        }
        return 'default'
    }

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column',
                height: '90vh'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="visits sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Calendarizar Visitas"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Calendario"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/calendario`)}
                />
                <Tab
                    label="Visitas del día"
                    style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                    onClick={() => history.push(`${url}/diarias`)}
                />
                <Tab
                  label="Visitas pendientes"
                  style={{ borderRight: '1px solid black', fontSize: getTabTextSize() }}
                  onClick={() => history.push(`${url}/pendientes`)}
                />
                {/* <Tab
                  label="Visitas atrasadas"
                  style={{ borderRight: '1px solid black' }}
                  onClick={() => history.push(`${url}/atrasadas`)}
                /> */}
            </Tabs>

            <Switch>
                <Route exact path={path} component={CalendarizeVisits} />
                <Route path={`${path}/calendario`} component={ReadOnlyCalendar} />
                <Route path={`${path}/diarias`} component={ReadOnlyDailyVisits} />
                <Route path={`${path}/pendientes`} component={Pendingvisits} />
                {/* <Route path={`${path}/atrasadas`} component={RecalendarizeVisits} /> */}
            </Switch>
        </div>
    );
};

export default VisitsHomePage;
