import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import moment from "moment";
// import 'moment/min/moment-with-locales'
import 'moment/src/locale/es'
import {MenuItem, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    select: {
        width: 160
    },
})
const getDatetimeLabel = (dt, view) => {
    switch (view) {
        case 'day':
            return moment(dt).format('DD-MM-YYYY');
        case 'week':
            return moment(dt).startOf('week').format('DD-MM-YYYY');
        case 'month':
            return moment(dt).format('MM-YYYY');
        default:
                return moment(dt).format('DD-MM-YYYY');
    }
}

const viewLabels = {
    day: 'Día',
    week: 'Semana',
    month: 'Mes',
}

export default function CalendarNav({calendar, dt: _dt, view: _view, views: _views,
                                        creationDefaultTitle,
                                        creationDefaultLocation,
                                        onShowCreationPopup,
                                    }){

    moment.locale('es');
    const classes = useStyles();
    const [ dt, setDt] = useState(_dt);
    const [ view, setView] = useState(_view);
    const [ views, setViews] = useState(_views);
    const handleChange = (e) => {
        setView(e.target.value);
        calendar.changeView(e.target.value, true);
    }

    const handlePrev = () => {
        const unit = view;
        calendar.prev();
        setDt(moment(dt).add(-1, unit).format());
    }
    const handleNext = () => {
        const unit = view;
        calendar.next();
        setDt(moment(dt).add(1, unit).format());
    }
    const handleNow = () => {
        setDt(moment().format());
        calendar.today();
    }
    const calendarFx = () => {
        if (!calendar) return;
        const {openCreationPopup, showCreationPopup } = calendar;

        const {_showCreationPopup} = calendar;
        // eslint-disable-next-line no-param-reassign,no-underscore-dangle
        calendar._showCreationPopup = (schedule, asd) => {
            if (onShowCreationPopup && schedule.triggerEventName === 'mouseup'){
                // onShowCreationPopup();
                _showCreationPopup({...schedule, title: creationDefaultTitle, location: creationDefaultLocation, raw: { a: 1}, raw2: 2});
            }
            // _showCreationPopup({...schedule, title: creationDefaultTitle || schedule.title, location: creationDefaultLocation || schedule.location, raw: { a: 1}, raw2: 2});
            _showCreationPopup({...schedule, title: creationDefaultTitle, location: creationDefaultLocation, raw: { a: 1}, raw2: 2});
            document.querySelector('#tui-full-calendar-schedule-title').value = (creationDefaultTitle);
            document.querySelector('#tui-full-calendar-schedule-location').value = (creationDefaultLocation);
        }

    }
    useEffect(() => {
        return calendarFx();
    },  );
    
    return <Grid container justifyContent="middle" alignItems="center">
        <Grid item xs>
            <Grid container alignItems="center" spacing={2}>
                {Boolean(views.length) &&
                    <Grid item>
                        <TextField className={classes.select} select value={view} variant="outlined" onChange={handleChange} size="small">
                            {views.map(v => <MenuItem value={v}>{viewLabels[v]}</MenuItem>)}
                        </TextField>
                    </Grid>
                }
                <Grid item>
                    <Button variant="outlined" onClick={handleNow} size="medium">
                        Hoy
                    </Button>
                    <IconButton onClick={handlePrev}><KeyboardArrowLeft /></IconButton>
                    <IconButton onClick={handleNext}><KeyboardArrowRight /></IconButton>
                </Grid>
            </Grid>
        </Grid>
        <Grid item>
            <Typography variant="h6" color="textPrimary">{getDatetimeLabel(dt, view)}</Typography>
            {/* <Typography color="textPrimary">Agenda visita para el Ticket Seleccionado</Typography> */}
        </Grid>
        <Grid item xs/>
    </Grid>
}

CalendarNav.propTypes = {
    dt: PropTypes.string,
    view: PropTypes.string,
    views: PropTypes.arrayOf(PropTypes.string)
}

CalendarNav.defaultProps = {
    dt: undefined,
    view: 'month',
    views: ['day', 'week', 'month']
}