import React from 'react';
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from '@material-ui/core/Typography'
import useStylesPages from "./styles";
import landingBigImage from "../images/quienesSomos3.jpg";
import landingLongImage from "../images/incio_alargada.jpg"
import NoAuthBanner from "../components/Banners/NoAuthBanner";
import AnnounceBanner from "../components/Banners/AnnounceBanner";
import ReportCompound from "../components/Compounds/ReportCompound";
import { isAuthenticated } from "../api/authenticationApi"

const LandingNoAuth = () => {
    const classes = useStylesPages();
    const mobile = window.innerWidth < 980
    const isAuth = isAuthenticated()
    const history = useHistory();

    document.title = "Magal PSEE | Inicio"

    const userType = localStorage.getItem('userType')

    const getImageClass = () => {
        if (window.innerWidth === 980 && window.innerHeight === 441){
            return classes.backgroundImageTabletLong
        }
        return classes.backgroundImage
    } 

    const redirectOnUserPermissions = (typeCode) => {
        switch (typeCode) {
            case 'A':
            case 'CA':
            case 'EPV':
                history.push('/tickets')
                break
            case 'SUP':
                history.push('/tickets')
                break
            case 'ING':
                history.push('/tickets/pendientes')
                break
            case 'ET':
                history.push('/equipo_tecnico')
                break
            case 'C':
                history.push('/cliente')
                break
            default:
                history.push('/')
        }
    }

    if(isAuth) {
        redirectOnUserPermissions(userType)
    }

    return (
        <div className={classes.root}>
            <img
                className={getImageClass()}
                src={mobile ? landingLongImage : landingBigImage}
                alt="Imagen de inicio sin autenticación"
            />
            {/* <AnnounceBanner /> */}
            <NoAuthBanner />
            <ReportCompound />
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                xs={11}
                md={10}
                lg={10}
                style={{paddingLeft: '10%'}}
            >
                <Typography
                    variant="caption"
                    display="block"
                    className={classes.disclaimerText}
                    style={{
                        marginBottom: 5
                    }}
                    // xs={10}
                    // md={6}
                    // lg={4}
                >
                    Las garantías inmobiliarias corresponden a aquellas indicadas en el Artículo 18 de la Ley General de
                    Urbanismo y Construcción. Se hace presente que en la web de la Cámara Chilena de la Construcción se
                    encuentra el Manual de Uso y Mantención de la Vivienda, el cual también le fue informado al momento de
                    compra de su departamento, Manual que contiene recomendaciones para la correcta aplicación de la
                    referida norma.
                </Typography>
            </Grid>
        </div>
    );
};

export default LandingNoAuth;