import React from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import useStylesButtons from "./styles";

const DangerButtons = (props) => {
    const {
        text,
        icon,
        onClickHandler,
        disabled,
        size,
        extraLarge
    } = props

    const classes = useStylesButtons()

    return (
        <Button
            className={extraLarge ? (
                clsx(classes.general, classes.danger, classes.extraLarge)
            ) : (
                clsx(classes.general, classes.danger)
            )}
            variant="outlined"
            startIcon={icon}
            onClick={onClickHandler}
            disabled={disabled}
            size={size || 'small'}
        >
            { text }
        </Button>
    );
};

DangerButtons.propTypes = {
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    icon: PropTypes.node.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    size: PropTypes.string,
    extraLarge: PropTypes.bool
};

DangerButtons.defaultProps = {
    disabled: false,
    size: 'small',
    extraLarge: false
}

export default DangerButtons;