import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from "snakecase-keys";
import useIsMountedRef from '../hooks/useIsMountedRef';
import axiosAPI from "./axiosApi";

export default function useData({ id, url, noSpecific, queryParams }) {
  const isMountedRef = useIsMountedRef();
  const [state, setState] = useState({ item: null, loading: true });

  const fetchItem = () => {
    setState({ ...state, loading: true });
    const fetchUrl = url.includes('{{id}}') ? url.replace('{{id}}', id) : `${url.replace(/\/$/, '')}/${id ? `${id}/` : ''}`
    axiosAPI.get(fetchUrl, {params: queryParams}).then((response) => {
      setState({
        ...state,
        item: { ...camelcaseKeys(response.data, { deep: true }) },
        loading: false
      });
    }).catch(() => {
      setState({...state, item: null, loading: false})
    });
  };

  useEffect(
    () => {
      if (noSpecific || id) {
        fetchItem();
      }else{
        setState({...state, item: null})
      }
    },
    [isMountedRef, id, new URLSearchParams(queryParams).toString()]
  );

  return {
    ...state,
    fetchItem,
    updateItem: (item) => {
      setState({
        ...state,
        item: { ...item },
        loading: false
      });
    },
  };
}

useData.propTypes = {
  queryParams: PropTypes.object
}

useData.defaultProps = {
  queryParams: {}
}