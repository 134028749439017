import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useQueryParam, StringParam} from "use-query-params";
import Typography from "@material-ui/core/Typography";
import {useSchedules, useVisit} from "../../api/visitApi";
import VisitList from "./VisitList";
import ManageVisitForm from "./Active/ManageVisitForm";

const ActiveVisits = () => {
    const { schedules, loading: loadingSchedules, addSchedule, removeSchedule, updateSchedule: handleUpdateSchedule } = useSchedules({visit__state__state_category: 'A'});
    const [visitId, setVisitId] = useQueryParam('visitId', StringParam);
    const { visit, loading: loadingVisit } = useVisit({id: visitId });
    const [ scheduleId, setScheduleId ] = useState(null);
    const handleCloseVisit = () => {
      removeSchedule(scheduleId);
    }
    const handleSelectSchedule = (vId, sId) => {
      setVisitId(vId);
      setScheduleId(sId);
    }

    return (
        <>
          <ManageVisitForm 
            open={Boolean(visitId)} 
            onClose={() => handleSelectSchedule(null, null)} 
            visit={visit} 
            onCloseVisit={handleCloseVisit}  
          />
          <Grid 
            container 
            spacing={3}
            style={{
              paddingLeft: '2%',
              paddingTop: '2%'
            }}
          >
            <Grid item xs={12} md={12} >
              <Typography variant="h5">Visitas activas</Typography>
              <VisitList
                showVisitStartDate
                loading={loadingSchedules}
                setVisitId={handleSelectSchedule}
                schedules={schedules} />
            </Grid>
          </Grid>
        </>
    );
};

export default ActiveVisits;
