import React, {useState} from 'react';
import TicketTablePreApprove from "./ToApprove/TicketTablePreApprove";

const ConsolidateTickets = props => {
    const [toogleRefetchTickets, setRefetchTickets] = useState(false)
    const refetchTickets = () => {
        setRefetchTickets(!toogleRefetchTickets)
        }
    return (
        <div>
            <TicketTablePreApprove refetchTickets={refetchTickets} title="Requerimientos por Aprobar"/>
        </div>
    );
};

ConsolidateTickets.propTypes = {

};

export default ConsolidateTickets;