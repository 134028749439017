import React, { useState, useEffect } from 'react';
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from "@material-ui/core/Box";
import {LinearProgress} from "@material-ui/core";
import {
  useTicket,
  useTickets
} from "../../../api/ticketApi";
import BaseDialog from "../Utils/BaseDialog";
import BaseTable from "../Utils/BaseTable";
import TicketDetail from "./TicketDetail";
import baja from "../../../images/baja.png";
import moderada from "../../../images/moderada.png";
import grave from "../../../images/grave.png";
import urgente from "../../../images/urgente.png";

const gravityLabels = {
  low: 'Baja',
  moderate: 'Moderada',
  high: 'Grave',
  urgent: 'Urgente',
}

const colorLabel = {
  low: '#059900',
  moderate: '#778000',
  high: '#a64b00',
  urgent: '#ab0303',
}

const colorBorderLabel = {
  low: '2px solid rgba(5,153,0,1.0)',
  moderate: '2px solid rgba(119,128,0,1.0)',
  high: '2px solid rgba(166,75,0,1.0)',
  urgent: '2px solid rgba(171,3,3,1.0)',
}

const TicketTable = ({statusCategory, title, removeOnSchedule}) => {
    const [maxResults, setMaxResults] = useState(100)
    const [page, setPage] = useState(0)

    const userType = localStorage.getItem('userType')
    const queryParams = {page: page + 1, maxResults, state__state_category: statusCategory}

    if (userType === "ING") {
        queryParams.project__ingeneer__username = localStorage.getItem('username')
    }

    const { tickets, loading, removeTicket } = useTickets(queryParams)
    const [selectedTicketId, setSelectedTicketId] = useState(null)
    const { ticket, loading: loadingTicketDetails, addVisit, removeVisit, updateVisit } = useTicket({id: selectedTicketId})
    const { enqueueSnackbar } = useSnackbar()
  

  const handleAddVisit = data => {
      addVisit(data);
      const ticketId = ticket.id;
      setSelectedTicketId(() => {
        setSelectedTicketId(null);
        removeTicket(ticketId);
      }, 3000);

  }

  const getTextClass = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '21px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '18px'}
    }
    return {}
  }

  const getGravityImg = (label) => {
    if (label === 'low') {
      return <img
                src={baja}
                // className={classes.image}
                alt="Gravedad Baja"
            />
    } 
    if (label === 'moderate') {
      return <img
                src={moderada}
                // className={classes.image}
                alt="Gravedad Moderada"
            />
    } 
    if (label === 'high') {
      return <img
                src={grave}
                // className={classes.image}
                alt="Gravedad Alta"
            />
    } 
    
    return <img
              src={urgente}
              // className={classes.image}
              alt="Gravedad Urgente"
          />
    
  }

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'clientName', numeric: false, disablePadding: false, label: 'Cliente' },
        { id: 'propertyName', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'faults', numeric: false, disablePadding: false, label: 'Problema' },
        { id: 'gravity', numeric: false, disablePadding: false, label: 'Gravedad' },
        { id: 'daysOpenned', numeric: false, disablePadding: false, label: 'Días abierto' },
        { id: 'iteration', numeric: false, disablePadding: false, label: 'N° de Requerimiento' },
        // { id: 'visit_count', numeric: false, disablePadding: false, label: 'N° visitas' },
        // { id: 'ticket_score', numeric: false, disablePadding: false, label: 'Nota' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton size='inherit' color="primary" onClick={e => setSelectedTicketId(row.id)}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                { row.clientName || <i>Sin cliente</i> }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.propertyName || <i>Sin propiedad</i>}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => {
        const fault = row.faults[0]
        const leftCount = row.faults.length - 1
        let content = null



        if (fault) {
          content = <>
            <div style={getTextClass()}>• {fault.faultZoneName} - {fault.faultTargetName} - {fault.faultTypeName}</div>
            {leftCount > 0 && <Typography style={getTextClass()} color="secondary" variant="inherit">+{leftCount} PROBLEMA{leftCount !== 1 ? 'S': ''}</Typography>}
          </>

        } else {
          content = <i style={getTextClass()}>No hay problemas asociados</i>
        }

        return <TableCell>
          {content}
            </TableCell>
      },
      (row, isItemSelected, labelId) => (
            <TableCell>
              {getGravityImg(row.gravity)}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.daysOpenned}
            </TableCell>
        ),
      (row, isItemSelected, labelId) => (
            <TableCell style={getTextClass()}>
                {row.iteration}
            </TableCell>
        )
    ]

    if (statusCategory === "A") {
        headCells.pop()
        rowComponents.pop()
    }

  if (loading) return <LinearProgress />;

    return (
      <>
          <BaseDialog
            hideCloseButton
            open={Boolean(selectedTicketId)}
            handleClose={() => {
                setSelectedTicketId(null)
            }}
            handleSuccess={() => {
                setSelectedTicketId(null)
            }}
            title="Detalles de Requerimiento"
            successButtonText="Ok"
            size="lg"
            tooltipText="Se detalla la información reportada en el requerimiento, junto con su numero de visitas y posibilidad de ver el listado y situación de las mismas"
          >
              {ticket && <TicketDetail
                  ticket={ticket}
                  handleVisitAdded={handleAddVisit}
                  handleVisitRemoved={removeVisit}
                  handleVisitUpdated={updateVisit}
                />
              }
          </BaseDialog>
        <BaseTable
            title={title}
            headers={headCells}
            rows={tickets.results || []}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={tickets.count}
            rowComponents={rowComponents}
        />
        </>
    );
};

TicketTable.propTypes = {

};

export default TicketTable;
