import React, {useCallback, useState} from 'react';
import {
    useHistory,
    Route,
    Switch,
    useRouteMatch,
    useLocation
} from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import useStylesPages from "../styles";
import { isAuthenticated } from "../../api/authenticationApi";

import UsersTable from "./UsersTable";
import UsersCreate from "./UsersCreate";
// import MassiveLoad from "./MassiveLoad";
// import ProjectsTable from "./Projects/ProjectsTable";
import ClientValidate from "./Validate/ClientValidate"
import ClientsTable from "./ClientsTable"
import SurveysTable from "./SurveysTable"
// import ProjectCreate from './Projects/ProjectCreate';
// import TeamsTable from "./Teams/TeamsTable"
// import TeamCreate from "./Teams/TeamCreate"

const UserTable = () => <UsersTable />
const ClientTable = () => <ClientValidate />
const UserCreate = () => <UsersCreate />
const ClientUserTable = () => <ClientsTable />
const SurveyTable = () => <SurveysTable />
// const MassiveLoadTable = () => <MassiveLoad /> // to edit
// const ProjectTable = () => <ProjectsTable />
// const ProjectAdd = () => <ProjectCreate />
// const TeamTable = () => <TeamsTable />
// const TeamAdd = () => <TeamCreate />

const ManagementHomePage = () => {
    const classes = useStylesPages();
    const isAuth = isAuthenticated()
    const history = useHistory();
    const location = useLocation()
    const { path, url } = useRouteMatch();

    const [value, setValue] = React.useState(() => {
        switch (location.pathname) {
            case '/usuarios/':
                return 0

            case '/usuarios/agregar':
                return 1

            case '/validar':
                return 2

            case '/usuarios/clientes':
                return 3

            case '/encuestas/':
                return 4

            // case '/equipos/':
            //     return 5

            default:
                return 0
        }
    });

    document.title = "Magal PSEE | Administración"

    const userType = localStorage.getItem('userType')

    if (!isAuth || userType !== 'A') {
        history.push('/')
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div
            className={classes.root}
            style={{
                width: '100%',
                flexDirection: 'column'
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="management sections"
                style={{
                    width: '100%'
                }}
            >
                <Tab
                    label="Administrar Usuarios"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(url)}
                />
                <Tab
                    label="Agregar nuevo usuario"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/agregar`)}
                />
                <Tab
                    label="Validar Clientes"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/validar`)}
                />
                <Tab
                    label="Administrar Clientes"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/clientes`)}
                />
                <Tab
                    label="Encuestas"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/encuestas`)}
                />
                {/* <Tab
                    label="Administrar Equipos"
                    style={{ borderRight: '1px solid black' }}
                    onClick={() => history.push(`${url}/equipos`)}
                /> */}
            </Tabs>

            <Switch>
                <Route exact path={path} component={UserTable} />
                <Route path={`${path}/agregar`} component={UserCreate} />
                <Route path={`${path}/validar`} component={ClientTable} />
                <Route path={`${path}/clientes`} component={ClientUserTable} />
                {/* <Route path={`${path}/carga`} component={MassiveLoadTable} />
                <Route path={`${path}/proyectos`} component={ProjectTable} /> */}
                <Route path={`${path}/encuestas`} component={SurveyTable} />
                {/* <Route path={`${path}/agregar_equipo`} component={TeamAdd} /> */}
            </Switch>
        </div>
    );
};

export default ManagementHomePage;