import React from "react";
import { render } from "react-dom";
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
import { QueryParamProvider } from 'use-query-params';
import { IconButton} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import configureStore from './store/configureStore'
import initialState from "./reducers/initialState";
import App from "./components/App";
import "./app.css";

const store = configureStore(initialState)
// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

render(
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <SnackbarProvider
                maxSnack={5}
                autoHideDuration={5000}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                ref={notistackRef}
                action={(key) => (
                  <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                  </IconButton>
                )}
            >
                <App />
            </SnackbarProvider>
          </QueryParamProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>,
    document.getElementById("app")
);
