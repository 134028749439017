import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Lightbox from "react-lightbox-component";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import ReportIcon from '@material-ui/icons/Report';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import {orange, red} from "@material-ui/core/colors";
import Label from "../../../components/Label/Label";
import {getImageUrl} from "../../../utils/image";


const stateLabels = {
    P: "Pendiente",
    A: "Activa",
    C: "Completada",
}

const visitStateColors = {
    P: "inherit",
    A: "warning",
    C: "success",
}

export default function VisitList({visits, expanded, setExpanded}){


    const handleAccordionChange = (name) => () => {
        if (expanded !== name) setExpanded(name);
        else setExpanded('');
    };

    const getBody1Class = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return {fontSize: '24px'}
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return {fontSize: '17px'}
        }
        return {}
      }
    
      const getBody2Class = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return {fontSize: '22px'}
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return {fontSize: '15px'}
        }
        return {}
      }

      const faultTextClass = () => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            return {marginRight: 20, fontSize: '21px'}
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
          return {marginRight: 20, fontSize: '15px'}
        }
        return {marginRight: 20}
      }

      const actionTextClass = (col, margin) => {
        if (window.innerWidth === 980 && window.innerHeight === 1294){
            if (margin) return {marginLeft: 20, color: col, fontSize: '20px'}
            return {color: col, fontSize: '20px'}
        }
        if (window.innerWidth === 980 && window.innerHeight === 441){
            if (margin) return {marginLeft: 20, color: col, fontSize: '15px'}
            return {color: col, fontSize: '15px'}
        }
        if (margin) return {marginLeft: 20, color: col}
        return {color: col}
      }

    return <>
        {!visits.length && <Typography style={getBody1Class()} variant="body1"><i>No hay visitas calendarizadas.</i></Typography>}
        {visits.map(visit =>
        <Accordion key={visit.id} expanded={expanded === visit.id} onChange={handleAccordionChange(visit.id)}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
            >
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha calendarizada</Typography>
                        <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(visit.suggestedDate).format('DD-MM-YY HH:mm')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography style={getBody2Class()} variant="body2" color="textSecondary">Estado</Typography>
                        <Typography style={getBody1Class()} variant="body1" color="textPrimary">{visit.stateLabel}</Typography>
                        {/* <Label color={visitStateColors[visit.stateLabel]}>{stateLabels[visit.stateLabel]}</Label> */}
                    </Grid>
                    <Grid item xs />
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                <Box style={{paddingRight: '36px'}} width="100%">
                    <Grid container spacing={3}>

                        <Grid item xs={12} md={4}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha efectiva</Typography>
                            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{visit.realDate ? moment(visit.realDate).format('DD-MM-YY HH:mm') : 'Por definir'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Nota de evaluación</Typography>
                            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{visit.visitScore || 'Sin evaluar'}</Typography>
                        </Grid>
                        {visit.realizedByName ? <Grid item xs={12} md={4}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Realizada por</Typography>
                            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{visit.realizedByName || '-'}</Typography>
                        </Grid> : <></>}
                        {visit.delay ? <>
                            <Grid item xs={12} md={4}>
                                <Typography style={getBody2Class()} variant="body2" color="textSecondary">Atraso</Typography>
                                <Grid style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography style={getBody1Class()} variant="body1" color="textPrimary">Atraso Normal <ReportProblemIcon size='inherit' style={{ color: orange[500] }} /> </Typography>
                                </Grid>
                            </Grid></> : <></>}
                        {visit.extremeDelay ? <>
                            <Grid item xs={12} md={4}>
                                <Typography style={getBody2Class()} variant="body2" color="textSecondary">Atraso</Typography>
                                <Grid style={{display: 'flex', alignItems: 'center'}}>
                                    <Typography style={getBody1Class()} variant="body1" color="textPrimary">Atraso Extremo <ReportIcon style={{ color: red[700] }} /> </Typography>
                                </Grid>                                
                            </Grid></> : <></>}
                        <Grid item xs={12} md={12}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas vistos durante esta visita</Typography>
                            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
                                {!visit.faultsViewed.length && <i>No se atendieron problemas</i>}
                                {visit.faultsViewed.map(fault => (
                                    <div key={fault.id} style={{ display: "flex", alignItems: 'center' }}>
                                        <div style={{ marginRight: 20 }}>• {fault.action} : {fault.fault}</div>
                                    </div>
                                ))}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Comentarios del cliente</Typography>
                            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{visit.comment || '-'}</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <div>
                                <Lightbox showImageModifiers={false}
                                          images={visit.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                                          }/></div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Firma del cliente</Typography>
                            { !visit.signatureSrc && <Typography style={getBody1Class()} variant="body1" color="textPrimary">Pendiente</Typography>}
                            { visit.signatureSrc && <img style={{width: '300px'}} alt='signature' src={visit.signatureSrc} />}
                        </Grid>
                    </Grid>
                </Box>
            </AccordionDetails>
        </Accordion>

    )}</>
}
