import React from 'react';
import ClosedTicketTable from "./Closed/ClosedTicketTable";

const ClosedTickets = props => {
    return (
        <div>
            <ClosedTicketTable title="Requerimientos Cerrados"/>
        </div>
    );
};

ClosedTickets.propTypes = {

};

export default ClosedTickets;