import snakecaseKeys from "snakecase-keys";
import axiosAPI, { setNewHeaders } from "./axiosApi";

export async function signUp(email, username, password) {
    const response = await axiosAPI.post("auth/users/create/", {
        email,
        username,
        password,
    });
    localStorage.setItem("user", response.data);
    return response;
}

export async function obtainToken(username, password) {
    const response = await axiosAPI.post("auth/token/obtain/", {
        username,
        password,
    });

    if (response.status === 200) {
        setNewHeaders(response);
    }

    return response;
}

export async function refreshToken(refresh) {
    const response = await axiosAPI.post("auth/token/refresh/", {
        refresh,
    });
    setNewHeaders(response);
    return response;
}

// eslint-disable-next-line
export async function logout(accessToken) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("userName")
    localStorage.removeItem("userType")
    localStorage.removeItem("username")
    // TODO: invalidate token on backend
}

export const isAuthenticated = () => {
    const token = localStorage.getItem("access_token");
    return !!token;
};

export async function verifyToken() {
    const token = localStorage.getItem("access_token");
    const response = await axiosAPI.post("auth/token/verify/", {
        token,
    });
    return response;
     
}

export async function sendMailToResetPassword(mail) {
    const response = await axiosAPI.post("auth/send_reset/", {
        mail,
    });
    return response;
}

export async function validateIDandToken(token, id) {
    const response = await axiosAPI.post("auth/validate_token/", {
        token,
        id,
    });
    return response;
}

export async function setNewPassword(token, id, password) {
    const response = await axiosAPI.post("auth/update_password/", {
        token,
        id,
        password
    });
    return response;
}

export async function resetPasswordAllUsers(data) {
    const response = await axiosAPI.get(`send_reset_all/`, snakecaseKeys(data, {deep: true}))
    return response;
}