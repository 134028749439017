import React, {useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {useQueryParam, StringParam} from "use-query-params";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import VisitCalendar from "./VisitCalendar";
import {useSchedules, useVisit, useVisits} from "../../api/visitApi";
import VisitDetail from "./VisitDetail";
import VisitList from "./VisitList";
import BaseDialog from "./BaseDialog";
import StartVisitForm from "./Pending/StartVisitForm";
import SecondaryButtons from "./Utils/SecondaryButtons";

const DailyVisits = ({readOnly, overdue, anyStatus}) => {
  const queryParams = overdue  ? {visit__state__state_category: 'P', only_past: true} : {visit__state__state_category: 'P', only_future: true};

    const { schedules, loading: loadingSchedules, addSchedule, removeSchedule, updateSchedule: handleUpdateSchedule } = useSchedules(queryParams);
    const { schedules: todaySchedules, loading: loadingTodaySchedules } = useSchedules(anyStatus ? {today: true} : {visit__state__state_category: 'P', today: true});
    const [visitId, setVisitId] = useQueryParam('visitId', StringParam);
    const { visit, loading: loadingVisit } = useVisit({id: visitId });
    const [ startVisit, setStartVisit ] = useState(false);
    const isTablet = window.innerWidth === 980 && window.innerHeight === 1294;

    // const [ reloadWindow, setReloadWindow] = useState(false); 

    return (
        <>
          <BaseDialog
            open={Boolean(visitId && !startVisit)}
            handleClose={() => {
              setVisitId(null)
              window.location.reload();
            }}
            handleSuccess={() => setStartVisit(true)}
            hideDefaultActions={readOnly}
            actions={readOnly && (() => <>
              <Box flexGrow={1} />
              <SecondaryButtons
                onClickHandler={() => {
                  setVisitId(null)
                  window.location.reload();
                }}
                text="Cerrar"
                size="medium"
              /></>)
            }
            title="Visita"
            successButtonText="Continuar"
            successButtonIcon={<ArrowForwardIcon style={{fontSize: 'inherit'}} />}
            closeButtonSize='large'
            size="md"
            tooltipText="Permite iniciar una visita y ver el detalle de su información"
          >
            {loadingVisit && <LinearProgress />}
            {visit && <VisitDetail visit={visit} />}
          </BaseDialog>
          <StartVisitForm 
            open={Boolean(startVisit && visit)} 
            onClose={() => {
              setStartVisit(false)
              setVisitId(null)
              window.location.reload();
            }} 
            visit={visit}  />
          <Grid 
            container 
            spacing={3}
            style={{
              paddingLeft: '2%',
              paddingTop: '2%'
            }}
          >
            <Grid item xs={12} md={3} >
              <Typography style={{fontSize: isTablet ? "28px" : "default"}} variant="h5">Visitas de hoy</Typography>
              <VisitList
                loading={loadingTodaySchedules}
                setVisitId={setVisitId}
                schedules={todaySchedules} />
            </Grid>
            <Grid item xs={12} md={9} >
              <VisitCalendar schedules={schedules} defaultView="day" defaultViews={[]} isReadOnly/>
            </Grid>
          </Grid>
        </>
    );
};

export default DailyVisits;
