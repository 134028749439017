import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import {green, red} from "@material-ui/core/colors";
import CancelTwoToneIcon from "@material-ui/icons/CancelTwoTone";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import Paper from '@material-ui/core/Paper';
// import Divider from "@material-ui/core/Divider";
import Lightbox from 'react-lightbox-component';
import 'react-lightbox-component/build/css/index.css';

const stateLabels = {
  P: 'El Requerimiento está siendo gestionado, pronto lo contactarán para agendar una visita.',
  A: 'El Requerimiento está siendo atendido, la próxima visita ya está agendada.',
  C: 'El Requerimiento está cerrado. Se envió una encuesta de satisfacción a su correo.',
  PA: 'El Requerimiento está siendo revisado para su aprobación.',
  NA: 'El Requerimiento fue rechazado. Motivo: No aplica postventa. Para mayor información, contactarse a postventa@magal.cl',
  EI: 'El Requerimiento fue rechazado. Motivo: Error en los datos ingresados. Para mayor información, contactarse a postventa@magal.cl',
  S: 'El Requerimiento fue solucionado.',
  CD: 'El Requerimiento está cerrado. Se envió una encuesta de satisfacción a su correo.'
}

const visitLabels = {
  P: 'Pendiente.',
  A: 'En proceso.',
  C: 'Finalizada.',
  S: 'Finalizada.',
  RI: 'Cancelada.',
  CI: 'Cancelada.',
  RC: 'Cancelada.',
  CC: 'Cancelada.'
}

export default function ClientTicketDetail({
  ticket}){
                
  const useStyles = makeStyles((theme) => ({
      root: {
          width: '100%',
      },
      paper: {
          width: '100%',
          marginBottom: theme.spacing(2),
      },
      table: {
          minWidth: 750,
      },
      container: {
          maxHeight: '70vh'
      },
      visuallyHidden: {
          border: 0,
          clip: 'rect(0 0 0 0)',
          height: 1,
          margin: -1,
          overflow: 'hidden',
          padding: 0,
          position: 'absolute',
          top: 20,
          width: 1,
      },
  }));
  const classes = useStyles();

  const getInfoSClass = (col) => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        if (col) return {color: col, fontSize: "21px" , marginRight: 20}
        return {fontSize: "21px" , marginRight: 20}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      if (col) return {color: col, fontSize: "16px" , marginRight: 20}
        return {fontSize: "16px" , marginRight: 20}
    }
    if (col) return {color: col, fontSize: "13px" , marginRight: 20}
    return {fontSize: "13px" , marginRight: 20}
  }

  const getClassNum = () => {
    // 0 = desktop | 1 tablet | 2 tabletLong
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return 1
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
        return 2
    }
    return 0
}

  const getBody1Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '25px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '18px'}
    }
    return {}
  }

  const getBody2Class = () => {
    if (window.innerWidth === 980 && window.innerHeight === 1294){
        return {fontSize: '23px'}
    }
    if (window.innerWidth === 980 && window.innerHeight === 441){
      return {fontSize: '16px'}
    }
    return {}
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleAccordionChange = (name) => () => {
    if (expanded !== name) setExpanded(name);
    else setExpanded('');
};

  
  return <>
  <Grid container>
    <Grid item xs={12}>
        <Typography style={{fontSize: (getClassNum() === 1) ? '20px' : 'default'}} variant="h6" color="textPrimary">
          Información del Requerimiento
        </Typography>
    </Grid>

    <Table>
    <TableBody>
        <TableRow colSpan="100%">
          <TableCell colSpan="100%">
            <Typography variant="body2" color="textSecondary">Propiedad</Typography>
            <Typography variant="body1" color="textPrimary">{ticket.property_name}</Typography>
          </TableCell>
          <TableCell/>
          <TableCell/>
          
        </TableRow>

        <TableRow colSpan="100%">
          <TableCell colSpan="100%">
            <Typography variant="body2" color="textSecondary">Estado del Requerimiento</Typography>
            <Typography variant="body1" color="textPrimary">{stateLabels[ticket.state_name]}</Typography>
          </TableCell>
          <TableCell/>
          <TableCell/>
          
        </TableRow>

        <TableRow>
          <TableCell>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Fecha de recepción</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{moment(ticket.report_date).format('DD/MM/YY HH:mm')}</Typography>
          </TableCell>
          <TableCell/>
          <TableCell/>
        </TableRow>

        <TableRow colSpan="100%">
           <TableCell colSpan="100%">
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Problemas</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">
              {ticket.faults.map(fault => (
                  <div>
                    {fault.is_approved ? 
                        <>
                        <div>
                          • {fault.fault_zone_name} &nbsp;
                          - {fault.fault_target_name} &nbsp;
                          - {fault.fault_type_name} &nbsp;
                          {
                          fault.solved ? (
                              <CheckCircleTwoToneIcon size='inherit' style={{ color: green[500] }} />
                          ) : (
                              <CancelTwoToneIcon size='inherit' style={{ color: red[500] }} />
                          )
                          }
                        </div> 
                        </>
                      : 
                        <>
                        <div style={{color: '#666666', fontStyle: 'italic', paddingRight: '1%'}}>
                          • {fault.fault_zone_name} &nbsp;
                          - {fault.fault_target_name} &nbsp;
                          - {fault.fault_type_name} &nbsp;
                          <span style={{color: 'red', paddingLeft: '1%'}}>
                            &nbsp;   (Rechazado | Motivo : {fault.reject_reason})
                          </span> 
                        </div> 
                      </>
                    }
                  </div>
              ))}
            </Typography>
          </TableCell>
        </TableRow>

        <TableRow>    
          <TableCell colSpan={5}>
            <Typography style={getBody2Class()} variant="body2" color="textSecondary">Explicación proporcionada</Typography>
            <Typography style={getBody1Class()} variant="body1" color="textPrimary">{ticket.ticket_text}</Typography>
          </TableCell>
          <TableCell/>
          <TableCell/>
        </TableRow>

        <TableRow>
          <TableCell colSpan={4}>
            <Typography variant="body2" color="textSecondary">Imágenes adjuntas</Typography>
            {ticket.images.length!==0 ? <div>
              <Lightbox showImageModifiers={false}
                        images={ticket.images.map(img => ({src: img.src, title: img.message, description: ' '}))
                        }/>
            </div> : <><p> No hay imágenes adjuntas.</p></>}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={4}>
            <Typography variant="body2" color="textSecondary">Visitas</Typography>
            {ticket.visits.length!==0 ? 
              <Grid>
              {ticket.visits.map(visit => (
                <Accordion key={visit.id} expanded={expanded === visit.id} onChange={handleAccordionChange(visit.id)}>
                  <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1c-content"
                      id="panel1c-header"
                  >
                      <Grid container spacing={3}>
                          <Grid item xs={4}>
                              <Typography variant="body2" color="textSecondary">Fecha calendarizada</Typography>
                              <Typography variant="body1" color="textPrimary">{moment(visit.suggested_date).format('DD-MM-YY HH:mm')}</Typography>
                          </Grid>
                          <Grid item xs={4}>
                              <Typography variant="body2" color="textSecondary">Estado</Typography>
                              <Typography variant="body1" color="textPrimary">{visitLabels[visit.state_label]}</Typography>
                              {/* <Label color={visitStateColors[visit.stateLabel]}>{stateLabels[visit.stateLabel]}</Label> */}
                          </Grid>
                          <Grid item xs />
                      </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                      <Box style={{paddingRight: '36px'}} width="100%">
                          <Grid container spacing={3}>
                              <Grid item xs={12} md={4}>
                                  <Typography variant="body2" color="textSecondary">Evaluación</Typography>
                                  <Typography variant="body1" color="textPrimary">{visit.survey_answered ? 'Contestada' : 'Sin contestar'}</Typography>
                              </Grid>
                              {visit.signature_src ? 
                                <Grid item xs={12} md={4}>
                                    <Lightbox 
                                      showImageModifiers={false}
                                      images={[{src: visit.signature_src, title: 'Firma Cliente', description: ' '}]}
                                    /> 
                                </Grid>   : <></>     } 
                          </Grid>
                      </Box>
                  </AccordionDetails>
              </Accordion>))}</Grid> : <><p> No hay registro de visitas.</p></>}
            {/* {ticket.visits.length!==0 ? 
            <div>
              {ticket.visits.map(visit => (
                  <div style={{ display: "flex", alignItems: 'center' }}>
                    <div style={getInfoSClass(null)}>
                      • Evaluación : {visitLabels[visit.state_label]}
                      - Fecha Calendarizada : {moment(visit.suggested_date).format('DD/MM/YY HH:mm')}
                    </div> 
                    
                    {visit.signature_src ? 
                    <Lightbox 
                      showImageModifiers={false}
                      images={[{src: visit.signature_src, title: 'Firma Cliente', description: ' '}]}
                    /> : <></>}
                  </div>
              ))}
              
            </div> : <><p> No hay registro de visitas.</p></>} */}
          </TableCell>
        </TableRow>
        
      </TableBody>
    </Table>
  </Grid>


  </>
}
