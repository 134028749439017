import React, { useState, useEffect } from 'react';
import {
    useHistory,
} from "react-router-dom";
import TableCell from "@material-ui/core/TableCell";
import {useSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import {LinearProgress} from "@material-ui/core";
import moment from "moment";

import SurveyDetail from "./SurveyDetail";
import {
  getClientSurveys
} from "../../api/usersApi";
import BaseDialog from "../../components/Dialogs/BaseDialog";
import BaseTable from "./Users/ClientBaseTable";

const SurveysTable = () => {
    
    const [maxResults, setMaxResults] = useState(100)
    const [page, setPage] = useState(0)
    const [userQuery, setUserQuery] = useState('')

    const queryParams = {page: page + 1, maxResults, userQuery}

    // const { users, loading } = useUsers(queryParams)
    const [selectedSurveyId, setSelectedSurveyId] = useState(null)
    const [survey, setSurvey] = useState(null);
    const [surveys, setSurveys] = useState([])
    const [loading, setLoading] = useState(true)
    const userType = localStorage.getItem('userType')
    const [total, setTotal] = useState(0)
    const history = useHistory();
    
    const { enqueueSnackbar } = useSnackbar()
    
    const getSurveyList = () => {
        getClientSurveys(page, maxResults, userQuery).then((response) => {
            setSurveys(response.data.rows)
            setTotal(response.data.total)
            setLoading(false)
        })
    }

    const HandleUserQuery = (value) => {
        setUserQuery(value);
    }


    useEffect(() => {
        getSurveyList();
    }, [page, maxResults, userQuery])

    const headCells = [
        { id: 'action', numeric: false, disablePadding: false, label: '' },
        { id: 'client_name', numeric: false, disablePadding: false, label: 'Nombre' },
        { id: 'rut', numeric: false, disablePadding: false, label: 'Rut' },
        { id: 'project', numeric: false, disablePadding: false, label: 'Proyecto' },
        { id: 'project_property', numeric: false, disablePadding: false, label: 'Propiedad' },
        { id: 'survey_type', numeric: false, disablePadding: false, label: 'Tipo Encuesta' },
        { id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
        { id: 'mark', numeric: false, disablePadding: false, label: 'Nota' },
    ];

    const rowComponents = [
      (row, isItemSelected, labelId) => (
          <TableCell align="left">
              <IconButton color="primary" onClick={e => {
                                        setSurvey(row);
                                        setSelectedSurveyId(row.id);
                                        }}>
                  <VisibilityIcon />
              </IconButton>
          </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.client_name }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.rut }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.project }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.project_property }
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.survey_type}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            <TableCell>
                { row.state}
            </TableCell>
        ),
        (row, isItemSelected, labelId) => (
            row.mark ? 
            <TableCell>
                {row.mark}
            </TableCell> :
            <TableCell>
                Sin nota
            </TableCell>
        ),
    ]

  if (loading) return <LinearProgress />;
  if (userType !== 'A'){
    history.push('/')
  }

    return (
      <>
          <BaseDialog
            // hideCloseButton
            open={Boolean(selectedSurveyId&&survey)}
            handleClose={() => {
                setSelectedSurveyId(null);
                setSurvey(null);
            }}
            handleSuccess={() => {
                setSelectedSurveyId(null);
                setSurvey(null);
            }}
            title="Detalles de encuesta"
            successButtonText="Cerrar"
            size="lg"
            tooltipText="Se detalla la información de la encuesta seleccionada."
          >
              {survey && <SurveyDetail
                  survey={survey}
                  onClose={() => {
                    setSelectedSurveyId(null);
                    setSurvey(null);
                  }}
                />
              }
          </BaseDialog>
        <BaseTable
            title="Lista de Encuestas"
            headers={headCells}
            rows={surveys}
            maxResults={maxResults}
            changeMaxResults={setMaxResults}
            page={page}
            changePage={setPage}
            total={total}
            rowComponents={rowComponents}
            setUserQuery={HandleUserQuery}
        />
        </>
    );
};

SurveysTable.propTypes = {

};

export default SurveysTable;
