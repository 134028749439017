import React, { useEffect, useState} from 'react';
import queryString from 'query-string'
import { useParams, useHistory, useLocation } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper"
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import {
    setNewPassword,
    validateIDandToken,
} from "../../api/authenticationApi";
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import PasswordInput from "../../components/Inputs/PasswordInput"

const PasswordReset = () => {
    const { id } = useParams();
    const { search } = useLocation();
    const values = queryString.parse(search)
    const { token } = values
    const [linkLoaded, setLinkLoaded] = useState(false)
    const [valid, setValid] = useState(false)

    useEffect(() => {
        validateIDandToken(token, id).then((response) => {
            if (response.data.error){
                setValid(false)
            } else{
                setValid(true)
            }
            setLinkLoaded(true);
        })
    }, [token, id])

    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [confirmed, setConfirmed] = useState(false)
    const [error, setError] = useState(false)
    const history = useHistory()

    const resetPassword = () => {
        setNewPassword(token,id,password).then((response) => {
            if (response.data.error){
                setError(true)
            } else {
                setError(false)
                setTimeout( () => history.push('/'), 1500)
            }
            setConfirmed(true)
        })
    }

    document.title = "Magal PSEE | Resetear Contraseña "

    if (!linkLoaded) {
        return ( 
            <Box 
                textAlign="center" 
                direction="column"
                alignItems="center"
                mt={3}
                xs={6}
                style={{ paddingTop: '10%'}}
            >
                <Paper
                    style={{
                        padding: 15,
                        justifyContent: 'center'
                    }}
                    elevation={3}
                    xs={12}
                    
                >
                    <Grid item xs={12}>
                        <Typography variant="h6" color="textPrimary">Se está redirigiendo al enlace ingresado.</Typography>
                    </Grid>
                </Paper>
            </Box>
        )
    }

    return (
        <Box 
            textAlign="center" 
            direction="column"
            alignItems="center"
            mt={3}
            xs={6}
            style={{ paddingTop: '10%'}}
        >  
            <Paper
                style={{
                    padding: 15,
                    justifyContent: 'center'
                }}
                elevation={3}
                xs={12}
                
            >
                {valid ? <><Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">Reestablecer contraseña</Typography>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        item
                        container
                        xs={6}                        
                    >
                        <PasswordInput
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value)
                            }}
                            id='password-input'
                            label="Contraseña"
                            placeholder="Ingresa tu contraseña"
                        />
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center'
                    }}
                >
                    <Grid
                        item
                        container
                        xs={6}                        
                    >
                        <PasswordInput
                            value={passwordRepeat}
                            onChange={(event) => {
                                setPasswordRepeat(event.target.value)
                            }}
                            id='password-repeat-input'
                            label="Repetir Contraseña"
                            placeholder="Repite la contraseña"
                        />
                    </Grid>
                </Grid>
            
                <Grid 
                    item
                    spacing={3}
                    xs={12}
                    style={{
                        justifyContent: 'center',
                        paddingTop: '1%',
                    }} md="auto"
                >
                    <SuccessButtons
                    icon={null}
                    onClickHandler={() => {
                        resetPassword()
                    }}
                    text="Resetear Contraseña"
                    disabled={password === '' || passwordRepeat === '' || (password !== passwordRepeat)}
                    />
                </Grid>
                {(confirmed && !error) ? 
                    <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">La contraseña ha sido reseteada exitosamente.</Typography>
                    </Grid> : <></>
                }
                {(confirmed && error) ? 
                    <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">No se pudo resetear la contraseña.</Typography>
                    </Grid> : <></>
                }</> : 
                <Grid item xs={12}>
                    <Typography variant="h6" color="textPrimary">El enlace no es válido o ha expirado.</Typography>
                </Grid>
                }
            </Paper>
        </Box> 
    );
};

export default PasswordReset;