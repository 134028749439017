import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import React, {useEffect, useState} from "react";
import { makeStyles } from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Divider from "@material-ui/core/Divider";
import {LinearProgress} from "@material-ui/core";
import moment from "moment";
import { BarChart, Bar, Text, PieChart, Title, Pie, Rectangle, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Cell } from 'recharts';
import 'moment/src/locale/es'
// import DatePicker from "react-datepicker";
import { DatePicker } from '@material-ui/pickers';
import SuccessButtons from "../../components/Buttons/SuccessButtons";
import SelectAutocompleteInput from "../../components/Inputs/SelectAutocompleteInput";
import {
    getStatistics
} from "../../api/statsApi";
import {
    getPropertyTypes
} from "../../api/projectsApi";
import {
    getFaultZones,
    getFaultTargets
} from "../../api/faultsApi";
import 'react-lightbox-component/build/css/index.css';

export default function StatisticsTable(){

    moment.locale('es');
    const [stData, setStData] = useState([])
    const [stOption, setStOption] = useState({name : 'Tiempo promedio en resolver un problema', value: 0})
    const [fromDate, setFromDate] = useState(new Date('2023-09-01T00:00:00.000Z'))
    const [toDate, setToDate] = useState(new Date())

    const [propertyType, setPropertyType] = useState(null)
    const [faultZone, setFaultZone] = useState(null)
    const [faultSurface, setFaultSurface] = useState(null)

    const [macroZone, setMacroZone] = useState([])
    const [faultZones, setFaultZones] = useState([])
    const [faultSurfaces, setFaultSurfaces] = useState([])

    const [currentKey, setCurrentKey] = useState("dias")

  const [statisticsOptions, setStatisticsOptions] = useState([
    {name : 'Tiempo promedio en resolver un problema', value: 0},
    {name : 'Tiempo promedio en agendar una visita', value: 1},
    {name : 'Cantidad de veces que se reporta un problema', value: 2},
    {name : 'Tipos de departamentos que presentan más problemas', value: 3},
    {name : 'Información general de Requerimientos', value: 4},
    {name : 'Información general de Visitas', value: 5},
    {name : 'Información general de Encuestas', value: 6},
  ])
  const [loading, setLoading] = useState(true)

  const [value, setValue] = useState(1)

  const COLORS = ["#8884d8", "#82ca9d", "#FFBB28", "#FF8042", "#AF19FF"];

  const CustomXAxisTick = ({ x, y, payload }) => {
    if (payload && payload.value) {
      return (
        <Text
            fontSize="12px"
            width="12px"
            x={x} 
            y={y} 
            textAnchor="middle" 
            verticalAnchor="start"
        >{payload.value}</Text>
      );
    }
    return null;
};
 

  const getStats = () => {
    const begin = moment(fromDate).format('YYYY-MM-DD');
    const end = (moment(toDate).format('YYYY-MM-DD'));
    getStatistics({
        option: stOption.value, 
        begin, 
        end,
        propertyType,
        faultZone,
        faultSurface
    }).then((response) => {
        setStData(response.data[0])
        console.log(response.data)
        if (response.data[0].results && response.data[0].results.length > 0){
            if (response.data[0].results.length > 0){
                setCurrentKey(Object.keys(response.data[0].results[0])[1])
            }
        }
        // response.data[0].results.map((entry, index) => (
        //     console.log(entry)
        // ))
        setLoading(false)
    })
  }

  const searchFaultZones = (ppType) => {
    getFaultZones(ppType).then((response) => {
        setFaultZones(response.data)
    })
  }

  const searchFaultSurfaces = (zoneId) => {
    getFaultTargets(zoneId).then((response) => {
        setFaultSurfaces(response.data)
    })
  }

  const currentChart = (param) => {
    switch(param) {
        case 1:
            return <BarChart 
                width={1200} 
                height={500} 
                data={stData.results}
                margin={{
                    top: 5,
                    right: 20,
                    bottom: 5,
                    left: 10
                }}
                barSize={20}
            >
                <text x={1200 / 2} y={20} fill="#5b69ae" textAnchor="middle" dominantBaseline="central">
                    <tspan fontSize="22">{stOption.name} - {moment(fromDate).format('DD/MM/YYYY')} a {moment(toDate).format('DD/MM/YYYY')}</tspan>
                </text>
                <CartesianGrid strokeDasharray="5 5" />
                <XAxis 
                    dataKey="nombre" 
                    scale="point" 
                    // angle={30}
                    axisLine={false}
                    // padding={{left: 12, right: 12}} 
                    dx={10}
                    dy={15}
                    minTickGap={-150}
                    interval={0}
                    tick={<CustomXAxisTick/>}
                    // tick={{
                    //     fontSize: 6
                    // }}
                />
                <YAxis />
                <Tooltip/>
                <Legend wrapperStyle={{ position: 'relative' }} />
                <Bar dataKey={currentKey} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
            </BarChart>

        case 2:
            return <LineChart 
                    width={1200} 
                    height={500} 
                    data={stData.results}
                    margin={{
                        top: 5,
                        right: 20,
                        bottom: 5,
                        left: 10
                    }}
                >
                    <text x={1200 / 2} y={20} fill="#5b69ae" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="22">{stOption.name} - {moment(fromDate).format('DD/MM/YYYY')} a {moment(toDate).format('DD/MM/YYYY')}</tspan>
                    </text>
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis 
                        dataKey="nombre" 
                        scale="point" 
                        // angle={30}
                        axisLine={false}
                        // padding={{left: 12, right: 12}} 
                        dx={10}
                        dy={15}
                        minTickGap={-150}
                        interval={0}
                        tick={<CustomXAxisTick/>}
                        // tick={{
                        //     fontSize: 6
                        // }}
                    />
                    <YAxis />
                    <Tooltip/>
                    <Legend wrapperStyle={{ position: 'relative' }} />
                    <Line type="monotone" dataKey={currentKey} stroke="#8884d8" />
                </LineChart>
        
        case 3:
            return <PieChart 
                    width={1200} 
                    height={500} 
                    // data={stData.results}
                    margin={{
                        top: 5,
                        right: 20,
                        bottom: 5,
                        left: 10
                    }}
                >
                    <text x={1200 / 2} y={20} fill="#5b69ae" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="22">{stOption.name} - {moment(fromDate).format('DD/MM/YYYY')} a {moment(toDate).format('DD/MM/YYYY')}</tspan>
                    </text>
                    <Pie
                        dataKey={currentKey}
                        nameKey="nombre"
                        isAnimationActive="true"
                        labelLine="true"
                        data={stData.results}
                        cx="50%"
                        cy="50%"
                        outerRadius={120}
                        fill="#8884d8"    
                    >
                        {stData.results.map((entry, index) => (
                            <Cell
                                key={`cell-${entry.nombre}`}
                                fill={COLORS[index % COLORS.length]}
                            />
                        ))}
                    </Pie>           
                    <Tooltip/>
                    <Legend wrapperStyle={{ position: 'relative' }}/>
                </PieChart>

        default:
            return <BarChart 
                    width={1200} 
                    height={500} 
                    data={stData.results}
                    margin={{
                        top: 5,
                        right: 20,
                        bottom: 5,
                        left: 10
                    }}
                    barSize={20}
                >
                    <text x={1200 / 2} y={20} fill="#5b69ae" textAnchor="middle" dominantBaseline="central">
                        <tspan fontSize="22">{stOption.name} - {moment(fromDate).format('DD/MM/YYYY')} a {moment(toDate).format('DD/MM/YYYY')}</tspan>
                    </text>
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis 
                        dataKey="nombre" 
                        scale="point" 
                        // angle={30}
                        axisLine={false}
                        // padding={{left: 12, right: 12}} 
                        dx={10}
                        dy={15}
                        minTickGap={-150}
                        interval={0}
                        tick={<CustomXAxisTick/>}
                        // tick={{
                        //     fontSize: 6
                        // }}
                    />
                    <Tooltip/>
                    <Legend wrapperStyle={{ position: 'relative' }} />
                    <Bar dataKey={currentKey} fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
                </BarChart>
    }
  }


  useEffect(() => {
    let ignore = false;
    
    if (!ignore){
        getStats();
        getPropertyTypes().then((response) => {
            if (response.data !== macroZone) {
                setMacroZone(response.data)
            }
        })
        // getFaultZones(propertyType).then((response) => {
        //     this.setState({faultZones: response.data})
        // })
    }        
    return () => { ignore = true; }
    },[]);

    const useStyles = makeStyles((theme) => ({
        root: {
            width: '100%',
        },
        paper: {
            width: '100%',
            marginBottom: theme.spacing(2),
        },
        subpaper: {
            // width: '80%',
            justifyContent: 'center',
            marginLeft: '5%',
            marginRight: '5%',
            marginTop: '5%',
            marginBottom: theme.spacing(2),
        },
        table: {
            minWidth: 750,
        },
        container: {
            maxHeight: '70vh'
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
    }));
    const classes = useStyles();

    if (loading) return <LinearProgress />;


  return <>
      <Grid 
            container 
            spacing={3} 
            style={{
                paddingLeft: '2%',
                paddingTop: '2%'
            }}
        >
            <Grid item xs={12} md={4}>
                <Paper className={classes.paper} >
                    <TableContainer className={classes.container}>
                    <Grid 
                        item xs={12}
                        mb={4}
                    >
                        <Table>
                        <TableBody >
                            <TableRow>
                                <TableCell>
                                    <Typography 
                                        variant="body2" 
                                        color="textPrimary" 
                                        colSpan={6}
                                    >
                                        Información General en el período seleccionado
                                    </Typography>
                                </TableCell>
                            </TableRow>

                            <Paper className={classes.subpaper} >
                                <Table>
                                <TableBody >
                                    <TableRow >
                                        <TableCell>
                                            <Typography variant="body2" color="textPrimary">
                                                Nuevos Requerimientos : {stData ? stData.ticket_count : 'Sin registro'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                    <TableRow colSpan='100%'>
                                        <TableCell>
                                            <Typography variant="body2" color="textPrimary" colSpan="100%">
                                                Visitas Calendarizadas : {stData ? stData.visit_count : 'Sin registro'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                    <TableRow colSpan='100%'>
                                        <TableCell>
                                            <Typography variant="body2" color="textPrimary" colSpan="100%">
                                                Encuestas creadas : {stData ? stData.survey_count : 'Sin registro'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                    </TableRow>
                                </TableBody>
                                </Table>
                            </Paper>

                        </TableBody>
                        </Table>
                    </Grid>
                    </TableContainer>
                </Paper>
            </Grid>
            <Grid 
                item
                xs={12} 
                md={8} 
                style={{
                    justifyContent: 'center',
                    paddingTop: '1%',
                    paddingBottom: '3%',
                }}
            >
                <Paper 
                    style={{
                        justifyContent: 'center',
                    }}
                
                >
                    <Grid
                        container
                        style={{
                            justifyContent: 'center',
                            paddingTop: '3%',
                            paddingBottom: '3%',
                        }}
                    >
                        <Typography 
                            variant="body2" 
                            color="textPrimary" 
                            colSpan={6}
                            style={{
                                justifyContent: 'center'
                            }}
                        >
                            Seleccionar datos
                        </Typography>
                        <Grid
                            item
                            xs={12}
                            style={{
                                marginLeft: '2%',
                                marginRight: '2%'
                            }}
                        
                        >
                            <SelectAutocompleteInput
                                value={stOption}
                                options={statisticsOptions}
                                disabled={false}
                                multiple={false}
                                handleChange={(event, newValue) => {
                                    if (stOption !== newValue){
                                        getStats()
                                    }
                                    setStOption(newValue)
                                }}
                                renderOptionFunction={(option) => {
                                    return option.name
                                }}
                                optionLabelFunction={(option) => {
                                    return option.name
                                }}
                                disableCloseOnSelect={false}
                                inputLabel="Estadistica"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        style={{
                            justifyContent: 'center',
                            paddingTop: '3%',
                            paddingBottom: '3%',
                        }}
                    >
                        <DatePicker 
                            label="Desde"
                            format="yyyy-MM-DD"
                            value={fromDate}
                            onChange={(e) => setFromDate(e)}
                        />
                        <DatePicker 
                            label="Hasta" 
                            format="yyyy-MM-DD"
                            value={toDate}
                            onChange={(newValue) => setToDate(newValue)}
                        />

                        <SuccessButtons
                            icon={null}
                            onClickHandler={() => {
                                getStats();
                            }}
                            text="Actualizar"
                            />
                    </Grid>
                    {stOption && (stOption.value === 0 || stOption.value === 2) ?
                        <Grid
                            container
                            style={{
                                justifyContent: 'center',
                                paddingTop: '3%',
                                paddingBottom: '3%',
                            }}
                        >
                            <Grid
                                item
                                xs={3}
                            >
                                <SelectAutocompleteInput
                                    value={propertyType}
                                    options={macroZone}
                                    disabled={false}
                                    multiple={false}
                                    handleChange={(event, newValue) => {
                                        setPropertyType(newValue);
                                        setFaultZone('');
                                        searchFaultZones(newValue.code);
                                    }}
                                    renderOptionFunction={(option) => {
                                        return option.label
                                    }}
                                    optionLabelFunction={(option) => {
                                        return option.label
                                    }}
                                    disableCloseOnSelect={false}
                                    inputLabel="Tipo de Propiedad"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <SelectAutocompleteInput
                                    value={faultZone}
                                    options={faultZones}
                                    disabled={false}
                                    multiple={false}
                                    handleChange={(event, newValue) => {
                                        setFaultZone(newValue);
                                        setFaultSurface('');
                                        setFaultSurfaces([]);
                                        searchFaultSurfaces(newValue.id);
                                    }}
                                    renderOptionFunction={(option) => {
                                        return option.name
                                    }}
                                    optionLabelFunction={(option) => {
                                        return option.name
                                    }}
                                    disableCloseOnSelect={false}
                                    inputLabel="Zona afectada"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={3}
                            >
                                <SelectAutocompleteInput
                                    value={faultSurface}
                                    options={faultSurfaces}
                                    disabled={false}
                                    multiple={false}
                                    handleChange={(event, newValue) => {
                                        setFaultSurface(newValue);                                        
                                    }}
                                    renderOptionFunction={(option) => {
                                        return option.name
                                    }}
                                    optionLabelFunction={(option) => {
                                        return option.name
                                    }}
                                    disableCloseOnSelect={false}
                                    inputLabel="Superficie afectada"
                                />
                            </Grid>
                        </Grid>    
                    : <></>}

                    <Grid
                        container
                        style={{
                            justifyContent: 'left',
                            paddingLeft: 2
                        }}
                    >
                        <Grid item style={{paddingLeft: 3, paddingRight: 3}}>
                            <Typography 
                                variant="body1" 
                                color="textPrimary" 
                            >
                                Tipo de gráfico : 
                            </Typography>
                        </Grid>
                        <Grid 
                            item 
                            style={{
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 5
                            }}
                        >
                            <SuccessButtons
                                icon={null}
                                onClickHandler={() => setValue(1)}
                                text="De barras"
                                size="medium"
                            />
                        </Grid>
                        <Grid 
                            item 
                            style={{
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 5
                            }}
                        >
                            <SuccessButtons
                                icon={null}
                                onClickHandler={() => setValue(2)}
                                text="De línea"
                                size="medium"
                            />
                        </Grid>
                        <Grid 
                            item 
                            style={{
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 5
                            }}
                        >
                            <SuccessButtons
                                icon={null}
                                onClickHandler={() => setValue(3)}
                                text="Tarta"
                                size="medium"
                                
                            />
                        </Grid>
                    </Grid>
                
                    {/* <Divider /> */}
                </Paper>      
            </Grid>
        </Grid>
        {!loading && stData.results.length > 0 ? 
        <Grid
            container
            style={{justifyContent: 'left'}}        
        >
            <Grid
                style={{
                    paddingLeft: 5, 
                    paddingRight: 2,
                    paddingTop: 7,
                }}
            >
                <Typography 
                    variant="body2" 
                    color="textPrimary" 
                    colSpan={6}
                >
                    Valor a visualizar:
                </Typography>
            </Grid>
            {Object.keys(stData.results[0]).map((key, index) => (
                key !== 'nombre' ?
                <Grid
                    style={{paddingLeft: 2, paddingRight: 2}}
                >
                    <SuccessButtons
                        icon={null}
                        onClickHandler={() => setCurrentKey(key)}
                        text={key}
                        size="medium"
                    />
                </Grid> : <></>
            ))
            }
        </Grid> : <></>}
        {!loading && stData.results.length > 0 ? 
            <Grid
                item
                xs={12}   
                style={{
                    justifyContent: 'center'
                }}         
            >
                {currentChart(value)}
            </Grid>
            : 
            <Grid
                item
                xs={12}   
                style={{
                    justifyContent: 'center',
                    paddingLeft: 5
                }}         
            >
                <Typography 
                    variant="body2" 
                    color="textPrimary" 
                    colSpan={6}
                >
                    No hay datos para visualizar en el gráfico.
                </Typography>
            </Grid>}
      </>
}

StatisticsTable.propTypes = {
}

StatisticsTable.defaultProps = {
}